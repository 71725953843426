<template>
  <section class="section">
    <template v-if="!withReleased">
      <hr class="title-achivements-hr animate-elem shiftLeft wrap"/>
      <h2 class="h2animated">
        <!-- <hr class="animate-elem fadeInLeft"/> -->
        <span>Достижения</span>
      </h2>
      <router-link to="/about" class="buttonsAnimated">
        <animated-button
          :classes="['buttons-group__tag-filter']"
          :settings="{
            class: 'w2y',
            text: 'Больше о нас',
            color: 'dark',
            icon: false,
          }"
        />
      </router-link>
    </template>
    <div class="achivements-wrapper" :class="withReleased && 'zindexUp'">
      <hr class="achivements-hr animate-elem shiftLeft" v-if="!withReleased" />
      <!-- <img class="background" src="@/assets/images/achivements.svg" alt="" /> -->
      <div class="achivements-container">
        <div class="achivements-item achivements-item--age">
          <!-- ниже получаются данные с бэка, сейчас поставил на автоматическое вычисление -->
          <!-- <span class="count animate-elem fadeInUp">{{ years }}</span> -->
          <span class="count animate-elem fadeInUp">{{ new Date().getFullYear() - 2008 }}</span>
          <span class="animate-elem fadeInUp second">лет занимаемся внедрением<br /> корпоративных порталов</span>
        </div>
         <div v-if="iW <= 768" class="achivements-item achivements-item--sertificated">
          <span class="count animate-elem fadeInUp">{{ special }}</span>
          <span class="animate-elem fadeInUp second">сертифицированных специалистов</span>
          <hr class="animate-elem shiftLeft wrap"/>
        </div>
        <div v-if="iW > 768" class="achivements-documents">
          <div class="achivements-document">
            <img src="@/assets/images/ISO.svg" alt="ISO" class="animate-elem fadeInUp"/>
            <span class="animate-elem fadeInUp second">Сертифицированы по системам ITIL, ITSM, ISO</span>
            <hr class="animate-elem shiftLeft"/>
          </div>
          <div class="achivements-document">
            <img src="@/assets/images/patent.svg" alt="ISO" class="animate-elem fadeInUp"/>
            <span class="animate-elem fadeInUp second">Собственные запантентованные технологии в реестре российского
              ПО</span>
            <hr class="animate-elem shiftLeft"/>
          </div>
        </div>
      </div>
      <div class="achivements-container">
        <div v-if="iW > 768" class="achivements-item achivements-item--sertificated">
          <span class="count animate-elem fadeInUp">{{ special }}</span>
          <span class="animate-elem fadeInUp second">сертифицированных специалистов</span>
          <hr class="animate-elem shiftLeft wrap"/>
        </div>
        <div v-if="iW <= 768" class="achivements-documents">
          <div class="achivements-document">
            <img src="@/assets/images/ISO.svg" alt="ISO" class="animate-elem fadeInUp"/>
            <span class="animate-elem fadeInUp second">Сертифицированы по системам ITIL, ITSM, ISO</span>
            <hr class="animate-elem shiftLeft wrap"/>
          </div>
          <div class="achivements-document">
            <img src="@/assets/images/patent.svg" alt="ISO" class="animate-elem fadeInUp"/>
            <span class="animate-elem fadeInUp second">Собственные запантентованные технологии в реестре российского
              ПО</span>
            <hr class="animate-elem shiftLeft wrap"/>
          </div>
        </div>
        <div class="achivements-documents">
          <div class="achivements-document">
            <img src="@/assets/images/rosoez.svg" alt="ISO" class="animate-elem fadeInUp"/>
            <span class="animate-elem fadeInUp second">Резидент ОЭЗ Дубна</span>
            <hr class="animate-elem shiftLeft wrap"/>
          </div>
          <div class="achivements-document">
            <img src="@/assets/images/bitrix.svg" alt="ISO" class="animate-elem fadeInUp bitrix"/>
            <span class="animate-elem fadeInUp second">ККВ и золотой партнер <br />Битрикс24</span>
            <hr class="animate-elem shiftLeft wrap"/>
          </div>
        </div>
      </div>
    </div>
    <div v-if="withReleased" class="released-projects">
      <div class="released-projects__wrapper">
        <span class="released-projects__number animate-elem fadeInUp">{{ number }}</span>
        <div class="released-projects__below animate-elem fadeInUp">
          <span class="animate-elem fadeInUp">{{ proektcountdescription }}</span>
          <router-link to="/cases">
            <AnimatedButton
              :classes="['buttons-group__tag-filter animate-elem fadeInUp']"
              :settings="{ class: 'w2y', text: 'Наши кейсы', icon: true }"
            />
          </router-link>
        </div>
      </div>
      <hr class="animate-elem shiftLeft"/>
    </div>
  </section>
</template>
<script>
import AnimatedButton from "../../UI/AB.vue";
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  components: {
    AnimatedButton,
  },
  props: {
    withReleased: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      number: 0,
      years: 0,
      special: 0,
      proektcountdescription: "",
      iW: 0
    };
  },
  computed: mapGetters(["widthPage"]),
  created() {
    axios
      .get("/api/aboutus/we")
      .then((response) => {
        this.number = response.data.proektcount;
        this.years = response.data.yearwork;
        this.special = response.data.specialistcount;
        this.proektcountdescription = response.data.proektcountdescription;
      })
      .catch((error) => {
        console.log(error);
      });
    window.addEventListener("resize", this.myEventHandler);
  },
  mounted() {
      this.iW = window.innerWidth
  },
  destroyed() {
      window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    myEventHandler(e) {
        this.iW = e.target.innerWidth
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";

.title-achivements-hr{
  overflow: hidden;
  border-bottom: 1px solid var(--color1);
  margin: 0;
}

h2 {
  margin-bottom: 40px;
  width: 100%;
  @include mediumDesctop {
    font-size: 80px;
  }
  @include smallLaptop {
    font-size: 32px;
    margin-bottom: 24px;
  }
}

section {
  max-width: $mainMaxWidth;
  @include smallLaptop {
    padding-top: var(--paddingTopPhones);
  }

  @include mediumAlt {
    padding-top: 124px;
  }
}

a {
  text-decoration: none;
}
.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
  border-radius: 25px;
  .zindexUp & {
    z-index: 1;
  }
}
.achivements-wrapper {
  margin-top: 40px;
  position: relative;
  // background-size: 100%;
  min-height: 695px;
  padding-top: 24px;
  padding-bottom: 80px;
  padding-left: 72px;
  padding-right: 92px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  background: url("../../../assets/images/achivements.png") center / cover no-repeat;
  @include mediumDesctop {
    padding-left: 70px;
    padding-right: 70px;
  }
  @include smallLaptop {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
    min-height: 470px;
  }
  @include largePhones {
    // background-image: url("../../../assets/images/background_small.png");
  }
  @include largePhonesAlt {
    flex-direction: column;
    min-height: auto;
  }
  >.achivements-hr {
    position: absolute;
    width: calc(100% - 140px);

    @include smallLaptop {
      width: calc(100% - 20px);
    }
  }
}

.achivements-container {
  box-sizing: border-box;
  width: fit-content;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  z-index: 1;

  &:last-child {
    @include largePhonesAlt {
      margin-top: 60px;
      gap: 20px;

      .achivements-documents:last-child {
        margin: auto;

        img {
          max-width: 100%;
        }
      }
    }
  }
  @include largePhonesAlt {
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    margin-bottom: 0;
  }
  @include mediumPhones {
    flex-direction: column;
  }
  .achivements-item {
    width: 100%;
    height: 50%;
    // overflow: hidden;
    &--age {
      display: flex;
      margin-top: 26px;
      .count {
        font-size: 300px;
        line-height: 110%;
      }
      span {
        font-size: 18px;
        line-height: 140%;
        &:not(.count) {
          margin-top: 30px;
        }
      }
      @include mediumLaptop {
        .count {
          font-size: 200px;
        }
      }
      @include phones {
        .count {
          font-size: 150px;
        }
        span {
          &:not(.count) {
            margin-top: 10px;
            font-size: 15px;
          }
        }
      }
      @include largePhonesAlt {
        .count {
          font-size: 110px;
        }
      }
      @include mediumPhones {
        .count {
          font-size: 90px;
        }
        span {
          &:not(.count) {
            margin-top: 10px;
            font-size: 13px;
          }
        }
      }
    }
    &--sertificated {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 26px;
      padding-bottom: 16px;
      height: fit-content;
      .count {
        font-size: 180px;
        line-height: 110%;
      }
      span {
        font-size: 18px;
        line-height: 140%;
      }
      @include mediumLaptop {
        .count {
          font-size: 120px;
        }
      }
      @include phones {
        .count {
          font-size: 90px;
        }
        span {
          &:not(.count) {
            font-size: 15px;
          }
        }
      }
      @include largePhonesAlt {
        .count {
          font-size: 60px;
        }
      }
      @include mediumPhones {
        .count {
          font-size: 45px;
        }
        span {
          &:not(.count) {
            font-size: 13px;
          }
        }
      }
    }
  }
  .achivements-documents {
    width: 100%;
    display: flex;
    height: 50%;
    @include smallLaptop {
      justify-content: space-between;
    }

    &:first-child {
      margin-bottom: 50px;
      @include largePhonesAlt {
        margin-bottom: 0;
      }
    }
  }
  .achivements-document {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    min-width: 142px;
    img {
      max-width: 149px;
      &.bitrix {
        background-color: #fff;
        height: 65px;
        width: 100%;
        border-radius: 4px;
      }
    }
    &:first-child {
      margin-right: 25px;
    }
    span {
      min-height: 86px;
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 140%;
    }

    @include laptop {
      img {
        max-width: 118px;
      }
      span {
        height: 56px;
        font-size: 16px;
      }
    }
    @include phones {
      img {
        max-width: 94px;
      }
      span {
        height: 50px;
        font-size: 13px;
      }
    }
    @include mediumPhones {
      img {
        max-width: 40vw;
      }
    }
    @include mediumPhones {
      span {
        height: 80px;
      }
    }
  }
}
.released-projects {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  hr {
    position: absolute;
    width: 100%;
    bottom: 0;
    border-top: 1px solid var(--color1);
    margin: 0;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: max-content;
  }
  &__number {
    font-size: 300px;
    background: linear-gradient(
      107.8deg,
      #ffd200 11%,
      #e62315 37.86%,
      #462dc7 78.98%,
      #001b81 91.57%
    );
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &__below {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    > span {
      display: flex;
      max-width: 392px;
      font-size: 24px;
      color: var(--color1);
    }
  }
  .aboutAchievements & {
    padding-bottom: 160px;
    // border-bottom: 1px solid var(--color1);
  }
  @include smallLaptop {
    &__number {
      font-size: 260px;
    }
  }
  @include largePhonesAlt {
    .aboutAchievements & {
      padding-bottom: 30px;
    }
    &__wrapper {
      display: flex;
    }
    &__number {
      align-self: center;
      font-size: 210px;
    }
    &__below {
      flex-direction: column;
      > span {
        max-width: 100%;
        margin-bottom: 16px;
      }
    }
  }
  @include mediumPhones {
    &__number {
      font-size: 35vw;
    }
  }
}
</style>
