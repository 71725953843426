import Vue from 'vue';
import Vuex from 'vuex';
import mainStore from './mainStore';
import productsStore from './productsStore';
import serviceStore from './serviceStore';
import searchStore from './searchStore';
import scrollContainer from './scrollContainer';
import calculatorStore from './calculatorStore';
import animation from './animation';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    mainStore,
    productsStore,
    serviceStore,
    searchStore,
    scrollContainer,
    calculatorStore,
    animation
  },
});
