<template>
  <tr class="table-item">
    <td class="title td">{{ mainTitle }}</td>
    <td class="box-user td">
      <ul class="portal-options">
        <li v-for="(item,i) in this.info" :key="i">
          <span v-if="active" :class="{ active: active.firstTd==item.firstTd }" @click="optionSelect(item)">{{item.firstTd}}</span>
        </li>
      </ul>
    </td>
    <td class="no-wrap box-price td" v-if="active">{{ active.secondTd }}</td>
    <td class="box-cta td">
      <button class="button" @click="toggleModal(active, 'portal')">
        <span>Смотреть условия</span>
        <div class="button__icon"></div>
        <Popup v-if="showPopup" @closePopup="closePopup" :title="'Актуальные цены для '+mainTitle">
          <table class="popup-top-table">
            <thead>
              <tr>
                <td v-if="active.users"><span>Пользователей</span></td>
                <td v-if="active.popupData.prolongationRate"><span>Стоимость продления</span></td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-if="active.users">{{ active.popupData.users }}</td>
                <td v-if="active.popupData.prolongationRate">{{ active.popupData.prolongationRate }}</td>
                <td v-if="active.popupData.description"> {{active.popupData.description}}</td>
              </tr>
            </tbody>
          </table>

          <table class="popup-bottom-table" v-if="active.popupData.transfer">
            <thead>
              <tr>
                <td><span>Переход на другую лицензию</span></td>
                <td><span>Стоимость</span></td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(dataObj, index) in active.popupData.transfer" :key="index">
                <td>{{dataObj[0]}}</td>
                <td>{{dataObj[1]}}</td>
              </tr>
            </tbody>
          </table>
        </Popup>
      </button>
    </td>
  </tr>
</template>

<script>
  import Popup from "../../../UI/popup.vue";
  import eventHub from '../../../eventHub'
  export default {
    props: {
      info: null
    },
    components: {
      Popup,
    },
    data() {
      return {
        showPopup: false,
        active: null,
        quoteModal: {visible: false}
      };
    },
    methods: {
      toggleModal(data, type) {
        this.quoteModal.visible = true
        data.mainTitle = this.mainTitle
        eventHub.$emit('emitModal', {content: data, quoteModal: this.quoteModal, modalType: type})
      },
      popupShow() {
        this.showPopup = true;
      },
      closePopup() {
        this.showPopup = false;
      },
      optionSelect(active) {
        this.active = active
      },
    },
    mounted() {
      for(let item in this.info) {
        this.active = this.info[item]
        return
      }
    },
    computed: {
      portalPrice() {
        return this.priceList[this.portalOption];
      },
      mainTitle() {
        for(let item in this.info) {
          return this.info[item].title
        }
        return ''
      }
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/styles/table.scss";
  @import "@/assets/styles/popup.scss";

  .section__wrapper_license {
    .box-rate-table {
      .table-wrapper {
        .table-item {
          @include largePhonesAlt {
            padding: 16px;
          }
          td {
            @include largePhonesAlt {
              padding: 0;
            }
            &::before {
              @include largePhonesAlt {
                font-size: 8px;
                padding: 4px 8px;
              }
            }
            &.title {
              @include largePhonesAlt {
                margin: 0;
                margin-bottom: 16px;
              }
            }
            &.box-price,
            &.box-user {
              @include largePhonesAlt {
                padding: 0;
                margin: 0;
                padding-top: 13px;
                margin-top: 8px;
              }
            }

            &.box-price {
              @include largePhonesAlt {
                margin-left: auto;
              }
            }
            &.box-cta {
              @include largePhonesAlt {
                padding: 0;
                margin: 0;
                padding-top: 13px;
                margin-top: 20px;
              }
            }
          }
        }
      }
    }
  }
</style>