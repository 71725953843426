<template>
    <div ref="myElement" class="splide">
        <hr v-if="type !== 'photos'" class="animate-elem shiftLeft wrap"/>
        <div class="splide__arrows">
            <h2 class="h2animated animate-elem shiftUp" v-if="h2text && type !== 'photos'" :class="{'h2-certificates': type === 'certficates', 'h2-reviews': type === 'reviews', 'smallh2': type === 'carAchivements', 'h2-simillar-news': type === 'news-alike'}"><span>{{ h2text }}</span></h2>
            <div v-if="type === 'photos' && iW > 768" class="how-works-text animate-elem fadeInUp" v-html="carousel.items[kindaCurrent-1].description"></div>
            <button class="splide__arrow splide__arrow--prev animate-elem fadeInRight">
                <div class="splide__arrow__scewed"></div>
                <span></span>
            </button>
            <button class="splide__arrow splide__arrow--next animate-elem fadeInRight">
                <div class="splide__arrow__scewed"></div>
                <span></span>
            </button>
        </div>
        <div class="splide__slider" :class="{'splide__slider__simillar-news': type === 'news-alike'}" :style="(type === 'reviews' || type === 'certficates' || type === 'teamAdaptive' || type === 'carAchivements') && {marginRight: '-20px'}">
            <div class="splide__track">
                <ul v-if="type === 'reviews'" class="splide__list">
                    <li v-for="(card, index) in carousel.items" :key="index" class="splide__slide splide__slide__about">
                        <div class="splide__slide__about__photo">
                            <span class="splide__slide__about__photo__decor-top animate-elem fadeInUp"></span>
                            <img :src="'/storage/'+card.image" alt="" class="splide__slide__about__photo__img animate-elem shiftUp wrap">
                        </div>
                        <div class="splide__slide__about__comment">
                            <span class="splide__slide__about__comment__text  animate-elem fadeInUp">{{ card.description }}</span>
                            <div class="splide__slide__about__comment__person">
                                <span class="splide__slide__about__comment__person__name  animate-elem fadeInUp">{{ card.name }}</span>
                                <span class="splide__slide__about__comment__person__position  animate-elem fadeInUp">{{ card.position }}</span>
                            </div>
                            <span class="splide__slide__about__comment__decor-bottom animate-elem fadeInUp"></span>
                        </div>
                    </li>
                </ul>
                <ul v-if="type === 'certficates'" class="splide__list">
                    <li v-for="(certififcate, index) in carousel.value" :key="index" class="splide__slide splide__slide__certififcate">
                        <div class="splide__slide__certififcate__container animate-elem shiftUp wrap">
                            <img :src="'/storage/'+certififcate" alt="" class="splide__slide__certififcate__image">
                            <div class="splide__slide__certififcate__cover">
                                <div></div>
                            </div>
                        </div>
                    </li>
                </ul>
                <ul v-if="type === 'photos'" class="splide__list">
                    <li v-for="(photo, index) in carousel.items" :key="index" class="splide__slide splide__slide__photo">
                        <div class="splide__slide__photo__container animate-elem fadeInUp">
                            <img :src="'/storage/'+photo.image" alt="" class="splide__slide__photo__image">
                        </div>
                    </li>
                </ul>
                <ul v-if="type === 'car'" class="splide__list">
                    <li v-for="(photo, index) in carousel.items" :key="index" class="splide__slide splide__slide__photo">
                        <div class="team__person__photo">
                            <img :src="'/storage/'+photo.image" alt="">
                        </div>
                        <div class="team__person__name">{{ photo.name }}</div>
                        <button class="team__person__button"></button>
                    </li>
                </ul>
                <ul v-if="type === 'teamAdaptive'" class="splide__list splide__list__team-adaptive">
                    <li v-for="(item, index) in carousel" :key="index" class="splide__slide splide__slide__photo">
                        <div class="team__person__photo animate-elem shiftUp wrap">
                            <img :src="'/storage/'+item.image" alt="">
                        </div>
                        <div class="team__person__name animate-elem fadeInUp">{{ item.name }}</div>
                        <div class="team__person__position animate-elem fadeInUp">{{ item.position }}</div>
                        <button class="team__person__button animate-elem fadeInUp" @click.prevent="toggleModalWindow('quotes')"></button>
                    </li>
                </ul>
                <ul v-if="type === 'carAchivements'" class="splide__list">
                    <li v-for="(photo, index) in carousel.items" :key="index" class="splide__slide splide__slide__photo">
                        <div class="team__person__photo animate-elem shiftUp wrap">
                            <img :src="'/storage/'+photo.image" alt="">
                        </div>
                        <div class="team__person__name animate-elem fadeInUp">{{ photo.name }}</div>
                        <button class="team__person__button animate-elem fadeInUp" @click.prevent="toggleModalWindow('history')"></button>
                    </li>
                </ul>
                <ul v-if="type === 'news-alike'" class="splide__list">
                    <li v-for="(news, index) in carousel" :key="index" class="splide__slide splide__slide__simillar-news">
                        <div class="splide__slide__simillar-news__info">
                            <div class="splide__slide__simillar-news__info__type">
                                <span>{{news.type}}</span>
                            </div>
                            <div class="splide__slide__simillar-news__info__date">{{news.publication_date}}</div>
                        </div>
                        <router-link :to="news.link" v-slot="{ href, route, navigate }" custom >
                            <a :href="href" :route="route" :navigate="navigate" class="splide__slide__simillar-news__name">
                                <span>{{news.name}}</span>
                            </a>
                        </router-link>
                        <div class="splide__slide__simillar-news__tag">
                            <span>{{news.tag}}</span>
                        </div>
                    </li>
                </ul>
                <ul v-if="type === 'service-cases'" class="splide__list">
                    <li v-for="(photo, index) in carousel.items" :key="index" class="splide__slide splide__slide__photo">
                        <div class="team__person__photo">
                            <img :src="photo.img" alt="">
                        </div>
                    </li>
                </ul>
            </div>
            <div v-if="showNumbers" class="splide__numbers animate-elem fadeInRight">
                <div class="splide__number__current">{{kindaCurrent}}</div>
                <div class="splide__number__total">/{{kindaPagin}}</div>
            </div>
        </div>
        <div v-if="type === 'photos' && iW <= 768" class="how-works-text animate-elem fadeInLeft" v-html="carousel.items[kindaCurrent-1].description"></div>
        <!-- <Lightbox :lightboxSettings="carousel.value" :lbx="lbx" /> -->
    </div>
</template>

<script>
import eventHub from '../../eventHub'
import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide-core.min.css';
// import Lightbox from './lightbox.vue'


export default {
    name: 'CarouselInside',
    // components: {
    //     Lightbox
    // },
    props: {
        carousel: null,
        settings: null,
        type: String,
        quoteModal: Object,
        h2text: String,
        iW: Number
    },
    data() {
        return {
            newsplide: null,
            kindaPagin: 0,
            kindaCurrent: 1,
            showNumbers: false,
            lbx: {
                index: undefined,
                visible: false
            },
            newSettings: {
                autoWidth: false,
                autoplay: false,
                gap: "10px",
                pagination: false,
                perMove: 1,
                perPage: 1,
                type: 'loop',
                flickPower: 300
            },
            breakpointsSettings: {
                768: {
                    autoWidth: false,
                    fixedWidth: '40vw',
                    flickPower: 300
                },
                414: {
                    perPage: 2,
                    fixedWidth: '73vw',
                    flickPower: 150
                }
            },
            extraSettings: {

            }
        }
    },
    mounted() {
        this.carLength = this.carousel.length
        this.splideSetuping()
        this.hasClass()
        window.addEventListener('resize', this.hasClass)
        if(this.carousel && this.carousel.items && Array.isArray(this.carousel.items)) {
            const regExp = new RegExp('(<a.+)(href=")(?!' + window.location.origin + ')', 'gm')
            this.carousel.items = this.carousel.items.map((item) => {
                // eslint-disable-next-line
                item.description = item.description.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
                return item
            })
        }
    },
    updated(){
        this.$nextTick(function(){
            if (this.carLength !== this.carousel.length) {
                this.newsplide.destroy(true)
                this.newsplide.mount()
            }
        })
    },
    methods: {
        toggleModalWindow(type) {
            eventHub.$emit('emitModal', {content: type === 'quotes' ? this.carousel : this.carousel.items, modalType: type, quoteModal: this.quoteModal})
        },
        toggleLightboxWindow() {
            eventHub.$emit('emitLightbox', {lbx: this.lbx, lightboxSettings: this.carousel.value})
        },
        hasClass() {
            const element = this.$refs.myElement
            if (!element) return
            if (element.classList.contains('office-photos__carousel')) {
                this.showNumbers = true
                // this.$el.querySelector('anotherelement').classList.add("your-another-class");
            } else {
                this.showNumbers = false
            }
            if (this.type === 'photos' && this.iW <= 768) {
                element.classList.add('splide__arrows__visible_small')
            } else {
                element.classList.remove('splide__arrows__visible_small')
            }
        },
        splideSetuping() {
            this.$nextTick(function () {
                // const newsplide = new Splide(this.$el, {fixedWidth: '320px', ...this.settings, autoWidth: false})
                this.newsplide = new Splide(this.$el, {...this.settings, flickPower: 300, breakpoints: this.type === 'teamAdaptive' ? this.breakpointsSettings : false})
                this.newsplide.on('move', (data) => {
                    this.kindaCurrent = data + 1
                })
                this.newsplide.on('mounted', () => {
                    this.kindaPagin = this.newsplide._Components.Elements.slides.length
                    this.kindaCurrent = this.newsplide._Components.Elements.slides.findIndex(i => {
                        return i.classList.contains('is-active')
                    }) + 1
                })
                this.newsplide.on('click', (data,e) => {
                    if (e.target && e.target.classList.contains('team__person__button')) {
                        let personIndex = 0
                        if (data.slideIndex < 0) {
                            personIndex = data.index
                        } else {
                            personIndex = data.slideIndex
                        }
                        this.quoteModal.visible = true
                        this.quoteModal.personId = personIndex
                        // document.body.style.overflow = 'hidden'
                    }
                    if (e.target && e.target.closest('.splide__slide__certififcate')) {
                        if (data.slideIndex < 0) {
                            this.lbx.index = data.index
                        } else {
                            this.lbx.index = data.slideIndex
                        }
                        this.lbx.visible = true
                        this.toggleLightboxWindow()
                    }
                })
                this.newsplide.mount()
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.splide {
    position: relative;
    box-sizing: border-box;

    hr {
        border-top: 1px solid var(--color1);
        margin: 0;
    }
    &__slider__simillar-news {
        width: calc(100% - 184px);
        max-width: 1087px;
        align-self: flex-end;
        @include largePhonesAlt {
            width: 100%;
        }
    }
}
.splide__arrows {
    position: relative;
    overflow: hidden;
    margin-bottom: 32px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 20px;
    >span {
        text-transform: uppercase;
        line-height: 100%;
        color: var(--color1);
    }
    .splide__arrow {
        display: flex;
        position: relative;
        width: 80px;
        min-width: 80px;
        height: 62px;
        border: none;
        outline: none;
        background-color: rgb(255, 255, 255);
        margin-right: 15px;
        cursor: pointer;
        >span {
            z-index: 1;
            display: block;
            margin: auto;
            width: 13px;
            height: 12px;
        }
        &--prev {
            margin-left: auto;
            >span {
                background-image: url("data:image/svg+xml,%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.999995 5.77778L0.670743 6.15407V5.40149L0.999995 5.77778ZM5.33333 1.98611H5.83333V2.213L5.66258 2.3624L5.33333 1.98611ZM5.33333 9.56944L5.66258 9.19316L5.83333 9.34256V9.56944H5.33333ZM0.670743 5.40149L3.55963 2.87371L4.21814 3.62629L1.32925 6.15407L0.670743 5.40149ZM3.55963 2.87371L5.00408 1.60982L5.66258 2.3624L4.21814 3.62629L3.55963 2.87371ZM4.83333 1.98611V0H5.83333V1.98611H4.83333ZM1.32925 5.40149L4.21814 7.92927L3.55963 8.68184L0.670743 6.15407L1.32925 5.40149ZM4.21814 7.92927L5.66258 9.19316L5.00408 9.94573L3.55963 8.68184L4.21814 7.92927ZM5.83333 9.56944V11.5556H4.83333V9.56944H5.83333Z' fill='%23001D82'/%3E%3Cpath d='M14 5.77783L5.5 5.77783' stroke='%23001D82'/%3E%3C/svg%3E");
            }
        }
        &--next {
            >span {
                background-image: url("data:image/svg+xml,%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 5.77778L13.3293 6.15407V5.40149L13 5.77778ZM8.66667 1.98611H8.16667V2.213L8.33742 2.3624L8.66667 1.98611ZM8.66667 9.56944L8.33742 9.19316L8.16667 9.34256V9.56944H8.66667ZM13.3293 5.40149L10.4404 2.87371L9.78186 3.62629L12.6708 6.15407L13.3293 5.40149ZM10.4404 2.87371L8.99592 1.60982L8.33742 2.3624L9.78186 3.62629L10.4404 2.87371ZM9.16667 1.98611V0H8.16667V1.98611H9.16667ZM12.6708 5.40149L9.78186 7.92927L10.4404 8.68184L13.3293 6.15407L12.6708 5.40149ZM9.78186 7.92927L8.33742 9.19316L8.99592 9.94573L10.4404 8.68184L9.78186 7.92927ZM8.16667 9.56944V11.5556H9.16667V9.56944H8.16667Z' fill='%23001D82'/%3E%3Cpath d='M0 5.77783L8.5 5.77783' stroke='%23001D82'/%3E%3C/svg%3E");
            }
        }
        &__scewed {
            box-sizing: border-box;
            position: absolute;
            top: 0;
            left: 5px;
            width: calc(100% - 10px);
            height: 100%;
            border: 1px solid #001D82;
            transform: skew(8deg, 0);
            transition: all .3s ease-in-out;
            &:hover {
                background-color: var(--color4);
            }
        }
    }
    @include largePhonesAlt {
        .splide__arrow {
            display: none;
        }
    }
    .simillar-news__carousel & {
        .splide__arrow {
            position: absolute;
            bottom: -94px;
            &--prev {
                left: 0;
            }
            &--next {
                left: 80px;
            }
        }
    }
}
.splide__list {
    .splide__slide {
        &__about {
            box-sizing: border-box;
            padding: 40px;
            display: flex;
            justify-content: space-between;
            width: 720px;
            height: 320px;
            background-color: #F0F0F0;
            transition: all .3s ease-in-out;
            &:hover {
                background-color: #FFF0AC;
            }
            &__photo {
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 230px;
                height: 100%;
                &__img {
                    width: 190px;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    background-color: #C4C4C4;
                }
                &__decor-top {
                    position: absolute;
                    z-index: 2;
                    display: block;
                    top: -20px;
                    left: -20px;
                    width: 74px;
                    height: 66px;
                    background-image: url("data:image/svg+xml,%3Csvg width='74' height='66' viewBox='0 0 74 66' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M29.7552 36.1181H17.3357L30.7902 0H15.7832L0 34.0394V66H29.7552V36.1181ZM72.965 36.1181H60.5455L74 0H58.993L43.2098 34.0394V66H72.965V36.1181Z' fill='%23FFD200'/%3E%3C/svg%3E");
                }
            }
            &__comment {
                position: relative;
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                &__text {
                    font-size: 28px;
                    line-height: 130%;
                    color: var(--color1);
                }
                &__person {
                    width: 100%;
                    height: fit-content;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    &__name {
                        width: 100%;
                        font-size: 16px;
                        line-height: 110%;
                        text-transform: uppercase;
                        color: var(--color1);
                    }
                    &__position {
                        width: 100%;
                        font-size: 12px;
                        line-height: 140%;
                        color: var(--color1);
                    }
                }
                &__decor-bottom {
                    position: absolute;
                    display: block;
                    bottom: 0;
                    right: 0;
                    width: 74px;
                    height: 66px;
                    background-image: url("data:image/svg+xml,%3Csvg width='74' height='66' viewBox='0 0 74 66' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M44.2448 36.1181H56.6643L43.2098 0H58.2168L74 34.0394V66H44.2448V36.1181ZM1.03497 36.1181H13.4545L0 0H15.007L30.7902 34.0394V66H1.03497V36.1181Z' fill='white'/%3E%3C/svg%3E");
                    content: '';
                }
            }
        }
    }
    &__team-adaptive {
        .splide__slide {
            width: 257px;
        }
        .team__person__photo {
            width: 100%;
            height: 49vw;
            >img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top left;
            }
            @include largePhonesAlt {
                height: 49vw;
            }
            @include smallPhonesAlt {
                height: 89vw;
            }
        }
        .team__person__position {
            height: 46px;
            font-size: 13px;
        }
    }
    @include largePhonesAlt {
        .splide__slide {
            &__about {
                padding: 20px;
                flex-direction: column;
                width: 294px;
                height: 352px;
                &__photo {
                    &__img {
                        width: 153px;
                        height: 193px;
                    }
                    &__decor-top {
                        top: -10px;
                        left: -10px;
                        width: 41px;
                        height: 37px;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                        z-index: 2;
                    }
                }
                &__comment {
                    &__decor-bottom {
                        width: 55px;
                        height: 49px;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }
            }
        }
    }
}
.splide__slide__certififcate {
    &__container {
        position: relative;
        box-sizing: border-box;
        width: 320px;
        height: 452px;
        border: 7px solid yellow;
        overflow: hidden;
        cursor: zoom-in;
        &:hover {
            .splide__slide__certififcate__cover {
                opacity: 1;
            }
        }
    }
    &__image {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left top;
    }
    &__cover {
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255,210,0,.6);
        transition: all .3s ease-in-out;
        >div {
            width: 81px;
            height: 81px;
            background-color: #fff;
            border-radius: 50%;
            background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.5 0L12.5 25.0002' stroke='%23001D82'/%3E%3Cpath d='M10 12.5L0 12.5' stroke='%23001D82'/%3E%3Cpath d='M25 12.5L15 12.5' stroke='%23001D82'/%3E%3C/svg%3E");
            background-size: initial;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
}
.office-photos__carousel {
    &.splide {
        .splide__arrows {
            height: auto;
            margin-bottom: 0;
            position: relative;
            flex-direction: column;
            justify-content: flex-start;
            border-top: none;
            padding-top: 0;
            >.how-works-text {
                border-top: 1px solid var(--color1);
                max-width: 533px;
                width: 100%;
                text-transform: none;
                line-height: 140%;
                ::v-deep >p {
                    font-size: 28px;
                    line-height: 140%;
                    color: var(--color1);
                }
            }
            .splide__arrow {
                position: absolute;
                bottom: 0;
                &--prev {
                    right: 80px;
                }
                &--next {
                    right: 0;
                }
            }
        }
        .splide__slider {
            width: 100%;
            max-width: 994px;
        }
        .splide__list {
            .splide__slide__photo {
                width: 100%;
                height: 512px;
                .splide__slide__photo__container {
                    width: 100%;
                    height: 100%;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
        .splide__numbers {
            height: 40px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            position: absolute;
            bottom: -50px;
            color: var(--color1);
            >div {
                color: inherit;
                line-height: 100%;
            }
            .splide__number__current {
                width: 19px;
                font-size: 26px;
            }
            .splide__number__total {
                font-size: 16px;
                margin-bottom: 2px;
            }
        }
        @include mediumLaptop {
            .splide__arrows {
                >.how-works-text {
                    max-width: 400px;
                    ::v-deep p {
                        font-size: 23px;
                    }
                }
            }
        }
        @include phones {
            .splide__arrows {
                >.how-works-text {
                    max-width: 320px;
                    ::v-deep p {
                        font-size: 20px;
                    }
                }
            }
        }
        @include largePhonesAlt {
            flex-direction: column;
            .splide__list {
                .splide__slide__photo {
                    width: 100%;
                    height: 383px;
                }
            }
        }
        @include smallPhonesAlt {
            .splide__arrows {
                .how-works-text {
                    ::v-deep p {
                        font-size: 18px;
                    }
                }
            }
            .splide__list {
                .splide__slide__photo {
                    width: 100%;
                    height: 183px;
                }
            }
        }
    }
}
.stories__carousel {
    &.splide {
        .splide__arrow {
            display: none;
        }
        .team__person__photo {
            width: 349px;
            height: 429px;
            >img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .team__person__name {
            margin: 10px 0;
            font-size: 18px;
            color: var(--color1);
            text-transform: uppercase;
        }
        .team__person__button {
            width: 28px;
            height: 28px;
            border: 1px solid var(--color1);
            border-radius: 50%;
            background-color: #fff;
            background-image: url("data:image/svg+xml,%3Csvg width='9' height='9' viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='4.5' y1='-2.18557e-08' x2='4.5' y2='9' stroke='%23001D82'/%3E%3Cline x1='3' y1='4.5' y2='4.5' stroke='%23001D82'/%3E%3Cline x1='9' y1='4.5' x2='6' y2='4.5' stroke='%23001D82'/%3E%3C/svg%3E");
            background-size: 9px 9px;
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer;
            transition: all .3s ease;
        }
        .splide__slide__photo {
            &:hover {
                .team__person__button {
                    background-color: var(--color4);
                }
            }
        }
        @include mediumLaptop {
            .smallh2 {
                font-size: 6vw;
            }
        }
        @include smallPhonesAlt {
            .smallh2 {
                font-size: 24px;
            }
            .team__person__photo {
                width: 70vw;
                height: 102vw;
            }
        }
    }
}
.team__person__name {
    margin-top: 6px;
    font-size: 18px;
    color: var(--color1);
    height: 30px;
}
.team__person__position {
    height: 50px;
    font-size: 16px;
    color: var(--color1);
}
.team__person__button {
    margin-top: auto;
    border: none;
    outline: none;
    background-color: #fff;
    width: 32px;
    height: 32px;
    background-image: url("data:image/svg+xml,%3Csvg width='28' height='29' viewBox='0 0 28 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 14.9111C0.5 7.45529 6.54416 1.41113 14 1.41113C21.4558 1.41113 27.5 7.45529 27.5 14.9111C27.5 22.367 21.4558 28.4111 14 28.4111H0.5V14.9111Z' stroke='%23001D82'/%3E%3Cline x1='7.63635' y1='11.8657' x2='20.3636' y2='11.8657' stroke='%23001D82'/%3E%3Cline x1='7.63635' y1='15.6839' x2='15.2727' y2='15.6839' stroke='%23001D82'/%3E%3Cline x1='7.63635' y1='19.5021' x2='15.2727' y2='19.5021' stroke='%23001D82'/%3E%3C/svg%3E");
    background-size: initial;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: all .3s ease-in-out;
}
.h2-team-adaptive {
    width: 100%;
    border-top: 1px solid var(--color1);
    padding-top: 20px;
    margin-bottom: 10px;
}
.h2-certificates {
    font-size: 40px;
    @include phones {
        font-size: 28px;
    }
    @include largePhonesAlt {
        font-size: 18px;
    }
}
.h2-reviews {
    font-size: 80px;
    @include largePhonesAlt {
        font-size: 24px;
    }
}
.h2-simillar-news {
    font-size: 80px;
    @include phones {
        font-size: 50px;
    }
    @include largePhonesAlt {
        font-size: 24px;
    }
}
.splide__arrows__visible_small {
    .splide__arrows {
        margin-top: 16px;
        min-height: 62px;
        order: 2;
    }
    .splide__slider {
        order: 1;
    }
    .splide__arrow {
        display: flex;
    }
    &.office-photos__carousel {
        .splide__numbers {
            bottom: -65px;
        }
    }
    .how-works-text {
        border-top: 1px solid var(--color1);
        max-width: 533px;
        width: 100%;
        text-transform: none;
        line-height: 140%;
        ::v-deep >p {
            font-size: 19px;
            line-height: 140%;
            color: var(--color1);
        }
        @include smallPhonesAlt {
            ::v-deep >p {
                font-size: 16px;
            }
        }
    }
}
.splide__slide__simillar-news {
    max-width: 1087px;
    width: 100%;
    height: max-content;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 10px;
    border: 1px solid var(--color1);
    background-color: #fff;
    &__info {
        margin-bottom: 46px;
        display: inline-flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        &__type {
            display: flex;
            width: fit-content;
            height: fit-content;
            padding: 6px 15px;
            border-radius: 60px;
            border: 1px solid var(--color1);
            >span {
                font-size: 13px;
                text-transform: uppercase;
                color: var(--color1);
            }
        }
        &__date {
            margin-left: 162px;
            font-size: 13px;
            color: var(--color1);
            text-transform: uppercase;
        }
    }
    &__name {
        margin-bottom: 20px;
        font-size: 32px;
        color: var(--color1);
        &:hover {
            color: var(--color1);
        }
    }
    &__tag {
        display: flex;
        width: fit-content;
        height: fit-content;
        padding: 6px 15px;
        border-radius: 60px;
        background-color: var(--color2);
        >span {
            font-size: 13px;
            color: #fff;
            text-transform: uppercase;
        }
    }
    @include largePhonesAlt {
        padding: 10px;
        &__info {
            margin-bottom: 24px;
            &__type {
                >span {
                    font-size: 10px;
                }
            }
            &__date {
                font-size: 10px;
                margin-left: 20px;
            }
        }
        &__name {
            font-size: 18px;
        }
        &__tag {
            >span {
                font-size: 10px;
            }
        }
    }
}
</style>