<template>
  <section v-bind:class="{interacive:interacive}">
    <hr class="animate-elem shiftLeft wrap"/>
    <h2 v-if="cases.name" class="line animate-elem shiftUp wrap">{{cases.name}}</h2>

    <div class="wrapper">
      <div class="headline animate-elem fadeInLeft">
        <p v-if="cases.description">
          {{cases.description}}
        </p>
        <router-link class="button" :to="cases.link" v-if="cases.link">
          <Button text="все кейсы" color="dark" />
        </router-link>
        <div class="button-wrapper" v-if="this.length>1">
          <button class="button-left" @click="switchSlider('left')" :disabled="begin"></button>
          <button class="button-right" @click="switchSlider('right')" :disabled="end"></button>
        </div>
        <div class="indicator" v-if="this.length > 1">
          <template>
            <span v-for="i in this.length" :key="i" v-bind:class="{active: i===activeCaseNumber}">
            </span>
          </template>
        </div>
      </div>

      <ul class="case-list animate-elem fadeInRight" v-if="widthPage > 1024">
        <li class="case-item" v-for="(item, i) in cases.value" :key="i" :name="item.name"
          v-bind:class="{active: showSlider(item,i)}" v-bind:style="{zIndex: i}">
          <div class="image-container">
            <img :src="item.logo" :alt="item.name">
            <router-link class="button" :to="item.link">
              <Button text="Кейс полностью" color="red" />
            </router-link>
          </div>
          <div class="case-container">
            <div class="case-header">
              <div class="case-service" v-if="item.service">
                Услуга
                <span v-for="(obj,i) in item.service" :key="i">{{obj}}</span>
              </div>
              <div class="case-service" v-if="item.branches">
                Отрасль
                <span v-for="(obj,i) in item.branches" :key="i">{{obj}}</span>
              </div>
            </div>
            <h3 class="case-name">
              {{item.name}}
            </h3>
            <ul class="case-points">
              <li class="red-list" v-for="(point,i) in item.points" :key="i" v-html="point">
              </li>
            </ul>
          </div>
        </li>
      </ul>

      <ul class="case-list animate-elem fadeInRight" v-else>
        <swiper class="swiper swiper-case" :options="{
        ...swiperOption, loop: cases.value && cases.value.length > 1,
         slidesPerView: cases.value && cases.value.length > 1 ? 1.2 : 1 }" ref="mySwiperRef">
          <swiper-slide v-for="(item, i) in cases.value" :key="i">
            <li class="case-item">
              <div class="image-container">
                <img :src="item.logo" :alt="item.name">
                <router-link class="button red" v-if="widthPage > 765" :to="item.link">
                  <Button text="Кейс полностью" ccolor="red" />
                </router-link>
              </div>
              <div class="case-container">
                <div class="case-header">
                  <div class="case-service" v-if="item.service">
                    Услуга
                    <span v-for="(obj,i) in item.service" :key="i">{{obj}}</span>
                  </div>
                  <div class="case-service" v-if="item.branches">
                    Отрасль
                    <span v-for="(obj,i) in item.branches" :key="i">{{obj}}</span>
                  </div>
                </div>
                <h3 class="case-name">
                  {{item.name}}
                </h3>
                <ul class="case-points">
                  <li v-for="(point,i) in item.points" :key="i" class="red-list">
                    {{point}}
                  </li>
                  <router-link v-if="widthPage <= 765" class="button" :to="item.link">
                    <Button text="Кейс полностью" color="red" />
                  </router-link>
                </ul>
              </div>
            </li>
          </swiper-slide>

        </swiper>
      </ul>
    </div>

  </section>
</template>
<script>
  import Button from './button.vue'
  import {
    mapGetters
  } from 'vuex'

  // Import Swiper Vue.js components
  import {
    Swiper,
    SwiperSlide
  } from 'vue-awesome-swiper'
  import {
    Swiper as SwiperClass,
    Navigation,
    Pagination
  } from 'swiper/swiper.esm.js'
  import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'

  // Swiper modules
  SwiperClass.use([Navigation, Pagination])

  // local component
  const {
    directive
  } = getAwesomeSwiper(SwiperClass)

  // Import Swiper styles
  import 'swiper/swiper-bundle.css'
  export default {
    components: {
      Button,
      Swiper,
      SwiperSlide,
    },
    props: {
      cases: null,
      interacive: null,
      activeFilters: null,
      onInput: null
    },
    data() {
      return {
        activeCase: null,
        activeCaseNumber: 1,
        begin: true,
        end: false,
        swiperOption: {
          // slidesPerView: this.cases.value.length > 1 ? 1.2 : 1,
          navigation: {
            nextEl: '.custom-button-next',
            prevEl: '.custom-button-prev'
          },
          spaceBetween: 10,
          // loop: this.cases.value.length > 1,
          grabCursor: true,
          freeMode: true,
        },
      }
    },
    methods: {
      switchSlider: function (e) {
        if (e) {
          if (e === "left") {
            this.activeCase = this.cases.value[this.activeCaseNumber - 2].name
            this.activeCaseNumber--
          }
          if (e === "right") {
            this.activeCase = this.cases.value[this.activeCaseNumber].name
            this.activeCaseNumber++
          }
        }
        if (this.activeCaseNumber === this.cases.value.length) {
          this.end = true
        } else {
          this.end = false
        }
        if (this.activeCaseNumber === 1) {
          this.begin = true
        } else {
          this.begin = false
        }
      },
      showSlider: function (e, i) {
        if (this.activeCaseNumber === i + 1) {
          this.activeCaseNumber = i + 1
          return true
        }
        return false
      }
    },
    computed: {
      length() {
        if (this.cases.value) {
          return this.cases.value.length
        }
        return 0
      },
      ...mapGetters(['widthPage'])
    },
    mounted() {
      if(this.cases && Array.isArray(this.cases.value)) {
        this.cases.value = this.cases.value.map((item) => {
          const regExp = new RegExp('(<a.+)(href=")(?!' + window.location.origin + ')', 'gm')
          if(item && Array.isArray(item.points)) {
            item.points = item.points.map((point) => {
              // eslint-disable-next-line
              point = point.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
              return point
            })
          }

          return item
        })
      }
    },
    directives: {
      swiper: directive
    },
  }
</script>
<style lang="scss" scoped>
  @import "../../assets/styles/mixins";
  @import "../../assets/styles/variables";

  section {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 160px;

    @include smallLaptop {
      flex-direction: column;
    }

    hr {
        border-top: 1px solid var(--color1);
        margin-top: 0;
        margin-bottom: 5px;
    }

    .headline {
        margin-right: 40px;

        @include smallLaptop {
          display: none;
        }

        .button-wrapper {
          position: relative;
          margin: 0;
        }

        .indicator {
          margin-top: 60px;
        }
      }

    h2.line {
      // margin-bottom: 80px;
      // @include smallLaptop {
      //   margin-bottom: 24px;
      // }
      // @include mediumPhones {
      //   padding-top: 10px;
      // }

      // @include largePhonesAlt {
      //   font-size: 24px;
      // }
    }

    &.index {
      flex-direction: row;
      flex-wrap: nowrap;

      h2 {
        position: absolute !important;
        width: calc(100% - 2*var(--mainPadding));
      }

      .button-wrapper {
        margin-bottom: 0;
        margin-top: auto;
      }
    }

    &.interacive {
      .case-service {
        cursor: pointer;
      }
    }
  }

  h2 {
    margin-bottom: 0;
    width: 100%;

    @include mediumDesctop {
      font-size: 80px;
    }

    @include mediumLaptop {
      font-size: 50px;
    }

    @include smallLaptop {
      font-size: 32px;
    }
  }

  .headline {
    font-size: 18px;
    line-height: 140%;
    min-height: 100%;
    min-width: 268px;
    color: var(--color1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;

    p {
      margin-bottom: 40px;
      max-width: 350px;
    }

    @include mediumDesctop {
      p {
        max-width: 311px;
      }
    }

    @include mediumLaptop {
      p {
        margin: 24px 0;
        font-size: 16px;
      }
    }

    @include smallLaptop {
      margin-bottom: 24px;

      p {
        margin: 24px 0;
        font-size: 16px;
      }
    }
  }

  .indicator {
    display: flex;
    height: 3px;
    margin-bottom: 0;
    margin-top: auto;

    @include smallLaptop {
      display: none;
    }

    span {
      width: 20px;
      background-color: #C4BAF4;
      margin-right: 8px;

      &.active {
        background-color: #E62315;
      }
    }
  }

  .case-list {
    list-style-type: none;
    margin: 0;
    margin-top: 0;
    padding: 0;
    color: var(--color1);
    // width: calc(100% - 180px);
    flex: 1;
    min-width: 800px;
    max-width: 1087px;
    display: flex;
    position: relative;
    overflow: hidden;

    @include mediumDesctop {
      max-width: 1050px;
    }

    @include smallDesktop {
      max-width: 950px;
    }

    @include laptop {
      min-width: 0;
      max-width: 850px;
    }

    @include mediumLaptop {
      width: fit-content;
    }

    @include smallLaptop {
      margin-top: 0;
      width: 100%;
      max-width: 100%;
      min-height: 420px;
    }

    @include mediumPhones {
      min-height: 600px;
    }
  }

  .case-item {
    background-color: var(--color5);
    border: 1px solid #001D82;
    box-sizing: border-box;
    border-radius: 10px;
    padding: var(--mainPadding);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 454px;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    transition: .3s transform ease;

    @include smallLaptop {
      min-height: 260px;
    }

    @include mediumLaptop {
      min-height: 420px;
      width: fit-content;
      margin-left: auto;
    }

    @include smallLaptop {
      width: 100%;
      position: relative;

      .button.red {
        margin: 0;
      }
    }

    @include phones {
      height: fit-content;
      max-height: fit-content;
      position: relative;
    }

    @include largePhones {
      flex-flow: column;
      align-items: center;
      padding: 10px;
      padding-bottom: 20px;

      .button {
        width: 100%;

        label {
          width: 100% !important;

          .button.red {
            min-width: 100%;
            max-width: 100%;
            width: 100%;
            margin-right: 0;
          }
        }
      }
    }

    &.active {
      position: static;
      transform: none;

      &~.case-item {
        transform: translateX(99%);
      }
    }
  }

  .image-container {
    width: 30%;
    min-width: 200px;
    margin-right: 7%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 184px;

    img {
      width: 100%;
      height: 100%;
      max-width: 260px;
      object-fit: contain;
    }

    .button {
      padding: 0;
      margin: auto;
      margin-top: 10px;
    }

    @include smallDesktop {
      margin-right: 80px;
    }

    @include laptop {
      margin-right: 23px;
    }

    @include mediumLaptop {
      max-width: 233px;
    }

    @include smallLaptop {
      margin-right: 23px;
    }

    @include largePhones {
      margin-right: 0;
      max-width: 100%;
      width: 100%;

      .button {
        display: block !important;
        width: 100% !important;
        margin-right: 0;
      }

      img {
        margin: auto;
      }
    }
  }

  .case-container {
    &:last-child {
      @include mediumLaptop {
        max-width: 420px;
      }

      @include smallLaptop {
        max-width: 100%;
      }
    }
  }

  .case-header {
    display: flex;

    @include largePhones {
      margin-top: 20px;
    }
  }

  .case-service {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 14px;
    line-height: 140%;

    span {
      margin-top: 10px;
      border: 1px solid var(--color1);
      text-transform: uppercase;
      padding: 6px 15px;
      border-radius: 60px;

      &.active {
        background-color: var(--color4);
      }
    }

    @include smallLaptop {
      width: fit-content;
      margin-right: 8px;
    }
  }

  .case-name {
    margin-top: 40px;
    margin-bottom: 40px;
    text-transform: uppercase;
    font-size: 40px;
    line-height: 110%;

    @include smallDesktop {
      font-size: 32px;
    }

    @include mediumLaptop {
      font-size: 24px;
    }

    @include phones {
      font-size: 18px;
      margin: 24px 0;
    }

    @include largePhones {
      font-weight: 400;
    }
  }

  .case-points {
    padding-left: 0;
    list-style-type: none;

    li {
      margin-bottom: 15px;

      @include phones {
        &.red-list {
          padding-left: 20px;
        }
      }

      @include mediumLaptop {
        font-size: 14px;
        line-height: 140%;
        margin-bottom: 8px;
      }
    }
  }

  .button-wrapper {
    margin-right: 20px;
    margin-left: 20px;
    display: flex;
    align-items: flex-end;
    position: absolute;
    bottom: 0;
    right: 0px;

    @include smallLaptop {
      display: none;
    }

    .button-left,
    .button-right {
      width: 70px;
      height: 62px;
      border: none;
      background: none;
      cursor: pointer;

      &:disabled {
        opacity: .3
      }
    }

    .button-left {
      background-image: url("data:image/svg+xml,%3Csvg width='70' height='62' viewBox='0 0 70 62' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.82664 61.5L0.554051 0.5H63.1734L69.446 61.5H6.82664Z' stroke='%23001D82'/%3E%3Cpath d='M28 30.7778L27.6708 31.1541V30.4015L28 30.7778ZM32.3333 26.9861H32.8333V27.213L32.6626 27.3624L32.3333 26.9861ZM32.3333 34.5694L32.6626 34.1932L32.8333 34.3426V34.5694H32.3333ZM27.6708 30.4015L30.5596 27.8737L31.2182 28.6263L28.3293 31.1541L27.6708 30.4015ZM30.5596 27.8737L32.0041 26.6098L32.6626 27.3624L31.2182 28.6263L30.5596 27.8737ZM31.8333 26.9861V25H32.8333V26.9861H31.8333ZM28.3293 30.4015L31.2182 32.9293L30.5596 33.6818L27.6708 31.1541L28.3293 30.4015ZM31.2182 32.9293L32.6626 34.1932L32.0041 34.9457L30.5596 33.6818L31.2182 32.9293ZM32.8333 34.5694V36.5556H31.8333V34.5694H32.8333Z' fill='%23001D82'/%3E%3Cpath d='M41 30.7778L32.5 30.7778' stroke='%23001D82'/%3E%3C/svg%3E%0A");
    }

    .button-right {
      background-image: url("data:image/svg+xml,%3Csvg width='70' height='62' viewBox='0 0 70 62' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.82664 61.5L0.554051 0.5H63.1734L69.446 61.5H6.82664Z' stroke='%23001D82'/%3E%3Cpath d='M41 30.7778L41.3293 31.1541V30.4015L41 30.7778ZM36.6667 26.9861H36.1667V27.213L36.3374 27.3624L36.6667 26.9861ZM36.6667 34.5694L36.3374 34.1932L36.1667 34.3426V34.5694H36.6667ZM41.3293 30.4015L38.4404 27.8737L37.7819 28.6263L40.6708 31.1541L41.3293 30.4015ZM38.4404 27.8737L36.9959 26.6098L36.3374 27.3624L37.7819 28.6263L38.4404 27.8737ZM37.1667 26.9861V25H36.1667V26.9861H37.1667ZM40.6708 30.4015L37.7819 32.9293L38.4404 33.6818L41.3293 31.1541L40.6708 30.4015ZM37.7819 32.9293L36.3374 34.1932L36.9959 34.9457L38.4404 33.6818L37.7819 32.9293ZM36.1667 34.5694V36.5556H37.1667V34.5694H36.1667Z' fill='%23001D82'/%3E%3Cpath d='M28 30.7778L36.5 30.7778' stroke='%23001D82'/%3E%3C/svg%3E%0A");
    }

    .indicator {
      position: absolute;
      left: 0;
      top: 112px;
    }
  }
  .wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;

    margin-top: 80px;
    @include smallLaptop {
      flex-wrap: unset;
      flex-direction: column;
      margin-top: 24px;
    }

    @include mediumPhones {
      padding-top: 10px;
    }

    @include largePhonesAlt {
      font-size: 24px;
    }
  }
</style>


<style lang="scss">
  @import "../../assets/styles/mixins";
  @import "../../assets/styles/variables";


  .swiper {
    width: 100%;
    height: 100%;
  }

  @include smallLaptop {
    .case-list {
      .button {
        padding: 0;
      }
    }

    .headline {
      a.button {
        padding: 0;
      }
    }
  }

  #cases {
    .case-list {
      min-height: unset !important;
    }
  }
</style>