<template>
    <ul class="annotation-list">
        <template v-for="(item, i) in value">
            <li v-if="typeof item == 'object'" class="annotation-item" :key="i">
                <div class="animate-elem shiftUp">
                    <span class="annotation-description" v-html="item.name"></span>
                    <span class="annotation-number">&lt;{{i+1}}/</span>
                </div>
            </li>
            <li v-else class="annotation-item" :key="i">
                <div class="animate-elem shiftUp">
                    <span class="annotation-description" v-html="item"></span>
                    <span class="annotation-number">&lt;{{i+1}}/</span>
                </div>
            </li>
        </template>
    </ul>
</template>
<script>
    export default {
        props: {
            value: null,
        },
        mounted() {
            if(this.value) {
                const regExp = new RegExp('(<a.+)(href=")(?!' + window.location.origin + ')', 'gm')

                if(this.value.items && Array.isArray(this.value.items)) {
                    this.value.items = this.value.items.map((item) => {
                        if(item && item.name) {
                            // eslint-disable-next-line
                            item.name = item.name.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
                            return item
                        }
                    })
                }

                if(this.value.items && !Array.isArray(this.value.items)) {
                    this.value.items = this.value.items.map((item) => {
                        if(item) {
                            // eslint-disable-next-line
                            item = item.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
                            return item
                        }
                    })
                }
            }
        },
    }
</script>
<style lang="scss" scoped>
    .annotation-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        margin-top: 80px;
        padding-left: 91px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        &:before {
            content: '';
            position: absolute;
            width: 71px;
            height: 90px;
            top: 0;
            left: 0;
            background-image: url("data:image/svg+xml,%3Csvg width='73' height='94' viewBox='0 0 73 94' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M72.3536 90.3536C72.5488 90.1583 72.5488 89.8417 72.3536 89.6464L69.1716 86.4645C68.9763 86.2692 68.6597 86.2692 68.4645 86.4645C68.2692 86.6597 68.2692 86.9763 68.4645 87.1716L71.2929 90L68.4645 92.8284C68.2692 93.0237 68.2692 93.3403 68.4645 93.5355C68.6597 93.7308 68.9763 93.7308 69.1716 93.5355L72.3536 90.3536ZM0.5 0V80H1.5V0H0.5ZM11 90.5H72V89.5H11V90.5ZM0.5 80C0.5 85.799 5.20101 90.5 11 90.5V89.5C5.7533 89.5 1.5 85.2467 1.5 80H0.5Z' fill='%23001D82'/%3E%3C/svg%3E%0A");
            background-size: 100%;
            background-position: center;
        }
        &.no-arrow {
            padding-left: 266px;
            &:before {
                content: none;
            }
            .annotation-item {
                width: calc(33.33% - var(--mainPadding))
            }
        }
        @include largePhonesAlt {
            margin-top: 24px;
            padding-left: 0;
            flex-direction: column;
            row-gap: 10px;
            &:before {
                display: none;
            }
        }
    }

    .annotation-item {
        width: calc(50% - var(--mainPadding));
        min-height: 188px;
        border-radius: 10px;
        padding: 0;
        position: relative;
        overflow: hidden;
        & > div {
            box-sizing: border-box;
            padding: 30px;
            height: 100%;
            min-height: 100%;
            background-color: #FFF0AC;
            @include largePhonesAlt {
                min-height: 106px;
            }
        }
        @include largePhonesAlt {
            min-height: 106px;
            width: 100% !important;
            margin-left: 0;
            // padding: 16px;
            font-size: 16px;
        }
    }

    .annotation-description {
        font-size: 18px;
        line-height: 160%;
        color: var(--color1)
    }

    .annotation-number {
        position: absolute;
        bottom: 14px;
        right: 14px;
        font-size: 70px;
        -webkit-text-stroke: 1px var(--color4);
        -webkit-text-fill-color: #0000;
        @include largePhonesAlt {
            font-size: 40px;
        }
    }
</style>
