<template>
  <div>
      <cookie-law>
      <div slot-scope="props">
        <p>
          Этот веб-сайт использует файлы cookie, чтобы обеспечить вам наилучший
          опыт работы.
        </p>
        <button class="skew" @click="props.accept">
          <span>Понятно</span>
        </button>
      </div>
    </cookie-law>
  </div>
</template>

<script>
import CookieLaw from "vue-cookie-law";
export default {
  components: {
    CookieLaw,
  },
};
</script>

<style lang="scss" scoped>
.Cookie {
  width: 300px;
  right: 20px;
  bottom: 20px;
  left: auto;
  background-color: #fff;
  color: #001d82;
  padding: 20px;

  p {
    margin-top: 0;
    margin-bottom: 80px;
  }

  .skew {
    color: #e62315;
    border: 1px solid #e62315;
    border-radius: 20px;
    padding: 8px 15px;
    font-size: 16px;
    background-color: #fff;
    cursor: pointer;
  }
}
</style>