<template>
    <section>
        <hr class="animate-elem shiftLeft wrap"/>
        <h2 class="animate-elem shiftUp wrap">{{value.name}}</h2>
        <ul class="animate-elem listfadeInLeft">
            <router-link tag="li" :to="item.link" v-for="(item,i) in value.value" :key="i">
                {{item.name}}
            </router-link>
        </ul>
    </section>
</template>
<script>
    export default {
        props: {
            value: null
        }
    }
</script>
<style lang="scss" scoped>
    section {
        margin-top: 172px;
        margin-bottom: 160px;
    }

    hr {
        border-top: 1px solid var(--color1);
        margin-top: 0;
        margin-bottom: 5px;
    }

    h2 {
        font-size: 40px;
        line-height: 110%;

        @include largePhonesAlt {
            font-size: 18px;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        margin-top: 80px;
        list-style-type: none;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;

        li {
            background: #462DC7;
            padding: 6px 15px;
            color: #fff;
            border-radius: 25px;
            font-size: 24px;
            line-height: 130%;
            margin-right: 8px;
            margin-bottom: 8px;
            text-transform: uppercase;
            cursor: pointer;
        }

        @include largePhonesAlt {
            margin-top: 24px;

            li {
                font-size: 14px;
            }
        }
    }
</style>