<template>
    <div class="about__events dflex-child_maxwidth">
        <div class="about__events__wrapper cropped">
            <EventsInsideSidemanu :tags="tags" :page="'eventsList'" :listReady="listReady" />
            <EventsInsideList :eventItems="eventItems" :listReady="listReady" />
        </div>
    </div>
</template>

<script>
import EventsInsideSidemanu from './eventsInsideSidemenu.vue'
import EventsInsideList from './evensInsideList.vue'

export default {
    name: 'eventsInside',
    components: {
        EventsInsideSidemanu,
        EventsInsideList
    },
    props: {
        eventItems: Array,
        listReady: Boolean,
        tags: Array
    }
}
</script>

<style lang="scss" scoped>
.about__events {
    &__wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

    @include smallHeight {
        &__wrapper {
            overflow: hidden;
            width: 100%;
            // flex-direction: column;
        }
    }

    @include mediumAlt {
        &__wrapper {
            overflow: hidden;
            width: 100%;
            flex-direction: column;
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}
#widget2 {

    @include smallHeight {
        display: none;
    }

    @include largePhonesAlt {
        display: none;
    }
}
</style>