<template>
    <div class="event-item dflex-child_maxwidth">
        <div class="event-item__wrapper cropped">
            <EventsInsideSidemanu page="event"/>
            <EventTemplateContainer :simillarNews="simillarNews" />
        </div>
        <Carousel v-if="simillarNews.items" :iW="iW" :h2text="'Похожий материал'" :wrapped="{isWrapped: false, wrapperClass: 'q'}" :carousel='simillarNews.items' :settings="stng" type="news-alike"/>
    </div>
</template>

<script>
import eventHub from '../../components/eventHub'
import EventsInsideSidemanu from '../../components/pages/about/eventsInsideSidemenu.vue'
import EventTemplateContainer from '../../components/pages/about/eventTemplateContainer.vue'
import Carousel from '../../components/UI/carousel.vue'
import {mapGetters,mapMutations} from 'vuex'

export default {
    metaInfo() {
        return {
            title: `События: ${this.metaNames.title}`,
            meta: this.metaNames.metas
        }
    },
    name: 'eventTemplate',
    components: {
        EventsInsideSidemanu,
        EventTemplateContainer,
        Carousel
    },
    data() {
        return {
            iW: 0,
            stng: {"type":"loop","perPage":1,"autoplay":false,"pagination":false,"autoWidth":false,"perMove":1,gap: '20px'},
            simillarNews: {
                items: []
            }
        }
    },
    methods: {
        ...mapMutations(['addMetaNames']),
        myEventHandler(e) {
            this.iW = e.target.innerWidth
        }
    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
    },
    mounted() {
        eventHub.$on('eventTemplateSeoData', (data) => {
            this.addMetaNames(data)
        })
        this.iW = window.innerWidth
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    },
    computed: {
        ...mapGetters(['metaNames'])
    }
}
</script>

<style lang="scss" scoped>

.event-item {
    display: flex;
    flex-direction: column;
    &__wrapper {
        width: 100%;
        @extend .event-item;
        flex-direction: row;
        &.cropped {
            padding-bottom: 0;
        }
        @include mediumLaptop {
            flex-direction: column-reverse;
        }
    }

}
</style>

<style lang="scss">
.event-item {
    .about__events__sidebar {
        .event-item__sidebar__filter {
            @include mediumLaptop {
                display: none;
            }
        }
        .about__events__sidebar__form {
            @include mediumLaptop {
                margin-top: 48px;
                width: 100%;
            }
        }
        @include mediumLaptop {
            padding-bottom: 0 !important;
            width: 100%;
            padding-right: 0;
        }
    }
}
</style>