<template>
    <ContactsInside :iW="iW" :value="valueContacts" :formFileds="formFileds" :settings1="settings1" :bodySettings1="bodySettings1" :phoneandmail="phoneandmail" :contactSocials="contactSocials" />
</template>

<script>
import ContactsInside from '../../components/pages/about/contactsInside.vue'

export default {
    metaInfo() {
        return {
            title: `Контакты`
        }
    },
    name: 'contacts',
    components: {
        ContactsInside
    },
    data() {
        return {
            iW: 0,
            formFileds: [
                {type: "text", placeholder: "Имя*", required: true},
                {type: "tel", placeholder: "Телефон", required: false, masked: '+7 (###) ###-##-##'},
                {type: "email", placeholder: "Email*", required: true},
                {type: "textarea", placeholder: "Сообщение", required: true}
            ],
            settings1: {
                list: [
                    {class: 'w2y', icon: false},
                    {class: 'w2y', icon: false}
                ]
            },
            bodySettings1: [
                [
                    {
                        address: 'г. Москва, Варшавское шоссе, 9, стр. 1',
                        link: 'https://yandex.ru/maps/-/CCUyZMhH1D'
                    },
                    {
                        address: 'г. Нижний Новгород, Московское шоссе, 12, этаж 4, офис 12',
                        link: 'https://yandex.ru/maps/-/CCUyEMumGA'
                    }
                ],
                [
                    {
                        address: 'ОЭЗ «Дубна», Программистов, 4, стр. 2',
                        link: 'https://yandex.ru/maps/-/CCUyEMu8lA'
                    }
                ]
            ],
            phoneandmail: [
                {text: '8 495 665-00-68', link: 'tel:+84956650068'},
                {text: 'info@hopper-it.ru', link: 'mailto:info@hopper-it.ru'}
            ],
            contactSocials: [
                // {
                //     name: 'fb',
                //     link: 'https://www.facebook.com/Ithopper/'
                // },
                // {
                //     name: 'ig',
                //     link: 'https://www.instagram.com/hopper.it/'
                // },
                //  {
                //      name: 'tg',
                //      link: 'asd2'
                //  },
                // {
                //     name: 'yt',
                //     link: 'asd3'
                // },
                {
                    name: 'vk',
                    link: 'https://vk.com/hopper_it'
                }
            ],
            valueContacts: {
                type: "valueContacts",
                name: null,
                items: [
                    {
                        name: "Для встреч и коммуникаций",
                        points: ["Ценность рубрики 1", "Ценность рубрики 2"]
                    },
                    {
                        name: "Рабочий офис",
                        points: [ "Ценность рубрики 3", "Ценность рубрики 4"]
                    }
                ]
            }
        }
    },
    methods: {
        myEventHandler(e) {
            this.iW = e.target.innerWidth
        }
    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
    },
    mounted() {
        this.iW = window.innerWidth
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    }
}
</script>