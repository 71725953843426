<template>
    <section v-bind:class="{index:cases.index, interacive:interacive}">
        <ul class="case-list" data-position-end="sticky">
            <li class="case-item" v-for="(item, i) in activeCases" :key="i" v-show="i < activeShowItems">
                <div class="image-container">
                    <img :src="item.logo" :alt="item.name">
                    <router-link v-if="widthPage > 765" class="button" :to="'/cases/'+item.link">
                        <Button text="Кейс полностью" color="red" />
                    </router-link>
                </div>
                <div class="case-container">
                    <div class="case-header">
                        <div class="case-service" v-if="item.services && item.services.length">
                            <template >
                                Услуга
                              <div class="case-service__wrapper">
                                  <span v-for="(service, key) in item.services" :key="key"
                                      v-bind:class="{active:activeFilter(service)}">
                                      {{service}}
                                  </span>
                              </div>
                            </template>
                        </div>
                        <div class="case-service" v-if="item.branches&&item.branches.length">
                            <template >
                                Отрасль
                                <div class="case-service__wrapper">
                                  <span v-for="(branch, key) in item.branches" :key="key"
                                      v-bind:class="{active:activeFilter(branch)}">
                                      {{branch}}
                                  </span>
                                </div>
                            </template>
                        </div>
                    </div>
                    <h3 class="case-name">
                      <router-link :to="'/cases/'+item.link">
                        {{item.name}}
                      </router-link>
                    </h3>
                    <ul class="case-points">
                        <li class="red-list" v-for="(point,i) in item.points" :key="i" v-html="point">
                        </li>
                    </ul>
                    <router-link v-if="widthPage <= 765" class="button" :to="'/cases/'+item.link">
                      <Button text="Кейс полностью" color="red" />
                    </router-link>
                </div>
            </li>
            <AnimatedButton
              @click="incActiveCases()"
              :classes="['buttons-group__show-more']"
              :settings="{class: 'w2y', text: 'показать еще', icon: true}"
              v-if="activeCasesLength"/>
        </ul>
    </section>
</template>
<script>
    import Button from './button.vue'
    import AnimatedButton from '../UI/AB.vue'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            Button,
            AnimatedButton
        },
        data() {
          return {
            activeCases: this.cases.cases,
            inc: this.showItems,
            activeShowItems: this.showItems
          }
        },
        props: {
            cases: null,
            interacive: null,
            activeFilters: null,
            onInput: null,
            showItems: {
              type: Number,
              default: 4
            }
        },
        methods: {
            activeFilter: function (e) {
                if (this.activeFilters) {
                    if (this.activeFilters.includes(e)) {
                        return true
                    }
                    return false
                }
            },
            filter() {
              this.activeCases = this.cases.cases.filter((item) => {
                return this.activeFilters.map(elem => {
                    if(item.branches.includes(elem)) {
                      return true
                    } else {
                      return item.services.includes(elem)
                    }
                  }).includes(true)
              })
            },
            incActiveCases() {
              this.activeShowItems += this.inc
            }
        },
        watch: {
          cases() {
            this.activeCases = this.cases.cases
            this.activeShowItems = this.showItems
          },
          activeFilters() {
            if(this.activeFilters.length === 0) {
              this.activeCases = this.cases.cases
              this.activeShowItems = this.showItems
            } else {
              this.filter()
            }
          }
        },
        computed: {
          ...mapGetters(['widthPage']),
          activeCasesLength() {
            if(this.activeCases && this.activeCases.length) {
              return this.activeCases.length > this.activeShowItems
            } else {
              return false
            }
          }
        }
    }
</script>
<style lang="scss" scoped>
    section {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-top: 40px;

        &.index {
            flex-direction: row;
            flex-wrap: nowrap;

            h2 {
                position: absolute !important;
                width: calc(100% - 2*var(--mainPadding));
            }
        }
    }

    h2 {
        margin-bottom: 120px;
    }

    .headline {
        margin-top: 120px;
        max-width: 350px;
        font-size: 18px;
        line-height: 140%;
        color: var(--color1);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        p {
            margin-bottom: 40px;
        }
    }

    .case-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        color: var(--color1);
        width: 100%;
        min-width: 900px;
        max-width: 1087px;
        margin-left: auto;
        padding-bottom: 80px;
        border-bottom: 1px solid #001D82;

        @include mediumDesctop {
          min-width: 600px;
          max-width: 1087px;
        }

        @include laptop {
          max-width: 800px;
        }

        @include mediumLaptopAlt {
          max-width: 100%;
          width: 100%;
        }

        @include largePhonesAlt {
          padding-bottom: 40px;
        }

        @include largePhones {
          min-width: 100%;
          padding-bottom: 24px;
        }
    }

    .case-item {
        background-color: var(--color5);
        border: 1px solid #001D82;
        box-sizing: border-box;
        border-radius: 10px;
        padding: var(--mainPadding);
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        min-height: 454px;

        &+.case-item {
            margin-top: 20px;
        }

        .button {
          width: 100%;
          padding: 0;
          margin-top: 20px;

          & > label {
            width: 100% !important;
          }
        }

        @include phones {
          min-width: unset;
          min-height: unset;
          padding: var(--mainPaddingSmallSize);
        }

        @include largePhones {
          flex-direction: column;
        }
    }

    .image-container {
        width: 30%;
        min-width: 260px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }

        @include laptop {
          width: 25%;
          min-width: 237px;
        }

        @include phones {
          margin-right: var(--mainPadding);
        }

        @include largePhones {
          margin-right: 0;
          width: 100%;
          max-height: 155px;
          margin-bottom: 20px;

          img {
            max-height: 155px;
          }
        }
    }

    .case-container {
        flex: 1;
        padding-left: var(--mainPadding);
        box-sizing: border-box;

        @include laptop {
          max-width: 600px;
        }

        @include phones {
          padding-left: 0;
        }

        @include largePhones {
          max-width: 100%;
          width: 100%;
        }
    }

    .case-header {
        display: flex;
    }

    .case-service {
        //width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 14px;
        line-height: 140%;

        &:first-child {
          margin-right: 10px;
        }

        span {
            margin-top: 10px;
            border: 1px solid var(--color1);
            text-transform: uppercase;
            padding: 6px 15px;
            border-radius: 60px;

            margin-right: 5px;
            display: inline-block;

            &.active {
                background-color: var(--color4);
            }
        }

        @include laptop {
          span {
            font-size: 13px;
          }
        }

        @include phones {
          font-size: 12px;

          &__wrapper {
            margin-top: 11px;
          }

          span {
            font-size: 10px;
          }
        }
    }

    .case-name {
        margin-top: 40px;
        margin-bottom: 40px;
        text-transform: uppercase;
        font-size: 40px;
        line-height: 110%;

        @include laptop {
          font-size: 32px;
        }

        @include phones {
          font-size: 18px;
          line-height: 120%;
          font-weight: normal;
          margin: 24px 0;
        }
    }

    .case-points {
        padding-left: 0;
        list-style-type: none;

        li {
            margin-bottom: 15px;
        }

        @include laptop {
          font-size: 14px;
        }

        @include phones {
          li {
          padding-left: 29px;
          }
        }

        @include mediumPhones {
          font-size: 12px;
        }
    }
</style>
