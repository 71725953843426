export default {
    actions: {

    },
    mutations: {
        addProduct(state, product) {
            state.scrollProduct = {};
            state.scrollObjects = [];

            state.scrollProduct = product;

            let elements = document.querySelectorAll(".content-container section");
            for (let i = 0; i < elements.length; i++) {
                let object = {
                    id: elements[i].id,
                    scroll: document
                        .querySelector("#" + elements[i].id)
                        .getBoundingClientRect().top,
                    height: document.querySelector("#" + elements[i].id).offsetHeight,
                    procent: "",
                };
                state.scrollObjects.push(object);
            }
        },
        clearScroll(state){
            state.scrollProduct = {};
            state.scrollObjects = [];
        }
    },
    state: {
        scrollProduct: {},
        scrollObjects: []
    },
    getters: {
        SCROLL_PRODUCTS(state) {
            return state.scrollProduct
        },
        SCROLL_OBJECTS(state) {
            return state.scrollObjects
        }
    },
};