<template>
    <div class="about__events__sidebar" id="filter" :data-position="iW > 884 ? 'sticky' : ''" >
        <div v-if="!listReady && page === 'eventsList'" class="eventlist-loader">
          <VLoader />
        </div>
          <template v-if="page === 'eventsList' || page === 'career'">
            <div class="about__events__sidebar__filter" v-if="page === 'eventsList' || page === 'career'">
                <div class="about__events__sidebar__filter__group">
                    <AnimatedButton v-for="(tag, index) in tags" :key="index + '_' + tag" :classes="['buttons-group__tag-filter', tag.active && 'is-active']" :settings="{class: 'w2y', text: tag.tag, icon: false}" @click="bebe3(tag)" />
                </div>
                <AnimatedButton v-if="listReady" :disabled="activeArr.length === 0" :classes="['buttons-group__tag-filter','about__events__sidebar__clear']" :settings="{class: 'w2y', text: 'сбросить', icon: true}" @click="bebe4" />
            </div>
            <ContactForm v-if="iH > 810 && iW > 884 && page === 'eventsList'" :info="{type: 'events',text: 'Подписаться на Hopper-IT', fields: formFileds}" :classes="['about__events__sidebar__form']" />
            <NewsWidget v-if="iH > 810 && iW > 884  && page === 'eventsList'" />
          </template>
          <template v-else>
              <div class="event-item__sidebar__filter" :data-position="'sticky'">
                  <router-link class="ab-router-link" tag="button" to="/events">
                      <AnimatedButton :classes="['buttons-group__tag-filter']" :settings="{anchor: true, class: 'w2y_right', text: 'вернуться', icon: true}" />
                  </router-link>
              </div>
              <ContactForm v-if="(iW > 884 || iH > 600)" :info="{type: 'events',text: 'Подписаться на Hopper-IT', fields: formFileds}" :classes="['about__events__sidebar__form']" />
          </template>
    </div>
</template>

<script>
import NewsWidget from './newsWidget.vue'
import ContactForm from './contactForm.vue'
import AnimatedButton from '../../UI/AB.vue'
import VLoader from '../../UI/VLoader.vue'
import eventHub from '../../eventHub'
import { mapGetters } from 'vuex'

export default {
    name: 'eventsInsideSidemanu',
    components: {
        NewsWidget,
        ContactForm,
        AnimatedButton,
        VLoader
    },
    props: {
        tags: Array,
        page: String,
        cities: Array,
        listReady: Boolean
    },
    data() {
        return {
            formFileds: [{type: "email", placeholder: "Email*", required: true}],
            activeArr: [],
            iW: 0,
            iH: 0
        }
    },
    computed: mapGetters(['widthPage']),
    methods: {
        myEventHandler(e) {
            this.iW = e.target.innerWidth
            this.iH = e.target.innerHeight
        },
        bebe3(ev) {
            ev.active = !ev.active
            this.activeArr = this.tags.filter(i => {
                return i.active
            }).map(j => {
                return j.tag
            })
            eventHub.$emit('bebe123', this.activeArr)
        },
        bebe4() {
            this.tags.forEach(tag => {
                tag.active = false
            })
            setTimeout(() => {
                this.activeArr = []
            }, 300);
            eventHub.$emit('bebe123', [])
        }
    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
    },
    mounted() {
        this.iW = window.innerWidth
        this.iH = window.innerHeight
    },
    onBeforeUnmount() {
        eventHub.$off('bebe123')
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    }
}
</script>

<style lang="scss" scoped>
.about__events {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    &__sidebar {
        position: relative;
        box-sizing: border-box;
        padding-right: 46px;
        padding-bottom: 60px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 507px;
        max-height: 100vh !important;
        // margin-right: 92px;
        &__filter__group {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            margin-bottom: 40px;
        }

        @include smallHeight {
            // width: 100%;
            padding-right: 0;
            padding-bottom: 60px;
            top: unset;
        }
    }
    &__event-list {
        position: relative;
        box-sizing: border-box;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 46px;
        padding-bottom: 160px;
        @include mediumAlt {
            padding-bottom: 30px;
        }
        &:after {
            position: absolute;
            bottom: 80px;
            right: 0;
            width: calc(100% - 46px);
            height: 1px;
            background-color: var(--color1);
            content: '';
        }
    }
    @include desctop {
        &__sidebar {
            &__filter__group {
                margin-bottom: 10px;
            }
        }
    }
    @include smallLaptop {
        &__sidebar {
            width: 410px;
            padding-right: 23px;
            padding-bottom: 24px;

            @include smallHeight {
            width: 100%;
            }
        }
    }
    @include mediumAlt {
        &__sidebar {
            top: 0;
            padding-right: 0;
            width: 100%;
            position: relative;
            z-index: var(--sidebarIndex);
            &__filter {
                position: relative;
                z-index: var(--sidebarIndex);
                & > div {
                    background: var(--color5);
                    position: relative;
                    z-index: var(--sidebarIndex);
                    width: 100vw;

                    &:first-child {
                        padding-bottom: 18px;
                        margin-bottom: 0;
                    }
                }
            }
            &__filter__group {
                padding-bottom: 8px;
                padding-top: 10px;
                overflow: auto;
                flex-wrap: nowrap;
            }
        }
    }
}
.eventlist-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 150px;
}
</style>