<template>
    <div class="dflex-cont dflex-cont_column_center">
        <Banner text="Решения" image="solutions.svg" class="dflex-child_maxwidth" />
        <section class="section dflex-child_maxwidth">
            <CustomFilter :value="filter" :onInput="updateFilters" :activeFilters="activeFilters" />
            <Solutions :value="solutions" :filter="activeFilters"/>
        </section>
    </div>
</template>
<script>
    import Banner from "../components/UI/banner.vue"
    import Solutions from "../components/pages/solutions/solutionList.vue"
    import CustomFilter from "../components/UI/customFilter.vue"
    import axios from 'axios'
    import {mapGetters,mapMutations} from 'vuex'
import eventHub from '../components/eventHub'
    export default {
        components: {
            Banner,
            Solutions,
            CustomFilter
        },
        data() {
            return {
                activeFilters: [],
                filter: [{
                    name: 'Задачи',
                    value: []
                }],
                solutions: [],
                loading: true
            }
        },
        metaInfo() {
            return {
                title: `${this.metaNames.title ?? 'Решения'}`,
                meta: this.metaNames.metas
            }
        },
        methods: {
            ...mapMutations(['addMetaNames', 'setLoading']),
            updateFilters(data) {
                if (data === 'resetAll') {
                    this.activeFilters = []
                } else {
                    if (this.activeFilters.includes(data)) {
                        let pos = this.activeFilters.indexOf(data)
                        this.activeFilters.splice(pos, 1)
                    } else {
                        this.activeFilters.push(data);
                    }
                }
            },
            fetchData() {
                this.error = this.solutions = null
                const time = Date.now()
                eventHub.$emit('updateLoader', true)
                // replace `getPost` with your data fetching util / API wrapper
                axios.get('/api/solutions/')
                  .then((response) => {
                      this.solutions = response.data.solutions
                      this.addMetaNames(response.data.seo)
                      setTimeout(() => {
                          for(let i=0; i<this.solutions.length; i++) {
                              if(!this.filter[0].value.includes(this.solutions[i].tag)) {
                                  this.filter[0].value.push(this.solutions[i].tag)
                              }
                          }
                      }, 1)
                  })
                  .catch((error) => {
                      this.error = error.toString()
                      console.log(error)
                      this.$router.push('/404')
                  })
                  .finally(() => {
                    const setTime = Date.now() - time > 1000 ? 0 : 1000 - (Date.now() - time)
                    setTimeout(() => {
                      eventHub.$emit('updateLoader', false)
                      setTimeout(() => {
                        this.setLoading(false)
                      }, 1000)
                    }, setTime)
                  });
            },
        },
        created() {
            this.$watch(
                () => this.$route.params,
                () => {
                    this.fetchData()
                },
                // fetch the data when the view is created and the data is
                // already being observed
                { immediate: true }
            )
        },
        computed: {
            ...mapGetters(['metaNames']),
        }
    }
</script>
<style lang="scss" scoped>
    .section {
        display: flex;
        position: relative;
        @include mediumLaptopAlt {
            flex-direction: column;
        }
    }
    .filter {
        width: 25vw;
        z-index: var(--sidebarIndex);
        margin-top: 40px;
        @include mediumLaptopAlt {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 100vw;
            padding: 0;
            margin: 0;
            height: fit-content;
        }
        .filter-item {
            width: fit-content;
        }
    }
    .cases {
        width: 100%;
    }
</style>
