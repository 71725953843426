export default {
  actions: {
    fetchPage(ctx, data) {
      ctx.commit("updatePage", data);
    },
    fetchHeaderTheme(ctx, data) {
      ctx.commit("updateHeaderTheme", data);
    }
  },
  mutations: {
    updatePage(state, pageReady) {
      state.status.pageReady = pageReady;
    },
    updateHeaderTheme(state, headerTheme) {
      state.status.headerDarkTheme = headerTheme;
    },
    updateWidth(state, payload) {
      state.widthPage = payload.width;
    },
    toggleHeader(state, headerIsOpen) {
      state.status.headerIsOpen = headerIsOpen;
    },
    addMetaNames(state, metaNames) {
      state.metaNames = { ...metaNames };
    },
    findScrollOffset(state, offset) {
      state.offset = offset;
    },
    setLoading(state, isUp) {
      state.isLoading = isUp;
    },
    setHeightFirstScreen(state, height) {
      state.heightFirstScreen = height
    }
  },
  state: {
    status: {
      pageReady: false,
      headerDarkTheme: true, //true-темная, false-светлая
      headerIsOpen: false
    },
    widthPage: document.documentElement.clientWidth,
    metaNames: {},
    offset: 0,
    isLoading: true,
    heightFirstScreen: 0
  },
  getters: {
    heightFirstScreen(state) {
      return state.heightFirstScreen
    },
    pageReady(state) {
      return state.status.pageReady;
    },
    headerTheme(state) {
      return state.status.headerDarkTheme;
    },
    headerIsOpen(state) {
      return state.status.headerIsOpen;
    },
    widthPage(state) {
      return state.widthPage;
    },
    metaNames: state => {
      const names = {
        title: state.metaNames.title,
        metas: []
      };
      if (state.metaNames.title) {
        names.metas.push({
          property: "og:title",
          content: `${state.metaNames.title}`,
          vmid: "og:title"
        });
      }
      if (state.metaNames.description) {
        names.metas.push({
          name: "description",
          content: `${state.metaNames.description}`
        });
        names.metas.push({
          property: "og:description",
          content: `${state.metaNames.description}`,
          vmid: "og:description"
        });
        names.metas.push({
          name: "description",
          content: `${state.metaNames.description}`
        });
      }
      if (state.metaNames.keywords) {
        names.metas.push({
          name: "Keywords",
          content: `${state.metaNames.keywords}`
        });
      }
      if (state.metaNames.follow_type) {
        names.metas.push({
          name: "robots",
          content: `${state.metaNames.follow_type}`
        });
      }
      if (state.metaNames.image) {
        names.metas.push({
          name: "twitter:image",
          content: `${window.location.origin}${state.metaNames.image}`
        });
        names.metas.push({
          property: "og:image",
          content: `${window.location.origin}${state.metaNames.image}`
        });
      }
      return names;
    },
    scrollOffset(state) {
      return state.offset;
    },
    isIPhone() {
      return /iPhone/.test(navigator.userAgent);
    },
    isSafari() {
      return (
        /Safari/.test(navigator.userAgent) && !/CriOS/.test(navigator.userAgent)
      );
    },
    isLoading(state) {
      return state.isLoading;
    }
  }
};
