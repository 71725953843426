<template>
  <div class="monq">
    <div class="hr"></div>
    <div class="monq-container">
      <div class="monq-text">
        <h2>Актуальные цены для продуктов MONQ</h2>
        <img src="@/assets/images/monq.png" alt="monq" class="monq-logo" />
      </div>
      <vform
        :inputs="inputs"
        :customCss="formCss"
        buttonType="dark"
        isMonq="true"
      />
    </div>
  </div>
</template>


<script>
import vform from "../../UI/form.vue";
export default {
  components: {
    vform,
  },
  data() {
    return {
      formCss: "flex:1 0 30%;",
      inputs: [
        {
          type: "text",
          placeholder: "Имя",
          required: true,
          name: "client_name",
          customCss: "flex:1;",
        },
        {
          type: "tel",
          placeholder: "Телефон",
          name: "phone",
        },
        {
          type: "email",
          placeholder: "E-mail",
          required: true,
          name: "email",
        },
        {
          type: "textarea",
          placeholder: "Вопрос",
          name: "description",
          customCss: "flex:3",
        },
      ],
      formSended: false,
    };
  },
  methods: {},
};
</script>


<style lang="scss" scoped>
.monq {
  background-color: #f0f0f0;
  padding: 20px 20px 60px;
  border-radius: 10px;

  .hr {
    margin: 0 0 10px;
    background-color: var(--color1);
    height: 1px;
  }

  .monq-container {
    display: flex;
  }

  .monq-text {
    flex: 1 1 50%;
    margin-right: 330px;
  }

  h2 {
    margin: 0px 0px 20px;
    font-size: 60px;
    font-weight: 400;
  }

  .monq-logo {
    width: 305px;
  }

  .monq-form {
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    .input {
      margin-right: 0;
    }
  }

  .button-container {
    display: flex;
    flex-direction: column;
  }

  .form-checkbox {
    display: flex;
    align-items: center;
    flex: 1 0 calc(50% - 10px);
    margin-bottom: 20px;
  }

  .form-btn {
    margin-left: auto;
  }
}

@media screen and (min-width: 1000px) and (max-width: $medium) {
  .monq .monq-text {
    margin-right: 0;

    h2 {
      font-size: 30px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .monq .monq-text {
    margin-right: 0;

    h2 {
      font-size: 20px;
      margin-bottom: 10px;
    }

    .monq-logo {
      width: 165px;
      margin-bottom: 20px;
    }
  }

  .monq-container {
    flex-direction: column;
  }
}

@include largePhonesAlt {
  .monq {
    padding: 20px 10px 24px 10px;
  }
}

// Сообщение отправлено
.message-delivered {
  .contact-wrapper {
    flex-direction: column;

    h2 {
      font-weight: 400;
      font-size: 40px;
      margin-bottom: 60px;
    }

    p {
      color: var(--color1);
      font-size: 24px;
      margin-bottom: 30px;
      margin-left: 260px;
      //max-width: 530px;
    }

    label {
      margin-left: 260px;
    }
  }
}
</style>