<template>
    <div :class="[classes]">
        <div class="headline__text">
            <span v-html="text"></span>
        </div>
        <div class="headline__logo"></div>
    </div>
</template>

<script>
export default {
    name: 'headlineInside',
    props: {
        text: String,
        classes: String
    },
    mounted() {
        if(this.text) {
            const regExp = new RegExp('(<a.+)(href=")(?!' + window.location.origin + ')', 'gm')
            // eslint-disable-next-line
            this.text = this.text.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
        }
    }
}
</script>

<style lang="scss" scoped>
.headline {
    position: relative;
    margin-bottom: 60px;
    padding: 20px;
    height: 586px;
    border-radius: 20px;
    // background: linear-gradient(94.5deg, #FFD200 0%, #FFF500 100%);
    background-image: url('../../../assets/images/qwe.png');
    background-size: cover;
    background-position: 0 0;
    background-repeat: no-repeat;
    &__text {
        display: flex;
        width: 100%;
        height: fit-content;
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 53px, var(--color1) 53px, var(--color1) 54px, rgba(255,255,255,0) 54px);
        background-size: 100% 56px;
        background-repeat: repeat-y;
        border-top: 1px solid var(--color1);
        >span {
            width: 100%;
            max-width: 1400px;
            font-size: 40px;
            line-height: 140%;
            color: var(--color1);
            text-transform: uppercase;
        }
    }
    &__logo {
        position: absolute;
        bottom: 20px;
        right: 20px;
        width: 71px;
        height: 58px;
        background-image: url("data:image/svg+xml,%3Csvg width='71' height='58' viewBox='0 0 71 58' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M43.9009 26.8047C45.4903 16.633 53.6335 8.5746 63.9123 7.00178C62.3229 17.1735 54.1797 25.2319 43.9009 26.8047ZM51.4796 48.0651C52.0748 49.4922 51.7619 50.6339 51.4612 50.9314C51.1605 51.229 50.0068 51.5387 48.5647 50.9436C46.1715 49.9598 44.5207 47.2514 43.8948 43.4378C47.7486 44.0632 50.4916 45.6907 51.4796 48.0651ZM34.1561 33.8065H36.8255V36.4481H34.1561V33.8065ZM22.4169 50.9496C20.9748 51.5387 19.815 51.229 19.5204 50.9375C19.2197 50.6399 18.9067 49.4922 19.5081 48.0712C20.5022 45.7029 23.2392 44.0693 27.0929 43.4499C26.4608 47.2575 24.8101 49.9659 22.4169 50.9496ZM7.07545 7.00178C17.3542 8.5746 25.4974 16.633 27.0868 26.8047C16.808 25.2319 8.66482 17.1735 7.07545 7.00178ZM67.5881 0C51.7742 0 38.7094 11.8721 37.0157 27.0841H33.9659C32.2722 11.8721 19.2136 0 3.39352 0H0V3.35818C0 19.0074 11.997 31.9361 27.3691 33.6122V36.6182C17.6058 37.6444 14.2552 43.037 13.2304 45.4964C11.6901 49.1825 12.2854 53.2815 14.7155 55.6863C16.2496 57.2045 18.4588 58 20.8152 58C22.2021 58 23.638 57.7267 25.0188 57.162C27.4979 56.1478 32.9472 52.8322 33.9904 43.1706H37.0096C38.0528 52.8322 43.4959 56.1478 45.9812 57.162C47.362 57.7267 48.7979 58 50.1848 58C52.5412 58 54.7504 57.2045 56.2845 55.6863C58.7146 53.2815 59.316 49.1825 57.7696 45.4964C56.7448 43.043 53.3942 37.6505 43.6309 36.6182V33.6122C59.003 31.9361 71 19.0074 71 3.35818V0H67.5881Z' fill='white'/%3E%3C/svg%3E");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    &__career {
        .headline__text {
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 79px, var(--color1) 79px, var(--color1) 80px, rgba(255,255,255,0) 80px);
            background-size: 100% 82px;
            >span {
                max-width: 1500px;
            }
        }
        span {
            font-size: 60px;
            ::v-deep .decoration__red {
                color: var(--color3);
            }
            ::v-deep .decoration__yellow {
                color: var(--color4);
            }
            ::v-deep .decoration__pre {
                background: linear-gradient(90deg, #FFD200 0%, #E62315 54.25%, #462DC7 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                user-select: none;
            }
        }
        @include phones {
            .headline__text {
                background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 43px, var(--color1) 43px, var(--color1) 44px, rgba(255,255,255,0) 44px);
                background-size: 100% 44px;
                >span {
                    font-size: 32px;
                }
            }
        }
        @include largePhonesAlt {
            height: 340px;
            padding: 20px 10px;
            .headline__text {
                background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 33px, var(--color1) 33px, var(--color1) 34px, rgba(255,255,255,0) 34px);
                background-size: 100% 36px;
                >span {
                    font-size: 26px;
                }
            }
        }
        @include smallPhonesAlt {
            .headline__text {
                background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 23px, var(--color1) 23px, var(--color1) 24px, rgba(255,255,255,0) 24px);
                background-size: 100% 29px;
                >span {
                    font-size: 20px;
                }
            }
        }
    }
    @include phones {
        .headline__text {
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 43px, var(--color1) 43px, var(--color1) 44px, rgba(255,255,255,0) 44px);
            background-size: 100% 44px;
            >span {
                font-size: 32px;
            }
        }
    }
    @include largePhonesAlt {
        height: 340px;
        padding: 20px 10px;
        margin-bottom: 0;
        .headline__text {
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 33px, var(--color1) 33px, var(--color1) 34px, rgba(255,255,255,0) 34px);
            background-size: 100% 36px;
            >span {
                font-size: 26px;
            }
        }
    }
    @include smallPhonesAlt {
        .headline__text {
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 23px, var(--color1) 23px, var(--color1) 24px, rgba(255,255,255,0) 24px);
            background-size: 100% 29px;
            >span {
                font-size: 20px;
            }
        }
    }
}
.career__advances {
    width: 100%;
}
[data-bgtemplate='1'], [data-bgtemplate='3'] {
    .career__advances {
        .headline__text {
            border-top: 1px solid #fff;
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 81px, #fff 81px, #fff 82px, rgba(255,255,255,0) 82px);
            background-size: 100% 84px;
        }
        span {
            font-size: 60px;
            color: #fff;
        }
        @include mediumLaptop {
            .headline__text {
                background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 58px, #fff 58px, #fff 59px, rgba(255,255,255,0) 59px);
                background-size: 100% 61px;
            }
            span {
                font-size: 46px;
            }
        }
        @include mediumPhones {
            .headline__text {
                background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 47px, #fff 47px, #fff 48px, rgba(255,255,255,0) 48px);
                background-size: 100% 50px;
            }
            span {
                font-size: 36px;
            }
        }
        @include smallPhonesAlt {
            .headline__text {
                background: none;
            }
            span {
                font-size: 20px;
            }
        }
    }
}
[data-bgtemplate='2'] {
    .career__advances {
        .headline__text {
            border-top: 1px solid var(--color1);
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 81px, var(--color1) 81px, var(--color1) 82px, rgba(255,255,255,0) 82px);
            background-size: 100% 84px;
        }
        span {
            font-size: 60px;
            color: var(--color1);
        }
        @include mediumLaptop {
            .headline__text {
                background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 58px, var(--color1) 58px, var(--color1) 59px, rgba(255,255,255,0) 59px);
                background-size: 100% 61px;
            }
            span {
                font-size: 46px;
            }
        }
        @include mediumPhones {
            .headline__text {
                background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 47px, var(--color1) 47px, var(--color1) 48px, rgba(255,255,255,0) 48px);
                background-size: 100% 50px;
            }
            span {
                font-size: 36px;
            }
        }
        @include smallPhonesAlt {
            .headline__text {
                background: none;
            }
            span {
                font-size: 20px;
            }
        }
    }
}
.advances-grid__item, .headline__career {
    .headline__logo {
        display: none;
    }
}
</style>