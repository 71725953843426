const PAGES = {
  index: "Index",
  services: "Services",
  solutions: "Solutions",
  solution: "Solution",
  products: "Products",
  license: "License",
  cases: "Cases",
  about: "/about",
  career: "/career",
  tender: "Tender",
  events: "Events"
};

export default PAGES;
