<template>
    <div class="dflex-child_maxwidth">
        <Headline :classes="'headline'" :text="value.wedo" />
        <Motto :value="value.weare" :photo="value.photo"/>
        <Advantage v-if="advantage" class="cropped" :value="advantage"/>
        <TabsSwitcher v-if="valueAbout" :container="'about'" :value="valueAbout" :settings1="settings1" />
        <Team v-if="!isIPhone && command && widthPage > 768" :container="'about'" :content="command.items" :headline='command.name' :type="'quotes'" />
        <Carousel :modalType="'quotes'" :iW="widthPage" v-if="isIPhone" :h2text="'наша команда'" :wrapped="{isWrapped: true, wrapperClass: 'office-photos'}" :carousel='command.items' :settings="achivementsSetting" type="teamAdaptive"/>
        <Carousel :modalType="'quotes'" :iW="widthPage" v-else-if="command && widthPage <= 768" :h2text="'наша команда'" :wrapped="{isWrapped: true, wrapperClass: 'office-photos'}" :carousel='command.items' :settings="achivementsSetting" type="teamAdaptive"/>
        <Achievements class="aboutAchievements" :withReleased="true"/>
        <Partners class="aboutPartners "/>
        <Carousel v-if="clientOpinion" :h2text="'Клиенты о нас'" :carousel='clientOpinion' :settings="achivementsSetting" type="reviews"/>
        <Carousel v-if="sertificats" :h2text="'Подтверждаем экспертизу по использованию отраслевых инструментов'" :carousel='sertificats' :settings="certificatesSetting" type="certficates"/>
        <!-- Если попросят выводить с сортировкой, то надо раскоментить то, что ниже и удалить 83-102 строчки -->
        <!-- <template v-for="(item,i) in value">
            <Advantage v-if="item.type && item.type=='advantage'" class="cropped" :value="item" :key="i"/>
            <Carousel v-else-if="item.type && item.type=='clientOpinion'" :carousel='item' :settings="achivementsSetting" type="reviews" :key="i"/>
            <Carousel v-else-if="item.type && item.type=='sertificats'" :carousel='item' :settings="certificatesSetting" type="certficates" :key="i"/>
            <Partners v-else-if="item.type && item.type=='clients_partners'" class="aboutPartners" :key="i"/>
            <Team v-else-if="item.type && item.type=='command'" :container="'about'" :content="item.items" :headline='item.name' :key="i"/>
            <TabsSwitcher v-else-if="item.type && item.type=='valueAbout'" :container="'about'" :value="item" :key="i"/>
        </template> -->
    </div>
</template>

<script>
import Achievements from '../../components/pages/index/achievements.vue'
import Partners from '../../components/pages/index/partners.vue'
import Carousel from '../../components/UI/carousel.vue'
import Headline from '../../components/pages/about/headline.vue'
import Advantage from '../../components/UI/advantage.vue'
import Motto from '../../components/pages/about/motto.vue'
import TabsSwitcher from '../../components/pages/about/tabsSwitcher.vue'
import Team from '../../components/pages/about/team.vue'
import axios from 'axios'
import {mapGetters,mapMutations} from 'vuex'

export default {
    metaInfo() {
        return {
            title: `${this.metaNames.title ?? 'О нас'}`,
            meta: this.metaNames.metas
        }
    },
    name: 'about',
    components: {
        Achievements,
        Partners,
        Carousel,
        Headline,
        Motto,
        TabsSwitcher,
        Team,
        Advantage
    },
    data() {
        return {
            achivementsSetting: {
                type: "loop",
                perPage: 1,
                autoplay: false,
                pagination:false,
                autoWidth:true,
                perMove: 1,
                gap: "20px"
            },
            certificatesSetting: {
                type: "loop",
                perPage: 1,
                autoplay: false,
                pagination:false,
                autoWidth:true,
                perMove: 1,
                gap: "20px"
            },
            advantage: undefined,
            clientOpinion: undefined,
            sertificats: undefined,
            command: undefined,
            valueAbout: undefined,
            value: {},
            settings1: {
                list: [
                    {class: 'w2y', icon: false},
                    {class: 'w2y', icon: false}
                ]
            },
        }
    },
    methods: {
        ...mapMutations(['addMetaNames']),
    },
    created() {
        axios.get('/api/aboutus/we')
            .then((response) => {
                this.addMetaNames(response.data.seo)
                this.value = response.data
                for(let item in response.data) {
                    let tmp = response.data[item]
                    if(typeof tmp =='object') {
                        if(tmp.type=='advantage') {
                            this.advantage = tmp
                        }
                        if(tmp.type=='clientOpinion') {
                            this.clientOpinion = tmp
                        }
                        if(tmp.type=='sertificats') {
                            this.sertificats = tmp
                        }
                        if(tmp.type=='command') {
                            this.command = tmp
                        }
                        if(tmp.type=='valueAbout') {
                            this.valueAbout = tmp
                        }
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            });
        window.addEventListener("resize", this.myEventHandler);
    },
    mounted() {
        this.iW = window.innerWidth
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    },
    computed: {
        ...mapGetters(['metaNames', 'widthPage', 'isIPhone']),
    }
}
</script>

<style lang="scss" scoped>
.aboutPartners {
    padding-top: 160px;
    @include largePhonesAlt {
        padding-top: 30px;
    }
}
section.cropped {
    @include largePhonesAlt {
        margin-top: 0;
        margin-bottom: 48px;
        padding-bottom: 0;
        &:last-child,
        &:first-child {
            margin-bottom: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }
}
</style>

<style lang="scss">
section.cropped {
    .values {
        .buttons-group__values {
            @include largePhonesAlt {
                padding-top: 0;
            }
        }
    }
    .values__text {
        .values__list {
            @include largePhonesAlt {
                margin-bottom: 0;
            }
        }
    }
    .splide__arrows {
        @include largePhonesAlt {
            padding-top: 10px;
            margin-bottom: 24px;
        }
    }
}
section.aboutAchievements {
    padding-top: 0;
    .released-projects {
        @include largePhonesAlt {
            padding-bottom: 32px !important;
        }
    }
    @include largePhonesAlt {
        padding-top: 0;
    }
}
</style>