<template>
    <section class="cropped bt">
        <hr/>
        <h2>{{h1}}</h2>
        <!-- <hr class="animate-elem shiftLeft wrap"/>
        <h2 class="animate-elem shiftUp wrap">{{h1}}</h2> -->
        <div class="advances-grid">
            <template v-for="(row, index) in settings">
                <div v-if="iW > 884" :key="`${row.id}_1`" class="advances-grid__item" :data-bgtemplate="index % 2 == 0 && index+1">
                <!-- <div v-if="iW > 884" :key="`${row.id}_1`" class="advances-grid__item animate-elem fadeInUpSmall" :data-bgtemplate="index % 2 == 0 && index+1"> -->
                    <span v-if="index % 2 == 0">
                        <HeadlineInside class="career__advances" :text="row.name"></HeadlineInside>
                        <div class="subtext">
                            <span v-html="row.description"></span>
                            <span>{{ index + 1 }}</span>
                        </div>
                    </span>
                    <img v-if="index % 2 != 0" :src="'/storage/'+row.image" alt="">
                </div>
                <div v-if="iW > 884" :key="`${row.id}_2`" class="advances-grid__item" :data-bgtemplate="index % 2 == 0 || index+1">
                <!-- <div v-if="iW > 884" :key="`${row.id}_2`" class="advances-grid__item animate-elem fadeInUpSmall" :data-bgtemplate="index % 2 == 0 || index+1"> -->
                    <span v-if="index % 2 != 0">
                        <HeadlineInside class="career__advances" :text="row.name"></HeadlineInside>
                        <div class="subtext">
                            <span v-html="row.description"></span>
                            <span>{{ index + 1 }}</span>
                        </div>
                    </span>
                    <img v-if="index % 2 == 0" :src="'/storage/'+row.image" alt="">
                </div>
                <div v-if="iW <= 884" :key="`${row.id}_3`" class="advances-grid__item" :data-bgtemplate="index+1">
                <!-- <div v-if="iW <= 884" :key="`${row.id}_3`" class="advances-grid__item animate-elem fadeInUpSmall" :data-bgtemplate="index+1"> -->
                    <span>
                        <HeadlineInside class="career__advances" :text="row.name"></HeadlineInside>
                        <div class="subtext">
                            <span v-html="row.description"></span>
                            <span>{{ index + 1 }}</span>
                        </div>
                    </span>
                </div>
                <img v-if="iW <= 884" :key="`${row.id}_4`" :src="'/storage/'+row.image" alt="">
            </template>
        </div>
    </section>
</template>

<script>
import HeadlineInside from './headlineInside.vue'
export default {
    name: 'advancedGrid',
    components: {
        HeadlineInside
    },
    props: {
        settings: Array,
        h1: String,
        qwe: Array,
        iW: Number
    },
    mounted() {
        // добавляется атрибут rel="nofollow" для ссылок которые ведут на сторонние ресурсы
        if(this.settings && Array.isArray(this.settings)) {
            const regExp = new RegExp('(<a.+)(href=")(?!' + window.location.origin + ')', 'gm')
            this.settings = this.settings.map((row) => {
                // eslint-disable-next-line
                row.description = row.description.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
                return row
            })
        }
    }
}
</script>

<style lang="scss" scoped>
hr {
    border-top: 1px solid var(--color1);
    margin: 0;
}
h2 {
    width: 100%;
    font-size: 100px;
    text-transform: uppercase;
    color: var(--color1);
    font-weight: 400;
    // margin-bottom: 60px;
    @include largePhonesAlt {
        font-size: 32px;
        margin-bottom: 24px;
    }
    @include smallPhonesAlt {
        font-size: 32px;
    }
}

.hidden-wrapper {
    margin-bottom: 60px;
    @include largePhonesAlt {
        margin-bottom: 24px;
    }
}

.advances-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    grid-gap: 20px;
    &__item {
        overflow: hidden;
        height: 519px;
        background-position: center, center;
        background-size: cover, cover;
        background-repeat: no-repeat, no-repeat;
        >img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        >span {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            box-sizing: border-box;
            padding: 20px;
            .subtext {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                span {
                    &:first-child {
                        flex: 1;
                        font-size: 20px;
                        line-height: 140%;
                        ::v-deep p {
                            margin: 0;
                        }
                    }
                    &:last-child {
                        margin-left: 108px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 56px;
                        height: 56px;
                        background-color: #fff;
                        border-radius: 50%;
                        font-size: 24px;
                        color: var(--color1);
                    }
                }
            }
        }
    }
    [data-bgtemplate='1'] {
        background-image: url('../../../assets/images/1232q.png'), linear-gradient(110.06deg, #FFD200 0%, #E62315 33.92%, #001B81 101.77%);
        >span {
            .subtext {
                span {
                    &:first-child {
                        color: #fff;
                    }
                }
            }
        }
    }
    [data-bgtemplate='2'] {
        background-image: url('../../../assets/images/1231q.png'), linear-gradient(94.5deg, #FFD200 0%, #FFF500 100%);
        >span {
            .subtext {
                span {
                    &:first-child {
                        color: var(--color1);
                    }
                }
            }
        }
    }
    [data-bgtemplate='3'] {
        background-image: url('../../../assets/images/1233q.png'), linear-gradient(94.5deg, #00B2FF 0%, #1400FF 100%);
        >span {
            .subtext {
                span {
                    &:first-child {
                        color: #fff;
                    }
                }
            }
        }
    }
    @include smallLaptop {
        &__item {
            >span {
                .subtext {
                    flex-direction: column;
                    span {
                        &:last-child {
                            margin-top: 16px;
                        }
                    }
                }
            }
        }
    }
    @include mediumAlt {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
        &__item {
            height: fit-content;
            >span {
                .subtext {
                    margin-top: 46px;
                    span {
                        &:first-child, &:last-child {
                            font-size: 16px;
                        }
                        &:last-child {
                            width: 32px;
                            height: 32px;
                        }
                    }
                }
            }
        }
        >img {
            width: 100%;
            height: 100%;
            max-height: 400px;
            object-fit: cover;
        }
    }
}
</style>