<template>
    <div>
        <First />
        <CasesNew :cases="cases" class="section" />
        <Achievements />
        <Partners />
        <Calculator />
    </div>
</template>
<script>
    import First from '../components/pages/index/first.vue'
    // import Cases from '../components/UI/caseScroll.vue'
    import CasesNew from '../components/pages/index/casesNew.vue'
    import Achievements from '../components/pages/index/achievements.vue'
    import Partners from '../components/pages/index/partners.vue'
    import Calculator from '../components/pages/index/calculator.vue'
    import axios from 'axios'
    import eventHub from '../components/eventHub'

    import {
        mapActions,
        mapMutations
    } from 'vuex'
    export default {
        components: {
            First,
            CasesNew,
            Achievements,
            Partners,
            Calculator
        },
        data() {
          return {
            cases: {
              name: 'Кейсы',
              type: 'cases',
              description: 'Реализуем комплексные внедрения для государственного и бизнес сектора',
              link: "/cases",
              index: "true",
              value: []
            }
          }
        },
        mounted() {
            const time = Date.now()
            eventHub.$emit('updateLoader', true)
            // this.setAnimationState(this.$route.name)
            this.fetchHeaderTheme(true)
            axios.get('/api/cases_main ')
            .then((response) => {
              this.cases.value = response.data.cases
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
              const setTime = Date.now() - time > 1000 ? 0 : 1000 - (Date.now() - time)
              setTimeout(() => {
                eventHub.$emit('updateLoader', false)
                setTimeout(() => {
                  this.setLoading(false)
                }, 1000)
              }, setTime)
            });
        },
        methods: {
          ...mapActions(['fetchHeaderTheme']),
          ...mapMutations(['setLoading'])
        }
    }
</script>
<style lang="scss" scoped>
@import "../assets/styles/mixins";
@import "../assets/styles/variables";

      section {
        padding-top: var(--paddingTop);
        @include smallDesktop {
          padding-top: var(--paddingTopLaptop);
        }
        @include smallLaptop {
          padding-top: var(--paddingTopPhones);
        }
        & h2.line {
            position: relative;
          padding-top: 0;

            &:before {
                content: '';
                left: 0;
                top: -20px;
                width: 100%;
                height: 1px;
                position: absolute;
                background-color: var(--color1);
            }
        }
    }
</style>

<style lang="scss" >
@import "../assets/styles/mixins";
@import "../assets/styles/variables";
.app__main__container {
  section {
    &>h2.line {
      @include mediumPhones {
        padding-top: 10px !important;
      }
    }

    @include largePhonesAlt {
      &:nth-child(3).section {
        padding-top: 100px;
      }
    }
  }
}
</style>
