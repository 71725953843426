<template>
  <svg @click="toggle" class="ham ham6" :class="{active: this.headerIsOpen}" viewBox="0 0 100 100" width="80">
    <path class="line middle" :class="{active: this.headerIsOpen}" d="m 70,40 c 0,0 -32.213436,0 -40,0 -7.786564,0 -6.428571,-4.640244 -6.428571,-8.571429 0,-5.895471 6.073743,-11.783399 12.286435,-5.570707 6.212692,6.212692 28.284272,28.284272 28.284272,28.284272"></path>
    <path class="line bottom" :class="{active: this.headerIsOpen}" d="m 69.575405,57.073826 h -40 c -13.100415,0 -14.380204,-31.80258 -6.899646,-33.421777 24.612039,-5.327373 -9.016154,52.337577 12.75751,30.563913 l 28.284272,-28.284272"></path>
  </svg>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: "burger",
  methods: {
    ...mapMutations(['toggleHeader']),
    toggle() {
      this.toggleHeader(!this.headerIsOpen)
    }
  },
  computed: mapGetters(['headerIsOpen'])
}
</script>

<style scoped>
  .ham {
    background-color: var(--color2);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    border-radius: 100px;
    width: 36px;
    height: 36px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: 400ms;
    user-select: none;
  }
  .hamRotate.active {
    transform: rotate(45deg);
  }
  .hamRotate180.active {
    transform: rotate(180deg);
  }
  .line {
    fill:none;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke: #fff;
    stroke-width:3;
    stroke-linecap:round;
  }


  .ham6 .middle {
    stroke-dasharray: 40 111;
  }
  .ham6 .bottom {
    stroke-dasharray: 40 172;
  }
  .ham6.active .middle {
    transform: translateY(10px);
    stroke-dashoffset: -71px;
  }
  .ham6.active .bottom {
    transform: translateY(10px);
    stroke-dashoffset: -132px;
  }

  .ham.active {
    background-color: var(--color4);
  }
</style>
