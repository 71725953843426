<template>
    <form :class="classes" action="" @submit.prevent="qwe()">
        <div :class="messageReceived === 0 ? 'form__wrapper' : 'form__wrapper__column'">
            <span>{{ setHeadlineText }}</span>
            <template v-if="messageReceived === 0 && (info.type === 'contacts' || info.type === 'events')">
                <fieldset>
                    <template v-for="(field, index) in info.fields">
                        <input v-mask="field.masked" v-model="inputvalues[index].text" v-if="field.type != 'textarea'" :key="index" :class="field.class" :type="field.type" :placeholder="field.placeholder" :required="field.required ? true : null">
                        <textarea v-else :key="index" :placeholder="field.placeholder" v-model="inputvalues[index].text" :required="field.required ? true : null" />
                    </template>
                    <span class="error">{{error}}</span>
                    <Checkbox @click="confirmTerms" placeholder="Согласен на обработку персональных данных в соответствии с политикой конфиденциальности" :checked='buttonIsActive' :required="true" />
                    <AnimatedButton :disabled="buttonStatus" :classes="['buttons-group__tag-filter']" :settings="{class: 'w2y', text: buttonText, icon: true}" />
                </fieldset>
            </template>
            <template v-if="messageReceived === 0 && info.type === 'positionCall'">
                <fieldset>
                    <template v-for="(field, index) in info.fields">
                        <input v-mask="field.masked" v-model="inputvalues[index].text" v-if="field.type != 'textarea' && field.type != 'file'" :key="index" :class="field.class" :type="field.type" :placeholder="field.placeholder" :required="field.required ? true : null">
                        <label class="input-label input-label__file" v-else-if="field.type === 'file'" :key="index">
                            <input @change="processFile($event, index)" :class="field.class" :type="field.type" :required="field.required ? true : null">
                            <div class="icon icon__add-file"></div>
                            <span>{{inputvalues[index].text ? inputvalues[index].text : 'Прикрепить файл'}}</span>
                            <div v-if="inputvalues[index].text" @click.prevent="removeFile($event, index)" class="icon icon__remove-file"></div>
                        </label>
                        <textarea v-else :key="index" :placeholder="field.placeholder" v-model="inputvalues[index].text" :required="field.required ? true : null" />
                    </template>
                    <div class="fieldset-control">
                        <Checkbox @click="confirmTerms" placeholder="Согласен на обработку персональных данных в соответствии с политикой конфиденциальности" :checked='buttonIsActive' :required="true" />
                        <AnimatedButton :disabled="buttonStatus" :classes="['buttons-group__tag-filter']" :settings="{class: 'w2y', text: buttonText, icon: true}" />
                    </div>
                </fieldset>
            </template>
            <template v-else-if="messageReceived === 1 && (info.type === 'contacts' || info.type === 'positionCall')">
                <div class="form-message">
                    <span v-if="info.type === 'contacts'" class="small">Мы получили ваше сообщение и свяжемся с вами в течении 15 минут.</span>
                    <span v-if="info.type === 'positionCall'" class="small">Спасибо. Мы получили ваш отклик и свяжемся с вами в ближайшее время.</span>
                    <AnimatedButton :disabled="buttonStatus" :classes="['buttons-group__tag-filter']" :settings="{class: 'w2y', text: 'Отправить ещё', icon: true}" @click="resetForm()" />
                </div>
            </template>
        </div>
    </form>
</template>
<script>
import Checkbox from '../../UI/checkbox.vue'
import AnimatedButton from '../../UI/AB.vue'
import axios from 'axios'

export default {
    name: 'contactForm',
    components: {
        Checkbox,
        AnimatedButton
    },
    data() {
        return {
            buttonIsActive: false,
            inputvalues: [],
            messageReceived: 0,
            error: null
        }
    },
    props: {
        info: Object,
        classes: Array,
        content: Object
    },
    created(){
        this.inputvalues = this.info.fields.map((i) => {
            return {text: '', req: i.required}
        })
    },
    computed: {
        buttonStatus() {
            const status = this.inputvalues.filter(i => {
                return i.req
            }).every(k => {
                return k.text.length
            })
            if (status && this.buttonIsActive) {
                return false
            } else {
                return true
            }
        },
        buttonText() {
            let text = ''
            if (this.info.type == 'contacts' || this.info.type == 'positionCall') {
                text = 'Отправить'
            } else {
                text = 'Подписаться'
            }
            return text
        },
        setHeadlineText() {
            let text = ''
            if (this.messageReceived === 0) {
                text = this.info.text
            } else {
                if (this.info.type === 'contacts') {
                    text = 'Сообщение отправлено'
                } else if (this.info.type === 'positionCall') {
                    text = 'Отклик отправлен'
                } else {
                    text = 'Теперь вы подписаны на новости'
                }
            }
            return text
        }
    },
    methods: {
        getCookie(name) {
            const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
            if (match) return match[2];
        },
        async qwe() {
            // let url
            // let inf = 'asd'
            // if (this.info.type === 'contacts') {
            //     url = '/api/addsubscribers';
            //     inf = this.content
            // }
            // if (this.info.type === 'events') {
            //     url = '/api/addsubscribers';
            //     inf = this.content
            // }
            // if (this.info.type === 'positionCall') {
            //     url = '/api/addsubscriber';
            //     inf = this.content
            // }
            // try {
            //     const response = await fetch(url, {
            //         method: 'POST',
            //         body: JSON.stringify({
            //             email: inf
            //         }),
            //         headers: {
            //             'Content-type': 'application/json; charset=UTF-8',
            //         }
            //     });
            //     const json = await response.json();
            //     console.log(response)
            //     if (this.info.type === 'contacts') {
            //         console.log('Contacts:', json);
            //     }
            //     if (this.info.type === 'events') {
            //         console.log('events:', json);
            //     }
            //     if (this.info.type === 'positionCall') {
            //         console.log('Position is:', json.title);
            //     }
            //     this.messageReceived = 1
            // } catch (error) {
            //     console.error('Ошибка:', error);
            //     this.messageReceived = 2
            // }
            axios.post("/api/addsubscriber", {
                email: this.inputvalues[0].text,
                roistatVisit: +this.getCookie('roistat_visit')
            },{
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            .then((response) => {
                if(response.data.success) {
                    this.messageReceived = 1
                    this.error = null
                } else {
                    this.messageReceived = 0
                    this.error = 'Вы уже подписаны на новости'
                }
            })
            .catch((error) => {
                console.log(error)
            });
        },
        processFile(input, index) {
            const filesLength = input.target.files.length
            if (filesLength) {
                const fileName = input.target.files[0].name
                this.inputvalues[index].text = fileName
            }
        },
        removeFile(item, index) {
            const inp = item.path[1].childNodes[0]
            inp.value = null
            this.inputvalues[index].text = ''
        },
        resetForm() {
            this.inputvalues.forEach(i => {
                i.text = ''
            })
            this.messageReceived = 0
            this.buttonIsActive = false
        },
        confirmTerms(ev){
            this.buttonIsActive = ev.target.checked
        }
    }
}
</script>
<style lang="scss" scoped>
.about__events__sidebar__form, .contacts__bottom__form {
    margin-bottom: 60px;
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
    background-color: #F0F0F0;
    border-radius: 10px;
    .form__wrapper, .form__wrapper__column {
        box-sizing: border-box;
        padding: 4px 0 0 0;
        border-top: 1px solid var(--color1);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        fieldset {
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            border: none;
        }
        >span {
            font-size: 24px;
            line-height: 130%;
            text-transform: uppercase;
            color: var(--color1);
            margin-bottom: 28px;
        }
        input, textarea {
            box-sizing: border-box;
            padding: 8px;
            width: 100%;
            height: 70px;
            background-color: #fff;
            outline: none;
            border: none;
            margin-bottom: 20px;
            color: var(--color1);
            font-family: 'SuisseIntl';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            border: 1px solid transparent;
            &::placeholder {
                color: var(--color1);
                font-family: 'SuisseIntl';
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
            }
            &:active, &:focus {
                border: 1px solid var(--color4);
            }
        }
        .input-label {
            position: relative;
            &__file {
                padding: 5px 10px;
                width: fit-content;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                height: auto;
                cursor: pointer;
                &:hover {
                    .icon {
                        &__add-file {
                        background-image: url("data:image/svg+xml,%3Csvg width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.10196 15.8091C3.73871 15.8091 2.45737 15.2786 1.49392 14.3151C0.530479 13.3517 0 12.0704 0 10.7071C0 9.34385 0.530479 8.0625 1.49392 7.09906L6.82602 1.76696L7.65294 2.59389L2.32085 7.92403C1.57779 8.66709 1.16822 9.65394 1.16822 10.7051C1.16822 11.7564 1.57779 12.7432 2.32085 13.4863C3.85378 15.0192 6.35015 15.0192 7.88308 13.4863L15.3995 5.96984C16.4956 4.87378 16.4956 3.08926 15.3995 1.9932C14.3034 0.897134 12.5189 0.897134 11.4229 1.9932L3.90643 9.50962C3.58659 9.82947 3.41106 10.2527 3.41106 10.7051C3.41106 11.1576 3.58659 11.5808 3.90643 11.9007C4.22628 12.2205 4.64949 12.3961 5.10196 12.3961C5.55443 12.3961 5.97764 12.2205 6.29749 11.9007L11.6296 6.56858L12.4565 7.3955L7.12636 12.7276C6.58613 13.2678 5.86843 13.5662 5.10391 13.5662C4.3394 13.5662 3.62169 13.2678 3.08146 12.7276C2.54123 12.1874 2.24284 11.4697 2.24284 10.7051C2.24284 9.94063 2.54123 9.22293 3.08146 8.6827L10.5979 1.16627C11.3507 0.413462 12.3492 0 13.4141 0C14.479 0 15.4775 0.413462 16.2303 1.16627C16.9831 1.91909 17.3966 2.91764 17.3966 3.98249C17.3966 5.0454 16.9831 6.0459 16.2303 6.79871L8.71195 14.3132C7.74656 15.2786 6.46522 15.8091 5.10196 15.8091Z' fill='%23ff0000'/%3E%3C/svg%3E");
                        }
                    }
                    span {
                        color: var(--color3);
                    }
                }
                input {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 0;
                    width: 0;
                    opacity: 0;
                    &:focus {
                        outline: none;
                    }
                }
                .icon {
                    margin-right: 10px;
                    &__add-file {
                        width: 18px;
                        height: 16px;
                        background-image: url("data:image/svg+xml,%3Csvg width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.10196 15.8091C3.73871 15.8091 2.45737 15.2786 1.49392 14.3151C0.530479 13.3517 0 12.0704 0 10.7071C0 9.34385 0.530479 8.0625 1.49392 7.09906L6.82602 1.76696L7.65294 2.59389L2.32085 7.92403C1.57779 8.66709 1.16822 9.65394 1.16822 10.7051C1.16822 11.7564 1.57779 12.7432 2.32085 13.4863C3.85378 15.0192 6.35015 15.0192 7.88308 13.4863L15.3995 5.96984C16.4956 4.87378 16.4956 3.08926 15.3995 1.9932C14.3034 0.897134 12.5189 0.897134 11.4229 1.9932L3.90643 9.50962C3.58659 9.82947 3.41106 10.2527 3.41106 10.7051C3.41106 11.1576 3.58659 11.5808 3.90643 11.9007C4.22628 12.2205 4.64949 12.3961 5.10196 12.3961C5.55443 12.3961 5.97764 12.2205 6.29749 11.9007L11.6296 6.56858L12.4565 7.3955L7.12636 12.7276C6.58613 13.2678 5.86843 13.5662 5.10391 13.5662C4.3394 13.5662 3.62169 13.2678 3.08146 12.7276C2.54123 12.1874 2.24284 11.4697 2.24284 10.7051C2.24284 9.94063 2.54123 9.22293 3.08146 8.6827L10.5979 1.16627C11.3507 0.413462 12.3492 0 13.4141 0C14.479 0 15.4775 0.413462 16.2303 1.16627C16.9831 1.91909 17.3966 2.91764 17.3966 3.98249C17.3966 5.0454 16.9831 6.0459 16.2303 6.79871L8.71195 14.3132C7.74656 15.2786 6.46522 15.8091 5.10196 15.8091Z' fill='%23001D82'/%3E%3C/svg%3E");
                    }
                    &__remove-file {
                        position: absolute;
                        right: -23px;
                        top: 6px;
                        width: 12px;
                        height: 12px;
                        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='10.1924' y1='1.70711' x2='1.70712' y2='10.1924' stroke='%23001D82' stroke-width='2'/%3E%3Cline x1='1.70711' y1='1.70711' x2='10.1924' y2='10.1924' stroke='%23001D82' stroke-width='2'/%3E%3C/svg%3E");
                    }
                }
                span {
                    font-size: 14px;
                    color: var(--color1);
                }
            }
        }
        textarea {
            height: 100px;
            resize: none;
        }
        label {
            margin-bottom: 28px;
        }
        button {
            align-self: flex-end;
        }
    }
    .form__wrapper__column {
        flex-direction: column;
    }
    @include smallLaptop {
        .form__wrapper {
            >span {
                font-size: 18px;
            }
            input {
                height: 55px;
            }
        }
    }
    @include largePhonesAlt {
        .form__wrapper {
            >span {
                font-size: 14px;
            }
            input {
                height: 50px;
            }
        }
    }
}
.about__events__sidebar__form {
    margin-top: 60px;
    @include desctop {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    @include smallLaptop {
        margin-top: 18px;
        margin-bottom: 18px;
    }
    @include largePhonesAlt {
        margin-top: 36px;
    }
}
.contacts__bottom__form {
    margin: 0;
    .form__wrapper, .form__wrapper__column {
        flex-direction: row;
        >span {
            width: 50%;
            font-size: 60px;
        }
        .form-message {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            max-width: 533px;
            margin: 105px 0 279px 50%;
            span {
                &.small {
                    text-transform: none;
                    font-size: 24px;
                    color: var(--color1);
                    margin-bottom: 30px;
                }
            }
        }
        >fieldset {
            margin-top: 12px;
            width: 50%;
        }
    }
    .form__wrapper__column {
        flex-direction: column;
        >span {
            width: 100%;
        }
    }
    &.position-callback__form {
        min-height: 50vh;
        background-color: #fff;
        .form__wrapper, .form__wrapper__column {
            flex-direction: column;
            input, textarea {
                background-color: #f0f0f0;
            }
            .form-message {
                max-width: 100%;
                margin: 0;
                >span {
                    &.small {
                        font-size: 20px;
                        max-width: 438px;
                    }
                }
            }
            >span {
                font-size: 24px;
            }
            >fieldset {
                width: 100%;
                .fieldset-control {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .input_container {
                        max-width: 373px;
                        margin-bottom: 5px;
                    }
                    @include mediumPhones {
                        flex-direction: column;
                        align-items: flex-start;
                        .input_container {
                            margin-bottom: 32px;
                        }
                    }
                }
            }
        }
        @include mediumPhones {
            padding: 0;
        }
    }
    @include largePhonesAlt {
        padding-left: 10px;
        padding-right: 10px;
        .form__wrapper {
            flex-direction: column;
            >span {
                width: 100%;
                font-size: 20px;
            }
            >fieldset {
                width: 100%;
                min-width: unset;
            }
        }
        .form__wrapper, .form__wrapper__column {
            >span {
                font-size: 30px;
            }
            .form-message {
                max-width: 100%;
                margin: 0;
                span {
                    &.small {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
.error {
    color: var(--color3);
    font-size: 12px;
    margin-top: -8px;
    padding-bottom: 12px;
}

.model {
    .animate-elem {
        opacity: 1;
    }
}
</style>