<template>
  <header ref="header" v-bind:class="{dark:headerTheme, open: this.headerIsOpen}" id="header" :style="{ background: this.bgc }">
    <div class="header-wrapper" :class="{ open: this.headerIsOpen }">
      <router-link to="/">
        <img v-if="headerTheme && !headerIsOpen" class="logo" src="../../src/assets/images/logo--light.svg" alt="Logo">
        <img v-else-if="!headerTheme || headerIsOpen" class="logo" src="../../src/assets/images/logo.svg" alt="Logo">
      </router-link>
      <div class="header-container">
        <template v-if="widthPage > 1200">
          <div class="header-item">
            <router-link class="header-link header-link--solutions" to="/solutions">Решения</router-link>
            <router-link class="header-link header-link--services" to="/services">Услуги</router-link>
            <router-link class="header-link header-link--products" to="/products">Продукты</router-link>
            <router-link class="header-link header-link--cases" to="/cases">Кейсы</router-link>
          </div>
          <Search />
          <div class="header-item">
            <router-link class="header-link" to="/license">Лицензии</router-link>
            <router-link class="header-link" :class="{'router-link-active': ['About', 'Career', 'Events', 'Contacts'].indexOf(this.$route.name) >= 0}" to="/about">О нас</router-link>
          </div>
          <div class="header-item">
            <router-link class="header-link" to="/tender">Пригласить в тендер</router-link>
          </div>
        </template>
        <Search class="search-button" v-if="widthPage <= 1200"/>
        <Burger v-if="widthPage <= 1200" />
      </div>
    </div>
    <transition name="menu-items" v-if="widthPage <= 1200 && this.headerIsOpen">
      <div class="burger-menu-wrapper">
        <div class="header-item">
          <router-link class="header-link header-link--solutions" to="/solutions">Решения</router-link>
          <router-link class="header-link header-link--services" to="/services">Услуги</router-link>
          <router-link class="header-link header-link--products" to="/products">Продукты</router-link>
          <router-link class="header-link header-link--cases" to="/cases">Кейсы</router-link>
        </div>
        <div class="header-item">
          <router-link class="header-link" to="/license">Лицензии</router-link>
          <router-link class="header-link" to="/about">О нас</router-link>
          <router-link class="header-link" to="/tender">Пригласить в тендер</router-link>
        </div>
      <div class="burger-menu-background" @mousedown="toggle"></div>
      </div>
    </transition>
  </header>
</template>

<script>
  import Search from "../components/UI/search.vue"
  import Burger from "@/components/UI/burger";
  import eventHub from '../components/eventHub'
  import {
    mapGetters, mapMutations
  } from 'vuex'

  export default {
    components: {
      Search,
      Burger
    },
    emits: ['toggle'],
    data() {
      return {
        scrollDirection: '',
        serviceGradient: 0,
        serviceBackgroundUpdate: 0,
        eleStart: 0,
        serviceBG: {}
      }
    },
    methods: {
      ...mapMutations(['toggleHeader']),
      toggle() {
        this.toggleHeader(!this.headerIsOpen)
      },
    },
    computed: {
      ...mapGetters(['headerIsOpen', 'widthPage', 'scrollOffset']),
      headerTheme: function () {
        if (this.$route.name == 'Index') {
          return true
        } else {
          return false
        }
      },
      bgc: function () {
        if (this.$route.name === 'Services') {
          return `linear-gradient(to right, #fff ${this.eleStart}px, #FFEC94 ${this.eleStart}px, #FFEC94 ${this.serviceBackgroundUpdate}px, rgb(255,255,255) ${this.serviceBackgroundUpdate}px, rgb(255,255,255) 100%)`
        } else if (this.$route.name === 'Index') {
          return this.scrollOffset > 50 ? '' : 'transparent'
        } else {
          return ''
        }
      }
    },
    mounted() {
      eventHub.$on('serviceBackgroundUpdate', data => {
        if (data.ele) {
          this.eleStart = data.nmber > 1 ? data.ele : 0
        }
        this.serviceBG[data.type] = data.nmber
        if (this.serviceBG.scroll < 0) {
          this.serviceBackgroundUpdate = 0
        } else {
          this.serviceBackgroundUpdate = this.serviceBG.slide
        }
      })
    }
  }
</script>
<style lang="scss">
  @include mediumLaptopAlt {
    :root{
      --headerHeight: 56px !important;
    }
  }
</style>
<style lang="scss" scoped>
  @import "../assets/styles/mixins";
  @import "../assets/styles/variables";

  .dark {
    .menu-items-enter, .menu-items-leave-to {
      background: var(--color1) !important;
    }

  }

  .menu-items-enter, .menu-items-leave-to {
    max-height: 0 !important;
  }

  .menu-items-enter-to, .menu-items-leave{
    max-height: 500px !important;
  }

  .menu-items-enter-active, .menu-items-leave-active {
    transition: all .2s linear;
    overflow: hidden;
  }

  .header-wrapper {
    transition: .4s;
    box-sizing: border-box;
    margin: 0 auto;
    padding: var(--mainPadding);
    display: flex;
    width: 100%;
    max-width: $mainMaxWidth;
    margin: auto;
    padding-right: calc(var(--mainPadding) + 18px);

    &.open {
      background-color: var(--color5);
    }

    @include mediumLaptop {
      padding: 10px;
      box-sizing: border-box;
    }
  }

  header {
    overflow: visible;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: var(--headerHeight);
    display: flex;
    box-sizing: border-box;
    z-index: var(--headerIndex);
    background-color: var(--color5);
    // transition: all .3s ease;

    &.hidden {
      overflow: hidden;
      height: 0;
    }

    &.dark {
      z-index: var(--headerIndex);
      background-color: var(--color5);
      // transition: .1s height linear;
      background-color: var(--color1);


      .header-link {
        color: var(--color5);

        &.header-link--solutions,
        &.header-link--services,
        &.header-link--products {
          &:after {
            background-image: url(../../src/assets/images/plus--light.svg);
          }

          @include mediumLaptop {
            margin-left: 10px;
          }
        }

        &.header-link--cases {
          border-color: var(--color5);

          @include mediumLaptop {
            margin-left: 10px;
          }
        }
      }

      .search-button {
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='19' viewBox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='0.646447' y1='18.6464' x2='4.64645' y2='14.6464' stroke='%23fff'/%3E%3Ccircle cx='12' cy='8' r='7.5' stroke='%23fff'/%3E%3C/svg%3E%0A");
      }
    }

    &.hidden {
      // автоскрытие хедера
      // height: 0;
      overflow: hidden;
    }

    &.open {
      background-color: var(--color5);
    }
  }

  .logo {
    transition: .1s;
    padding-right: 110px;
    width: 164px;

    @include mediumLaptop {
      padding-right: 48px;
      width: 110px;
    }
  }

  .header-container {
    z-index: var(--headerIndex);
    flex: 1;
    display: flex;
    justify-content: space-between;
  }

  .header-item {
    display: flex;
    align-items: flex-start;
  }

  .header-link {
    font-size: 13px;
    line-height: 15px;
    text-transform: uppercase;
    text-decoration: none;
    color: var(--color1);
    padding-top: 6px;
    transition: .3s all ease;

    &.router-link-active {
      color: var(--color3)
    }

    &+.header-link {
      margin-left: 46px;

      @include mediumLaptop {
        margin-left: 32px;
      }
    }

    &.header-link--solutions,
    &.header-link--services,
    &.header-link--products,
    &.header-link--cases {
      margin-left: 10px;
      position: relative;
      padding: 6px 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: var(--color1);
      background-size: 100%;

      &.router-link-active {
        color: var(--color5) !important;
      }
    }

    &.header-link--solutions,
    &.header-link--services,
    &.header-link--products {
      &:after {
        content: ' ';
        margin-left: 15px;
        width: 9px;
        height: 9px;
        background-image: url(../../src/assets/images/plus.svg);
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    &.header-link--solutions {
      border: 1px solid var(--color2);
      box-sizing: border-box;
      border-radius: 60px;

      &.router-link-active {
        background: var(--color2);
        color: var(--color5);
          &:after {
          content: ' ';
          margin-left: 15px;
          width: 9px;
          height: 9px;
          background-image: url(../../src/assets/images/plus--light.svg);
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }


    &.header-link--services {
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(../assets/images/red-figure.png);

        &.router-link-active {
        color: var(--color5);
        background-image: url(../../src/assets/images/red-figure-active.svg);

          &:after {
          content: ' ';
          margin-left: 15px;
          width: 9px;
          height: 9px;
          background-image: url(../../src/assets/images/plus--light.svg);
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }

      &.header-link--products {
          background-repeat: no-repeat;
          background-position: center;
        background-image: url(../assets/images/yellow-figure.png);

      &.router-link-active {
        color: var(--color1);
        background-image: url("data:image/svg+xml,%3Csvg width='128' height='27' viewBox='0 0 128 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.40109 26.5L0.623308 0.5H121.599L127.377 26.5H6.40109Z' fill='%23FFD200' stroke='%23FFD200'/%3E%3C/svg%3E%0A");
      }
    }

    &.header-link--cases {
      border: 1px solid var(--color1);
      box-sizing: border-box;
      border-radius: 60px;

      &.router-link-active {
        background-color: var(--color1);
        color: var(--color5)
      }
    }
  }

  .header-dark-mask {
    background: #000;
    position: absolute;
    z-index: -11;
    width: 100%;
    height: 100vh;
  }

  .burger-menu-wrapper {
    position: absolute;
    z-index: -1;
    left: 0;
    top: var(--headerHeight);
    background: #fff;
    width: 100vw;
    padding-left: var(--mainPadding);
    padding-right: 26px;
    box-sizing: border-box;

    display: flex;
    justify-content: space-between;

    @include extraSmallPhones{
      padding-left: var(--mainPaddingSmallSize);
      padding-right: var(--mainPaddingSmallSize);

      .header-link {
        font-size: 12px;
      }
    }

    .header-link--cases {
      border: 1px solid var(--color1) !important;
    }

    .header-link--cases {
      border: 1px solid var(--color1) !important;
    }

    .header-link--cases {
      border: 1px solid var(--color1) !important;
    }


    &.open {
      top: 56px;

      &.burger-menu-wrapper:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        display: block;
        width: 100%;
        height: 100vh;
        background: #000;
        opacity: .4;
      }
    }

    .header-item {
      display: flex;
      flex-flow: column;
      margin: 32px 0;

      @include extraSmallPhones {
        &:last-child {
          width: 100px;
        }
      }

      .header-link {
        color: var(--color1);
        margin: 0;
        margin-bottom: 8px;

        &.router-link-active {
          color: var(--color3);

          &:after {
            background-image: url(../../src/assets/images/plus--light.svg) !important;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        &.header-link--solutions,
        &.header-link--services,
        &.header-link--products {

          &:after {
            content: ' ';
            margin-left: 15px;
            width: 9px;
            height: 9px;
            background-image: url(../../src/assets/images/plus.svg);
            background-repeat: no-repeat;
            background-position: center;
          }
        }

        &.header-link--solutions {
          border: 1px solid var(--color2);
          box-sizing: border-box;
          border-radius: 60px;

          &.router-link-active {
            background-color: #462DC7;

            &:after {
              background-image: url(../../src/assets/images/plus--light.svg);
            }
          }
        }

        &.header-link--services {
          background-repeat: no-repeat;
          background-position: center;
          background-image: url("data:image/svg+xml,%3Csvg width='106' height='27' viewBox='0 0 106 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.5214 0.527466L13.524 0.527465L97.8697 0.500068L101.665 4.32562L101.668 4.32908L105.499 8.11591L105.472 13.4975L105.472 13.5025L105.499 18.8841L101.668 22.6709L101.665 22.6744L97.8697 26.4999L13.524 26.4725L13.5214 26.4725L8.12936 26.499L4.33547 22.6744L4.33549 22.6744L4.332 22.6709L0.501028 18.8841L0.527507 13.5025H0.527531L0.527507 13.4975L0.501029 8.11591L4.332 4.32907L4.33202 4.32909L4.33547 4.32561L8.12936 0.501022L13.5214 0.527466Z' stroke='%23E62315'/%3E%3C/svg%3E%0A");

          &.router-link-active {
            background-image: url("data:image/svg+xml,%3Csvg width='106' height='27' viewBox='0 0 106 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.5214 0.527466L13.524 0.527465L97.8697 0.500068L101.665 4.32562L101.668 4.32908L105.499 8.11591L105.472 13.4975L105.472 13.5025L105.499 18.8841L101.668 22.6709L101.665 22.6744L97.8697 26.4999L13.524 26.4725L13.5214 26.4725L8.12936 26.499L4.33547 22.6744L4.33549 22.6744L4.332 22.6709L0.501028 18.8841L0.527507 13.5025H0.527531L0.527507 13.4975L0.501029 8.11591L4.332 4.32907L4.33202 4.32909L4.33547 4.32561L8.12936 0.501022L13.5214 0.527466Z' fill='%23E62315' stroke='%23E62315'/%3E%3C/svg%3E%0A");

            &:after {
              background-image: url(../../src/assets/images/plus--light.svg);
            }
          }
        }

        &.header-link--products {
          background-repeat: no-repeat;
          background-position: center;
          background-image: url("data:image/svg+xml,%3Csvg width='128' height='27' viewBox='0 0 128 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.40109 26.5L0.623308 0.5H121.599L127.377 26.5H6.40109Z' stroke='%23FFD200'/%3E%3C/svg%3E%0A");

          &.router-link-active {
            background-image: url("data:image/svg+xml,%3Csvg width='128' height='27' viewBox='0 0 128 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.40109 26.5L0.623308 0.5H121.599L127.377 26.5H6.40109Z' fill='%23FFD200' stroke='%23FFD200'/%3E%3C/svg%3E%0A");
          }
        }

        &.header-link--cases {
          border-color: var(--color1);
        }
      }
    }
  }

  .burger-menu-background {
    position: absolute;
    z-index: 9999;
    top: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background: #000;
    opacity: 0.3;
  }

  .search-button {
    background: none;
    border: none;
    width: 40px;
    height: 40px;
    position: relative;
    background-position: center 30%;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='19' viewBox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='0.646447' y1='18.6464' x2='4.64645' y2='14.6464' stroke='%23001d82'/%3E%3Ccircle cx='12' cy='8' r='7.5' stroke='%23001d82'/%3E%3C/svg%3E%0A");
    cursor: pointer;
    transition: .3s background ease;
    margin-left: auto;
    //margin-right: 24px;

    &:hover {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='19' viewBox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='0.646447' y1='18.6464' x2='4.64645' y2='14.6464' stroke='%23E62315'/%3E%3Ccircle cx='12' cy='8' r='7.5' stroke='%23E62315'/%3E%3C/svg%3E%0A");
    }
  }
</style>
