export default function productsAnimation(timeline) {
  timeline
    .set(".headline > img", { yPercent: 100 })
    .set(".headline > hr", { width: 0 })
    .set(".headline > h1", { opacity: 0, y: 100 })
    .set(".section", { opacity: 0, y: 150 })
    .to(".headline > img", { yPercent: 0 })
    .to(".headline > hr", { width: "100%" }, ">")
    .to(".headline > h1", { opacity: 1, y: 0 }, "<50%")
    .to(".section", { opacity: 1, y: 0 }, "<0");
}
