export default function solutionsAnimation(timeline) {
  timeline
    .set(".headline > img", { yPercent: 100 })
    .set(".headline > hr", { width: 0 })
    .set(".headline > h1", { opacity: 0, y: 100 })
    .set("#filter .filter-wrapper", { opacity: 0, xPercent: -100 })
    .set("#filter div:last-child", { opacity: 0, xPercent: -100 })
    .set(".solution-list", { opacity: 0, y: 300 })
    .to(".headline > img", { yPercent: 0 })
    .to(".headline > hr", { width: "100%" }, ">")
    .to(".headline > h1", { opacity: 1, y: 0 }, "<50%")
    .to("#filter .filter-wrapper", { opacity: 1, xPercent: 0 }, "<70%")
    .to("#filter div:last-child", { opacity: 1, xPercent: 0 }, "<0")
    .to(".solution-list", { opacity: 1, y: 0 }, "<70%");
}
