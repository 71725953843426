<template>
    <section>
        <h2 class="headline headline_decor animate-elem fadeInUp">{{form.name}}</h2>
        <div class="form-wrapper">
            <picture>
              <source class="background" srcset="@/assets/images/achivements.png" media="(min-width: 1383px)">
              <img class="background" src="@/assets/images/background_form_mobail.png" alt="MDN">
            </picture>
            <hr>
            <div class="form-container">
                <div class="information-wrapper">
                    <div class="information-wrapper__col">
                      <div class="information">
                        <span class="animate-elem fadeInUp">{{ new Date().getFullYear() - 2008 }}</span>
                        <p class="animate-elem fadeInUp">лет занимаемся внедрением корпоративных порталов</p>
                      </div>
                      <div class="information information_about_bitrix">
                        <span class="animate-elem fadeInUp"><img src="@/assets/images/bitrix.svg" alt="ISO" class="bitrix"/></span>
                        <p class="animate-elem fadeInUp">Мы «Золотой» партнер<br/>Битрикс24</p>
                      </div>
                    </div>
                    <div class="information-wrapper__col">
                      <div class="information">
                          <span class="animate-elem fadeInUp">150+</span>
                          <p class="animate-elem fadeInUp">сертифицированных специалистов</p>
                      </div>
                      <div class="information">
                          <span class="animate-elem fadeInUp"></span>
                          <p class="animate-elem fadeInUp">Опыт внедрений проектов<br>от 100 до 15000 пользователей</p>
                      </div>
                    </div>
                </div>
                <Form :inputs="form.value" buttonType="light" checkboxStyle="color: #fff" isSolutions="true" btncolor="light"/>
            </div>
        </div>
    </section>
</template>
<script>
    import Form from '../../UI/form.vue'
    import {mapGetters} from 'vuex'
    export default {
        components: {
            Form
        },
        props: {
            form: null
        },
        computed: mapGetters(['widthPage'])
    }
</script>
<style lang="scss" scoped>
    $minWidth: 461px;

    .headline_decor {
      &:first-letter {
        margin-left: 260px;
      }
      &:before {
        content: "";
        position: relative;
        display: inline-block;
        height: 60px;
        margin-right: 10px;
        background-position-y: 16px;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='192' height='33' viewBox='0 0 192 33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.564 20.68V21.56L22.828 32.384V31.372L1.664 21.12L22.828 10.824V9.856L0.564 20.68ZM42.2203 33V21.56H53.5723V20.68H42.2203V9.592H41.2963V20.68H29.9443V21.56H41.2963V33H42.2203ZM57.6086 33L70.3686 1.1H69.4446L56.6406 33H57.6086ZM96.6157 20.68L74.3517 9.856V10.824L95.5157 21.12L74.3517 31.372V32.384L96.6157 21.56V20.68ZM111.212 0.527998C105.404 0.527998 101.136 3.388 100.652 10.604H101.62C101.972 3.344 106.636 1.364 111.212 1.364C115.876 1.364 119.484 3.696 119.484 7.876C119.484 11.484 116.844 13.772 114.204 15.708C110.816 18.172 108.748 19.976 108.748 24.068V25.168H109.628V24.068C109.628 20.108 111.872 18.524 114.644 16.456C117.592 14.344 120.496 11.836 120.496 7.744C120.496 2.992 116.228 0.527998 111.212 0.527998ZM109.628 33V28.864H108.748V33H109.628ZM121.89 33L134.65 1.1H133.726L120.922 33H121.89ZM138.193 20.68V21.56L160.457 32.384V31.372L139.293 21.12L160.457 10.824V9.856L138.193 20.68ZM179.849 33V21.56H191.201V20.68H179.849V9.592H178.925V20.68H167.573V21.56H178.925V33H179.849Z' fill='url(%23paint0_linear_324_3421)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_324_3421' x1='-3' y1='18.0001' x2='195' y2='18.0001' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FFD200'/%3E%3Cstop offset='0.542526' stop-color='%23E62315'/%3E%3Cstop offset='1' stop-color='%23462DC7'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
        background-size: 100%;
        width: 250px;
      }

      &:after {
        content: "";
        display: inline-block;
        margin-left: 10px;
        margin-bottom: -3px;
        width: 55px;
        height: 60px;
        background-position-y: 10px;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='35' height='33' viewBox='0 0 35 33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.044 0.527998C5.236 0.527998 0.968 3.388 0.484 10.604H1.452C1.804 3.344 6.468 1.364 11.044 1.364C15.708 1.364 19.316 3.696 19.316 7.876C19.316 11.484 16.676 13.772 14.036 15.708C10.648 18.172 8.58 19.976 8.58 24.068V25.168H9.46V24.068C9.46 20.108 11.704 18.524 14.476 16.456C17.424 14.344 20.328 11.836 20.328 7.744C20.328 2.992 16.06 0.527998 11.044 0.527998ZM9.46 33V28.864H8.58V33H9.46ZM21.7219 33L34.4819 1.1H33.5579L20.7539 33H21.7219Z' fill='url(%23paint0_linear_324_3422)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_324_3422' x1='2.61513e-08' y1='18.0001' x2='35' y2='18.0001' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FFD200'/%3E%3Cstop offset='0.542526' stop-color='%23E62315'/%3E%3Cstop offset='1' stop-color='%23462DC7'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
        background-size: 100%;
      }

      @include smallDesktop {
        &:first-letter {
          margin-left: 210px;
        }
        &:before {
          height: 40px;
          background-position-y: 7px;
          background-size: unset;
          width: 190px;
        }
        &:after {
          background-size: unset;
          height: 40px;
        }
      }

      @include smallLaptop {
        &:first-letter {
          margin-left: 100px;
        }

        &:before {
          background-position-y: 0;
          background-size: 100% 100%;
          height: 20px;
          width: 90px;
        }

        &:after {
          margin-left: 0;
          height: 20px;
          width: 16px;
          background-size: 100% 100%;
          background-position-y: 0;
        }
      }

      @include mediumPhones {
        &:before {
          height: 20px;
          width: 100px;
        }
        &:after {
          margin-bottom: -5px;
        }
        &:first-letter {
          margin-left: 120px;
        }
      }

      @include smallPhones {
        &:after {
          background-size: 100% 100%;
          height: 16px;
          max-width: 40px;
          margin-bottom: 0;
        }
      }
    }

    .headline {
      position: relative;
      box-sizing: border-box;
      align-items: flex-start;
      max-width: var(--large);
      margin: 63px auto;
      margin-top: 0;
      width: 100%;

      border-bottom: 1px solid rgba(0, 0, 0, 0);
      text-align: left;
      justify-content: flex-start;
      font-style: normal;
      font-weight: normal;
      font-size: 60px;
      line-height: 130%;
      text-transform: uppercase;
      color: var(--color1);

      background: linear-gradient(180deg, rgba(0, 29, 130, 0) 74px, white 74px, var(--color1) 75px);
      background-size: 100% 75px;
      background-repeat: repeat;
      background-position-y: 1px;

      @include smallDesktop {
        font-size: 32px;
        line-height: 45px;
        background: linear-gradient(
                180deg,
                rgba(0, 29, 130, 0) 44px,
                white 44px,
                var(--color1) 45px
        );
        background-size: 100% 45px;
        background-position-y: 1px;
      }

      @include smallLaptop {
        font-size: 20px;
        line-height: 28px;
        background: linear-gradient(
                180deg,
                rgba(0, 29, 130, 0) 26px,
                white 26px,
                var(--color1) 27px
        );
        background-size: 100% 27px;
        background-position-y: 1px;
      }

      @include mediumPhones {
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: 16px;
        line-height: 23px;
        border-bottom: 1px solid rgba(0, 28, 130, 0.2);

        background: linear-gradient(
                180deg,
                rgba(0, 29, 130, 0) 21px,
                white 21px,
                var(--color1) 26px
        );
        background-size: 100% 23px;
        background-position-y: 2px;
      }

      @include smallPhones {
        line-height: 24px;
        background: linear-gradient(
                180deg,
                rgba(0, 29, 130, 0) 22px,
                white 22px,
                var(--color1) 27px
        );
        background-size: 100% 24px;
        background-position-y: 1px;
        border-bottom: 1px solid rgba(0, 0, 0, 0);
      }
    }

    section {
        margin-top: 160px;

        @include smallLaptop {
          margin-top: 80px;
        }
    }

    .form-wrapper {
        position: relative;
        min-height: 480px;
        padding-top: 50px;
        padding-left: 72px;
        padding-right: 72px;
        margin-top: 42px;
        display: flex;
        flex-direction: column;
        z-index: 1;

        .background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            -o-object-fit: cover;
            object-fit: cover;
            border-radius: 25px;
        }
        @include largePhonesAlt {
          box-sizing: border-box;
          padding: 10px 20px;
          max-height: 810px;
        }

        @include laptop {
          padding-left: 14px;
          padding-right: 14px;
        }

        @include phones {
          min-height: 840px;
        }
    }

    .form-container {
        display: flex;
        height: 440px;
        column-gap: 30px;

        // @include smallDesktop {
        //   height: 900px;
        // }
        @include phones {
          min-width: unset;
          flex-direction: column;
          row-gap: 24px;

          * {
            min-width: unset;
          }
      }
    }

    .information-wrapper {
        flex: 1;
        min-width: $minWidth;
        margin-bottom: 8px;
        display: flex;

        &__col {
          @include phones {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            row-gap: 24px;
            width: 40%;
          }

          @include smallPhonesAlt {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            row-gap: 24px;
            width: 50%;
          }
        }
        @include largePhonesAlt {
          max-height: 328px;
        }

        @include phones {
            gap: 10px;
            justify-content: space-between;
            min-width: unset;
        }
    }

    .information {
        height: 154px;
        color: var(--color5);
        margin-right: 40px;
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        span {
          overflow: visible;
          font-size: 100px;
          line-height: 110%;

          @include phones {
            font-size: 80px;
          }

          @include mediumPhones {
            font-size: 65px;
          }

          @include smallPhonesAlt {
            font-size: 60px;
          }
        }
        &_about {
          &_bitrix {
            span {
              margin-top: auto;
              margin-bottom: 36px;
              font-size: 29px;
              font-weight: bold;

              @include phones {
                font-size: 19px;
                margin-bottom: 15px;
                margin-top: 0;
              }

              @include smallPhonesAlt {
                font-size: 19px;
                margin-bottom: 15px;
                margin-top: 0;
              }

              &:after {
                // content: '';
                display: inline-block;
                width: 17px;
                height: 17px;
                background: var(--color5);
                border-radius: 100px;
                position: relative;
                top: -5px;
                left: 3px;
              }
            }
          }
        }
        p {
            margin: 0;
            font-size: 16px;
            line-height: 140%;

            @include phones {
              font-size: 14px;
            }

            @include smallPhonesAlt {
              font-size: 14px;
            }
        }

        @include phones {
          height: fit-content;
          margin: 0;
          width: unset;
          max-width: 100%;
        }
    }

    .form {
        display: flex;
        flex-direction: column;
        flex: 1;
        min-width: $minWidth;

        .input_container {
            width: 100%;
            margin-right: 0;
        }

        @include largePhonesAlt {
          max-height: 444px;
        }

        @include phones {
          min-width: unset;
          .textarea_container {
              min-height: 80px;
              max-height: 120px;
              textarea {
                box-sizing: border-box;
                min-height: 80px;
                height: 80px;
              }
            }
        }
    }
</style>
<style lang="scss">
  .pin-spacer + .content-container .information-wrapper {
    width: 100%;
    column-gap: 30px;

    .information-wrapper__col {
      width: calc(50% - 30px);
      display: flex;
      flex-direction: column;
      margin-bottom: 50px;

      @include smallLaptop {
        max-width: 250px;
      }

      &:first-child{
        @include smallPhonesAlt {
          width: calc(35%);
        }
        @include extraSmallPhones {
          width: 38%;
          margin-left: 0;
        }
      }

      &:last-child{
        @include smallLaptop {
          margin-left: auto;
        }
        @include smallPhonesAlt {
          width: calc(61% - 20px);
          margin-left: 0;
        }
        @include extraSmallPhones {
          width: 50%;
          margin-left: 0;
        }
      }

      .information {
        margin-right: 0;
        width: 100%;

        &:last-child {
          margin-bottom: 0;
          margin-top: 24px;
        }

        p {
          width: 100%;
          @include extraSmallPhones {
            font-size: 13px;
          }
        }

        span {
          @include smallPhonesAlt {
            font-size: 80px;
          }

          @include extraSmallPhones {
            font-size: 60px;
          }
        }


        &.information_about_bitrix {
          .bitrix {
            width: 120px;
            height: 65px;
            background-color: #fff;
            border-radius: 4px;
          }
          @include smallPhonesAlt {
            span {
              font-size: 19px;
              display: flex;

              &::after {
                top: 0;
                left: 5px;
              }
            }
          }

          @include extraSmallPhones {
            span {
              font-size: 17px;
            }
          }
        }
      }
    }
  }

  .information-wrapper + .form {
    .input_container {
      .placeholder {
        max-width: 323px;
      }
    }

    .user-agreement {
      color: #fff;
      text-decoration: none;
    }

    & > div {
      margin-top: 20px;
      @include largePhonesAlt {
        margin-top: 16px;
      }
    }
  }
</style>