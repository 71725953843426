<template>
    <section class="cropped">
        <TabsSwitcherHeader :value="value" :buttonsSettings="settings1" :container="container" @switchActiveButton="switcher" />
        <TabsSwitcherBody  :value="value" :container="container" :valuesOpenedTab="activeTabIndex" :tabsSettings="bodySettings" />
    </section>
</template>

<script>
import TabsSwitcherHeader from './tabsSwitcherHeader.vue';
import TabsSwitcherBody from './tabsSwitcherBody.vue';

export default {
    components: {
        TabsSwitcherHeader,
        TabsSwitcherBody
    },
    props: {
        headerSettings: Object,
        bodySettings: Array,
        container: String,
        settings1: Object,
        value: null
    },
    data() {
        return {
            activeTabIndex: 0
        }
    },
    methods: {
        switcher(q) {
            this.activeTabIndex = q
        }
    }
}
</script>