<template>
  <section class="section section__solution dflex-cont dflex-cont_row_center solution">
    <div class="dflex_inside dflex-child_maxwidth">
      <ScrollContainer data-position="sticky"/>
      <div class="content-container" data-position-end="sticky">
        <template class="section-container" v-for="(item, key) in product">
          <HeadlineMenu
            v-if="key == 'client_name' && product.name"
            :name="product.name"
            :list="productsList"
            listName="Продукты"
            :key="key"
          />
          <Advantage
            v-else-if="item.type == 'advantage'"
            :value="item"
            :id="key"
            :key="key"
          />
          <Value
            v-else-if="item.type == 'value' && item"
            :value="item"
            :id="key"
            :key="key"
          />
          <Value-solution
            v-else-if="item.type == 'valueSolution' && item"
            :value="item"
            :id="key"
            :key="key"
          />
          <For-solutions
            v-else-if="item.type == 'forSolutions' && item"
            :value="item"
            :id="key"
            :key="key"
          />
          <Faq
            v-else-if="item.type == 'faq' && item"
            :value="item"
            :id="key"
            :key="key"
          />
          <Slider
            v-else-if="item.type == 'slider' && item"
            :value="item"
            :id="key"
            :key="key"
          />
          <Cases
            v-else-if="item.type == 'cases' && item"
            :cases="item"
            :id="key"
            :key="key"
          />
          <Form
            v-else-if="item.type == 'form' && item"
            :form="item"
            :id="key"
            :key="key"
          />
          <Services
            v-else-if="item.type == 'services'"
            :value="item"
            :id="key"
            :key="key"
          />
          <For
            v-else-if="item.type == 'for'"
            :value="item"
            :id="key"
            :key="key"
          />
        </template>
      </div>
    </div>
  </section>
</template>
<script>
import eventHub from "../components/eventHub";
import { mapGetters, mapMutations } from "vuex";
import HeadlineMenu from "../components/UI/headlineMenu.vue";
import Advantage from "../components/UI/advantage.vue";
import Value from "../components/pages/products/value.vue";
import ValueSolution from "../components/pages/products/valueSolution.vue";
import ForSolutions from "../components/pages/products/forSolutions.vue";
import Faq from "../components/pages/products/faq.vue";
import Slider from "../components/pages/products/slider.vue";
import Cases from "../components/UI/caseScroll.vue";
import Form from "../components/pages/products/form.vue";
import Services from "../components/pages/solutions/services.vue";
import For from "../components/pages/solutions/for.vue";
import axios from "axios";
import ScrollContainer from "../components/UI/scrollContainer.vue";

export default {
  metaInfo() {
    return {
      title: `${this.metaNames.title ?? "Решения"}`,
      meta: this.metaNames.metas,
    };
  },
  components: {
    HeadlineMenu,
    Advantage,
    Value,
    ValueSolution,
    ForSolutions,
    Faq,
    Slider,
    Cases,
    Form,
    Services,
    For,
    ScrollContainer,
  },
  data() {
    return {
      scroll: 0,
      scrollObjects: [],
      elements: "",
      product: {},
      productsList: [
        {
          name: "Битрикс24",
          link: "/products/product-template",
        },
        {
          name: "UNA Company",
          link: "/products/product-template",
        },
        {
          name: "UNA Contact",
          link: "/products/product-template",
        },
        {
          name: "Jira Software",
          link: "/products/product-template",
        },
        {
          name: "Confluence",
          link: "/products/product-template",
        },
        {
          name: "Jira service management",
          link: "/products/product-template",
        },
      ],
      form: {
        name: "Задать вопрос по продукту или запросить демо",
        type: "form",
        value: [
          {
            type: "text",
            placeholder: "Имя",
            required: true,
            name: "client_name",
            value: "",
            customCss: "background: rgba(255,255,255,.05);color:#fff",
          },
          {
            type: "tel",
            placeholder: "Телефон",
            name: "phone",
            customCss: "background: rgba(255,255,255,.05);color:#fff",
          },
          {
            type: "email",
            placeholder: "E-mail",
            required: true,
            name: "email",
            value: "",
            customCss: "background: rgba(255,255,255,.05);color:#fff",
          },
          {
            type: "textarea",
            placeholder: "Комментарий",
            required: true,
            name: "description",
            customCss: "background: rgba(255,255,255,.05);color:#fff",
          },
        ],
      },
    };
  },
  methods: {
    ...mapMutations(["addMetaNames", 'setLoading']),
    fetchData() {
      const time = Date.now()
      eventHub.$emit("updateLoader", true);
      // replace `getPost` with your data fetching util / API wrapper
      axios
        .get("/api" + this.$route.path)
        .then((response) => {
          this.addMetaNames(response.data.seo);
          this.product = response.data;
          this.product["form"] = this.form;
          setTimeout(async () => {
            this.$store.commit("addProduct", response.data);
            await this.SCROLL_OBJECTS
            await eventHub.$emit("solutionsDone", true);
            await eventHub.$emit("updateLoader", false);
          }, 1);
        })
        .catch((error) => {
          console.log(error);
          this.$router.push("/404");
        })
        .finally(() => {
          const setTime = Date.now() - time > 1000 ? 0 : 1000 - (Date.now() - time)
          setTimeout(() => {
            eventHub.$emit('updateLoader', false)
            setTimeout(() => {
              this.setLoading(false)
            }, 1000)
          }, setTime)
        });
    },
  },
  computed: {
    ...mapGetters(["widthPage", "headerIsActive", "metaNames", "SCROLL_OBJECTS"]),
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData();
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
};
</script>
<style lang="scss" scoped>
$scroll-width: 237px;

.section {
  display: flex;
  overflow: hidden;

  &:first-child {
    margin-top: 0;
  }

  section {
    margin-top: 160px;
    margin-bottom: 0;

    // &:first-of-type {
    //   margin-top: 0;
    // }

    @include largePhonesAlt {
      &:first-of-type {
        margin-top: 24px;
      }
    }
  }

  @include smallDesktop {
    section {
      margin-top: 110px;
    }
  }

  @include mediumLaptopAlt {
    flex-direction: column;

    section {
      margin-top: 48px;
    }
  }

  @include phones {
    padding-top: 24px;
  }

  @include largePhones {
    section {
      margin-top: 24px;
    }
  }
}

.content-container {
  flex: 1;
  margin-left: 85px;
  max-width: calc(100% - 277px);
  min-width: 0;

  @include mediumLaptopAlt {
    max-width: 100%;
    margin-left: 0;
  }
}

.headline-button {
  height: 72px;
  width: 72px;
  background-color: var(--color2);
  border-radius: 10px;
  border: none;
  cursor: pointer;
  margin-left: 20px;
  position: relative;
  display: inline-flex;

  &:before,
  &:after {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='7' viewBox='0 0 14 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 1L6.63181 0.661721L7.36819 0.661721L7 1ZM11.5938 6L11.5938 6.5L11.3741 6.5L11.2256 6.33828L11.5938 6ZM2.40625 6L2.77444 6.33828L2.62586 6.5L2.40625 6.5L2.40625 6ZM7.36819 0.661721L10.4307 3.99505L9.69431 4.67161L6.63181 1.33828L7.36819 0.661721ZM10.4307 3.99505L11.9619 5.66172L11.2256 6.33828L9.69431 4.67161L10.4307 3.99505ZM11.5937 5.5L14 5.5L14 6.5L11.5938 6.5L11.5937 5.5ZM7.36819 1.33828L4.30569 4.67161L3.56931 3.99505L6.63181 0.661721L7.36819 1.33828ZM4.30569 4.67161L2.77444 6.33828L2.03806 5.66172L3.56931 3.99505L4.30569 4.67161ZM2.40625 6.5L2.18557e-08 6.5L-2.18557e-08 5.5L2.40625 5.5L2.40625 6.5Z' fill='white'/%3E%3C/svg%3E%0A");
    position: absolute;
    width: 14px;
    height: 7px;
    top: 20px;
    left: 29px;
  }

  &:after {
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='7' viewBox='0 0 14 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 6L6.63181 6.33828L7.36819 6.33828L7 6ZM11.5938 1L11.5938 0.5L11.3741 0.5L11.2256 0.661721L11.5938 1ZM2.40625 0.999999L2.77444 0.661721L2.62586 0.5L2.40625 0.499999L2.40625 0.999999ZM7.36819 6.33828L10.4307 3.00495L9.69431 2.32839L6.63181 5.66172L7.36819 6.33828ZM10.4307 3.00495L11.9619 1.33828L11.2256 0.661721L9.69431 2.32839L10.4307 3.00495ZM11.5937 1.5L14 1.5L14 0.5L11.5938 0.5L11.5937 1.5ZM7.36819 5.66172L4.30569 2.32839L3.56931 3.00495L6.63181 6.33828L7.36819 5.66172ZM4.30569 2.32839L2.77444 0.661721L2.03806 1.33828L3.56931 3.00495L4.30569 2.32839ZM2.40625 0.499999L2.18557e-08 0.499999L-2.18557e-08 1.5L2.40625 1.5L2.40625 0.499999Z' fill='white'/%3E%3C/svg%3E%0A");
    top: unset;
    bottom: 20px;
    left: 29px;
  }
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  a {
    max-width: 100%;
    margin-bottom: 5px;
    position: relative;
    overflow: hidden;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100%);
      white-space: nowrap;
      width: 100%;
    }

    .scroll {
      position: absolute;
      top: 0;
      left: 0;
      background-color: var(--color4);
      height: 100%;
      z-index: -1;
    }
  }

  @include mediumLaptopAlt {
    flex-direction: row;
    width: 100%;
  }
}
.section {
  &__solution {
    .button.dark.arrow-left {
      span {
        font-size: 15px;
      }

      .button__icon {
        width: 20px;
        background-repeat: no-repeat;
        background-position-x: 10px;
        background-position-y: -1px;
      }
    }
  }

  #services {
    ul {
      flex-wrap: wrap;

      li {
        min-width: fit-content;
        height: fit-content;
        margin-bottom: 20px;
        box-sizing: border-box;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  #cases {
    display: flex;

    .line {
      order: 1;
    }

    .case-list {
      margin-top: 80px;
      margin-left: auto;
      flex-shrink: 0.5;
    }

    .headline {
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-end;

      height: fit-content;
      margin-top: 80px;
      margin-bottom: auto;

      .button-wrapper {
        position: static;
        margin-right: auto;
        margin-left: 0;
      }

      .indicator {
        margin-top: 60px;
      }
    }
  }

  .adventage-list {
    gap: 20px;
    .adventage-item {
      width: calc(33.33% - var(--mainPaddingSmallSize)) !important;
    }
  }

  .form-wrapper {
    .form {
      .input_container,
      .textarea_container {
        background: rgba(255, 255, 255, 0.05);
        color: #fff;
      }

      label:nth-last-child(2) {
        background: none;
      }

      .placeholder {
        color: #fff;
        max-width: 320px;
      }

      .textarea_container {
        min-height: 120px;
      }
    }
  }

  @include smallDesktop {
    .headline_decor {
      padding-left: 20px;
    }
  }

  @include laptop {
    h2.line {
      font-size: 60px;
    }

    .headline_decor {
      padding-left: 0;
    }

    #services {
      ul {
        flex-wrap: wrap;

        li {
          min-width: fit-content;
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  @include mediumLaptopAlt {
    .content-container {
      section:first-of-type {
        .description,
        .answer {
          display: none;
        }
      }

      .value-wrapper {
        flex-direction: column;
      }

      .adventage-list {
        padding: 0;

        &:before {
          display: none;
        }
      }
    }
  }

  @include phones {
    .content-container {
      h2.line {
        font-size: 40px;
        font-weight: 400;
      }

      section {
        .description {
          padding: 0;
          margin-top: 24px;
          margin-bottom: 0;

          &:before {
            display: none;
          }
        }
      }

      #services {
        ul {
          margin-top: 24px;

          li {
            font-size: 20px;
          }
        }
      }

      #cases {
        margin-top: 24px;
        padding-top: 0;

        .case-list {
          margin-top: 24px;
        }
      }

      .form-wrapper {
        padding-left: var(--mainPaddingSmallSize);
        padding-right: var(--mainPaddingSmallSize);

        display: flex;
        flex-direction: column;

        .form-container {
          display: flex;
          flex-direction: column;

          .information-wrapper {
            min-width: unset;

            .information {
              width: calc(40%);
              margin-right: 0;

              &:nth-child(2n) {
                margin-right: 0;
                margin-left: auto;
              }
            }

            span {
              font-size: 80px;
            }

            p {
              font-size: 14px;
            }
          }

          .form {
            min-width: unset;

            .input_container,
            .textarea_container {
              min-width: unset;
              width: 100%;
            }

            label:nth-last-child(2) {
              background: none;
            }

            .placeholder {
              color: #fff;
              max-width: 320px;
            }

            .textarea_container {
              min-height: 120px;
            }
          }
        }
      }

      .value-wrapper {
        margin-top: 24px;

        .value-description {
          min-width: 100px;
          margin-right: 0;
        }
      }
    }
  }

  @include largePhones {
    .scroll-container {
      .button-wrapper {
        .button.dark {
          span {
            font-size: 10px !important;
          }
        }
      }
    }

    .content-container {
      h2.line {
        font-size: 24px;
        padding-top: var(--mainPaddingSmallSize);
      }

      section {
        margin-top: 48px;

        .description {
          font-size: 18px;
          padding: 0;
          margin-top: 24px;
          margin-bottom: 0;

          :before {
            display: none;
          }
        }
      }

      #services {
        ul {
          margin-bottom: -5px;

          li {
            font-size: 14px;
            margin-bottom: 5px;
          }
        }
      }

      .adventage-list {
        flex-direction: column;

        .adventage-description {
          font-size: 16px;
        }

        .adventage-item {
          margin-left: 0;
          width: 100% !important;
          margin-top: 10px !important;
          padding: 16px;
          padding-bottom: 46px;
          min-height: unset;

          .adventage-number {
            font-size: 40px;
          }

          &:first-child {
            margin-top: 0 !important;
          }
        }
      }

      .value-wrapper {
        .value-description {
          font-size: 18px;
        }
      }

      #cases {
        margin-top: 48px;
      }
    }
  }

  @include mediumPhones {
    .content-container {
      .form-wrapper {
        .form-container {
          .information-wrapper {
            .information {
              width: calc(50% - 10px);
              margin-right: 20px;
              height: fit-content;

              &:nth-child(2n) {
                margin-left: 0;
                margin-right: 0;
              }

              span {
                font-size: 60px;
                margin-bottom: 16px;
              }
            }
          }

          .form {
            .input_container,
            .textarea_container {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }

  @include extraSmallPhones {
    .content-container {
      .form-wrapper {
        padding: 20px var(--mainPaddingSmallSize);

        .form-container {
          .information-wrapper {
            margin-bottom: 48px;

            .information {
              &:nth-child(n + 2) {
                margin-bottom: 0;
              }
            }

            span {
              font-size: 55px;
            }

            p {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.section.section__solution {
  .line.h2animated {
    width: 100%;
  }

  .scrollbar {
    top: 35px;
    @include largePhonesAlt {
      top: 0;
    }
  }

  .button.dark.arrow-left {
    top: -34px;
    z-index: 99;
    position: absolute;
  }

  .content-container {
    section:first-child {
      margin-top: 40px;
      @include largePhonesAlt {
        margin-top: 0;
      }
    }
  }

  .case-list {
    @include mediumLaptop {
      width: 100%;
      min-height: unset;
      margin-top: 24px;
    }
  }

  .case-item {
    @include mediumLaptop {
      margin: 0;
      width: 100%;
    }

    .case-container {
      @include mediumLaptop {
        max-width: unset;
      }
    }
  }

  .headline {
    @include mediumLaptop {
      display: none;
    }
  }
}
.dflex_inside {
  display: flex;
  @include mediumLaptopAlt {
    flex-direction: column;
    height: fit-content;
    position: relative;
  }
}
</style>