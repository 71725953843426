<template>
    <section>
        <hr class="animate-elem shiftLeft wrap"/>
        <h2 class="line animate-elem shiftUp wrap">{{value.name}}</h2>
        <div class="value-wrapper">
            <p class="value-description animate-elem fadeInLeft" v-if="value.description" v-html="value.description">
            </p>
            <ul class="points-list animate-elem listfadeInRight" v-if="value.items">
                <li class="point" v-for="(point, j) in value.items" :key="j">
                    {{point.name}}
                </li>
            </ul>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        value: null
    },
    mounted() {
        if(this.value && this.value.description) {
            const regExp = new RegExp('(<a.+)(href=")(?!' + window.location.origin + ')', 'gm')
            // eslint-disable-next-line
            this.value.description = this.value.description.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
        }
    }
}
</script>
<style lang="scss" scoped>
$minWidth: 510px;
    hr {
        border-top: 1px solid var(--color1);
        margin: 0;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding:0;
    }
    section {
        margin-top: 160px;
    }
    .value-item + .value-item {
        margin-top: 170px;
    }
    .value-wrapper {
        margin-top: 80px;
        display: flex;
        flex-wrap: wrap;

        @include smallLaptop {
            margin-top: 24px;
            flex-direction: column;
        }
    }
    .value-description {
        flex: 1;
        margin: 0;
        font-size: 24px;
        line-height: 160%;
        color: var(--color1);
        margin-right: 100px;
        margin-bottom: 24px;
        min-width: $minWidth;

        @include largePhonesAlt {
            font-size: 16px;
        }
    }
    .points-list {
        flex: 1;
        margin: 0;
        display: flex;
        flex-direction: column;
        min-width: $minWidth;
        row-gap: 10px;

        @include phones {
            min-width: unset;
        }
    }
    .point {
        font-size: 20px;
        line-height: 160%;
        color: var(--color1);
        padding: 20px;
        padding-left: 93px;
        background-color: #FFF0AC;
        border-radius: 10px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: 20px;
            left: 20px;
            width: 32px;
            height: 32px;
            background-color: #FFD200;
            border-radius: 32px;
        }

        @include phones {
            padding-left: 61px;
            padding-top: 10px;
            padding-bottom: 10px;
            margin-bottom: 0;

            &:before {
            top: 8px;
            left: 10px;
            }
        }

        @include largePhonesAlt {
            font-size: 16px;
        }
    }
</style>