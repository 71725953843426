<template>
    <label v-bind:style="customStyle">
        <button class="button" v-if="!href" v-bind:class="color" >
            <span>{{text}}</span>
            <div class="button__icon"></div>
        </button>
        <a :href="href" class="button" v-if="href" :target="target">
            <span>{{text}}</span>
            {{color}}
            <div class="button__icon"></div>
        </a>
    </label>
</template>
<script>
    export default {
        props: {
            text: null,
            href: null,
            target: null,
            color: null,
            customStyle:null
        }
    }
</script>