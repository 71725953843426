<template>
  <section>
    <div class="table box-rate-table">
      <div class="table-title">
        <h2>{{value.name}}</h2>
      </div>
      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <td v-for="(heading, i) in value.data.headings" :key="i">
                <span class="no-wrap">{{ heading }}</span>
              </td>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in value.data.info">
              <template v-if="item.data_arr">
                <Portal :info="item.data_arr" :key="i"/>
              </template>
              <tr class="table-item" ref="tableItem" v-else :key="i">
                  {{item.data_arr}}
                  <template v-for="td, key in item">
                    <template v-if="key=='data_arr'">
                      <Portal :info="td" :key="key"/>
                    </template>
                    <BoxTd v-else-if="key=='popupData'" :key="key" :info="item"/>
                    <td v-else :key="key" :class="td && td.indexOf('₽') >= 0 ? 'no-wrap' : ''">{{td}}</td>
                  </template>
                </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
  import Portal from "./box-rates/portal.vue";
  import eventHub from '../../eventHub'
  // import Popup from "../../UI/popup.vue";
  import BoxTd from "./box-rates/boxTd.vue";

  export default {
    components: {
      Portal,
      // Popup,
      BoxTd,
    },
    props: {
      value: null
    },
    data() {
      return {
        windowWidth: null,
        showPopup: false,
        transferData: {},
        quoteModal: {visible: false}
      };
    },

    mounted() {
      this.$nextTick(() => {
        window.addEventListener("resize", this.onResize);
      });
    },

    beforeDestroy() {
      window.removeEventListener("resize", this.onResize);
    },
    methods: {
      toggleModal(data, type) {
        this.quoteModal.visible = true
        eventHub.$emit('emitModal', {content: data, quoteModal: this.quoteModal, modalType: type})
      },
      onResize() {
        this.windowWidth = window.innerWidth;
      },
      popupShow(e) {
        this.showPopup = true;
        this.transferData = e
      },
      closePopup() {
        this.showPopup = false;
      }
    },

    computed: {
      portalPrice() {
        return this.prices[this.portalOption];
      },
    },
  };
</script>



<style lang="scss" scoped>
  @import "@/assets/styles/table.scss";

    .section__wrapper_license {
    .box-rate-table {
      .table-wrapper {
        .table-item {
          @include largePhonesAlt {
            padding: 16px;
            // padding-bottom: 0;
          }
          td {
            @include largePhonesAlt {
              padding: 0;
              margin: 0;
              margin-top: 8px;
              padding-top: 13px;

              &:first-child {
                padding: 0;
                margin: 0;
                margin-bottom: 16px;
              }

              &:nth-child(3) {
                margin-left: auto;
              }
            }
            &::before {
              @include largePhonesAlt {
                font-size: 8px;
                padding: 4px 8px;
              }
            }
            &.title {
              @include largePhonesAlt {
                margin: 0;
                margin-bottom: 16px;
              }
            }
            &.box-price,
            &.box-user {
              @include largePhonesAlt {
                padding: 0;
                margin: 0;
                padding-top: 13px;
                margin-top: 8px;
              }
            }

            &.box-price {
              @include largePhonesAlt {
                margin-left: auto;
              }
            }
            &.box-cta {
              @include largePhonesAlt {
                padding: 0;
                margin: 0;
                padding-top: 13px;
                margin-top: 12px;
              }
            }

            &:not(.box-cta.td):nth-child(2),
            &:not(.box-cta.td):nth-child(3) {
              margin-bottom: 12px;
            }
          }
        }
      }
    }
  }
</style>
