<template>
      <div :class="qwClss">
          <h2 v-if="value">
              {{value.name}}
          </h2>
          <div class="buttons-group buttons-group__values">
              <AnimatedButton v-for="(button, index) in buttonSettings" :key="index"
                  :classes="['buttons-group__values__item', {'is-active': valuesOpenedTab == index}]" :settings="button"
                  @click="bebe12(index), $emit('switchActiveButton', index)" />
          </div>
      </div>
</template>

<script>
    import AnimatedButton from '../../UI/AB.vue'

    export default {
        name: "tabsSwitcherHeader",
        components: {
            AnimatedButton
        },
        data() {
            return {
                valuesOpenedTab: 0,
                buttonSettings: []
            }
        },
        props: {
            buttonsSettings: Object,
            container: String,
            value: null
        },
        computed: {
            qwClss() {
                if (this.container === 'contacts') {
                    return 'address__control'
                }
                if (this.container === 'about') {
                    return 'values'
                }
                return ''
            }
        },
        methods: {
            bebe12(q) {
                this.valuesOpenedTab = q
            }
        },
        mounted() {
            if (this.value) {
                this.buttonSettings = this.value.items.map((item, index) => {
                    return {
                        class: this.buttonsSettings.list[index].class,
                        text: item.name,
                        icon: this.buttonsSettings.list[index].icon
                    }
                })
            }
        },
        emits: ['switchActiveButton']
    }
</script>

<style lang="scss" scoped>
    hr {
        width: 100%;
        border-top: 1px solid var(--color1);
        margin: 0;
    }
    .values {
        overflow: hidden;
        // flex-wrap: wrap;
        margin-bottom: 80px;
        height: max-content;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        > h2 {
            padding-top: 20px;
            margin-right: auto;
            width: fit-content;
        }

        .buttons-group__values {
            padding-top: 16px;

            button {
                &:first-child {
                    margin-right: 8px;
                }
            }
        }
        @include largePhonesAlt {
            flex-direction: column;
            margin-bottom: 20px;
        }
        @include mediumPhones {
            margin-bottom: 10px;
        }
    }

    .address {
        width: 50%;
        box-sizing: border-box;
        padding-bottom: 60px;
        overflow: hidden;
    }

    .address__control {
        margin-bottom: 101px;
        @include mediumLaptop {
            margin-bottom: 48px;
        }
    }
    .h2animated.no-height {
        height: 0;
    }
</style>

// <style lang="scss">
//     .values {
//         .hidden-wrapper:first-child {
//             width: 0;
//             overflow: hidden;
//         }
//     }
// </style>