export default function licenseAnimation(timeline) {
  timeline
    .set(".headline > img", { yPercent: 100 })
    .set(".headline > hr", { width: 0 })
    .set(".headline > h1", { opacity: 0, y: 100 })
    .set(".content-container", { opacity: 0, y: 150 })
    .set(".scroll-container", { opacity: 0, xPercent: -50 })
    .to(".headline > img", { yPercent: 0 })
    .to(".headline > hr", { width: "100%" }, ">")
    .to(".headline > h1", { opacity: 1, y: 0 }, "<50%")
    .to(".content-container", { opacity: 1, y: 0 }, "<0")
    .to(".scroll-container", { opacity: 1, xPercent: 0 }, "<0");
}
