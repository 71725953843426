<template>
    <div>
        <a v-if="settings.anchor"
            :target="settings.target && settings.target"
            :href="href" :rel="settings.rel"
            :class="[`button-full-animation__${settings.class}`, classes]"
            @click="navigate && navigate">
            <span>
                <span>{{settings.text}}</span>
            </span>
            <div v-if="settings.icon" class="button__icon"></div>
        </a>
        <button :name="settings.buttonName"
            v-else :disabled="disabled"
            :class="[`button-full-animation__${settings.class}`, classes]"
            @click="$emit('click')">
            <span>
                <span>{{settings.text}}</span>
            </span>
            <div v-if="settings.icon" class="button__icon"></div>
        </button>
    </div>
</template>

<style lang="scss" scoped>
.buttons-group {
    &__values {
        &__item {
            padding: 6px 15px;
            border: 1px solid var(--color1);
            border-radius: 90px;
            >span {
                span {
                    font-size: 16px;
                    color: var(--color1);
                }
            }
            &.buttons__map-open {
                >span {
                    span {
                        font-size: 13px;
                    }
                }
            }
            @include smallLaptop {
                >span {
                    span {
                        font-size: 13px;
                    }
                }
            }
            @include largePhonesAlt {
                >span {
                    span {
                        font-size: 10px;
                    }
                }
            }
            @include extraSmallPhones {
                padding: 4px 7px;
            }
        }
    }
    &__tag-filter {
        box-sizing: border-box;
        width: 100%;
        display: inline-flex;
        max-width: fit-content;
        align-items: center;
        margin: 0 5px 5px 0;
        padding: 6px 15px;
        border: 1px solid var(--color1);
        border-radius: 50px;
        >span {
            font-size: 13px;
            text-transform: uppercase;
            color: var(--color1);
            span {
                width: 100%;
                text-overflow: ellipsis;
            }
        }
        &.about__events__sidebar__clear {
            padding: 6px 30px 6px 15px;
            >.button__icon {
                position: absolute;
                right: 0;
                top: 0;
                width: 16px;
                height: 16px;
                background-image: url("data:image/svg+xml,%3Csvg width='12' height='15' viewBox='0 0 12 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.87499 12.5002H7.01562L5.78124 13.7346L6.21874 14.1721L8.20312 12.1877L6.21874 10.2034L5.78124 10.6409L7.01562 11.8752H3.87499C2.64061 11.8752 1.62498 10.8596 1.62498 9.62523V5.37522H0.999979V9.62523C0.999979 11.219 2.28123 12.5002 3.87499 12.5002Z' fill='%23001D82' stroke='%23001D82' stroke-width='0.4'/%3E%3Cpath d='M6.21874 4.35936L4.98436 3.12498H8.12499C9.35937 3.12498 10.375 4.14061 10.375 5.37499V9.625H11V5.37499C11 3.78123 9.71875 2.49998 8.12499 2.49998H4.98436L6.21874 1.2656L5.78123 0.828101L3.79685 2.81248L5.78123 4.79686L6.21874 4.35936Z' fill='%23001D82' stroke='%23001D82' stroke-width='0.4'/%3E%3C/svg%3E");
                background-size: 12px 15px;
                background-position: center;
                background-repeat: no-repeat;
                transition: all 1s ease;
                transform: translate(-6px, 4px) rotate(0);
            }
            &:hover {
                >.button__icon {
                    transform: translate(-7px, 4px) rotate(360deg);
                }
            }
        }
    }

    &__show-more {
        width: fit-content;
        padding: 6px 15px;
        text-transform: uppercase;
        display: flex;
        align-content: center;
        border: 1px solid #001D82;
        border-radius: 60px;
        margin: 80px auto 0;
        cursor: pointer;

        @include largePhonesAlt {
            margin: 40px auto 0;
        }

        & .button__icon {
            display: inline-block;
            width: 15px;
            height: 5px;
            margin-top: 2px;
            margin-left: 15px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='7' viewBox='0 0 14 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 6L6.63181 6.33828L7.36819 6.33828L7 6ZM11.5938 1L11.5938 0.5L11.3741 0.5L11.2256 0.661721L11.5938 1ZM2.40625 0.999999L2.77444 0.661721L2.62586 0.5L2.40625 0.499999L2.40625 0.999999ZM7.36819 6.33828L10.4307 3.00495L9.69431 2.32839L6.63181 5.66172L7.36819 6.33828ZM10.4307 3.00495L11.9619 1.33828L11.2256 0.661721L9.69431 2.32839L10.4307 3.00495ZM11.5937 1.5L14 1.5L14 0.5L11.5938 0.5L11.5937 1.5ZM7.36819 5.66172L4.30569 2.32839L3.56931 3.00495L6.63181 6.33828L7.36819 5.66172ZM4.30569 2.32839L2.77444 0.661721L2.03806 1.33828L3.56931 3.00495L4.30569 2.32839ZM2.40625 0.499999L2.18557e-08 0.499999L-2.18557e-08 1.5L2.40625 1.5L2.40625 0.499999Z' fill='%23001D82'/%3E%3C/svg%3E%0A");
        }
    }
}
.button-contats-link {
    width: fit-content;
}
.button-red {
    margin: 60px 0 0;
}
</style>

<script>
// import eventHub from '../eventHub'

export default {
    name: 'AB',
    props: {
        settings: {
            type: Object,
            default() {
                return { anchor: false }
            }
        },
        classes: [Array, String],
        disabled: Boolean,
        href: String,
        navigate: Function,
        route: Object
    },
    emits: ['click'],
    // methods: {
    //     handelClick() {
    //         if(this.settings.type === 'show more') {
    //             eventHub.$emit('updAnimation')
    //             this.$emit('click')
    //             console.log('check upd')
    //         }
    //         this.$emit('click')
    //     }
    // }
}
</script>