<template>
    <div class="event-item__event-container" data-position-end="sticky">
        <router-link v-if="iW <= 1248" tag="button" class="ab-router-link" :to="`/events`">
            <AnimatedButton :classes="['buttons-group__tag-filter']" :settings="{anchor: true, class: 'w2y_right', text: 'вернуться', icon: true}" />
        </router-link>
        <template v-if="!articleReady">
            <div class="eventlist-loader">
                <div class="loader1"></div>
            </div>
        </template>
        <template v-else>
            <div class="event-item__event-header">
                <div class="event-item__event-header__info event-item__event-header__info_bordered event-item__tag">{{data.type}}</div>
                <span class="event-item__event-header__info event-item__date">{{data.publication_date}}</span>
                <div class="event-item__event-header__info event-item__event-header__info_bordered event-item__more-tag">{{data.tag}}</div>
            </div>
            <div class="event-item__event-body" v-if="data.name"><h1>{{data.name}}</h1></div>
            <template v-for="(item,i) in data.value">
                <template v-if="item.type==='block'">
                    <div class="event-item__event-body" :key="`ev_body_${i}`"><h2 :key="`h2_${i}`">{{item.name}}</h2></div>
                    <div class="event-item__event-body" v-html="item.html_description" :key="i"></div>
                </template>
                <template v-else>
                    <div class="event-item__event-body" :key="i">
                        <blockquote>
                            <p>{{item.description}}</p>
                            <div class="quote-person">
                                <div class="quote-person__avatar">
                                    <img :src="'/storage/'+item.image" alt="Автор цитаты">
                                </div>
                                <div class="quote-person__info">
                                    <span class="quote-person__info__name">{{item.citation_name}}</span>
                                    <span class="quote-person__info__position">— {{item.citation_position}}</span>
                                </div>
                            </div>
                        </blockquote>
                    </div>
                </template>
            </template>
        </template>
        <!-- <div class="event-item__event-body">
            <h5>Межрегиональный форум «Трансформация высшего образования и трансфер технологий в цифровой экономике»</h5>
            <p>В рамках проекта «Цифровизация образования» при поддержке Министерства высшего образования и науки и Министерства просвещения Российской Федерации 21 сентября 2020 года в Майкопском государственном технологическом университете (республика Адыгея) состоялся Межрегиональный форум «Трансформация высшего образования и трансфер технологий в цифровой экономике».</p>
            <img src="@/assets/images/event-template1.jpg" alt="">
            <p>Действительно, вузы оказались в зоне риска, и в условиях пандемии им пришлось оперативно пересматривать модели обучения и взаимодействия со студентами, осуществлять процесс приема заявлений от абитуриентов и организовывать весь процесс деятельности.</p>
            <ul>
                <li class="red-list">Ставьте и контролируйте задачи любой сложности</li>
                <li class="red-list">Добавляйте чек-листы и файлы для работы</li>
                <li class="red-list">Выбирайте ответственных, наблюдателей и сроки</li>
                <li class="red-list">Работайте вместе над задачами и проектами с любого устройства</li>
                <li class="red-list">Используйте разные методики планирования: Мой план, канбан-доска, диаграмма Ганта, слоты в календаре</li>
                <li class="red-list">Гибкие фильтры помогут найти любую задачу за секунды</li>
            </ul>
            <h4>Модули внутри решения гибко<br>настраиваются под  задачи</h4>
            <p>В рамках проекта «Цифровизация образования» при поддержке Министерства высшего образования и науки и Министерства просвещения Российской Федерации 21 сентября 2020 года в Майкопском государственном технологическом университете (республика Адыгея) состоялся Межрегиональный форум «Трансформация высшего образования и трансфер технологий в цифровой экономике». Мероприятие было посвящено обсуждению вопросов трансформации системы управления вузом, обмена опытом внедрения новых форматов и инструментов образования, развития технологий и технологических проектов на базе университетов. Форум прошел с участием представителей 51 регионального вуза, научно-исследовательских центров, индустриальных компаний-партнеров, а также государственных органов исполнительной власти из более 20 регионов России.</p>
            <ol>
                <li>Ставьте и контролируйте задачи любой сложности</li>
                <li>Добавляйте чек-листы и файлы для работы</li>
                <li>Выбирайте ответственных, наблюдателей и сроки</li>
                <li>Работайте вместе над задачами и проектами с любого устройства</li>
                <li>Используйте разные методики планирования: Мой план, канбан-доска, диаграмма Ганта, слоты в календаре</li>
                <li>Гибкие фильтры помогут найти любую задачу за секунды</li>
            </ol>
            <blockquote>
                <p>Технической поддержкой и эксплуатацией высоконагруженных информационных систем мы занимаемся уже более 8 лет. Чтобы уменьшить рутину, решили автоматизировать техническую поддержку и разработали специальных ботов, которые круглосуточно проверяют и тестируют прикладное программное обеспечение, взаимодействуют с пользователями и уведомляют службу поддержки о возникших сбоях.</p>
                <p>Так появилась серия продуктов, которые полностью адаптированы под потребности любых информационных систем и готовы к интеграции с ИТ-инфраструктурой любой сложности.</p>
                <div class="quote-person">
                    <div class="quote-person__avatar">
                        <img src="@/assets/images/kn_av.png" alt="">
                    </div>
                    <div class="quote-person__info">
                        <span class="quote-person__info__name">Наталья Краснобаева</span>
                        <span class="quote-person__info__position">— Генеральный директор</span>
                    </div>
                </div>
            </blockquote>
        </div> -->
    </div>
</template>

<script>
import axios from 'axios'
import eventHub from '../../eventHub'
import AnimatedButton from '../../UI/AB.vue'

export default {
    metaInfo() {
        return {
            script: [{
              type: 'application/ld+json',
              json: {
                '@context': 'https://schema.org',
                '@type': 'Article',
                mainEntityOfPage: {
                  '@type': 'WebPage',
                  '@id': `${window.location.href}`
                },
                headline: `${this.data.name}`,
                'description': `${this.data.seo.description}`,
                image: 'https://hopper-it.ru/front/img/logo.79f0a422.svg',
                author: {
                  '@type': 'Organization',
                  name: 'Hopper IT'
                },
                publisher: {
                  '@type': 'Organization',
                  name: 'Hopper IT',
                  logo: {
                    '@type': 'ImageObject',
                    url: 'https://hopper-it.ru/front/img/logo.79f0a422.svg'
                  }
                },
                datePublished: `${this.data.json_date}`
              }
            }],
        }
    },
    name: 'eventTemplateContainer',
    components: {
        AnimatedButton
    },
    data() {
        return {
            iW: 0,
            data: {},
            articleReady: false,
            formFileds: [{type: "email", placeholder: "Email*", required: true}],
        }
    },
    props: {
        simillarNews: Object,
    },
    methods: {
        myEventHandler(e) {
            this.iW = e.target.innerWidth
        }
    },
    created() {
        axios.get('/api' + this.$route.path)
            .then((response) => {
                // добавляется атрибут rel="nofollow" для ссылок которые ведут на сторонние ресурсы
                if(response.data && response.data.value) {
                    const regExp = new RegExp('(<a.+)(href=")(?!' + window.location.origin + ')', 'gm')
                    this.data = {...response.data, value: response.data.value.map((item) => {
                        // eslint-disable-next-line
                        item.html_description = item.html_description.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
                        return item
                    })}
                } else {
                    this.data = response.data
                }
                eventHub.$emit('eventTemplateSeoData', response.data.seo)
                this.simillarNews.items = this.data.simillar_news
                this.articleReady = true
            })
            .catch((error) => {
                console.log(error)
                this.$router.push('/404')
            });
        window.addEventListener("resize", this.myEventHandler);
    },
    mounted() {
        this.iW = window.innerWidth
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    },
}
</script>

<style lang="scss" scoped>

.event-item {
    &__event-container {
        position: relative;
        box-sizing: border-box;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 46px;
        padding-bottom: 40px;

        &:after {
            position: absolute;
            top: 0px;
            right: 0;
            width: calc(100% - 40px);
            height: 1px;
            background-color: var(--color1);
            content: '';
        }

        .ab-router-link {
            position: relative;
            width: 100%;
            padding-bottom: 24px;
            &:after {
                position: absolute;
                bottom: 0px;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: var(--color1);
                content: '';
            }
        }

        @include mediumLaptop {
            padding-left: 0;
            &:after {
                display: none;
            }
        }
    }
    &__event-header {
        padding-top: 10px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .event-item__event-header__info {
            font-size: 13px;
            color: var(--color1);
            text-transform: uppercase;
            &_bordered {
                box-sizing: border-box;
                border-radius: 60px;
                padding: 8px 16px;
                font-size: 13px;
            }
        }
        .event-item__tag {
            border: 1px solid var(--color1);
            margin-right: 24px;
        }
        .event-item__more-tag {
            border: 1px solid transparent;
            margin-left: 20%;
            background-color: #462DC7;
            color: #fff;
        }
    }
    @include mediumLaptop {
        &__event-container {
            padding-left: 0;
            padding-bottom: 0;
            &:after {
                width: 100%;
            }
        }
        &__event-header {
            justify-content: flex-start;
            flex-wrap: wrap;
            .event-item__event-header__info {
                font-size: 10px;
            }
            .event-item__date {
                width: 50%;
            }
            .event-item__more-tag {
                margin-left: 0;
                margin-top: 16px;
            }
        }
    }
}
.event-item__event-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    row-gap: 40px;
    ::v-deep img {
        margin: 40px 0;
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    ::v-deep h1 {
        padding-top: 10px;
        border-top: 1px solid var(--color1);
        width: 100%;
        text-align: left;
        font-size: 40px;
        line-height: 110%;
        color: var(--color1);
        font-weight: 400;
        text-transform: uppercase;
    }
    ::v-deep h2 {
        width: 100%;
        font-size: 36px;
        font-weight: 400;
        color: var(--color1);
    }
    ::v-deep h4 {
        padding-top: 10px;
        border-top: 1px solid var(--color1);
        width: 100%;
        text-align: left;
        font-size: 40px;
        line-height: 110%;
        color: var(--color1);
        font-weight: 400;
        text-transform: uppercase;
    }
    ::v-deep h5 {
        width: 100%;
        font-size: 36px;
        font-weight: 400;
        color: var(--color1);
    }
    ::v-deep p,
    ::v-deep div {
        width: 100%;
        max-width: 78%;
        font-size: 18px;
        line-height: 160%;
        color: var(--color1);
        margin: 0;
    }
    ::v-deep ul {
        margin: 0;
        padding: 0;
        width: 100%;
        max-width: 78%;
        list-style-type: none;
        box-sizing: border-box;
        list-style-position: inside;
        li {
            color: var(--color1);
            font-size: 16px;
            margin-bottom: 15px;
            padding-left: 49px;
            position: relative;
            &:before {
                content: '';
                width: 9px;
                height: 11px;
                position: absolute;
                top: 4px;
                left: 0;
                background-repeat: no-repeat;
                background-size: 100%;
                background-position: center;
                background-image: url("data:image/svg+xml,%3Csvg width='9' height='11' viewBox='0 0 9 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 5.5L4.72849e-07 11L9.53674e-07 -3.93402e-07L9 5.5Z' fill='%23E62315'/%3E%3C/svg%3E%0A");
            }
        }
    }
    ::v-deep ol {
        margin: 0;
        max-width: 78%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        box-sizing: border-box;
        counter-reset: li;
        li {
            font-size: 16px;
            color: var(--color1);
            margin-bottom: 15px;
            list-style-type: none;
            &:before {
                content: "." counter(li);
                color: var(--color3);
                display: inline-block;
                width: 16px;
                margin-left: -42px;
                margin-right: 35px;
                text-align: right;
                direction: rtl;
                counter-increment: li;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        p {
            width: 100%;
            max-width: 78%;
            font-size: 18px;
            line-height: 160%;
            color: var(--color1);
        }
        ul {
            margin: 60px 0;
            padding: 0;
            width: 100%;
            max-width: 78%;
            list-style-type: none;
            box-sizing: border-box;
            list-style-position: inside;
            li {
                color: var(--color1);
                font-size: 16px;
                margin-bottom: 15px;
            }
        }
        ol {
            margin: 60px 0;
            width: 100%;
            max-width: calc(78% - 50px);
            list-style: none;
            counter-reset: li;
            li {
                font-size: 16px;
                color: var(--color1);
                margin-bottom: 15px;
                &:before {
                    content: "." counter(li);
                    color: var(--color3);
                    display: inline-block;
                    width: 16px;
                    margin-left: -50px;
                    margin-right: 35px;
                    text-align: right;
                    direction: rtl;
                    counter-increment: li;
                }
            }
        }
    }
    @include mediumLaptop {
        row-gap: 20px;
        ::v-deep img {
            margin: 0;
        }
        ::v-deep h1 {
            font-size: 18px;
        }
        ::v-deep h2 {
            font-size: 18px;
        }
        ::v-deep h4 {
            font-size: 18px;
        }
        ::v-deep h5 {
            font-size: 18px;
        }
        ::v-deep p,
        ::v-deep div {
            max-width: 100%;
            font-size: 16px;
        }
        ::v-deep ul {
            max-width: 100%;
            li {
                font-size: 14px;
                padding-left: 15px;
                &:before {
                    top: 7px;
                }
            }
        }
    }
}
blockquote {
    box-sizing: border-box;
    margin: 60px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    border-top: 1px solid var(--color4);
    width: 100%;
    background: url("data:image/svg+xml,%3Csvg width='74' height='66' viewBox='0 0 74 66' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M29.7552 36.1181H17.3357L30.7902 0H15.7832L0 34.0394V66H29.7552V36.1181ZM72.965 36.1181H60.5455L74 0H58.993L43.2098 34.0394V66H72.965V36.1181Z' fill='%23FFD200'/%3E%3C/svg%3E"), url("data:image/svg+xml,%3Csvg width='74' height='66' viewBox='0 0 74 66' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M44.2448 36.1181H56.6643L43.2098 0H58.2168L74 34.0394V66H44.2448V36.1181ZM1.03497 36.1181H13.4545L0 0H15.007L30.7902 34.0394V66H1.03497V36.1181Z' fill='%23F3F3F3'/%3E%3C/svg%3E");
    background-position: left 20px, right calc(100% - 20px);
    background-repeat: no-repeat, no-repeat;
    p {
        width: 100%;
        max-width: 78%;
        font-size: 24px;
        line-height: 130%;
        color: var(--color1);
        font-style: italic;
    }
    .quote-person {
        width: 100%;
        max-width: 78%;
        height: 72px;
        padding: 40px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid var(--color4);
        &__avatar {
            overflow: hidden;
            width: 72px;
            height: 72px;
            border-radius: 50%;
            margin-right: 20px;
            >img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__info {
            width: auto;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            &__name {
                font-size: 16px;
                color: var(--color1);
                text-transform: uppercase;
            }
            &__position {
                font-size: 12px;
                color: var(--color1)
            }
        }
    }
    @include mediumLaptop {
        padding: 50px 0 75px;
        background-size: 41px 36px, 50px 45px;
    }
}
.eventlist-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 150px;
}
.loader1 {
    opacity: .7;
    width: 30px;
    height: 30px;
    border: 3px solid transparent;
    border-top: 3px solid var(--color1);
    border-bottom: 3px solid var(--color1);
    border-radius: 50%;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.eventlist-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 150px;
}
.loader1 {
    opacity: .7;
    width: 30px;
    height: 30px;
    border: 3px solid transparent;
    border-top: 3px solid var(--color1);
    border-bottom: 3px solid var(--color1);
    border-radius: 50%;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
