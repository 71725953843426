<template>
    <ul class="solution-list" data-position-end="sticky">
        <li class="solution-item" v-for="(item,key) in activeValue" :key="key" v-show="activeShowItems > key && (filter.length===0 || filter.includes(item.tag))">
            <h3><router-link :to="'/solutions/'+item.link">{{item.name}}</router-link></h3>
            <div class="solution-wrapper">
                <ul class="when-list">
                    <span class="label">Когда требуется?</span>
                    <li class="red-list" v-for="(when, i) in item.whenList" :key="i">
                        {{when}}
                    </li>
                </ul>
            </div>
            <div class="solution-wrapper">
                <ul class="decisions-list">
                    <span class="label">База решения на продуктах</span>
                    <router-link tag="li" :to="decision.link" v-for="(decision, i) in item.decisionList" :key="i">
                        <span class="name">{{decision.name}}</span>
                        <img :src="'/storage/'+decision.img" :alt="decision.name">
                        <div class="icon"></div>
                    </router-link>
                </ul>
                <div class="department">
                    <span class="label">Кому требуется?</span>
                    <p>{{item.department}}</p>
                </div>
            </div>
            <div class="button-wrapper">
                <router-link tag="button" class="button red" :to="'/solutions/'+item.link">
                    <span>Подробнее</span>
                    <div class="button__icon"></div>
                </router-link>
            </div>
        </li>
        <AnimatedButton
          @click="incShowItems()"
          :classes="['buttons-group__show-more']"
          :settings="{class: 'w2y', text: 'показать еще', icon: true}"
          v-if="activeCasesLength"/>
    </ul>
</template>
<script>
  import AnimatedButton from '../../UI/AB.vue'

  export default {
      components: {
        AnimatedButton
      },
      data() {
        return {
          inc: this.showItems,
          activeValue: this.value,
          activeShowItems: this.showItems
        }
      },
      props: {
        value: Array,
        filter: Array,
        showItems: {
          type: Number,
          default: 4
        }
      },
      methods: {
        incShowItems() {
          this.activeShowItems += this.inc
        }
      },
      watch: {
        value() {
          this.activeValue = this.value
        },
        filter() {
          if(this.filter.length === 0) {
            this.activeShowItems = this.inc
            this.activeValue = this.value
          } else {
            this.activeValue = this.value.filter((item) => {
              return this.filter.includes(item.tag)
            })
          }
        },
      },
      computed: {
        activeCasesLength() {
          if(this.activeValue && this.activeValue.length) {
            return this.activeValue.length > this.activeShowItems
          } else {
            return false
          }
        }
      }
  }
</script>
<style lang="scss" scoped>
    .solution-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        color: var(--color1);
        width: 70%;
        min-width: 900px;
        max-width: 1087px;
        margin-left: auto;
        margin-top: 40px;
        padding-bottom: 80px;
        border-bottom: 1px solid #001D82;

        display: flex;
        flex-flow: column;
        row-gap: 20px;


        @include mediumDesctop {
          min-width: 600px;
          max-width: 1087px;
        }

        @include mediumLaptopAlt {
          margin-top: 0;
          max-width: 100%;
          width: 100%;
          margin-top: 24px;
        }

        @include largePhonesAlt {
            padding-bottom: 40px;
        }

        @include largePhones {
          min-width: 100%;
          row-gap: 12px;
        }
    }

    .solution-item {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid var(--color1);
        border-radius: 10px;
        padding: var(--mainPadding);

        h3 {
            width: 100%;
            margin-top: 0;
            margin-bottom: 40px;
        }

        @include smallDesktop {
          padding: var(--mainPadding);

          h3 {
            margin-bottom: 30px;
          }
        }

        @include phones {
          padding: var(--mainPadding) var(--mainPaddingSmallSize);
        }

        @include largePhones {
          flex-direction: column;

          h3 {
            font-size: 18px;
            margin-bottom: 24px;
          }
        }
    }

    .solution-wrapper {
        width: calc(50% - 45px);
        margin-right: 45px;

        &+.solution-wrapper {
            margin-right: 0;
            margin-left: 45px;
        }

        @include smallDesktop {
          margin-right: var(--mainPadding);
          width: calc(50% - var(--mainPadding));

          &+.solution-wrapper {
            margin-left: var(--mainPadding);
          }
        }

        @include largePhones {
          width: 100%;

          display: flex;
          flex-direction: column;
          align-items: unset;

          &+.solution-wrapper {
            margin-left: 0;
            margin-top: 24px;
          }
        }
    }

    .button-wrapper {
        width: 100%;
        margin-top: 20px;
    }

    .label {
        padding: 6px 15px;
        background-color: var(--color4);
        color: var(--color1);
        border-radius: 60px;
        font-size: 13px;
        line-height: 15px;
        text-transform: uppercase;
        display: inline-block;
        margin-bottom: 5px;
    }

    .when-list {
        padding-left: 0;
        margin: 0;
        list-style-type: none;

        li {
            margin-top: 15px;
        }

        @include mediumLaptop {
          font-size: 14px;
        }
    }

    .decisions-list {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 15px;
            position: relative;
            min-height: 55px;
            padding-left: 67px;
            cursor: pointer;

            .name {
                font-size: 16px;
                line-height: 110%;
                text-transform: uppercase;
            }

            img {
                width: 55px;
                height: 55px;
                position: absolute;
                top: 0;
                left: 0;
                object-fit: contain;
            }

            .icon {
                width: 21px;
                height: 21px;
                background-color: var(--color1);
                border-radius: 21px;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url("data:image/svg+xml,%3Csvg width='9' height='9' viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='4.5' y1='-2.18557e-08' x2='4.5' y2='9' stroke='white'/%3E%3Cline x1='3' y1='4.5' y2='4.5' stroke='white'/%3E%3Cline x1='9' y1='4.5' x2='6' y2='4.5' stroke='white'/%3E%3C/svg%3E");
            }
        }

        @include mediumPhones {
          li {
            margin-top: 20px;
          }
        }
    }

    .department {
        margin-top: 40px;

        p {
            margin-top: 20px;
            margin-bottom: 0;
            font-size: 16px;
            line-height: 140%
        }

        @include mediumLaptop {
          p {
            font-size: 14px;
          }
        }

        @include largePhones {
          margin-top: 24px;
        }
    }
</style>
