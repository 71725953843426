export default {
    actions: {
      fetchFilter(ctx, data) {
        ctx.commit('updateFilter', data);
      }
    },
    mutations: {
      updateFilter(state, filter) {
        if(filter==='resetAll') {
            state.activeFilter =[]
        } else {
            if(state.activeFilter.includes(filter)) {
                let pos = state.activeFilter.indexOf(filter)
                state.activeFilter.splice(pos,1)
            }
            else {
                state.activeFilter.push(filter);
            }
        }
      }
    },
    state: {
        activeFilter: []
    },
    getters: {
      activeFilter(state) {
        return state.activeFilter;
      }
    },
  };