<template>
    <div class="dflex-cont dflex-cont_column_center">
        <Banner class="dflex-child_maxwidth" text="Кейсы" image="cases.svg" filter="true" :onInput="updateMainFilter" :business="directory" />
        <section class="section dflex-child_maxwidth">
            <CustomFilter :value="filter" :onInput="updateFilters" :activeFilters="activeFilters" />
            <Cases :cases="cases" class="cases" :onInput="updateFilters" :activeFilters="activeFilters"
                :interacive="true" />
        </section>
    </div>
</template>
<script>
    import Banner from "../components/UI/banner.vue"
    import Cases from "../components/UI/caseList.vue"
    import CustomFilter from "../components/UI/customFilter.vue"
    import axios from 'axios'
    import eventHub from "../components/eventHub"
    import {mapGetters,mapMutations} from 'vuex'

    export default {
        metaInfo() {
            return {
                title: `${this.metaNames.title ?? 'Кейсы'} ${this.directory === 'business' ? ' - Бизнес-сектор' : ' - Гос сектор'}`,
                meta: this.metaNames.metas
            }
        },
        components: {
            Banner,
            Cases,
            CustomFilter
        },
        data() {
            return {
                activeFilters: [],
                filter: [{
                    name: 'Услуга',
                    value: []
                }, {
                    name: 'Отрасль',
                    value: []
                }],
                cases: {},
                business: true,
                directory: localStorage.getItem('productDirectory'),
                showFilters: false
            }
        },
        methods: {
            ...mapMutations(['addMetaNames', 'setLoading']),
            updateFilters(data) {
                if (data === 'resetAll') {
                    this.activeFilters = []
                } else {
                    if (this.activeFilters.includes(data)) {
                        let pos = this.activeFilters.indexOf(data)
                        this.activeFilters.splice(pos, 1)
                    } else {
                        this.activeFilters.push(data);
                    }
                }
            },
            updateMainFilter(data, isFirstLoad = false) {
                isFirstLoad && eventHub.$emit('updateLoader', true)
                const time = Date.now()

                this.directory = data
                localStorage.setItem('productDirectory', data)
                this.activeFilters = []
                axios.get('/api/cases/type/' + this.directory)
                    .then((response) => {
                      this.addMetaNames(response.data.seo)
                      this.cases = response.data
                      setTimeout(() => {
                        this.createFilters()
                      }, 1)
                    })
                    .catch((error) => {
                      console.log(error)
                      this.$router.push('/404')
                    })
                    .finally(() => {
                      const setTime = Date.now() - time > 1000 ? 0 : 1000 - (Date.now() - time)
                      isFirstLoad && setTimeout(() => {
                        eventHub.$emit('updateLoader', false)
                        setTimeout(() => {
                          this.setLoading(false)
                        }, 1000)
                      }, setTime)
                    });
                // this.createFilters()
            },
            createFilters() {
                this.filter[0].value = []
                this.filter[1].value = []
                for (let tmp of this.cases.cases) {
                    for (let branch of tmp.branches) {
                        if (!this.filter[1].value.includes(branch)) {
                            this.filter[1].value.push(branch)
                        }
                    }
                    for(let service of tmp.services) {
                        if(!this.filter[0].value.includes(service)) {
                            this.filter[0].value.push(service)
                        }
                    }
                }
                this.showFilters = true
            }
        },
        mounted() {
            if (this.directory != 'business' && this.directory != 'natioanal') {
                this.directory = 'business'
                localStorage.setItem('productDirectory', 'business')
            }
            this.updateMainFilter(this.directory, true)
        },
        computed: {
            ...mapGetters(['metaNames'])
        }
    }
</script>
<style lang="scss" scoped>
    .section {
        display: flex;
        position: relative;
        @include mediumLaptopAlt {
            flex-direction: column;
            padding-top: var(--paddingTopPhones);
      }

      @include largePhonesAlt {
        padding-top: 10px;
      }
    }

    .filter {
        width: 25vw;
        z-index: var(--sidebarIndex);
        margin-top: 40px;
        @include mediumLaptopAlt {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 100vw;
            padding: 0;
            margin: 0;
            height: fit-content;
        }
        .filter-item {
            width: fit-content;
        }
    }
    .cases {
        width: 100%;
    }
</style>