<template>
    <section class="cropped">
        <hr class="animate-elem shiftLeft wrap"/>
        <h2 class="smallh2 h2animated">
            <span>{{h2text}}</span>
        </h2>
        <template v-if="setPositionSection">
            <div class="aviable-positions">
                <EventsInsideSidemanu :page="'career'" :tags="cities" :listReady="listReady" />
                <div class="about__events__event-list aviable-positions__list animate-elem listfadeInRight" data-position-end="sticky">
                    <template v-for="(item,index) in positionsData" >
                        <CareerAccordionItem v-if="sorting(item)" :key="index" :class="{'is-visible': activeEl == index}" :itemInfo="item" :quoteModal="quoteModal" :content="setPositionInfo" @toggle="toggleAccordion(index)" />
                    </template>
                </div>
            </div>
            <!-- <Modal :iW="iW" :formFileds="formFileds" :modalType="'positionCallback'" :content="setPositionInfo" :quoteModal="quoteModal" /> -->
        </template>
        <span v-else v-html="ifThereIsNoPositions" class="no-positions"></span>
    </section>
</template>

<script>
import EventsInsideSidemanu from './eventsInsideSidemenu.vue'
import CareerAccordionItem from '../../UI/careerAccordionItem.vue'
// import Modal from './modal.vue'
import eventHub from '../../eventHub'

export default {
    name: 'positionsAccordion',
    components: {
        EventsInsideSidemanu,
        CareerAccordionItem,
        // Modal
    },
    props: {
        positionsData: {
            type: Array,
            require: false,
            default: function(){
                return []
            }
        },
        cities: Array,
        listReady: Boolean,
        h2text: String,
        iW: Number
    },
    data() {
        return {
            ifThereIsNoPositions: '<p>На данный момент наша команда полностью укомплектована. Но мы всегда рады талантливым специалистам.</p><p>Если у вас есть желание работать с нами, то вы можете направить свое резюме на <a class="footer-link" href="mailto:hr@hopperit.ru">hr@hopperit.ru</a></p>',
            activeEl: -1,
            activeTagsByName: [],
            quoteModal: {
                visible: false,
                personId: undefined
            },
            formFileds: [
                {type: "text", placeholder: "Имя*", required: true},
                {type: "text", placeholder: "Телефон", required: false, masked: '+7 (###) ###-##-##'},
                {type: "email", placeholder: "Email*", required: true},
                {type: "textarea", placeholder: "Текст сопроводительного письма*", required: true},
                {type: "file", required: false}
            ]
        }
    },
    mounted() {
        eventHub.$on('bebe123', (data) => {
            this.activeTagsByName = data
        })

        if(this.ifThereIsNoPositions) {
            const regExp = new RegExp('(<a.+)(href=")(?!' + window.location.origin + ')', 'gm')
            // eslint-disable-next-line
            this.ifThereIsNoPositions = this.ifThereIsNoPositions.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
        }
    },
    onBeforeUnmount() {
        eventHub.$off('bebe123')
    },
    computed: {
        setPositionInfo() {
            if (this.positionsData) {
                return this.positionsData.find(i => {
                    if (i.id === this.quoteModal.personId) {
                        return i
                    }
                })
            }
            return null
        },
        setPositionSection() {
            return this.positionsData.length ? true : false
        }
    },
    methods: {
        toggleAccordion(item) {
            eventHub.$emit('updAnimation')
            this.activeEl == item ? this.activeEl = -1 : this.activeEl = item
        },
        sorting(tag) {
            tag = tag.cities.map(i => {
                return i.name
            })
            let activeTag
            if (this.activeTagsByName.length === 0) {
                activeTag = true
            } else {
                activeTag = tag.some(t => {
                    return this.activeTagsByName.indexOf(t) >= 0
                })
            }
            return activeTag
        }
    }
}
</script>

<style lang="scss" scoped>
hr {
    border-top: 1px solid var(--color1);
    margin: 0;
}
h2 {
    width: 100%;
    margin-bottom: 80px;
    &.smallh2 {
        width: 100%;
    }
    @include largePhonesAlt {
        &.smallh2 {
            padding-top: 20px;
            margin-bottom: 24px;
            margin-right: 0;
            font-size: 24px;
        }
    }
}
.no-positions {
    ::v-deep p {
        font-family: 'SuisseIntl';
        font-size: 19px;
        color: var(--color1);
    }
}
.aviable-positions {
    width: 100%;
    display: flex;
    &__list {
        width: 75vw;

        @include mediumAlt {
          width: 70vw;
        }

        @include largePhonesAlt {
          width: 100%;
        }
    }
    @include largePhonesAlt {
        flex-direction: column;
    }
    @include smallPhonesAlt {
        flex-direction: column;
    }
}
</style>
<style lang="scss">
section.cropped {
    .aviable-positions {
        .about__events__sidebar {
            @include largePhonesAlt {
                padding-bottom: 0;
            }
        }
        .about__events__event-list {
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            & > .aviable-positions__list__item {
                margin-bottom: 0;
            }
            @include largePhonesAlt {
                margin-top: 24px;
            }
        }
    }
    & > .office-photos__carousel.splide {
        padding-bottom: 0;
    }
}
</style>