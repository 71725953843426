import './assets/styles/index.scss';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import PortalVue from 'portal-vue'
import VueMask from 'v-mask'
import VueMeta from 'vue-meta'

Vue.use(PortalVue)
Vue.use(VueMask);
Vue.use(VueMeta)

// добавление кастомной директивы для скрола
// Vue.directive('scroll', {
//   inserted: function (el, binding) {
//     let f = function (evt) {
//       if (binding.value(evt, el)) {
//         window.removeEventListener('scroll', f)
//       }
//     }
//     window.addEventListener('scroll', f)
//   }
// })

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  axios,
  render: h => h(App),
}).$mount('#app');
