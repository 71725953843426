<template v-if="carousel.text">
    <section class="cropped" :class="{'self-align--end': type == 'news-alike'}">
        <div v-if="wrapped.isWrapped" :class="wrapped.wrapperClass">
            <CarouselInside :h2text="h2text" :quoteModal="quoteModal" :class="[carousel.classes, type === 'carAchivements' && 'stories__carousel']" :carousel="carousel" :settings="settings" :type="type"/>
            <!-- <Modal :iW="iW" :modalType="modalType" :content="sortData" :quoteModal="quoteModal" /> -->
        </div>
        <template v-else>
            <CarouselInside :iW="iW" :h2text="h2text" :class="[carousel.classes, type === 'photos' && 'office-photos__carousel', type === 'news-alike' && 'simillar-news__carousel']" :carousel="carousel" :settings="settings" :type="type"/>
        </template>
    </section>
</template>

<script>
import CarouselInside from '../pages/about/carouselInside.vue'
// import Modal from '../pages/about/modal.vue'

export default {
    name: 'Carousel',
    components: {
        CarouselInside,
        // Modal
    },
    data() {
        return {
            quoteModal: {
                visible: false,
                personId: undefined,
                test: false
            }
        }
    },
    computed: {
        sortData() {
            return this.modalType === 'quotes' ? this.carousel : this.carousel.items
        }
    },
    props: {
        modalType: String,
        iW: {
            type: Number,
            default: undefined
        },
        h2text: String,
        content: [Array, Object],
        carousel: [Array, Object],
        settings: null,
        type: String,
        wrapped: {
            type: Object,
            default() {
                return { isWrapped: false, wrapperClass: '' }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.office-photos__carousel {
    display: flex;
    flex-direction: row;
    &.splide {
        position: relative;
        padding-bottom: 60px;
    }
}
.simillar-news__carousel {
    display: flex;
    flex-direction: column;
}
.self-align--end {
    width: 100%;
    max-width: 1362px;
    align-self: flex-end;
    // margin-right: 20px;
}
</style>