<template>
  <portal to="simple-modal">
    <transition name="fade" appear>
      <div class="wrapper">
        <div class="popup-mask" @click="closePopup"></div>
        <div class="popup-wrapper">
          <div class="close-popup">
            <button class="button" @click="closePopup">
              <span>Закрыть</span>
            </button>
          </div>
          <div class="popup" :class="{ moveup: isOpen }">
            <div class="title">
              <div class="hr"></div>
              <h4>{{ title }}</h4>
            </div>
            <div class="body">
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
export default {
  props: ["title"],
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    closePopup() {
      this.$emit("closePopup");
    },
  },
  mounted() {
    this.isOpen = true;
    document.querySelector("body").style.overflow = "hidden";
    if (window.innerWidth > 1000) {
      document
        .querySelector("#app > div > div")
        .classList.add("perspective__child-end");

      document.querySelector("body").style.paddingTop = "0";
    }
  },
  destroyed() {
    this.isOpen = false;
    document.querySelector("body").style.overflow = "";
    document
      .querySelector("#app > div > div")
      .classList.remove("perspective__child-end");
    document.querySelector("body").style.paddingTop = "var(--headerHeight)";
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/popup.scss";
</style>