<template>
    <section class="section">
        <div class="scroll-container animate-elem fadeInLeft" :data-position="widthPage > 1024 ? 'sticky' : 'adaptive-menu'">
            <!-- <Button text="Вернуться" @click.native="$router.go(-1)" color="dark arrow-left" /> -->
            <AnimatedButton
              :classes="['buttons-group__tag-filter']"
              :settings="{anchor: true, class: 'w2y_right', text: 'вернуться', icon: true}"
              @click.native="$router.go(-1)"
              />
            <div class="navigation-content" v-if="widthPage > 960">
                <div class="header">
                    <img :src="'/storage/'+this.case.image" alt="">
                    <span class="name">{{this.case.name}}</span>
                </div>
                <div class="case-service">
                    Услуга
                    <span v-for="(service,i) in this.case.services" :key="i">
                        {{service}}
                    </span>
                </div>
                <div class="case-service">
                    Отрасль
                    <span v-for="(branch,i) in this.case.branches" :key="i">
                        {{branch}}
                    </span>
                </div>
                <AnimatedButton :classes="['buttons-group__tag-filter', 'button-red', 'icon_plus']" :settings="{class: 'w2y', text: 'Задать вопрос по кейсу', icon: false}" @click="toggleModalWindow('caseCallback')" />
            </div>
            <div class="navigation-content" v-else>
              <div class="header">
                <span class="name">{{this.case.name}}</span>
              </div>
              <div class="navigation-content__wrapper">
                <img :src="'/storage/'+this.case.image" alt="">
                <div class="navigation-content__wrapper-services">
                  <div class="case-service" v-if="this.case.services">
                    Услуга
                    <span v-for="(service,i) in this.case.services" :key="i">
                          {{service}}
                      </span>
                  </div>
                  <div class="case-service" v-if="this.case.branches">
                    Отрасль
                    <span v-for="(branch,i) in this.case.branches" :key="i">
                          {{branch}}
                      </span>
                  </div>
                </div>
              </div>
              <AnimatedButton :classes="['buttons-group__tag-filter', 'button-red', 'icon_plus']" :settings="{class: 'w2y', text: 'Задать вопрос по кейсу', icon: false}" @click="toggleModalWindow('caseCallback')" />
            </div>
        </div>
        <div class="content-container" data-position-end="sticky">
          <hr class="animate-elem shiftLeft wrap"/>
          <h1 class="line animate-elem shiftUp wrap">{{this.case.name}}</h1>
          <template v-for="(item,key) in this.case">
              <template v-if="item && item.type && item.type ==='annotation' && item.points">
                <Annotation v-for="(item,key) in item.points" :type="item.type" :value="item" :key="key"/>
              </template>
              <section v-if="item && item.type && item.type==='problem' && item.points" class="problem animate-elem fadeInUpSmall" :key="key">
                  <template v-for="(point,i) in item.points">
                      <ul v-if="Array.isArray(point)" :key="i">
                          <li v-for="(obj,k) in point" :key="k" class="red-list" v-html="obj">
                          </li>
                      </ul>
                      <span v-else :key="i" v-html="point">
                      </span>
                  </template>
              </section>

              <section v-else-if="item && item.type && item.type==='solution'  && item.points" class="solution animate-elem fadeInUpSmall" :key="key">
                  <template v-for="(point, i) in item.points">
                      <h3 v-if="point && point.type==='title'" :key="i">{{point.detail}}</h3>

                      <div v-else-if="point && point.type==='image'" class="image-container" :key="i">
                          <img :src="'/storage/'+point.detail" alt="">
                      </div>

                      <div :key="i" class="card" v-if="point && point.type==='card'">
                        <div class="animate-elem shiftUp">
                          <div v-html="point.detail"></div>
                          <span class="number">&lt;{{point.id}}/</span>
                        </div>
                      </div>
                      <!-- <div v-if="point && point.type==='card'" class="card" :key="key" v-html="point.detail">
                          <span class="number">&lt;{{point.id}}/</span>
                      </div> -->
                  </template>
              </section>

              <ul v-else-if="item && item.type && item.type==='result'" class="result animate-elem fadeInUpSmall" :key="key">
                  <li v-for="(point,i) in item.points" :key="i">
                      <span class="animate-elem fadeInUpSmall">{{point.name}}</span>
                      <!-- eslint-disable-next-line -->
                      <p class="animate-elem fadeInUpSmall" v-html="point.description"></p>
                  </li>
              </ul>

              <ul v-else-if="item && item.type && item.type==='feedback' && item.points" class="feedback" :key="key">
                  <h2 v-if="item.points.length==1">Отзыв</h2>
                  <h2 v-if="item.points.length>1">Отзывы</h2>
                  <li v-for="(image,i) in item.points" :key="i">
                      <div class="image-wrapper" @click="toggleLightboxWindow(i, item.points)">
                          <img :src="'/storage/'+image" alt="logo">
                      </div>
                  </li>
              </ul>

              <CaseScroll v-else-if="item && item.type && item.type==='cases'" :cases="item" :key="key"/>
          </template>
        </div>
        <!-- <transition name="popup-animation"> -->
        <div class="image-popup" v-if="isPopupActive" :style="{transform: `translateY(${positionPopup}px)`}">
            <div class="popup-wrapper">
                <img :src="'/storage/'+popupImage" class="popup" alt="logo">
                <button class="button close" @click="toggleModalWindow()">Закрыть</button>
            </div>
        </div>
        <!-- </transition> -->
    </section>
</template>
<script>
    import CaseScroll from '../components/UI/caseScroll.vue'
    // import Button from '../components/UI/button.vue'
    import Annotation from '../components/UI/annotation.vue'
    import axios from 'axios'
    import AnimatedButton from '../components/UI/AB.vue'
    import eventHub from '../components/eventHub'
    import {mapGetters,mapMutations} from 'vuex'

    export default {
        metaInfo() {
            return {
                title: `Кейсы: ${this.metaNames.title ?? this.seoName}`,
                meta: this.metaNames.metas
            }
        },
        components: {
            CaseScroll,
            // Button,
            AnimatedButton,
            Annotation
        },
        data() {
            return {
                isPopupActive: false,
                popupImage: '',
                positionPopup: 0,
                cards:[],
                case: {},
                formFileds: [
                  {type: "text", placeholder: "Имя*", required: true},
                  {type: "text", placeholder: "Телефон", required: false, masked: '+7 (###) ###-##-##'},
                  {type: "email", placeholder: "Email*", required: true},
                  {type: "textarea", placeholder: "Вопрос*", required: true}
                ],
                quoteModal: {
                  visible: false
                }
            }
        },
        methods: {
          ...mapMutations(['addMetaNames', 'setLoading']),
            toggleModalWindow(type) {
              this.quoteModal.visible = true
              eventHub.$emit('emitModal', {formFileds: this.formFileds, modalType: type, quoteModal: this.quoteModal})
              // eventHub.$emit('emitModal', {content: image, iW: this.widthPage, quoteModal: this.quoteModal, modalType: type})
            },
            toggleLightboxWindow(index, lightboxSettings) {
              const newLBX = {index: index, visible: true}
              eventHub.$emit('emitLightbox', {lbx: newLBX, lightboxSettings: lightboxSettings})
            },
            calcSolution() {
                // функция для подсчета номера карточки. Надеюсь это никто не увидит
                let tmp = 1
                for (let item in this.case) {
                    if(this.case && this.case[item] && this.case[item].type == 'solution') {
                        for(let point in this.case[item].points) {
                            if(this.case[item].points[point] && this.case[item].points[point].type =='card') {
                                this.case[item].points[point].id = tmp
                                tmp++
                            }
                        }
                    }
                }
            },
            showPopup: function (e) {
                this.isPopupActive = !this.isPopupActive
                this.popupImage = e
                const scrollContent = document.querySelector('.scroll-content')
                if(this.isPopupActive && scrollContent) {
                  scrollContent.style.setProperty('--translatePage', +scrollContent.style.transform.match(/-[0-9]*/)[0] + 'px')
                  scrollContent.classList.add('fixed')
                  this.positionPopup = this.widthPage > 1248 ? +scrollContent.style.transform.match(/-[0-9]*/)[0] * -1 -75 : +scrollContent.style.transform.match(/-[0-9]*/)[0] * -1 -95
                } else {
                  scrollContent.classList.remove('fixed')
                }
            }
        },
        computed: {
          ...mapGetters(['widthPage','metaNames'])
        },
        created() {
          const time = Date.now()
          eventHub.$emit('updateLoader', true)
          axios.get('/api' + this.$route.path)
              .then((response) => {
                  this.addMetaNames(response.data.seo)
                  this.seoName = response.data.name
                  this.case = response.data
                  const regExp = new RegExp('(<a.+)(href=")(?!' + window.location.origin + ')', 'gm')
                  if(response.data) {
                    let resultObj = response.data
                    for (let key in resultObj) {
                      if(resultObj[key] && resultObj[key].type && resultObj[key].type === 'result') {
                        if(resultObj[key].points) {
                          resultObj[key].points.map(point => {
                            // eslint-disable-next-line
                            point.description = point.description.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
                            return point
                          })
                        }
                      }
                      if(resultObj[key] && resultObj[key].type && Array.isArray(resultObj[key].points) &&resultObj[key].type === 'solution') {
                        resultObj[key].points = resultObj[key].points.map(point => {
                          if(point && point.type === 'card') {
                            // eslint-disable-next-line
                            point.detail = point.detail.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
                            return point
                          }
                        })
                      }
                      if(resultObj[key] && resultObj[key].type && resultObj[key].type === 'problem' && typeof resultObj[key].points === 'object') {
                        for (let secondKey in resultObj[key].points) {
                          if(Array.isArray(resultObj[key].points[secondKey])) {
                            resultObj[key].points[secondKey] = resultObj[key].points[secondKey].map(obj => {
                            // eslint-disable-next-line
                            return obj = obj.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
                          })
                          } else {
                            // eslint-disable-next-line
                            resultObj[key].points[secondKey] = resultObj[key].points[secondKey].replace(regExp, '$1 rel="nofollow" target="_blank" $2')
                          }
                        }
                      }
                    }
                    this.case = resultObj
                  }
                  this.calcSolution()
              })
              .catch((error) => {
                  console.log(error)
                  this.$router.push('/404')
              })
              .finally(() => {
                const setTime = Date.now() - time > 1000 ? 0 : 1000 - (Date.now() - time)
                setTimeout(() => {
                  eventHub.$emit('updateLoader', false)
                  setTimeout(() => {
                    this.setLoading(false)
                  }, 1000)
                }, setTime)
              });
        }
    }
</script>
<style lang="scss" scoped>
    $width: 277px;
    $paddingLeft: 296px;
    $paddingLeftSmallDesctop: 220px;
    $paddingLeftMediumLaptop: 180px;

    h1 {
      font-size: 24px;
      line-height: 130%;
      color: var(--color1);
      text-transform: uppercase;
    }

    hr {
        border-top: 1px solid var(--color1);
        margin: 0;
    }

    .content-container {
      margin-left: 40px;
      margin-top: 60px;

      @include mediumLaptop {
        margin-top: 30px;
      }

      @include phones {
        margin-left: unset;
        margin-top: 0;
      }
    }

    .line {
      @include phones {
        display: none;
      }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    .section {
        position: relative;
        display: flex;
        max-width: 1680px;

        @include laptop {
          padding-left: var(--mainPaddingSmallSize);
          padding-right: var(--mainPaddingSmallSize);
        }

        @include smallLaptop {
          padding-top: 0;
        }

        @include phones {
          display: flex;
          flex-direction: column;
        }
    }

    .scroll-container {
      position: relative;
      width: $width;
      min-width: $width;
      top: 60px;
      padding: 0;
      max-height: 50vh;
      min-height: 425px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 80vh;

      .button {
        margin-top: 36px;
      }

      @include mediumLaptop {
        top: 30px;
      }

      @include phones {
        position: static;
        width: 100%;
        padding-top: unset;
        z-index: var(--sidebarIndex);
        height: fit-content;
        background-color: #fff;
        padding: 0;
        margin-top: 24px;
        min-height: unset;
        max-height: unset;


        label {
          .button {
            span {
              font-size: 15px !important;
            }
          }
        }
      }
    }

    .navigation-content {
      .header {
        margin-bottom: 40px;

        .name {
          font-size: 15px;
          line-height: 17px;
          text-transform: uppercase;
          color: var(--color1);
        }

        img {
          width: 100%;
          height: 134px;
          object-fit: cover;
        }
      }

      .case-service {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        font-size: 14px;
        line-height: 140%;
        color: var(--color1);
        margin-top: 24px;

        span {
          font-size: 13px;
          line-height: 15px;
          color: var(--color1);
          border-radius: 60px;
          border: 1px solid var(--color1);
          padding: 6px 15px;
          text-transform: uppercase;
          margin-top: 10px;
          transition: .3s background ease;
        }
      }

      @include phones {
        margin-top: 24px;

        width: 100%;
        max-width: unset;
        min-width: unset;

        .header {
          margin-bottom: 24px;

          .name {
            border-top: 1px solid var(--color1);
            padding-top: 10px;
            font-size: 20px;
            line-height: 110%;
            width: 100%;
            display: block;
          }
        }

        &__wrapper {
          display: flex;
          align-items: center;
          max-height: 230px;

          img {
            max-height: 230px;
            margin-bottom: auto;

          }
        }

        &__wrapper-services {
          margin-left: auto;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .case-service {
            &:first-child {
              margin-top: 0;
            }
            span {
              font-size: 10px;
              text-align: center;
            }
          }
        }
      }

      @include largePhonesAlt {
        &__wrapper {
          .navigation-content__wrapper-services {
            width: 50%;
          }
        }
      }

      @include mediumPhones {
        &__wrapper {
          display: flex;
          justify-content: space-between;
          img {
            // max-height: 100px;
            max-width: 40%;
            // height: 100%;
          }

          .navigation-content__wrapper-services {
            width: 50%;
            .case-service {
              width: fit-content;

              span {
                // text-align: left;
              }
            }
          }
        }
      }
    }

    .problem,
    .solution,
    .result {
        padding-left: $paddingLeft;
        font-size: 36px;
        line-height: 130%;
        color: var(--color1);
        position: relative;

        &:before {
            content: 'Проблема';
            position: absolute;
            top: 0;
            left: 0;
            font-size: 24px;
            line-height: 28px;
            text-transform: uppercase;
            color: var(--color5);
            padding: 6px 15px;
            border-radius: 50px;
            background-color: var(--color3);
        }

        &:after {
            content: '';
            width: $paddingLeft;
            height: 50px;
            position: absolute;
            top: 15px;
            left: 0;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='175' height='49' viewBox='0 0 175 49' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M174.854 4.35355C175.049 4.15829 175.049 3.84171 174.854 3.64645L171.672 0.464466C171.476 0.269204 171.16 0.269204 170.964 0.464466C170.769 0.659728 170.769 0.976311 170.964 1.17157L173.793 4L170.964 6.82843C170.769 7.02369 170.769 7.34027 170.964 7.53553C171.16 7.7308 171.476 7.7308 171.672 7.53553L174.854 4.35355ZM0.5 24V44H1.5V24H0.5ZM5 48.5H135V47.5H5V48.5ZM139.5 44V8H138.5V44H139.5ZM143 4.5H174.5V3.5H143V4.5ZM139.5 8C139.5 6.067 141.067 4.5 143 4.5V3.5C140.515 3.5 138.5 5.51472 138.5 8H139.5ZM135 48.5C137.485 48.5 139.5 46.4853 139.5 44H138.5C138.5 45.933 136.933 47.5 135 47.5V48.5ZM0.5 44C0.5 46.4853 2.51472 48.5 5 48.5V47.5C3.067 47.5 1.5 45.933 1.5 44H0.5Z' fill='%23E62315'/%3E%3C/svg%3E%0A");
            z-index: -1;

            @include smallDesktop {
              background-size: 40%;
              left: -20px;
            }

            @include mediumLaptop {
              background-size: 30%;
              left: -30px;
            }
        }
        ul {
            margin-top: var(--mainPadding)
        }

      @include smallDesktop{
        font-size: 32px;
        padding-left: $paddingLeftSmallDesctop;

        &:before {
          font-size: 20px;
        }
      }

      @include mediumLaptop{
        font-size: 28px;
        padding-left: $paddingLeftMediumLaptop;

        &:before {
          font-size: 18px;
        }
      }

      @include smallLaptop {
        padding-left: unset;
        margin-top: 100px;

        font-size: 18px;

        &:before {
          top: -20px;
          transform: translateY(-100%);
        }

        &::after {
          display: none;
        }
      }
    }

    .problem {
      @include phones {
        margin-top: 110px;
        &:before {
          position: absolute;
          width: fit-content;
          top: -20px;
        }

        &::after {
          display: none;
        }

      }
    }

    .solution {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-top: 80px;
        gap: 20px;

        &:before {
            content: 'Решение';
            background-color: var(--color4);
        }

        &:after {
            background-image: url("data:image/svg+xml,%3Csvg width='175' height='49' viewBox='0 0 175 49' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M174.854 4.35355C175.049 4.15829 175.049 3.84171 174.854 3.64645L171.672 0.464466C171.476 0.269204 171.16 0.269204 170.964 0.464466C170.769 0.659728 170.769 0.976311 170.964 1.17157L173.793 4L170.964 6.82843C170.769 7.02369 170.769 7.34027 170.964 7.53553C171.16 7.7308 171.476 7.7308 171.672 7.53553L174.854 4.35355ZM0.5 24V44H1.5V24H0.5ZM5 48.5H135V47.5H5V48.5ZM139.5 44V8H138.5V44H139.5ZM143 4.5H174.5V3.5H143V4.5ZM139.5 8C139.5 6.067 141.067 4.5 143 4.5V3.5C140.515 3.5 138.5 5.51472 138.5 8H139.5ZM135 48.5C137.485 48.5 139.5 46.4853 139.5 44H138.5C138.5 45.933 136.933 47.5 135 47.5V48.5ZM0.5 44C0.5 46.4853 2.51472 48.5 5 48.5V47.5C3.067 47.5 1.5 45.933 1.5 44H0.5Z' fill='%23FFD200'/%3E%3C/svg%3E%0A");
        }

        h3 {
            width: 100%;
        }
        .image-container {
            padding: 30px;
            width: 100%;
            box-sizing: border-box;
            max-width: 100%;
            overflow: hidden;
            background-color: #c4c4c459;
            border-radius: 10px;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        @include mediumLaptop {
          flex-direction: column;
        }

        @include smallLaptop {
          margin-top: 100px;

          .image-container {
            box-sizing: border-box;
            padding: 15px;
          }
        }

        @include phones {
          row-gap: 10px;
        }
    }

    .card {
        position: relative;
        word-break: break;
        overflow: hidden;

        & > div {
          padding: 30px;
          padding-bottom: 73px;
          background-color: #FFF0AC;
          border-radius: 10px;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
        }

        .number {
            position: absolute;
            bottom: 14px;
            right: 14px;
            // margin-bottom: 20px;
            font-size: 70px;
            -webkit-text-stroke: 1px var(--color4);
            -webkit-text-fill-color: #0000;
        }

        @include mediumLaptop {
          width: 100%;
          box-sizing: border-box;
        }

        @include smallLaptop {
          padding-top: 15px;
          padding-left: 15px;
          padding-right: 15px;
          width: 100%;
          margin-right: 0;
          box-sizing: border-box;
        }

        @include phones {
          padding-bottom: 24px;
          min-height: 0;
          // margin-bottom: 10px;

          div {
            font-size: 16px;
          }

          .number {
            position: static;
            margin-bottom: 0;
            margin-left: 20px;
            margin-top: -10px;
            float: right;
            font-size: 40px;
            height: 30px;
          }
        }
    }

    .result {
        margin-top: 160px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 60px;
        column-gap: 90px;
        box-sizing: border-box;

        &:before {
            content: 'Результат';
            background-color: var(--color4);
        }

        &:after {
            background-image: url("data:image/svg+xml,%3Csvg width='175' height='49' viewBox='0 0 175 49' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M174.854 4.35355C175.049 4.15829 175.049 3.84171 174.854 3.64645L171.672 0.464466C171.476 0.269204 171.16 0.269204 170.964 0.464466C170.769 0.659728 170.769 0.976311 170.964 1.17157L173.793 4L170.964 6.82843C170.769 7.02369 170.769 7.34027 170.964 7.53553C171.16 7.7308 171.476 7.7308 171.672 7.53553L174.854 4.35355ZM0.5 24V44H1.5V24H0.5ZM5 48.5H135V47.5H5V48.5ZM139.5 44V8H138.5V44H139.5ZM143 4.5H174.5V3.5H143V4.5ZM139.5 8C139.5 6.067 141.067 4.5 143 4.5V3.5C140.515 3.5 138.5 5.51472 138.5 8H139.5ZM135 48.5C137.485 48.5 139.5 46.4853 139.5 44H138.5C138.5 45.933 136.933 47.5 135 47.5V48.5ZM0.5 44C0.5 46.4853 2.51472 48.5 5 48.5V47.5C3.067 47.5 1.5 45.933 1.5 44H0.5Z' fill='%23FFD200'/%3E%3C/svg%3E%0A");
        }

        li {
            position: relative;
            width: auto;
            flex: 1 1 35%;

            &:last-child {
              margin-bottom: unset;
            }


            &:before {
                content: '';
                left: 0;
                top: -10px;
                width: 100%;
                height: 1px;
                position: absolute;
                background-color: var(--color1);
            }

            span {
                font-size: 40px;
                line-height: 110%;
                text-transform: uppercase;
            }

            p {
                font-size: 20px;
                line-height: 130%;
            }
        }

      @include smallDesktop {
        li {
          width: 40%;
          span {
            font-size: 32px;
          }
        }
      }

      @include laptop {
        li {
          span {
            font-size: 28px;
          }
        }
      }

      @include mediumLaptop {
        li {
          span {
            font-size: 22px;
          }
        }
      }

      @include smallLaptop {
        padding-left: unset;
        margin-top: 100px;
        padding-top: 10px;
        row-gap: 30px;
        column-gap: 40px;
        li {
          width: 100%;
          margin-left: unset;
          margin-bottom: 30px;

          span {
            font-size: 18px;
          }

          p {
            font-size: 16px;
            margin-top: 10px;
            margin-bottom: 0px;
          }
        }
      }

      @include phones {
        li {
          margin-bottom: 0;
        }
      }
    }

    .feedback {
      position: relative;
        display: flex;
        flex-wrap: wrap;
        margin-top: 160px;
        border-top: 1px solid var(--color1);
        padding-top: 10px;

        h2 {
            flex: 1;
        }

        li {
            width: calc(33.33% - 20px);
            margin-right: 20px;
            margin-bottom: 20px;
            overflow: hidden;

            &:last-child {
              margin-bottom: 0;
            }

            img {
                padding: 10px;
                background-color: var(--color4);
                width: calc(100% - 40px);
                object-fit: cover;
                cursor: pointer;
            }
        }

        @include smallLaptop {
          margin-top: 100px;
        }

        @include largePhonesAlt {
          margin-top: 48px;
        }
    }

    .image-popup {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        object-fit: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100000;

        img {
            height: 80%;
            max-height: 80vh;
            width: unset;
            max-width: 65vw;
            padding: 10px;
            background-color: var(--color4);
        }

        .close {
            position: absolute;
            top: 24px;
            right: -20px;
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: -40px;
            width: 100%;
            height: calc(100vh + 80px);
            background-color: #000D39;
            opacity: .3;
            z-index: -1;
        }

        @include largePhonesAlt {
          img {
            max-height: 40vh;
            max-width: 90vw;
          }

          .close {
            transform: translateY(-100%);
            top: -8px;
            right: 0;
          }
        }
    }

    .popup-wrapper {
        position: relative;
    }

    .modal-window {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: var(--modalIndex);
        display: flex;
        align-items: flex-end;
        justify-content: center;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #000D39;
            opacity: .3;
            z-index: -1;
            transition: .3s opacity ease;
        }
    }

    .modal-wrapper {
        background: #fff;
        padding: var(--mainPadding);
        padding-bottom:70px;
        position: relative;
        transition: .3s transform ease;

        .close {
            position: absolute;
            top: 0;
            right: -20px;
            transform: translateX(100%)
        }
    }

    .modal-name {
        font-size: 24px;
        line-height: 130%;
        text-transform: uppercase;
        color: var(--color1);
        margin-bottom: 58px;
        display: block;
        padding-top: 10px;
        border-top: 1px solid var(--color1)
    }

    .modal-animation-enter-active,
    .modal-animation-leave-active {
        .modal-window:before {
            opacity: .3;
        }

        transition: .3s;
    }

    .modal-animation-enter,
    .modal-animation-leave-to {
        .modal-wrapper {
            transform: translateY(100%)
        }
    }
</style>

<style lang="scss">
.scroll-content.fixed {
  --translatePage: 0px;
  transform: translateY(var(--translatePage)) !important;
}
  .navigation-content {
    & .button-red.icon_plus {
      min-width: 235px;

      @include largePhonesAlt {
        min-width: 100%;
      }
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        right: calc(50% - 105px);
        background-image: url("data:image/svg+xml,%3Csvg width='9' height='10' viewBox='0 0 9 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='4.5' y1='0.5' x2='4.5' y2='9.5' stroke='%23E62315'/%3E%3Cline x1='3' y1='5' y2='5' stroke='%23E62315'/%3E%3Cline x1='9' y1='5' x2='6' y2='5' stroke='%23E62315'/%3E%3C/svg%3E%0A");
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    @include phones {
      margin-top: 34px;

      width: 100%;
      max-width: unset;
      min-width: unset;

      .button-full-animation__w2y.buttons-group__tag-filter.button-red {
        margin-bottom: 0;
        margin-top: 24px;
        width: 100%;
        display: flex;
        justify-content: center;

        span {
          font-size: 15px;
        }
      }

      & .button-red.icon_plus {
        &::after {
          right: calc(50% - 135px);
        }
      }
    }
  }
</style>