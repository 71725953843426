<template>
  <div class="section">
    <div class="scroll-container" data-position="sticky">
      <AnimatedButton
        :classes="['buttons-group__tag-filter']"
        :settings="{anchor: true, class: 'w2y_right', text: 'вернуться', icon: true}"
        @click.native="$router.go(-1)"
        />
    </div>
    <div class="content-container" data-position-end="sticky">
      <section class="section-container search-intro">
        <div class="hr"></div>
        <h2 class="search-title">Результаты поиска</h2>
        <div class="search-controls" >
          <input type="text" class="search-input" v-model="searchText" @keyup.enter="handleSearch"/>
          <button class="search-button" @click.prevent="handleSearch"></button>
        </div>
        <div class="hr"></div>
      </section>
      <div v-if="FILTERED_POSTS.length">
        <VLoader v-if="isSearching"/>
        <template v-else>
          <section class="section-container search-count">
            <h3 v-html="seatchText"></h3>
          </section>
          <section class="section-container search-result">
            <ul
              class="search-result__container"
              v-for="item in searchFinal"
              :key="item[0]"
            >
              <li class="search-result__section">
                <span class="chips">{{ item[0] }}</span>
                <ul id="cases" class="search-result__item-list">
                  <li v-for="(result, i) in item[1]" :key="i">
                    <div class="search-result__item">
                      <h4 class="search-result__item-title">
                        {{ result.title }}
                      </h4>
                      <p class="search-result__item-description" v-html="result.description">
                      </p>
                      <router-link :to="result.class === 'Услуги' ? {path: 'services', query: { link: result.link }} : result.link" >
                        <Button text="Подробнее" color="red" />
                      </router-link>
                      <!-- .split('/')[1] -->
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </section>
        </template>
      </div>

      <div v-else>
        <section class="section-container search-count">
          <VLoader v-if="isSearching"/>
          <template v-else>
            <h3 v-if="SEARCH_WORD === ''">Введите что-то для поиска</h3>
            <h3 v-else>по запросу «{{ SEARCH_WORD }}» ничего не найдено</h3>
          </template>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import axios from 'axios'
import Button from "./button.vue";
import AnimatedButton from './AB.vue'
import VLoader from './VLoader.vue'
export default {
  components: {
    Button,
    VLoader,
    AnimatedButton
  },

  data() {
    return {
      searchText: "",
    };
  },
  methods: {
    ...mapMutations(['updatePage', 'setLoading', "setIsSearching"]),
    handleClose() {
      this.$emit("handleClose");
    },
    handleSearch() {
      this.setIsSearching(true)
      // if(this.SEARCH_POSTS.length > 0) {
      //   this.$store.commit("SET_SEARCH", this.searchText);
      //   this.$store.commit("SET_SEARCH_TAGS", ["Все"]);
      //   this.$store.commit("FILTER_POSTS");
      //   this.isSearching = false
      // } else {
      axios
        .get("api/search")
        .then((response) => {
          this.$store.dispatch("SET_SEARCH_POSTS", response.data);
          this.$store.commit("SET_SEARCH", this.searchText);
          this.$store.commit("SET_SEARCH_TAGS", ["Все"]);
          this.$store.commit("FILTER_POSTS");
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.setIsSearching(false)
        });
      // }
    },
  },
  computed: {
    ...mapGetters(["FILTERED_POSTS", "SEARCH_WORD", "SEARCH_POSTS", "widthPage", "isSearching"]),
    seatchText() {
      let text
      if(this.SEARCH_WORD.trim() === '') {
        text = `Всего найдено результатов: <b>${this.FILTERED_POSTS.length}</b>. Введите что-то для поиска`
      } else {
        text = `по запросу «<b>${this.SEARCH_WORD}</b>» найдено результатов: <b>${this.FILTERED_POSTS.length}</b>`
      }
      return text
    },
    cases() {
      return this.FILTERED_POSTS.filter((u) => {
        if (u.class === "Кейсы" && Array.isArray(u.description)){
          u.description = u.description.join("<br>")
        }
        return u.class === "Кейсы";
      });
    },
    services() {
      return this.FILTERED_POSTS.filter(function (u) {
        return u.class === "Услуги";
      });
    },
    events() {
      return this.FILTERED_POSTS.filter(function (u) {
        return u.class === "События";
      });
    },
    solutions() {
      return this.FILTERED_POSTS.filter(function (u) {
        if (u.class === "Решения" && Array.isArray(u.description)){
          u.description = u.description.join("<br>")
        }
        return u.class === "Решения";
      });
    },
    products() {
      return this.FILTERED_POSTS.filter(function (u) {
        return u.class === "Продукты";
      });
    },
    searchFinal() {
      const resultArray = [];
      this.cases.length ? resultArray.push(["Кейсы", this.cases]) : null;
      this.services.length
        ? resultArray.push(["Сервисы", this.services])
        : null;
      this.events.length ? resultArray.push(["События", this.events]) : null;
      this.solutions.length
        ? resultArray.push(["Решения", this.solutions])
        : null;
      this.products.length
        ? resultArray.push(["Продукты", this.products])
        : null;

      // resultArray = resultArray.map(item => {
      //   if(!Array.isArray(item) && typeof item === 'object') {
      //     for (const key in item){
      //       const regExp = new RegExp('(<a.+)(href=")(?!' + window.location.origin + ')', 'gm')
      //       // eslint-disable-next-line
      //       item[key] = item[key].description.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
      //     }
      //   }
      //   return item
      // })
      return resultArray;
    },
  },
  mounted() {
    this.setLoading(false)
  }
};
</script>

<style lang="scss" scoped>
.section {
  padding-top: 0;
  display: flex;
  min-height: calc(100vh - 100px);
}

.result {
  min-height: calc(100vh - 100px);
}

.hr {
  height:1px;
  background-color:var(--color1)
}

.search-title {
  margin-top:10px;
}

.scroll-container {
  width: 277px;
  position: relative;
  top: calc(var(--headerHeight) + 38px);
  padding-top: 0;
  height: calc(100% - var(--headerHeight));
  padding-bottom: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;

  margin-top: -90px;
  @include mediumLaptop{
    top: calc(var(--headerHeightMobile) + 38px);
  }
}

.content-container {
  background-color: #fff;
  flex: 1;
  // margin-left: 277px;
  // max-width: calc(100% - 277px);

  hr {
    border-color: var(--color1);
    margin-top: 0;
  }

  h2 {
    margin-bottom: 80px;
  }
}

.search-intro {
  margin-bottom: 40px;

  @include phones {
    margin-top: 40px;
  }
}

.search-controls {
  display: flex;
  align-items: baseline;

  .search-button {
    background: none;
    border: none;
    width: 40px;
    height: 25px;
    position: relative;
    background-position: center 30%;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='19' viewBox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='0.646447' y1='18.6464' x2='4.64645' y2='14.6464' stroke='%23001d82'/%3E%3Ccircle cx='12' cy='8' r='7.5' stroke='%23001d82'/%3E%3C/svg%3E%0A");
    cursor: pointer;
    transition: 0.3s background ease;
    &:hover {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='19' viewBox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='0.646447' y1='18.6464' x2='4.64645' y2='14.6464' stroke='%23E62315'/%3E%3Ccircle cx='12' cy='8' r='7.5' stroke='%23E62315'/%3E%3C/svg%3E%0A");
    }
  }

  .search-input {
    flex-grow: 1;
    font-size: 18px;
    color: #001d82;
    border: none;

    &:focus {
      border: none;
      outline: none;
    }

    &:focus-visible {
      outline: none;
    }
  }
}

.search-count {
  margin-bottom: 80px;
  h3 {
    font-weight: 400;
    margin: 0;
  }
}

.search-result__container,
.search-result__item-list {
  list-style-type: none;
  padding: 0;
}

.search-result__container:last-child {
  margin: 0;
  margin-bottom: 160px;

  @include phones {
    margin-bottom: 0;
  }
}

.search-result__section {
  margin-bottom: 120px;
}

.chips {
  background-color: #ffd200;
  color: #001d82;
  padding: 6px 15px;
  margin-bottom: 40px;
  border-radius: 20px;
  font-size: 24px;
  display: inline-block;
  text-transform: uppercase;
}

.search-result__item {
  padding: 20px;
  border: 1px solid #001d82;
  border-radius: 10px;
  margin-bottom: 20px;

  .search-result__item-title {
    margin: 0;
    margin-bottom: 20px;
    font-size: 24px;
    color: #001d82;
    font-weight: 400;
    text-transform: uppercase;
  }

  .search-result__item-description {
    font-size: 18px;
    line-height: 1.6;
    color: #001d82;
    margin-bottom: 20px;
  }
}

.search-result__item-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@include mediumLaptop {
  .section {
    padding-top: 95px;
  }
}

@media screen and (min-width: 0px) and (max-width: 960px) {
  .section {
    display:flex;
    flex-direction: column;
  }

  .scroll-container {
    position:absolute;
    top: 170px;
    height: fit-content;
    padding-top:0;
    padding-bottom:10px;
  }

  .content-container {
    max-width: 100%;
    margin: 0;

    h2 {
      margin-bottom: 24px;
      font-size: 32px;
    }
  }

  .search-intro {
    margin-bottom: 24px;
  }

  .search-count {
    margin-bottom: 24px;
    h3 {
      font-size: 14px;
    }
  }

  .search-result__section {
    margin-bottom: 48px;
  }

  .chips {
    font-size: 14px;
    margin-bottom: 16px;
  }

  .search-result__item {
    padding: 16px 10px;

    .search-result__item-title {
      font-size: 14px;
      margin-bottom: 10px;
    }

    .search-result__item-description {
      font-size: 16px;
      margin: 0;
      margin-bottom: 20px;
    }

    .search-result__item-description {
      -webkit-line-clamp: 8;
    }
  }
}
</style>
