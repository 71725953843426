<template>
<transition-group name="fade" :duration="{ enter: 350, leave: 50 }">
  <form
    key="1"
    class="form"
    @submit.prevent="sendForm"
    v-show="!sendMessage"
    :style="customCss"
    ref="mainForm"
  >
    <slot></slot>
    <div class="input-wrapper" v-if="inputWrapped">
      <Input
        v-for="(input, i) in inputs"
        :key="i"
        :placeholder="input.placeholder"
        :type="input.type"
        :name="input.name"
        :customCss="input.customCss"
        :required="input.required"
        :value="input.value"
        :files="files"
        @processFile="processFile"
        @removeFile="removeFile"
        :animation="animation"
      />
    </div>
    <Input
      v-else
      v-for="(input, i) in inputs"
      :key="i"
      :placeholder="input.placeholder"
      :type="input.type"
      :name="input.name"
      :customCss="input.customCss"
      :required="input.required"
      :value="input.value"
      :files="files"
      @processFile="processFile"
      @removeFile="removeFile"
      :animation="animation"
    />
    <div class="btn-wrapper" v-if="inputWrapped">
      <Checkbox
        placeholder="Согласен на обработку персональных данных в соответствии с политикой конфиденциальности"
        :checked="false"
        :required="true"
        :customStyle="checkboxStyle"
        :classes="animation ? ['animate-elem', 'fadeInLeft'] : []"
      />
      <AnimatedButton :classes="['buttons-group__tag-filter', 'animate-elem', 'fadeInRight']" :settings="{class: 'w2y', text: 'Отправить', icon: true}" />
    </div>
    <template v-else>
      <div class="btn-wrapper-tender">
        <Checkbox
          placeholder="Согласен на обработку персональных данных в соответствии с политикой конфиденциальности"
          :checked="false"
          :required="true"
          :customStyle="checkboxStyle"
        />
        <AnimatedButton :classes="['buttons-group__tag-filter']" :settings="{class: 'w2y', text: 'Отправить', icon: true}" />
      </div>
    </template>
  </form>
  <div key="2" v-show="sendMessage" class="message-delivered" :class="{'del-monq':isMonq, 'del-index':isIndex, 'del-solutions':isSolutions, 'del-tender':isTender, 'del-popup':isPopup}">
    <div class="contact-wrapper">
      <h3>Сообщение отправлено</h3>
      <p>Мы получили ваше сообщение и свяжемся с вами в течение дня.</p>
      <AnimatedButton v-on:click.native="sendMessage = !sendMessage" :classes="['buttons-group__tag-filter']" :settings="{class: 'w2y', text: 'Отправить ещё', icon: true}" />
    </div>
  </div>
</transition-group>
</template>
<script>
import Input from "../UI/input.vue";
import Checkbox from "../UI/checkbox.vue";
// import Button from "../UI/button.vue";
import axios from "axios";
import AnimatedButton from '../UI/AB.vue'

export default {
  components: {
    Input,
    Checkbox,
    // Button,
    AnimatedButton
  },
  props: {
    headline: null,
    inputs: null,
    customCss: null,
    checkboxStyle: null,
    buttonType: null,
    buttonStyle: null,
    inputWrapped: null,
    isMonq:null,
    isIndex:null,
    isSolutions:null,
    isTender:null,
    isPopup:null,
    btncolor:{
      type:String,
      default:"dark"
    },
    animation: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      sendMessage: false,
      files: [],
    };
  },
  methods: {
    getCookie(name) {
      const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
      if (match) return match[2];
    },
    sendForm: function () {
      let form = this.$refs.mainForm;
      let formData = new FormData(form);
      for (let i = 0; i < this.files.length; i++) {
        formData.append("file", this.files[i]);
      }

      formData.append("type", this.$route.path);
      formData.append("roistatVisit", +this.getCookie('roistat_visit'));
      axios.post("/api/add_form_mess", formData);
      this.sendMessage = true;
      this.files = [];
    },
    processFile: function (e) {
      this.files = Array.from(e.target.files);
    },
    removeFile: function (index) {
      this.files.splice(index, 1);
    },
  },
};
</script>
<style lang="scss" scoped>

// Костыль для формы на странице "Пригласить в тендер"
// класс "tender" добавляется этому компоненту в компоненте Tender
.tender {
  .form {
    @include mediumPhones {
      flex-direction: column !important;
      .input_container {
        width: 100%;
        margin-right: 0 !important;
      }
    }
  }
}

.btn-wrapper-tender {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 15px;

  @include largePhonesAlt {
    flex-flow: column;
    row-gap: 10px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;

  .input_container {
    width: 100%;
    margin-right: 0;
  }
  .placeholder {
    color: #fff !important;
  }

  @include smallPhonesAlt {
    width: 100%;
  }
}

.message-delivered {
  .contact-wrapper {
    flex-direction: column;

    h3 {
      font-weight: 400;
      font-size: 40px;
      margin-bottom: 60px;
      color: var(--color1);
    }

    p {
      color: var(--color1);
      font-size: 24px;
      margin-bottom: 30px;
      margin-left: 260px;
    }

    label {
      display: flex;
      margin-left: 260px;
    }
  }
}

.del-monq {
  flex:1 0 30%;

  h3{
    margin-top:0;
  }

  .contact-wrapper p{
    margin-left:0;
  }

  .contact-wrapper label{
    margin-left:0;
  }
}

.del-index{
  margin-bottom:40px;
}

.del-solutions {
  flex:1 0 10%;
  .contact-wrapper h3{
    margin-top:0;
    color:#fff;
  }

  .contact-wrapper p{
    margin-left:0;
    color:#fff;
  }

  .contact-wrapper label{
    margin-left:0;
  }
}

.del-tender {
  max-width:1086px;
  margin-left:auto;
}

.del-popup {
    max-width:588px;

    .contact-wrapper p,
    .contact-wrapper label{
        margin-left:0;
    }
}

.btn-sumbit {
  margin-left: auto;
}

.input-wrapper {
  display: flex;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.btn-wrapper {
  display: flex;
  width: 48%;
  justify-content: space-between;
  align-items: center;
}
</style>