export default function mainAnimation(timeline) {
  timeline
    .set("._loading-anim__hedline", { y: 300 })
    .set(".headline__menu__hr", { width: 0 })
    .set("._loading-anim__header-btns", { x: 300, opacity: 0 })

    .to("._loading-anim__hedline_step--1", {
      delay: 1,
      y: 0,
      onComplete: function() {
        this._targets[0].parentElement.classList.add(
          "headline_decor__wrapper__ready"
        );
      }
    })
    .to("._loading-anim__hedline_step--2", { y: 0 })
    .to("._loading-anim__hedline_step--3", { y: 0 }, "<50%")
    .to(".headline__menu__hr", {
      width: "calc(100% - 40px)",
      duration: 0.3,
      ease: "none",
      onComplete: function() {
        this._targets.forEach(i => {
          i.classList.add("bebe");
          i.style.width = "";
        });
      }
    })
    .to("._loading-anim__header-btns", { x: 0, opacity: 1 });
}
