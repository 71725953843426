export default function casesAnimation(timeline) {
  timeline
    .set(".headline > img", { yPercent: 100 })
    .set(".headline > hr", { width: 0 })
    .set(".headline > h1", { opacity: 0, y: 100 })
    .set(".cases", { opacity: 0, y: 150 })
    .set("#filter .filter-wrapper", { opacity: 0, xPercent: -50 })
    .set("#filter div:last-child", { opacity: 0, xPercent: -50 })
    .set(".headline button", { yPercent: 105 })
    .to(".cases-page__banner", { opacity: 1, duration: 0 })
    .to(".headline > img", { yPercent: 0 }, ">")
    .to(".headline > hr", { width: "100%" }, ">")
    .to(".headline > h1", { opacity: 1, y: 0 }, "<50%")
    .to(".headline button", { yPercent: 0 }, "<50%")
    .to(".cases", { opacity: 1, y: 0 }, "<0")
    .to("#filter .filter-wrapper", { opacity: 1, xPercent: 0 }, "<0")
    .to("#filter div:last-child", { opacity: 1, xPercent: 0 }, "<0");
}
