export default {
    mutations: {
      toggelDropDown(state, type) {
        if(type === 'task') {
          state.isOpenBranch = false
          state.isOpenTask = !state.isOpenTask
        } else {
          state.isOpenTask = false
          state.isOpenBranch = !state.isOpenBranch
        }
      },
      addCalculatorItem(state, payload) {
        if(payload.type === 'task') {
          if(payload.active) {
            state.activeTasks = [...state.activeTasks, payload.item]
          } else {
            state.tasks = [...state.tasks, payload.item]
          }
        } else {
          if(payload.active) {
            state.activeBranches = [...state.activeBranches, payload.item]
          } else {
            state.branches = [...state.branches, payload.item]
          }
        }
      },
      removeCalculatorItem(state, payload) {
        if(payload.type === 'task') {
          if(payload.active) {
            state.activeTasks = state.activeTasks.filter(item => item.name !== payload.name)
          } else {
            state.tasks = state.tasks.filter(item => item.name !== payload.name)
          }
        } else {
          if(payload.active) {
            state.activeBranches = state.activeBranches.filter(item => item.name !== payload.name)
          } else {
            state.branches = state.branches.filter(item => item.name !== payload.name)
          }
        }
      },
      closeDropDowns(state, e) {
        if(e.target && typeof e.target.className === 'string' && (e.target.closest('.drop-down') || e.target.className.match('drop-down'))) {
          return null
        }
        state.isOpenBranch = false
        state.isOpenTask = false
      }
    },
    state: {
      isOpenTask: false,
      isOpenBranch: false,
      branches: [
        {
          name: "branches_1_Государственная организация",
          text: "Государственная организация",
        },
        {
          name: "branches_2_IT-компания",
          text: "IT-компания",
        },
        {
          name: "branches_3_Логистика",
          text: "Логистика",
        },
        {
          name: "branches_4_Финансы-и-страхование",
          text: "Финансы и страхование",
        },
        {
          name: "branches_5_Бытовые-услуги",
          text: "Бытовые услуги",
        },
        {
          name: "branches_6_Образование",
          text: "Образование",
        },
        {
          name: "branches_7_Производство",
          text: "Производство",
        },
        {
          name: "branches_8_ЖКХ",
          text: "ЖКХ",
        },
        {
          name: "branches_9_Другая",
          text: "Другая",
        },
      ],
      tasks: [
        {
          name: "tasks_1_Автоматизация-бизнес-процессов",
          text: "Автоматизация бизнес-процессов",
        },
        {
          name: "tasks_2_Внедрить-CRM",
          text: "Внедрить CRM",
        },
        {
          name: "tasks_3_HR-задачи",
          text: "HR-задачи",
        },
        {
          name: "tasks_4_Техническая-поддержка",
          text: "Техническая поддержка",
        },
        {
          name: "tasks_5_Мониторинг-трафика-в-сети",
          text: "Мониторинг трафика в сети",
        },
        {
          name: "tasks_6_Комплексное-управление-ИТ",
          text: "Комплексное управление ИТ",
        },
        {
          name: "tasks_7_Внедрение-КЭДО",
          text: "Внедрение КЭДО",
        },
        {
          name: "tasks_8_Внедрение-Service-Desk",
          text: "Внедрение Service Desk",
        },
        {
          name: "tasks_9_Внедрение-BI-систем",
          text: "Внедрение BI-систем",
        },
        {
          name: "tasks_10_RPA",
          text: "RPA",
        },
        {
          name: "tasks_11_Другое",
          text: "Другое",
        },
      ],
      activeBranches: [
      ],
      activeTasks: [
      ]
    },
    getters: {
      isOpenTask(state) {
        return state.isOpenTask;
      },
      isOpenBranch(state) {
        return state.isOpenBranch;
      },
      branches(state) {
        return state.branches;
      },
      tasks(state) {
        return state.tasks;
      },
      activeBranches(state) {
        return state.activeBranches;
      },
      activeTasks(state) {
        return state.activeTasks;
      },
    },
  };