<template>
    <section class="headline anim1">
        <hr>
        <h1>{{text}}</h1>
        <div class="filter" v-if="filter">
            <button class="business" v-bind:class="{active: isActive}" @click="updateFilter('business')"><h2>Бизнес-сектор</h2></button>
            <button class="gos" v-bind:class="{active: !isActive}" @click="updateFilter('natioanal')"><h2>Гос. Сектор</h2></button>
        </div>
        <img class="dflex__image" :src="require(`@/assets/images/${image}`)" alt="">
    </section>
</template>
<script>
    import eventHub from '../eventHub'

    export default {
        props: {
            text: null,
            image: null,
            filter: null,
            onInput: null,
            business: null
        },
        methods: {
            updateFilter: function (e) {
                this.onInput(e)
                eventHub.$emit('updAnimation')
            }
        },
        computed: {
            isActive() {
                if(this.business === 'natioanal') {
                    return false
                }
                return true
            }
        }
    }
</script>
<style lang="scss" scoped>
    .headline {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        overflow: hidden;
        height: 24vw;
        max-height: 460px;
        min-height: 220px;
        width: 100%;
        position: relative;
        box-sizing: border-box;
        padding: var(--mainPadding) 92px;
        margin: 0;
        z-index: 1;

        hr {
            display: flex;
            align-self: flex-start;
            border-color: var(--color1);
            margin: 0;
        }

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
            border-radius: 25px;
            z-index: -1;
            &.dflex__image {
                width: calc(100% - 40px);
                left: 20px;

                @include smallLaptop {
                    width: 100%;
                    left: 0;
                }
            }
        }

        h2 {
          margin-bottom: var(--mainPadding);
        }

        h1 {
            font-size: var(--h2-size);
            line-height: 110%;
            color: var(--color1);
            margin: 0;
            text-transform: uppercase;

            @include laptop {
                font-size:80px;
            }

            @include smallLaptop {
                font-size:36px;
            }

            @include largePhonesAlt{
                font-size:24px;
            }
        }

        @include smallLaptop {
          padding: var(--mainPadding) 10px;
        }

        @include largePhones {
          padding: var(--mainPaddingSmallSize);
          margin: 0 var(--mainPaddingSmallSize);
          max-width: 100%;
          width: 100%;

          h2 {
            margin-bottom: var(--mainPaddingSmallSize);
          }
        }
    }

    .filter {
        margin-top: 20px;
        overflow: hidden;
        button {
            background: none;
            border: none;
            font-size: 40px;
            line-height: 110%;
            color: var(--color5);
            padding: 4px 15px;
            cursor: pointer;
            text-transform: uppercase;

            h2{
                font-size:inherit;
                margin:0;
                font-weight:inherit;
                color: var(--color5);
            }
            &.active {
                h2 {
                    color: var(--color1);
                }
            }

            &.business {
                box-shadow: inset 0 0 1px 1px #fff;
                border-radius: 40px;

                &.active {
                    background-color: var(--color4);
                    box-shadow: none;
                }
            }

            &.gos {
                padding-left: 32px;
                padding-right: 32px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url("data:image/svg+xml,%3Csvg width='320' height='53' viewBox='0 0 320 53' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.3492 52.5L0.648798 0.5H305.651L319.351 52.5H14.3492Z' stroke='white'/%3E%3C/svg%3E%0A");

                &.active {
                    background-image: url("data:image/svg+xml,%3Csvg width='320' height='53' viewBox='0 0 320 53' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0H306.036L320 53H13.9639L0 0Z' fill='%23FFD200'/%3E%3C/svg%3E%0A");
                }
            }

            @include phones {
              font-size: 24px;
            }

            @include largePhones {
              font-size: 18px;
            }

            @include mediumPhones {
              font-size: 17px;
              &.business,
              &.gos {
                background-position-y: 1px;
                padding-left: 15px;
                padding-right: 15px;
              }
            }

            @include smallPhones {
              font-size: 14px;

              &.gos {
                padding-left: 20px;
                padding-right: 20px;
              }
            }
        }
    }
</style>
