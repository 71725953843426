<template>
    <div ref="caseItem">
      <div class="image-container" v-if="widthPage > 1388">
        <img ref="caseItemImg" :src="item.logo" :alt="item.name"/>
        <router-link class="button" :to="item.link">
            <AnimatedButton
              :classes="['buttons-group__tag-filter']"
              :settings="{ class: 'r2y', text: 'кейс полностью', icon: true }"
            />
        </router-link>
      </div>
      <div class="case-container">
        <div class="case-header">
          <div class="case-service" v-if="item.service">
            Услуга
            <span v-for="(obj, i) in item.service" :key="i">{{ obj }}</span>
          </div>
          <div class="case-service" v-if="item.branches">
            Отрасль
            <span v-for="(obj, i) in item.branches" :key="i">{{ obj }}</span>
          </div>
          <div class="image-container" v-if="widthPage < 1388">
            <img ref="caseItemImg" :src="item.logo" :alt="item.name"/>
          </div>
        </div>
        <h4 class="case-name">
          {{ item.name }}
        </h4>
        <ul class="case-points">
          <li
            class="red-list"
            v-for="(point, i) in item.points"
            :key="i"
            v-html="point"
          ></li>
        </ul>
        <router-link class="button" :to="item.link" v-if="widthPage < 1388">
          <AnimatedButton
            :classes="['buttons-group__tag-filter']"
            :settings="{ class: 'r2y', text: 'кейс полностью', icon: true }"
          />
        </router-link>
      </div>
    </div>
</template>

<script>
import AnimatedButton from '../UI/AB.vue'
import {mapGetters} from 'vuex'

export default {
    name: 'CasesNewItem',
    components: {
        AnimatedButton
    },
    props: {
        item: Object
    },
    computed: mapGetters(['widthPage'])
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/mixins";
@import "../../assets/styles/variables";
h4{
  font-size: 24px;
  line-height: 130%;
  color: var(--color1);
  text-transform: uppercase;
}

.case-item {
  overflow: hidden;
  z-index: 1;
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  max-width: 900px;
  max-height: unset;
  display: flex;
  border: 1px solid var(--color1);
  border-radius: 10px;
  background-color: #fff;
  .image-container {
    max-width: 350px;
    box-sizing: border-box;
    padding-right: 112px;
    min-height: 194px;
    > img {
      width: 237px;
      height: auto;
      max-height: 134px;
      object-fit: cover;
      @include laptop {
        max-height: 100px;
        object-fit: contain;
      }
      @include mediumPhones {
        width: 100%;
      }
      @include smallPhones {
        max-height: 100px;
      }
      @include smallPhonesAlt {
        max-height: 50px;
      }
    }

    @include laptop {
      margin-left: auto;
      padding: 0;
      width: fit-content;
      height: unset;
      min-height: unset;
    }

    @include mediumPhones {
      padding: 0;
    }
  }
  .case-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    .case-header {
      width: 100%;
      display: flex;
      align-items: center;
      .case-service {
        display: flex;
        flex-direction: column;
        color: var(--color1);
        font-size: 14px;
        > span {
          margin-top: 10px;
          width: fit-content;
          height: fit-content;
          border: 1px solid var(--color1);
          border-radius: 60px;
          padding: 6px 15px;
          color: var(--color1);
          text-transform: uppercase;
          font-size: 13px;
        }
      }

      & > img {
        margin-left: auto;
      }
    }

    .case-name {
      @include mediumPhones {
        font-size: 16px;
        margin: 6px;
      }

      @include mediumPhones {
        font-size: 16px;
        margin: 6px;
      }
    }
  }
  .case-points {
    padding-left: 0;
    list-style-type: none;
    li {
      margin-bottom: 15px;

      &.red-list {
        @include phones {
            padding-left: 20px;
        }

        @include mediumPhones {
          font-size: 13px;
          line-height: 110%;
        }

        @include smallPhones {
          font-size: 12px;
        }
      }

      @include mediumLaptop {
        font-size: 14px;
        line-height: 140%;
        margin-bottom: 8px;
      }
    }

    @include laptop {}
    margin: 0;
  }
  @include laptop {
    flex-direction: column;
  }

  @include mediumPhones {
    overflow: hidden;
    flex-direction: column;
    height: max-content;
  }
}
a.button {
  justify-content: center;
  width: fit-content;
  padding-left: 0;
  padding-right: 0;
  >div {
    display: flex;
    >button {
      box-sizing: border-box;
      &.tmp-btn.buttons-group__tag-filter {
        width: 100% !important;
      }
    }
  }

  @include laptop {
    margin-top: 10px;
  }
}
</style>