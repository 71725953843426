<template>
    <div class="canvas-wrapper" :style="{height: heightFirstScreen + 'px'}">
        <canvas class="scene scene--full" id="scene" ref="scene"></canvas>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as THREE from './js/three.min';
import './js/TweenMax.min'
    export default {
        name: 'Particles',
        mounted() {
            setTimeout(() => {
                this.loadOldShit()
            }, 0);
        },
        computed: {
            ...mapGetters(['heightFirstScreen'])
        },
        methods: {
            loadOldShit() {
                const canvas = this.$refs.scene;
                let width = canvas.offsetWidth,
                    height = canvas.offsetHeight;

                let renderer = new THREE.WebGLRenderer({
                    canvas: canvas,
                    antialias: true
                });
                renderer.setPixelRatio(window.devicePixelRatio > 1 ? 2 : 1);
                renderer.setSize(width, height);
                renderer.setClearColor(0x001D82);

                let scene = new THREE.Scene();

                let camera = new THREE.PerspectiveCamera(50, width / height, 0.1, 2000);
                camera.position.set(0, 0, 80);

                let loader = new THREE.TextureLoader();
                loader.crossOrigin = "Anonymous";
                let dotTexture = loader.load('https://i.ibb.co/DKn4J2q/dot-Texture.png');

                let radius = 50;
                let sphereGeom = new THREE.IcosahedronGeometry(radius, 5);
                let dotsGeom = new THREE.Geometry();
                let bufferDotsGeom = new THREE.BufferGeometry();
                let positions = new Float32Array(sphereGeom.vertices.length * 3);
                for (let i = 0;i<sphereGeom.vertices.length;i++) {
                    let vector = sphereGeom.vertices[i];
                    animateDot(i, vector);
                    dotsGeom.vertices.push(vector);
                    vector.toArray(positions, i * 3);
                }

                function animateDot(index, vector) {
                        TweenMax.to(vector, 4, {
                            x: 0,
                            z: 0,
                            ease:Back.easeOut,
                            delay: Math.abs(vector.y/radius) * 2,
                            repeat:-1,
                            yoyo: true,
                            yoyoEase:Back.easeOut,
                            onUpdate: function () {
                                updateDot(index, vector);
                            }
                        });
                }
                function updateDot(index, vector) {
                        positions[index*3] = vector.x;
                        positions[index*3+2] = vector.z;
                }

                let attributePositions = new THREE.BufferAttribute(positions, 3);
                bufferDotsGeom.addAttribute('position', attributePositions);
                let shaderMaterial = new THREE.ShaderMaterial({
                    uniforms: {
                        texture: {
                            value: dotTexture
                        }
                    },
                    // vertexShader: document.getElementById("wrapVertexShader").textContent,
                    vertexShader: `
                        #define PI 3.1415926535897932384626433832795
                            attribute float size;
                            void main() {
                                vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
                                gl_PointSize = 3.0;
                                gl_Position = projectionMatrix * mvPosition;
                            }`,
                    // fragmentShader: document.getElementById("wrapFragmentShader").textContent,
                    fragmentShader: `
                        uniform sampler2D texture;
                        void main(){
                            vec4 textureColor = texture2D( texture, gl_PointCoord );
                            if ( textureColor.a < 0.3 ) discard;
                            vec4 dotColor = vec4(0, 0.061, 0.262, 1);
                            vec4 color = dotColor * textureColor;
                            gl_FragColor = color;
                        }`,
                    transparent:true
                });
                let dots = new THREE.Points(bufferDotsGeom, shaderMaterial);
                scene.add(dots);

                function render(a) {
                    dots.geometry.verticesNeedUpdate = true;
                    dots.geometry.attributes.position.needsUpdate = true;
                    renderer.render(scene, camera);
                }

                function onResize() {
                    canvas.style.width = '';
                    canvas.style.height = '';
                    width = canvas.offsetWidth;
                    height = canvas.offsetHeight;
                    camera.aspect = width / height;
                    camera.updateProjectionMatrix();  
                    renderer.setSize(width, height);
                }

                let mouse = new THREE.Vector2(0.8, 0.5);
                function onMouseMove(e) {
                    mouse.x = (e.clientX / window.innerWidth) - 0.5;
                    mouse.y = (e.clientY / window.innerHeight) - 0.5;
                    TweenMax.to(dots.rotation, 4, {
                        x : (mouse.y * Math.PI * 0.5),
                        z : (mouse.x * Math.PI * 0.2),
                        ease:Power1.easeOut
                    });
                }

                TweenMax.ticker.addEventListener("tick", render);
                window.addEventListener("mousemove", onMouseMove);
                let resizeTm;
                window.addEventListener("resize", function(){
                    resizeTm = clearTimeout(resizeTm);
                    resizeTm = setTimeout(onResize, 200);
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
.canvas-wrapper {
    width: 100vw;
    position: absolute;
    left: 0;
    // right: 0;
    top: 0;
    z-index: 0;

    .scene {
        width: 100%;
        height: 100%;
        // left: 0;
        // right: 0;
        // top: 0;
        // bottom: 0;
    }
}
</style>