<template>
    <div class="filter anim2" id="filter" data-position="sticky">
        <div class="filter-wrapper" v-for="(filter, i) in value" :key="i" v-show="filter.value.length>0">
            <span>{{filter.name}}</span>
            <ul class="filter_list" v-if="widthPage > 1200">
                <li class="filter-item" v-for="(filter,i) in filter.value" :key="i">
                    <!-- <button @click="updateFilter(filter)" class="button dark"
                        v-bind:class="{active:activeFilter(filter)}"><span>{{filter}}</span></button> -->
                    <AnimatedButton @click="updateFilter(filter)" :classes="['buttons-group__tag-filter', activeFilter(filter) && 'is-active']" :settings="{class: 'w2y', text: filter, icon: false}" />
                </li>
            </ul>
            <ul class="filter_list" v-else>
              <swiper class="swiper swiper-partner" :options="swiperOption" ref="swiperRefCustomFilter">
                <swiper-slide v-for="(filter,i) in filter.value" :key="i">
                  <li class="filter-item">
                    <button @click="updateFilter(filter)" class="button dark"
                            v-bind:class="{active:activeFilter(filter)}"><span>{{filter}}</span></button>
                  </li>
                </swiper-slide>
              </swiper>
            </ul>
        </div>
        <AnimatedButton :disabled="activeFilters.length === 0" @click="updateFilter('resetAll')" :classes="['buttons-group__tag-filter','about__events__sidebar__clear']" :settings="{class: 'w2y', text: 'сбросить', icon: true}" />
    </div>
</template>
<script>

  import { mapGetters } from 'vuex'
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  import { Swiper as SwiperClass } from 'swiper/swiper.esm.js'
  import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
  import AnimatedButton from '../UI/AB.vue'

  // local component
  const {directive} = getAwesomeSwiper(SwiperClass)
  // Import Swiper styles
  import 'swiper/swiper-bundle.css'
  import eventHub from '../eventHub'

    export default {
        components: {
          Swiper,
          SwiperSlide,
          AnimatedButton
        },
        props: {
            value: null,
            onInput: null,
            activeFilters: null
        },
        data() {
            return {
                swiperOption: {
                  slidesPerView: 'auto',
                  spaceBetween: 5,
                  freeMode: true,
                },
                directives: {
                  swiper: directive
                }
            }
        },
        methods: {
            updateFilter: async function (e) {
              const duration = 600
              await this.onInput(e)
              await eventHub.$emit('filterUpd', duration)
            },
            activeFilter: function (e) {
              if (this.activeFilters.includes(e)) {
                return true
                }
                return false
            }
        },
        computed: mapGetters(['widthPage'])
    }
</script>
<style lang="scss" scoped>
    .swiper-slide {
      width: auto !important;

      @include mediumLaptopAlt {
        &:first-child {
          margin-left: 0;
        }
      }

      @include largePhones {
        &:first-child {
          margin-left: 0;
        }
      }
    }

    .filter {
      >div:not(.filter-wrapper) {
        padding: 0 0 20px 0;
        background-color: #fff;

        @include mediumLaptopAlt {
          padding-bottom: 0;
          padding-top: 5px;
        }
      }
      @include largePhonesAlt {
      }
    }
    .filter-wrapper {
        padding: 0 0 20px 0;
        background-color: #fff;

        &>span {
            font-size: 14px;
            line-height: 140%;
            color: var(--color1);
            margin-bottom: 10px;
            display: block;
        }

        @include mediumLaptopAlt {
          width: calc(100% + 40px);
          margin-left: -20px;
          padding-bottom: 0;

          &:last-child {
            margin-bottom: 110px;
          }
          &:first-child {
            background: #fff;
            padding-top: 20px;
            // margin-left: 20px;
          }
          &>span {
            margin-bottom: 5px;
            margin-left: 20px;
          }
        }

        @include largePhonesAlt {
          width: calc(100% + 20px);
          margin-left: -10px;
          &:first-child {
            padding-top: 10px;
          }
          &>span {
            margin-bottom: 5px;
            margin-left: 10px;
          }
        }
    }

    .filter_list {
        margin: 0;
        padding: 0;
        list-style-type: none;
        margin-bottom: 40px;
        display: flex;
        flex-wrap: wrap;

        @include mediumLaptopAlt {
          margin-bottom: 0;
        }
    }

    .filter-item {
        margin-right: 5px;
        margin-bottom: 5px;
        max-width: 100%;
        .button {
          height: fit-content;
        }

        @include mediumLaptopAlt {
          margin-right: 0;
          margin-bottom: 5px;
        }
    }

    button.active {
        background-color: var(--color4);
    }

    .swiper-wrapper {
      .swiper-slide {
        @include mediumLaptopAlt {
          &:first-child {
            margin-left: 20px;
          }

          &:last-child {
            margin-right: 20px !important;
          }
        }

        @include largePhonesAlt {
          &:first-child {
            margin-left: 10px;
          }

          &:last-child {
            margin-right: 10px !important;
          }
        }
      }
    }
</style>
