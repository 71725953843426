<template>
    <section>
        <hr class="animate-elem shiftLeft wrap"/>
        <h2 class="line animate-elem shiftUp wrap">{{value.name}}</h2>
        <ul class="solutions-list" v-if="value.items">
            <li class="solution" v-for="(solution, i) in value.items" :key="i">
                <div class="animate-elem shiftUp">
                    <div class="image-wrapper">
                        <img :src="solution.img" alt="logo">
                    </div>
                    <hr>
                    <div class="labels">
                        <div class="main-labels">
                            <span v-for="(label, j) in test(solution.name)" :key="j">{{label}}</span>
                        </div>
                        <div class="dublicates-labels">
                            <span v-for="(label, j) in test(solution.name)" :key="j">{{label}}</span>
                        </div>
                    </div>
                    <span class="description" v-for="(item,i) in solution.description" :key="i" v-html="item">
                    </span>
                </div>
            </li>
        </ul>
        <span v-if="value.description" v-html="value.description"></span>
    </section>
</template>
<script>
    export default {
        props: {
            value: null
        },
        methods: {
            test: function (e) {
                let tmp
                let result = []
                tmp = e.split(' ')

                for (let i = 0; i < tmp.length; i++) {
                    result.push(tmp[i])
                }
                return result
            }
        },
        mounted() {
            // FIXME Throws na error
            if(this.value && this.value.description) {
                const regExp = new RegExp('(<a.+)(href=")(?!' + window.location.origin + ')', 'gm')
                // eslint-disable-next-line
                this.value.description = this.value.description.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
            }
            if(this.value.items && Array.isArray(this.value.items)) {
                const regExp = new RegExp('(<a.+)(href=")(?!' + window.location.origin + ')', 'gm')
                this.value.items = this.value.items.map((solution) => {
                    if(Array.isArray(solution.description)){
                        solution.description = solution.description.map((item) => {
                            // eslint-disable-next-line
                            return item.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
                        })
                    }
                    return solution
                })
            }
        },
        // computed: {
        //     test2:function() {
        //         return this.value.items
        //     }
        // }
    }
</script>
<style lang="scss" scoped>
    section {
        margin-top: 160px;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    hr {
        border-top: 1px solid var(--color1);
        margin: 0;
    }

    .solutions-list {
        margin-top: 80px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        flex-wrap: wrap;
        gap: 20px;

        .hidden-wrapper {
            width: 30%;
        }

        @include laptop {
          grid-template-columns: repeat(2, 1fr);
        }

        @include mediumAlt {
          grid-template-columns: repeat(1, 1fr);
        }

        @include largePhonesAlt {
            margin-top: 24px;
        }
    }

    .solution {
        flex: 1;
        display: flex;
        flex-direction: column;
        // max-width: 400px;
        overflow: hidden;

        & > div {
            height: 100%;
            box-sizing: border-box;
            padding: var(--mainPadding);
            background-image: url('../../../../src/assets/images/mask.svg');
            background-position: top left;
            background-size: 100%;
            background-repeat: no-repeat;
            background-color: #462DC7;
            border-radius: 20px;
        }

        &:last-child {
            margin-right: 0;
        }

        .labels > .main-labels {
            z-index: 1;
        }
    }

    .image-wrapper {
        margin: 0 auto;
        height: 320px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include smallLaptop {
          height: 190px;

          img {
            height: 130px;
          }
        }
    }

    .labels {
        font-size: 24px;
        line-height: 47px;
        color: var(--color1);
        text-transform: uppercase;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        /*min-height: 94px;*/
        margin-top: 10px;
        margin-bottom: 23px;

        @include smallLaptop {
          font-size: 14px;
          margin-bottom: 12px;
        }

        .dublicates-labels {
            user-select: none;
            position: absolute;
            color: #fff0;
            height: 100%;

            span:before {
                content: '';
                position: absolute;
                top: 4px;
                left: -19px;
                height: 39px;
                width: calc(100% + 38px);
                background-color: #fff;
                border-radius: 100px;
                opacity: .99;
                z-index: 0;
            }
        }

        .main-labels,
        .dublicates-labels {
            display: flex;
            flex-wrap: wrap;
            padding-left: 19px;
            padding-right: 19px;
        }

        .main-labels {
            z-index: 10;
        }

        span {
            margin-right: 6px;
            position: relative;
            display: inline-block;
        }
    }

    .description {
        margin-top: 23px;
        font-size: 16px;
        line-height: 160%;
        color: var(--color5);
    }
</style>
