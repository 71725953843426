<template>
    <transition @enter="quoteModalEnter" @leave="quoteModalLeave" :css="false">
        <div v-if="lbx.visible" @click="qwe()" class="lightbox">
            <div class="lightbox__container">
                <div class="lightbox__container__inside">
                    <img :src="'/storage/'+lightboxSettings[lbx.index]" alt="">
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { gsap } from "gsap"
import eventHub from '../../eventHub'
export default {
    props: {
        lightboxSettings: Array,
        lbx: Object
    },
    methods: {
        qwe() {
            const asd = {...this.lbx, visible: false}
            eventHub.$emit('emitLightbox', {
                lbx: asd, lightboxSettings: this.lightboxSettings
            })
        },
        quoteModalEnter(el, done) {
            this.tl3 = gsap.timeline()
            this.tl3
                .fromTo('.lightbox__container', {scale: .3}, {scale: 1, duration: .3})
                .then(done)
        },
        quoteModalLeave(el, done) {
            const styleOverflow = () => {
                document.querySelector('body').style.overflow = ''
            }
            this.tl3.reverse().then(styleOverflow).then(done)
        },
    }
}
</script>

<style lang="scss" scoped>
.lightbox {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.5);
    width: 100%;
    height: 100%;
    cursor: zoom-out;
    &__container {
        width: 100%;
        height: 100%;
        &__inside {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            >img {
                width: auto;
                max-width: 90%;
                max-height: 90%;
                object-fit: cover;
            }
        }
    }
}
</style>