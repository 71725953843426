<template>
  <section class="section">
    <h2 class="line">страница не найдена</h2>
    <span class="content-block">
      Но вы можете вернуться на <router-link to="/">главную</router-link> или
      посмотреть <router-link to="/cases">наши кейсы</router-link>,
      <router-link to="/services">услуги</router-link>,
      <router-link to="/products"> продукты</router-link> или
      <router-link to="/solutions">решения</router-link>
    </span>
    <img class="image" src="@/assets/images/404.svg" alt="" />
  </section>
</template>
<script>
  import eventHub from '../components/eventHub'

  export default {
    mounted() {
      setTimeout(() => {
        eventHub.$emit('updateLoader', false)
        setTimeout(() => {
          eventHub.$emit('updateLoader', false)
        }, 1100)
      }, 1100)
    }
  }
</script>
<style lang="scss" scoped>
.section {
  padding-top: 40px;
  height: calc(100vh - 93px);
  min-height: 600px;

  @include smallLaptop {
    height: 100vh;
    min-height: unset;
  }

  @include phones {
    height: 100vh;
    padding-bottom: 50px;
    padding-top: 24px;
  }
}

h2 {
  font-weight: 400;
  font-size: 60px;
}

h2.line {
  padding-top: 10px;
}

.content-block {
  color: var(--color1);
  font-size: 32px;
  line-height: 110%;
  max-width: 420px;
  display: block;
  margin-top: 10px;

  a {
    color: var(--color3);
    text-decoration: none;
  }
}

.image {
  position: absolute;
  bottom: 5vh;
  right: var(--mainPadding);
}

@include largePhones{
  h2.line {
    font-size: 32px;
    margin-bottom: 24px;
  }

  .content-block {
    font-size: 18px;
    margin-bottom: 40px;
    display: block;
    max-width: 100%;
  }
  .image {
    position: relative;
    bottom: 0;
    display: block;
    right: 0;
    width: 100%;
    margin-top: 50px;
  }
}

@include mediumLaptop{
  .image {
    position: relative;
    bottom: 0;
    display: block;
    right: 0;
    width: 100%;
    margin-top: 60px;
  }
}
</style>
