<template>
    <div class="dflex-cont dflex-cont_column_center">
        <Banner text="Продукты" image="products.svg" class="dflex-child_maxwidth"/>
        <ProductList class="dflex-child_maxwidth" />
    </div>
</template>
<script>
    import Banner from "../components/UI/banner.vue"
    import ProductList from "../components/pages/products/productList.vue"
    import axios from "axios"
    import eventHub from "../components/eventHub"
    import {mapGetters,mapMutations} from 'vuex'

    export default {
        metaInfo() {
            return {
                title: `${this.metaNames.title ?? 'Продукты'}`,
                meta: this.metaNames.metas
            }
        },
        components: {
            Banner,
            ProductList
        },
        methods: {
            ...mapMutations(['addMetaNames', 'setLoading']),
        },
        created() {
            const time = Date.now()
            eventHub.$emit('updateLoader', true)
            axios.get("/api/products")
                .then((response) => {
                    this.addMetaNames(response.data.seo)
                })
                .catch((error) => {
                    console.log(error)
                    this.$router.push('/404')
                })
                .finally(() => {
                  const setTime = Date.now() - time > 1000 ? 0 : 1000 - (Date.now() - time)
                  setTimeout(() => {
                    eventHub.$emit('updateLoader', false)
                    setTimeout(() => {
                      this.setLoading(false)
                    }, 1000)
                  }, setTime)
                });
        },
        computed: {
            ...mapGetters(['metaNames'])
        }
    }
</script>