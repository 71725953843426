<template>
    <div class="dflex-child_maxwidth">
      <div v-if="loader" class="wrapper-loader">
        <VLoader />
      </div>
      <template v-else>
        <AdvancedGrid :iW="iW" :h1="careerValues.name" :settings="careerValues.items" />
        <Carousel :iW="iW" v-if="carHowWorks" :h2text="carHowWorks.name" :wrapped="{isWrapped: false, wrapperClass: 'office-photos'}" :carousel='carHowWorks' :settings="howWorksSettings" type="photos"/>
        <Carousel :iW="iW" v-if="carAchievements" :modalType="'history'" :h2text="carAchievements.name" :wrapped="{isWrapped: true, wrapperClass: 'qwe'}" :carousel='carAchievements' :settings="achivementsSetting" type="carAchivements"/>
        <Headline class="headline__career" :text="headlineText" :prefDecoration="'<+/>?/<+'"></Headline>
        <PositionsAccordion :iW="iW" :h2text="positions2.name" :positionsData="positions2.items" :cities="cityList2" :listReady="listReady" />
      </template>
    </div>
</template>

<script>
import Carousel from '../../components/UI/carousel.vue'
import VLoader from '../../components/UI/VLoader.vue'
import Headline from '../../components/pages/about/headline.vue'
import AdvancedGrid from '../../components/pages/about/advancedGrid.vue'
import PositionsAccordion from '../../components/pages/about/positionsAccordion.vue'
import axios from 'axios'
import eventHub from '../../components/eventHub'
import {mapGetters,mapMutations} from 'vuex'

export default {
    metaInfo() {
        return {
            title: `${this.metaNames.title ?? 'Карьера'}`,
            meta: this.metaNames.metas
        }
    },
    name: 'career',
    components: {
        Headline,
        Carousel,
        AdvancedGrid,
        PositionsAccordion,
        VLoader
    },
    created() {
        this.loader = true
        axios.get('/api/aboutus/career')
            .then((response) => {
                this.addMetaNames(response.data.seo)
                const data = response.data
                for(let item in data) {
                    // Работа в Хоппер ИТ
                    if(item.split("_")[0]=='advances') {
                        this.careerValues = data[item]
                    } else if (item.split("_")[0]=='carouselAchievements') {
                        // Карусель
                        this.carAchievements = data[item]
                    } else if (item.split("_")[0]=='slider') {
                        // Истории успеха
                        this.carHowWorks = data[item]
                    } else if (item.split("_")[0]=='positions') {
                        // Вакансии
                        this.positions2 = data[item]
                        this.cityList2 = data[item].items.map(item => {
                            return item.cities
                        }).flat().reduce((total, i) => {
                            const asd = total.find(j => {
                                // console.log(j)
                                return j.id && j.id == i.id
                            })
                            return asd ? total : [...total, i]
                        },[]).map(n => {
                            return {tag: n.name, active: false}
                        })
                    }
                }
                // Работа в Хоппер ИТ - Добавляем картинки из advance
                // data.advances_7.items = data.advances_7.items.map((i, index) => {
                //     return {'img': this.advances[index].img, ...i}
                // })
                this.listReady = true
            })
            .catch((error) => {
                console.log(error)
                this.$router.push('/404')
            })
            .finally(() => {
              this.loader = false
              eventHub.$emit('updateLoader', false)
            });
        window.addEventListener("resize", this.myEventHandler);
    },
    data() {
        return {
            loader: false,
            listReady: false,
            cityList2: [],
            achivementsSetting: {
                type: "loop",
                perPage: 1,
                autoplay: false,
                pagination: false,
                autoWidth: true,
                perMove: 1,
                gap: "20px"
            },
            howWorksSettings: {
                type: "loop",
                perPage: 1,
                autoplay: false,
                pagination: false,
                autoWidth: true,
                perMove: 1,
                gap: "20px"
            },
            careerValues: {},
            carAchievements: undefined,
            carHowWorks: undefined,
            positions2: {},
            quoteModal: {
                visible: false,
                personId: undefined,
                test: false
            },
            headlineText: '<span class="decoration__pre"><+/>?/<+</span> Сейчас в нашей команде <span class="decoration__red">больше 180 человек</span>. Мы быстро растем и ищем тех, кто хочет развиваться вместе с нами.',
            cityList: [],
            advances: [
                {img: 'support.jpg'},
                {img: 'atmosphere.png'},
                {img: 'support.jpg'},
            ],
            iW: 0
        }
    },
    methods: {
        ...mapMutations(['addMetaNames']),
        myEventHandler(e) {
            this.iW = e.target.innerWidth
        }
    },
    mounted() {
        this.iW = window.innerWidth
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    },
    computed: {
        ...mapGetters(['metaNames'])
    }
}
</script>

<style scoped>
  .wrapper-loader {
    display: flex;
    justify-content: center;
    align-content: center;

    width: 100vw;
    height: 40vh;
  }
</style>