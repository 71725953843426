export default {
    actions: {
        fetchSimilar(ctx, data) {
            ctx.commit('updateSimilar', data);
        },
        fetchLinks(ctx,data) {
            ctx.commit('updateLinks', data)
        },
        fetchActive(ctx,data) {
            ctx.commit('updateActive', data)
        }
    },
    mutations: {
        updateSimilar(state, tmp) {
            state.storage.similar = tmp
        },
        updateLinks(state,tmp) {
            state.storage.links = tmp
        },
        updateActive(state,tmp) {
            state.storage.active = tmp
        }
    },
    state: {
        storage: {
            similar: {},
            links: {},
            active: {}
        }
    },
    getters: {
        similarServices(state) {
            return state.storage.similar;
        },
        links(state) {
            return state.storage.links
        },
        active(state) {
            return state.storage.active
        }
    },
};