import Vue from "vue";
import VueRouter from "vue-router";

import store from "@/store";

import eventHub from "../components/eventHub";

import Index from "../pages/index.vue";
import AboutUs from "../pages/about/about.vue";
import AboutAlt from "../pages/about/aboutAlt.vue";
import Career from "../pages/about/career.vue";
import Events from "../pages/about/events.vue";
import Contacts from "../pages/about/contacts.vue";
import Cases from "../pages/cases.vue";
import Products from "../pages/products.vue";
import License from "../pages/license.vue";
import PageNotFound from "../pages/404.vue";
import Solutions from "../pages/solutions.vue";
import Services from "../pages/services.vue";
import ProductTemplate from "../pages/productTemplate.vue";
import CaseTemplate from "../pages/caseTemplate.vue";
import SolutionTemplate from "../pages/solutionTemplate.vue";
import Tender from "../pages/tender.vue";
import EventTemplate from "../pages/about/eventTemplate.vue";
import VueMeta from "vue-meta";
import SearchResult from '../components/UI/searchResult.vue';

Vue.use(VueMeta);
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index
  },
  {
    path: "/about",
    name: "AboutAlt",
    component: AboutAlt,
    children: [
      {
        name: "About",
        path: "/about",
        component: AboutUs
      },
      {
        name: "Career",
        path: "/career",
        component: Career
      },
      {
        name: "Events",
        path: "/events",
        component: Events
      },
      {
        name: "Contacts",
        path: "/contacts",
        component: Contacts
      },
      {
        name: "News",
        path: "/news/:catchAll(.*)*",
        component: EventTemplate
      }
    ]
  },
  {
    path: "/cases",
    name: "Cases",
    component: Cases
  },
  {
    path: "/cases/:catchAll(.*)*",
    name: "Case",
    component: CaseTemplate
  },
  {
    path: "/products",
    name: "Products",
    component: Products
  },
  {
    path: "/products/:catchAll(.*)*",
    name: "Product",
    component: ProductTemplate
  },
  {
    path: "/license",
    name: "License",
    component: License
  },
  {
    path: "/solutions",
    name: "Solutions",
    component: Solutions
  },
  {
    path: "/solutions/:catchAll(.*)*",
    name: "Solution",
    component: SolutionTemplate
  },
  {
    path: "/services",
    name: "Services",
    component: Services
  },
  {
    path: "/tender",
    name: "Tender",
    component: Tender
  },
  {
    path: "/search",
    name: "Search",
    component: SearchResult
  },
  {
    path: "*",
    name: "404",
    component: PageNotFound
  }
];

const router = new VueRouter({
  mode: "history",
  routes: routes
});

router.beforeEach((to, from, next) => {
  if (/[A-Z]+/g.test(to.path)) {
    next({
      path: to.path.toLowerCase()
    });
  }

  // setTimeout(() => store._mutations.setAnimationState[0](to.name), 3000)

  eventHub.$emit("updAnimation");
  store.commit("toggleHeader", false);
  next();
});

export default router;
