<template>
  <div class="images-slider animate-elem fadeInUpSmall">
    <div class="images-slider__wrapper-navigation" v-if="values.length > 1">
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
      <div class="swiper-pagination" slot="pagination"></div>
    </div>
    <Swiper class="swiper" :options="swiperOption">
      <SwiperSlide v-for="({img}, i) in values" :key="i">
        <img class="images-slider__image" :src="img" alt="слайд">
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { Swiper as SwiperClass } from 'swiper/swiper.esm.js'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'

// local component
const {directive} = getAwesomeSwiper(SwiperClass)
// Import Swiper styles
import 'swiper/swiper-bundle.css'

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  props: [
      'values'
  ],
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        freeMode: false,
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
      },
      directives: {
        swiper: directive
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .images-slider {
    width: 100%;
    overflow: hidden;
    &__wrapper-navigation {
      width: fit-content;
      height: 62px;
      position: relative;
      left: 0;
      z-index: 11;
    }
    &__image {
      width: 100%;
      border-radius: 10px;
    }

    @include mediumPhones {
      &__wrapper-navigation {
        width: 100%;
        margin-bottom: 12px;
        height: 44px;
        flex-direction: row-reverse;
      }
    }
  }
</style>

<style lang="scss">
.images-slider {
  &__wrapper-navigation {
    display: flex;
    height: 70px;
    margin-bottom: 40px;
    .swiper-button-prev,
    .swiper-button-next{
      background-image: url("data:image/svg+xml,%3Csvg width='70' height='62' viewBox='0 0 70 62' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.82664 61.5L0.554051 0.5H63.1734L69.446 61.5H6.82664Z' stroke='%23001D82'/%3E%3Cpath d='M40.9998 30.7778L41.3291 31.1541V30.4015L40.9998 30.7778ZM36.6665 26.9861H36.1665V27.213L36.3373 27.3624L36.6665 26.9861ZM36.6665 34.5694L36.3373 34.1932L36.1665 34.3426V34.5694H36.6665ZM41.3291 30.4015L38.4402 27.8737L37.7817 28.6263L40.6706 31.1541L41.3291 30.4015ZM38.4402 27.8737L36.9958 26.6098L36.3373 27.3624L37.7817 28.6263L38.4402 27.8737ZM37.1665 26.9861V25H36.1665V26.9861H37.1665ZM40.6706 30.4015L37.7817 32.9293L38.4402 33.6818L41.3291 31.1541L40.6706 30.4015ZM37.7817 32.9293L36.3373 34.1932L36.9958 34.9457L38.4402 33.6818L37.7817 32.9293ZM36.1665 34.5694V36.5556H37.1665V34.5694H36.1665Z' fill='%23001D82'/%3E%3Cpath d='M28 30.7778L36.5 30.7778' stroke='%23001D82'/%3E%3C/svg%3E%0A");
      background-size: 100%;
      background-repeat: no-repeat;
      position: relative;
      top: 25px;
      left: unset;
      right: unset;
      width: 70px;
      height: 62px;
      //background: red;
      &:after {
        display: none;
      }
    }

    .swiper-button-prev {
      transform: rotate(180deg);
    }

    .swiper-pagination {
      margin-top: 10px;
      margin-left: 30px;
      position: relative;
      bottom: unset;
      right: unset;
      left: unset;
      width: 60px;

      .swiper-pagination-current {
        font-size: 26px;
        margin-right: 10px;
      }
    }
  }

  @include mediumPhones {
    &__wrapper-navigation {
      .swiper-button-next {
        order: 1;
      }
      .swiper-button-next,
      .swiper-button-prev {
        width: 49px;
        height: 43px;
      }
      .swiper-button-prev {
        order: 2;
        //margin-left: auto;
      }
      .swiper-pagination {
        order: 3;
        margin-left: 0;
        margin-right: auto;
      }
    }
  }
}
</style>
