<template>
    <div class="casses__inner">
      <div class="casses__inner__hr">
        <hr>
      </div>
      <div class="casses__inner__wrapper">
        <div class="casses__inner__left" ref="left">
          <h2 class="h2animated">
            <span>кейсы</span>
          </h2>
          <div class="spanAnimated">
            <span class="casses__text">Реализуем комплексные внедрения для государственного и бизнес сектора</span>
          </div>
          <router-link to="/cases" class="buttonsAnimated">
            <AnimatedButton
              :classes="['buttons-group__tag-filter']"
              :settings="{ class: 'w2y', text: 'все кейсы', icon: true }"
            />
          </router-link>
        </div>
        <div class="casses__inner__right">
          <div v-for="(item, i) in sortCases" :key="i" class="case-item__modified__container">
            <CasesNewItem class="case-item case-item__modified" :item="item" :name="item.name" />
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import AnimatedButton from '../../UI/AB.vue'
import CasesNewItem from '../../UI/casesNewItem.vue'
export default {
    name: 'cases-new',
    components: {
      AnimatedButton,
      CasesNewItem
    },
    data() {
      return {
        items: []
      }
    },
    props: {
        cases: Object
    },
    computed: {
      sortCases() {
        const caseData = [...this.cases.value]
        return caseData.sort((a,b) => {
          return (a.points.join(',').length + a.name.length + a.points.length*10) - (b.points.join(',').length + b.name.length + b.points.length*10)
        })
      }
    }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";
.case-item__modified__container {
  overflow: hidden;
  display: flex;
  width: auto;
  height: auto;
  margin-bottom: 30px;
  border-radius: 10px;
}
.casses__inner {
  width: 100%;
  box-sizing: border-box;
  padding: 160px 24px 0;
  display: flex;
  flex-direction: column;
  max-width: $mainMaxWidth;
  &__hr {
    margin-bottom: 16px;
    width: 100%;
    height: 1px;
    > hr {
      margin: 0;
      padding: 0;
      width: 0;
      height: 100%;
      background-color: var(--color1);
    }
  }
  &__wrapper {
    display: flex;
    width: 100%;
  }
  &__left {
    box-sizing: border-box;
    padding: 0 120px 0 0;
    width: 100%;
    max-width: 570px;
    .casses__text {
      display: inline-flex;
      font-size: 18px;
      line-height: 160%;
      color: var(--color1);
      margin: 20px 0 40px;

      @include largePhonesAlt {
        margin-bottom: 24px;
      }
    }

    @include largePhonesAlt {
      padding-right: 0;
    }
  }
  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
    flex: 1;
  }
  @include mediumLaptopAlt {
    &__wrapper {
      flex-direction: column;
    }
  }

  @include largePhonesAlt {
    padding-top: 48px;
  }
}
</style>