<template>
    <div class="team" v-if="content">
        <hr class="animate-elem shiftLeft wrap"/>
        <h2 class="animate-elem shiftUp wrap">{{headline}}</h2>
        <div class="team__members">
            <div v-for="(member, index) in content" :key="index" class="team__person">
                <template v-if="container == 'about'">
                    <div class="team__person__photo animate-elem shiftUp wrap">
                        <img :src="'/storage/'+member.image" alt="member.name">
                    </div>
                    <div class="team__person__name animate-elem fadeInUp">{{ member.name }}</div>
                    <div class="team__person__position animate-elem fadeInUp">{{ member.position }}</div>
                    <button class="team__person__button animate-elem fadeInUp" @click.prevent="toggleModalWindow(index, 'quotes')"></button>
                </template>
                <template v-else-if="container == 'career'">
                    <div class="team__person__photo">
                        <img :src="'/storage/'+member.image" alt="member.name">
                    </div>
                    <div class="team__person__name">{{ member.name }}</div>
                    <button class="team__person__button" @click.prevent="toggleModalWindow(index, 'history')"></button>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import eventHub from '../../eventHub'
export default {
    name: 'teamList',
    props: {
        content: null,
        container: null,
        headline: null,
        quoteModal: Object
    },
    methods: {
        toggleModalWindow(id, type) {
            this.quoteModal.visible = true
            this.quoteModal.personId = id

            eventHub.$emit('emitModal', {content: this.content, modalType: type, quoteModal: this.quoteModal})
        }
    }
}
</script>

<style lang="scss" scoped>
.team {
    hr {
        border-top: 1px solid var(--color1);
        margin: 0;
    }
    >h2 {
        margin-bottom: 80px;
    }
    &__members {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-auto-rows: auto;
        // display: flex;
        // flex-wrap: wrap;
        grid-gap: 82px 20px;
        margin-top: 80px;
        @include mediumLaptop {
            grid-template-columns: repeat(5, 1fr);
        }
        @include smallLaptop {
            grid-template-columns: repeat(4, 1fr);
        }
        @include largePhonesAlt {
            margin-top: 24px;
            grid-template-columns: repeat(3, 1fr);
        }
    }
    &__person {
        min-height: 460px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        max-width: 220px;
        &__photo {
            position: relative;
            background-color: tomato;
            width: 100%;
            height: 316px;
            >img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
            &:after {
                opacity: 0;
                position: absolute;
                display: flex;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(110.24deg, #FFD200 0%, #E62315 49.05%, #462DC7 100.01%);
                content: '';
                transition: all .3s ease-in-out;
            }
        }
        &__name {
            min-height: 45px;
            margin: 10px 0;
            font-size: 18px;
            color: var(--color1);
            text-transform: uppercase;
        }
        &__position {
            margin-bottom: 10px;
            font-size: 16px;
            color: var(--color1);
        }
        &__button {
            margin-top: auto;
            border: none;
            outline: none;
            background-color: #fff;
            width: 32px;
            height: 32px;
            background-image: url("data:image/svg+xml,%3Csvg width='28' height='29' viewBox='0 0 28 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 14.9111C0.5 7.45529 6.54416 1.41113 14 1.41113C21.4558 1.41113 27.5 7.45529 27.5 14.9111C27.5 22.367 21.4558 28.4111 14 28.4111H0.5V14.9111Z' stroke='%23001D82'/%3E%3Cline x1='7.63635' y1='11.8657' x2='20.3636' y2='11.8657' stroke='%23001D82'/%3E%3Cline x1='7.63635' y1='15.6839' x2='15.2727' y2='15.6839' stroke='%23001D82'/%3E%3Cline x1='7.63635' y1='19.5021' x2='15.2727' y2='19.5021' stroke='%23001D82'/%3E%3C/svg%3E");
            background-size: initial;
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer;
            transition: all .3s ease-in-out;
        }
        &:hover {
            .team__person__photo {
                &:after {
                    opacity: .5;
                }
            }
            .team__person__button {
                background-image: url("data:image/svg+xml,%3Csvg width='28' height='29' viewBox='0 0 28 29' fill='%23FFD200' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 14.9111C0.5 7.45529 6.54416 1.41113 14 1.41113C21.4558 1.41113 27.5 7.45529 27.5 14.9111C27.5 22.367 21.4558 28.4111 14 28.4111H0.5V14.9111Z' stroke='%23001D82'/%3E%3Cline x1='7.63635' y1='11.8657' x2='20.3636' y2='11.8657' stroke='%23001D82'/%3E%3Cline x1='7.63635' y1='15.6839' x2='15.2727' y2='15.6839' stroke='%23001D82'/%3E%3Cline x1='7.63635' y1='19.5021' x2='15.2727' y2='19.5021' stroke='%23001D82'/%3E%3C/svg%3E");
            }
        }
    }
}
</style>