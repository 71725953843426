<template>
    <div class="service" :style="{width: widthPage <= 1680 && widthPage >= 1200 && serviseWrapperWidth !==0 ? serviseWrapperWidth + 'px' : ''}" :class="{active:isReady}">
      <AnimatedButton v-if="widthPage < 1200" @click="backToServices()" :classes="['buttons-group__tag-filter', 'service-single-back']" :settings="{anchor: false, class: 'w2y_right', text: 'вернуться', icon: true}" />
      <template v-if="widthPage < 1200">
        <span class="service__name">{{value.name}}</span>
      </template>
        <section class="description animated">
          <hr class="title-achivements-hr"/>
          <h1>{{value.description}}</h1>
        </section>

      <template v-for="(item,i) in value">
            <template v-if="i!='client_name' && i!='description'">
                <div class="blocks item animated" v-if="item.type==='advantage_service'" :key="i">
                    <hr class="title-achivements-hr"/>
                    <h2 class="block-name">{{item.name}}</h2>
                    <ul class=" fadeInUpSmall">
                        <li v-for="(block, j) in item.items" :key="j">
                            <span>{{block.name}}</span>
                            <p v-html="block.html"></p>
                        </li>
                    </ul>
                </div>

                <div class="examples item" v-if="item.type === 'slider'" :key="i">
                  <hr class="title-achivements-hr"/>
                  <h2 class="examples__name">{{item.name}}</h2>
                  <ImagesSlider :values="item.items"/>
                </div>

                <div class="list item animated" v-else-if="item.type==='simple_list'" :key="i">
                    <hr class="title-achivements-hr"/>
                    <h2 class="list-name">{{item.name}}</h2>
                    <ul class=" fadeInUpSmall" v-for="(list, j) in item.items" :key="j">
                        <span v-if="list.name" v-html="list.name"></span>
                        <p v-if="list.html" v-html="list.html"></p>
                        <!-- <template v-if="list.description">
                            <li v-for="(listItem,k) in list.description" :key="k" v-html="listItem">
                            </li>
                        </template> -->
                        <div class="insert" v-html="list.description">
                        </div>
                    </ul>
                </div>

                <div class="list item animated" v-else-if="item.type==='complex_list'" :key="i">
                    <hr class="title-achivements-hr"/>
                    <h2 class="list-name">{{item.name}}</h2>
                    <ul class=" fadeInUpSmall" v-for="(list, j) in item.items" :key="j">
                        <span v-if="list.name">{{list.name}}</span>
                        <span v-if="list.html" v-html="list.html"></span>
                        <div class="insert" v-html="list.description"></div>
                        <!-- <template v-if="list.description">
                            <li v-for="(listItem,k) in list.description" :key="k" v-html="listItem">
                            </li>
                        </template> -->
                    </ul>
                </div>
                <div v-else-if="item.type==='text' && item.name" :key="i" class="text item animated">
                  <hr class="title-achivements-hr" :key="i"/>
                  <div class=" fadeInUp" :key="i"  v-html="item.name"></div>
                </div>
            </template>
        </template>
        <div class="form-wrapper item animated" v-if="form">
            <hr class="title-achivements-hr"/>
            <h2 class="form-name">{{form.name}}</h2>
            <Form :inputs="form.value" buttonType="dark" isPopup="true" :animation="false"/>
        </div>
    </div>
</template>
<script>
    import eventHub from '../components/eventHub'
    import Form from '../components/UI/form.vue'
    import ImagesSlider from '../components/UI/imagesSlider'
    import AnimatedButton from '../components/UI/AB.vue'
    import {mapActions, mapGetters} from 'vuex'
    export default {
        components: {
            Form,
            ImagesSlider,
            AnimatedButton
        },
        props: {
          value: Object
        },
        data() {
            return {
                isReady: false,
                src: ['https://avatars.mds.yandex.net/get-zen_doc/98986/pub_6117c0c474a4fa168760abb3_6117c0c6cdef433a852d14f7/scale_1200', 'https://avatars.mds.yandex.net/get-zen_doc/98986/pub_6117c0c474a4fa168760abb3_6117c0c6cdef433a852d14f7/scale_1200', 'https://avatars.mds.yandex.net/get-zen_doc/98986/pub_6117c0c474a4fa168760abb3_6117c0c6cdef433a852d14f7/scale_1200'],
                form: {
                    name: 'Задать вопрос по услуге',
                    type: 'form',
                    value: [{
                        type: 'text',
                        placeholder: 'Имя',
                        required: true,
                        name: 'client_name',
                        value: ''
                    }, {
                        type: 'tel',
                        placeholder: 'Телефон',
                        name: 'phone',
                    }, {
                        type: 'email',
                        placeholder: 'E-mail',
                        required: true,
                        name: 'email',
                        value: ''
                    }, {
                        type: 'textarea',
                        placeholder: 'Вопрос',
                        required: true,
                        name: 'description',
                    }]
                },
                serviseWrapperWidth: 0
            }
        },
        methods: {
            ...mapActions(['fetchSimilar','fetchLinks','fetchActive']),
            myEventHandler() {
              if(document.querySelector('.header-item')) {
                this.serviseWrapperWidth = document.querySelector('.header-item').getBoundingClientRect().left + document.querySelector('.header-item').getBoundingClientRect().width + 40;
              } else this.serviseWrapperWidth = 0
            },
            backToServices() {
              eventHub.$emit('goBackToServices', false)
            }
        },
        computed: mapGetters(['widthPage']),
        mounted () {
          window.addEventListener('resize', this.myEventHandler)
          if(document.querySelector('.header-item')) {
            this.serviseWrapperWidth = document.querySelector('.header-item').getBoundingClientRect().left + document.querySelector('.header-item').getBoundingClientRect().width + 40;
          }
        },
        destroyed() {
          window.removeEventListener("resize", this.myEventHandler);
        },
    }
</script>
<style lang="scss" scoped>
    h1{
      line-height: 130%;
      font-weight: 400;
      margin:0;
      font-size:inherit;
    }

    hr {
        border: unset;
        height: 1px;
        background: var(--color1);
        margin: 0;
    }

    ul,ol {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .service {
        width: 100%;
        max-width: 626px;
        background-color: #FFEC94;
        display: flex;
        flex-direction: column;
        padding: 70px 20px;
        margin: 0;
        box-sizing: border-box;
        color: var(--color1);
        @include largePhonesAlt {
          padding-bottom: 30px;
        }
        &__name {
          font-size: 18px;
          background: #FFD200;
          border-radius: 60px;
          line-height: 21px;
          width: fit-content;
          margin-top: 24px;
          padding:6px 15px;
        }

        .item {
          margin-top: 60px;

          ul{
            margin-top: 60px;

            li {
              span {
                font-size: 16px;
              }
            }

            li.red-list {
              margin-top: -10px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        @include smallDesktop {
          padding-left: 80px;
          padding-right: 56px;
        }

        @include laptop {
          margin-top: -26px;
        }

        @include mediumLaptop {
          // width: 51%;
          max-width: 100%;
          padding-left: 40px;
          padding-right: 26px;

          .item {
            margin-top: 60px;
          }
        }

        @include smallLaptop {
          padding-top: 48px;

          &__name {
            margin-top: 0;
          }

          .button.dark {
            width: 150px;
            span {
              font-size: 15px;
            }
          }

          .item {
            ul{
              li {
                span {
                  font-size: 16px;
                }
              }
            }
          }
        }

        @include phones {
          width: 100%;
          margin-top: 0;
          margin-right: 0;
          padding-left: var(--mainPaddingSmallSize);
          padding-right: var(--mainPaddingSmallSize);
          padding-bottom: 48px;

          .item {
            margin-top: 48px;
            ul{
              margin-top: 32px;

              &:first-of-type {
                margin-top: 24px;
              }
              li {
                span {
                  box-sizing: border-box;
                  min-height: 31px;
                  line-height: 19px;
                }
              }
            }
          }
        }

        @include mediumPhones {
          .item {
            ul{
              li {
                span {
                  font-size: 14px;
                }
              }

              li.red-list {
                margin-top: 0;
                padding-left: 29px;

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }

          .item:first-of-type {
            margin-top: 32px;
          }
        }
    }

    .examples {
      padding-top: 10px;

      hr {
        margin-bottom: 5px;
      }

      &__name {
        text-transform: uppercase;
        font-size: 24px;
        display: block;
        margin-bottom: 50px;
        font-weight:400;

        @include mediumPhones {
          margin-bottom: 20px;
          font-size: 14px;
        }
      }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: transform .3s;
    }

    .fade-enter,
    .fade-leave-to {
        transform: translateX(-100%) translateY(-94px);
    }

    .description {
        position: relative;
        font-size: 36px;
        line-height: 130%;
        z-index: 1;

        &::before {
            content: '';
            position: absolute;
            width: 92px;
            height: 95px;
            top: -64px;
            left: -92px;
            z-index: -1;
            background-position: center;
            background-size: 100%;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='92' height='95' viewBox='0 0 92 95' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M92 40.8428L0 0V17.6326L71.5555 47.8598L0 77.5473V95L92 54.8769V40.8428Z' fill='%23FFD200'/%3E%3C/svg%3E%0A");
        }

        @include mediumLaptopAlt {
          &:before {
            display: none;
          }
        }

        @include smallDesktop {
          &:before {
            left: -30px;
            width: 56px;
          }
        }

        @include smallLaptop {
          &:before {
            display: none;
          }
        }

        @include largePhonesAlt {
          padding-top: 0;
        }

        @include phones {
          font-size: 25px;
          margin-top: 48px;
        }

        @include mediumPhones {
          font-size: 18px;
        }
    }

    .list-name,
    .block-name {
        font-size: 24px;
        line-height: 130%;
        padding-top: 10px;
        width: 100%;
        display: block;
        font-weight:400;

        @include mediumPhones {
          font-size: 14px;
        }
    }

    .list,.insert {
        margin-top: 70px;

        ::v-deep ul{
          margin: 0;
          padding: 0;
          list-style: none;
          display: flex;
          flex-direction: column;
          row-gap: 15px;

          &>li {
            position: relative;
            margin: 0;
            font-size: 16px;
            line-height: 140%;
            padding-left: 49px;
            color: var(--color1);
            &::before {
              content: '';
              position: absolute;
              transform: translateY(50%);
              left: 0;
              width: 11px;
              height: 9px;
              background-size: contain;
              background-repeat: no-repeat;
              background-image: url("data:image/svg+xml,%3Csvg width='9' height='11' viewBox='0 0 9 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 5.5L4.72849e-07 11L9.53674e-07 -3.93402e-07L9 5.5Z' fill='%23E62315'/%3E%3C/svg%3E%0A");
            }
          }

          @include largePhonesAlt {
            li {
              padding-left: 29px;
              margin: 0;
            }
          }
        }
        ::v-deep ol{
          margin: 0;
          padding: 0;
          padding-left: 19px;
          display: flex;
          flex-direction: column;
          row-gap: 15px;

          li {
            font-size: 16px;
            line-height: 140%;
            padding-left: 30px;
            color: var(--color1);
            &::marker{
              color: var(--color3);
              font-weight: bold;
            }
          }

          @include largePhonesAlt {
            li {
              padding-left: 29px;
              margin: 0;
            }
          }
        }

        // ul {
        //     margin-top: 60px;

        //     span {
        //         display: inline-block;
        //         padding: 6px 15px;
        //         background-color: #fff;
        //         border-radius: 60px;
        //         text-transform: uppercase;
        //         margin-bottom: 28px;
        //     }

        //     p {
        //       margin: 0;
        //     }

        //     li {
        //         font-size: 16px;
        //         line-height: 140%;
        //         margin-bottom: 15px;
        //     }
        // }

        // @include phones {
        //   ul {
        //     span {
        //       font-size: 14px;
        //       margin-bottom: 16px;
        //     }

        //     p {
        //       font-size: 14px;
        //     }

        //     li {
        //       font-size: 14px;
        //       line-height: 140%;
        //       margin-bottom: 8px;
        //     }

        //     p {
        //       margin: 0;
        //     }
        //   }
        // }
    }

    .blocks {
        margin-top: 80px;

        ul {
            display: flex;
            flex-wrap: wrap;
            margin-top: 60px;

            li {
                width: calc(50% - 30px);
                margin-right: 60px;
                margin-bottom: 40px;

                &:nth-child(2n) {
                    margin-right: 0;
                }

                &:nth-last-child(-n+2) {
                  margin-bottom: 0;
                }

                span {
                    background: #FFD200;
                    border-radius: 60px;
                    font-size: 20px;
                    line-height: 23px;
                    padding: 6px 15px;
                    display: inline-block;
                    text-transform: uppercase;
                    word-break: break-word;
                    hyphens: auto;
                    max-width: 100%;
                }

                p {
                    font-size: 16px;
                    line-height: 140%;
                    margin-bottom: 0;
                }
            }
        }

        @include smallLaptop {
          ul {
            li {
              width: 100%;
              margin-bottom: 24px;
              margin-right: 0;

              &:nth-last-child(-n+2) {
                margin-bottom: 24px;
              }

              &:last-child {
                margin-bottom: 0;
              }

              p {
                margin-bottom: 0;
              }

            }
          }
        }

        @include phones {
          ul {
            li {
              p {
                font-size: 14px;
              }
            }
          }
        }

        @include mediumPhones {
          ul {
            flex-direction: column;

            li {
              span {
                font-size: 14px;
              }
            }
          }
        }
    }

    .form-wrapper {
        margin-top: 80px;

        h2 {
            font-size: 24px;
            line-height: 130%;
            color: var(--color1);
            text-transform: uppercase;
            margin-bottom: 32px;
            padding-top: 10px;
            display: inline-block;
            width: 100%;
            font-weight: 400;
        }

        @include mediumPhones {
          h2 {
            font-size: 14px;
          }

          .form {
            .input_container {
              margin-bottom: 0 !important;
            }
          }
        }
    }

    .text {
        margin-top: 80px;
        padding-top: 10px;
        font-size: 36px;
        line-height: 130%;

        @include phones {
          font-size: 25px;
        }

        @include mediumPhones {
          font-size: 18px;
        }
    }
</style>

<style lang="scss">
.service {
  .button.dark:first-of-type {
    height: 30px;
    box-sizing: border-box;
  }

  .form {
     label[type="button"] {
       margin-bottom: 0;
     }
    .input_container {
      margin-bottom: 10px;
    }

    .textarea_container {
      min-height: 120px;

      textarea {
        min-height: 120px;
      }
    }
  }

  ul{
    li.red-list {
      &::before {
        display: none;
      }
      ul {
        padding-left: 0px;
        list-style: none;
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        li {
          &::before {
            content: '';
            width: 9px;
            height: 11px;
            position: absolute;
            left: 0;
            transform: translateY(50%);
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center;
            background-image: url("data:image/svg+xml,%3Csvg width='9' height='11' viewBox='0 0 9 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 5.5L4.72849e-07 11L9.53674e-07 -3.93402e-07L9 5.5Z' fill='%23E62315'/%3E%3C/svg%3E%0A");
          }
        }
      }
    }
  }

  @include phones {
    .form {
      .input_container{
        margin-bottom: 10px;
        max-height: 50px;

        .placeholder {
          top: 18px;
        }
      }

    label[type="button"] {
        margin-bottom: 0;
      }
    }
  }
}

.similar {
  margin-top: 26px;
  @include phones {
    margin-top: -86px;

    .case-item {
      .button {
        padding: 0;
      }
    }
  }
}
button.button-full-animation__w2y_right {
  &.service-single-back {
    margin-bottom: 24px;
  }
}
</style>
