<template>
    <section>
<!--        <h2 class="line">{{value.name}}</h2>-->
        <ul class="question-list">
            <li v-for="(question, i) in value.items" :key="i">
                <label class="question" v-if="question">
                    <input class="question-checkbox visually-hidden" type="checkbox">
                    <span class="question-name colorful" v-if="question"><span>?/</span>{{question.name}}</span>
                    <div class="indicator"></div>
                    <p class="answer" v-html="question.text"></p>
                </label>
            </li>
        </ul>
    </section>
</template>
<script>
    export default {
        props: {
            value: null
        },
        mounted() {
            if(this.value && this.value.items && Array.isArray(this.value.items)) {
                const regExp = new RegExp('(<a.+)(href=")(?!' + window.location.origin + ')', 'gm')
                this.value.items = this.value.items.map((question) => {
                    // eslint-disable-next-line
                    if(question && question.text) {
                        question.text = question.text.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
                        return question
                    }
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    section {
        margin-top: 160px;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .question-list {
        margin-top: 80px;
        width: 80%;
        margin-right: 0;
        margin-left: auto;
        li {
            margin-bottom: 10px;
        }
        @include largePhonesAlt {
            width: 100%;
            margin: 0;
        }
    }

    .question {
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;
        cursor: pointer;
        position: relative;
        display: block;
        @include largePhonesAlt {
            padding: 23px 26px;
        }
    }

    .question-name {
        font-size: 22px;
        line-height: 130%;
        color: var(--color1);
        text-transform: uppercase;
        span {
            margin-right: 20px;
        }
        @include largePhonesAlt {
            display: inline-flex;
            width: calc(100% - 50px);
            font-size: 16px;
        }
    }

    .answer {
        margin: 0;
        width: 60%;
        margin-left: 30%;
        margin-right: 10%;
        font-size: 16px;
        line-height: 160%;
        color: var(--color1);
        height: 0;
        box-sizing: border-box;
        overflow: hidden;
        transition: .3s all ease;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            border: 1px solid var(--color1);
            border-radius: 20px;
        }
        @include largePhonesAlt {
            width: 100%;
            margin: 0;
            font-size: 14px;
            line-height: 140%;
        }
    }

    .indicator {
        position: absolute;
        top: 22px;
        right: 20px;
        width: 45px;
        height: 45px;
        background-color: var(--color1);
        border: 1px solid transparent;
        border-radius: 100px;
        transition: .3s background-color ease,.3s transform ease;

        &:after {
            content: '';
            position: absolute;
            width: 2px;
            height: 25px;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
            background-color: var(--color5);
            border-radius: 10px;
            box-shadow: 0 0 2.5px 2.5px var(--color1);
            transition: .3s background-color ease,.3s box-shadow ease;
        }

        &::before {
            content: '';
            position: absolute;
            width: 25px;
            height: 2px;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
            background-color: var(--color5);
            border-radius: 10px;
            transition: .3s background-color ease;
        }
    }

    .question-checkbox:checked {
        &~.indicator {
            background-color: var(--color4);
            transform: rotate(90deg);
            &:before {
                background-color: var(--color1);
            }

            &:after {
                background-color: var(--color1);
                box-shadow: 0 0 2.5px 2.5px var(--color4);
            }
        }

        &~.answer {
            height: unset;
            padding-top: 40px;
        }
    }
</style>
