<template>
    <section class="section">
        <div class="buttons-group buttons-group__partners">
            <button @click.prevent="valuesOpenedTab = 'clients'" class="buttons-group__partners__item clients" :class="{_cp__active: valuesOpenedTab == 'clients'}">
                <span>
                    <span><h2>Клиенты</h2></span>
                </span>
            </button>
            <button @click.prevent="() => {valuesOpenedTab = 'partners'; firstShow = false}" class="buttons-group__partners__item partners" :class="{_cp__active: valuesOpenedTab == 'partners'}">
                <span>
                    <span><h2>Партнеры</h2></span>
                </span>
            </button>
        </div>
        <transition @enter="enter" @leave="leave" :css="false" mode="out-in">
            <!-- <ul key="123" class="clients-list" v-if="valuesOpenedTab == 'clients'"> -->
            <ul key="123" :class="['clients-list', firstShow && 'animate-elem listfadeInLeft']" v-if="valuesOpenedTab == 'clients'">
              <template v-if="widthPage > 1248">
                  <li class="clients-item cp-animation__item" v-for="(client,i) in clients" :key="i">
                    <a :href="client.link" :style="styleImage" target="_blank" rel="dofollow">
                        <img :src="'/storage/'+client.icon" alt="logo">
                    </a>
                </li>
              </template>
              <template v-else-if="widthPage <= 1248">
                <swiper class="swiper swiper-partner" :options="swiperOption" ref="mySwiperRefPartner">
                  <swiper-slide v-for="(client,i) in clients" :key="i">
                    <li class="clients-item">
                      <a :href="client.link" target="_blank" rel="dofollow">
                          <img :src="'/storage/'+client.icon" alt="logo">
                      </a>
                    </li>
                  </swiper-slide>
                </swiper>
              </template>
            </ul>
            <!-- <ul key="456" class="partners-list animate-elem listfadeInLeft" v-else> -->
            <ul key="456" class="partners-list" v-else>
                <template v-if="widthPage > 1248">
                  <li class="clients-item cp-animation__item" v-for="(partner,i) in partners" :key="i">
                    <a :href="partner.link" target="_blank" :style="styleImage" rel="dofollow">
                        <img :src="'/storage/'+partner.icon" alt="logo">
                    </a>
                </li>
              </template>
              <template v-else-if="widthPage <= 1248">
                <swiper class="swiper swiper-partner" :options="swiperOption" ref="mySwiperRefPartner">
                  <swiper-slide v-for="(partner,i) in partners" :key="i">
                    <li class="clients-item">
                      <a :href="partner.link" target="_blank" rel="dofollow">
                          <img :src="'/storage/'+partner.icon" alt="logo">
                      </a>
                    </li>
                  </swiper-slide>
                </swiper>
              </template>
            </ul>
        </transition>
    </section>
</template>
<script>
    import { mapGetters } from 'vuex'
    import axios from 'axios'
    import { gsap } from 'gsap'

    // Import Swiper Vue.js components
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    import {Swiper as SwiperClass, Navigation, Pagination} from 'swiper/swiper.esm.js'
    import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'

    // Swiper modules
    SwiperClass.use([ Navigation, Pagination])

    // local component
    const {directive} = getAwesomeSwiper(SwiperClass)
    // Import Swiper styles
    import 'swiper/swiper-bundle.css'

    export default {
        components: {
          Swiper,
          SwiperSlide,
        },
        data() {
            return {
                valuesOpenedTab: 'clients',
                clients: [],
                partners: [],
                swiperOption: {
                  breakpoints: {
                    1024: {
                      spaceBetween: 0,
                      slidesPerView: 7.2,
                      freeMode: true,
                    },
                    980: {
                      slidesPerView: 5.2,
                      spaceBetween: 0,
                      freeMode: true,
                    },
                    340: {
                      slidesPerView: 3.4,
                      spaceBetween: 0,
                      freeMode: true,
                    }
                  },
                  navigation: {
                    nextEl: '.custom-button-next',
                    prevEl: '.custom-button-prev'
                  },
                  slidesPerView: 'auto',
                  spaceBetween: 0,
                  freeMode: true,
                  pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                  },
                },
                directives: {
                  swiper: directive
                },
                firstShow: true
            }
        },
        methods: {
            enter(el, done) {
                let tl3 = gsap.timeline({onComplete: done})
                const asd = Array.from(el.querySelectorAll('.cp-animation__item'))
                asd.forEach(i => {
                    tl3.to(i.firstElementChild, {opacity: 0, x: 100, duration: 0})
                    tl3.to(i, {borderColor: '#001d8200', duration: 0})
                })
                asd.forEach(i => {
                    tl3.to(i.firstElementChild, {opacity: 1, x: 0, ease: "power1.out", duration: .3}, '-=0.1')
                })
                tl3.add('scene1')
                asd.forEach(i => {
                    tl3.to(i, {borderColor: '#001d8224', duration: .3}, 'scene1')
                })
            },
            leave(el, done) {
                let tl4 = gsap.timeline({onComplete: done})
                const asd = Array.from(el.querySelectorAll('.cp-animation__item'))
                asd.forEach(i => {
                    tl4.to(i.firstElementChild, {y: -60, opacity: 0, ease: "power1.out", duration: .5, delay: .3}, 0)
                    tl4.to(i, {borderColor: '#001d8224', duration: 0}, .6)
                    tl4.to(i, {borderColor: '#001d8200', duration:.30}, .8)
                })
            }
        },
        mounted() {
          axios.get('/api/aboutus/we')
                .then((response) => {
                  this.clients = response.data.clients_partners.clients
                  this.partners = response.data.clients_partners.partners
                })
                .catch((error) => {
                    console.log(error)
                });
        },
        computed: {
          ...mapGetters(['widthPage']),
          styleImage() {
            return {
              opacity: this.firstShow ? 1 : 0
            }
          }
        }
    }
</script>
<style lang="scss" scoped>
@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";
    .section {
      max-width: $mainMaxWidth;
    }
    h2 {
      @include smallLaptop {
        line-height: 32px;
      }
    }

    .clients {
        padding-right: 35px;
        padding-left: 35px;
        padding-top: 9px;
        padding-bottom: 9px;
        border: 1px solid var(--color1);
        border-radius: 150px;
        text-transform: uppercase;
        @include mediumDesctop {
          font-size: 80px;
        }

        @include smallLaptop {
          font-size: 32px;
        }

        @include mediumPhones {
          font-size: 18px;
        }
    }

    .partners {
      padding-right: 35px;
      padding-left: 35px;
      padding-top: 9px;
      padding-bottom: 9px;
      color: #fff;
      background-position: center;
      background-size: 100%;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='637' height='128' viewBox='0 0 637 128' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28.1999 127.5L0.620236 0.5H608.8L636.38 127.5H28.1999Z' fill='%23462DC7' stroke='%23462DC7'/%3E%3C/svg%3E%0A");
      text-transform: uppercase;
      @include mediumDesctop {
        font-size: 80px;
      }

      @include smallLaptop {
        font-size: 32px;
      }

      @include mediumPhones {
        font-size: 18px;
      }
    }

    @keyframes MoveUpInitial {
      to {
        transform: translate3d(0,-105%,0);
      }
    }

    @keyframes MoveUpEnd {
      from {
        transform: translate3d(0,100%,0);
      }
      to {
        transform: translate3d(0,0,0);
      }
    }

    .buttons-group__partners {
        display: flex;
        width: 100%;
        &__item  {
            overflow: hidden;
            position: relative;
            padding: 19px 36px 17px;
            outline: none;
            border: none;
            background-color: #fff;
            cursor: pointer;
            transition: all .15s ease-in-out;

            @include mediumPhones {
              padding: 9px 20px;
            }
            &.partners {
              @include largePhonesAlt {
                padding: 9px 30px;
              }
            }
            span {
                display: block;
                overflow: hidden;
            }
            >span {
                z-index: 2;
                position: relative;
                >span {
                    font-size: 100px;
                    line-height: 100%;
                    color: var(--color1);
                    text-transform: uppercase;
                    transition: all .3s ease-out;
                    transition-delay: .1s;

                    @include mediumLaptop {
                      font-size: 80px;
                    }

                    @include smallLaptop {
                      font-size: 60px;
                    }

                    @include phones {
                      font-size: 32px;
                    }

                    @include mediumPhones {
                      font-size: 22px;
                    }
                    @include extraSmallPhones {
                      font-size: 16px;
                    }
                }

                h2 {
                    font-size: 100px;
                    line-height: 100%;
                    color: inherit;
                    text-transform: uppercase;
                    font-weight:400;

                    @include mediumLaptop {
                      font-size: 80px;
                    }

                    @include smallLaptop {
                      font-size: 60px;
                    }

                    @include phones {
                      font-size: 32px;
                    }

                    @include mediumPhones {
                      font-size: 22px;
                    }
                    @include extraSmallPhones {
                      font-size: 16px;
                    }
                }
            }
            &.clients {
                border: 1px solid #001D82;
                border-radius: 150px;
                margin-right: 10px;
                background-color: var(--color1);
                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: #fff;
                    content: '';
                    transition: transform 0.3s ease-out;
                    transform-origin: 100% 50%;
                }
                &._cp__active {
                    background-color: var(--color1);
                    &:before {
                        transform: scale3d(0,1,1);
                        transform-origin: 0% 50%;
                    }
                    span {
                        color: #fff;
                    }
                }
            }
            &.partners {
                clip-path: polygon(0 0, calc(100% - 30px) 0, 100% 100%, 30px 100%);
                background-color: #462DC7;
                &:before {
                    z-index: -1;
                    position: absolute;
                    top: 1px;
                    left: 1px;
                    width: calc(100% - 2px);
                    height: calc(100% - 2px);
                    clip-path: polygon(0 0, calc(100% - 30px) 0, 100% 100%, 30px 100%);
                    background-color: #fff;
                    content: '';
                    transition: transform 0.3s ease-out;
                    transform-origin: 100% 50%;
                }
                &._cp__active {
                    background-color: #462DC7;
                    &:before {
                        transform: scale3d(0,1,1);
                        transform-origin: 0% 50%;
                    }
                    span {
                        color: #fff;
                    }
                }
            }
            //     background-image: url("data:image/svg+xml,%3Csvg width='637' height='128' viewBox='0 0 637 128' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28.1999 127.5L0.620236 0.5H608.8L636.38 127.5H28.1999Z' fill='%23462DC7' stroke='%23462DC7'/%3E%3C/svg%3E%0A");
            &:hover {
                >span {
                    >span {
                        animation: MoveUpInitial 0.15s forwards ease-out 0.15s, MoveUpEnd 0.15s forwards ease-out 0.35s;
                    }
                }
                &.clients {
                    &:before {
                        transform: scale3d(0,1,1);
                        transform-origin: 0% 50%;
                    }
                    span {
                        color: #fff;
                    }
                }
                &.partners {
                    &:before {
                        transform: scale3d(0,1,1);
                        transform-origin: 0% 50%;
                    }
                    span {
                        color: #fff;
                    }
                }
            }
        }
    }

    .clients-list {
      margin-bottom: 0;
    }
    .partners-list, .clients-list {
        overflow: hidden;
        display: flex;
        list-style-type: none;
        flex-wrap: wrap;
        margin-top: 80px;
        padding: 0;
        position: relative;
        padding: 0;
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 1px;
            width: 100%;
            background-color: #fff;
        }

        .aboutPartners & {
            padding-bottom: 160px;
            margin-bottom: 0;
            @include largePhonesAlt {
              padding-bottom: 30px;
            }
        }

        @include smallLaptop {
          margin-top: 34px;
        }
    }

    .partners-item, .clients-item {
        width: 16.66%;
        height: 220px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: cover;
        position: relative;
        &:before,
        &:after {
            content: '';
            position: absolute;
            bottom: -10px;
            left: -20px;
            width: 40px;
            height: 20px;
            background-color: #fff;
            z-index: 1;
        }
        &:after {
            left: unset;
            right: -20px;
        }
        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
          padding: 20px;
          box-sizing: border-box;
        }
        a {
          padding: 20px;
          width: 100%;
          height: 100%;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        border-right: 1px solid #001d8224;
        border-bottom: 1px solid #001d8224;

        &:first-child,
        &:nth-child(6n+1) {
            border-left: 1px solid #001d8224;
        }
        &:nth-child(6n) {
            border-right: none;
        }
        @include mediumDesctop {
          height: 150px;
        }

        @include mediumLaptop {
          width: 100%;
          border-bottom: none;
        }

        @include mediumPhones {
          height: 75px;
          box-sizing: border-box;
          a {
            display: flex;
            //width: 140%;
            border-bottom: 1px solid rgba(0, 29, 130, .2);
            padding: 0;
            img {
              padding: 15px;
            }
          }
        }
    }

    .swiper-slide {
      width: fit-content;
    }
</style>