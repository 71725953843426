<template>
    <section class="cropped">
        <HeadlineInside :text="text" :classes="classes" />
    </section>
</template>

<script>
import HeadlineInside from './headlineInside.vue'
export default {
    name: 'headline',
    components: {
        HeadlineInside
    },
    props: {
        text: String,
        prefDecoration: String,
        classes: String
    }
}
</script>