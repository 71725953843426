<template>
    <EventsInside :eventItems="eventItems" :listReady="listReady" :tags="tags" />
</template>

<script>
import EventsInside from '../../components/pages/about/eventsInside.vue'
import axios from 'axios'
import {mapGetters,mapMutations} from 'vuex'
// import eventHub from '../../components/eventHub'

export default {
    metaInfo() {
        return {
            title: `${this.metaNames.title ?? 'События'}`,
            meta: this.metaNames.metas,
            script: [{
                type: 'text/javascript',
                innerHTML: `
                    (function (d, s) {
                        var js, fjs = d.getElementsByTagName(s)[0];
                        js = d.createElement(s);
                        js.src = "https://widgets.mos.ru/cnews/citywidgets.js";
                        fjs.parentNode.insertBefore(js, fjs);
                        let i = setInterval(() => {
                            if (window.city_widget && document.readyState === "complete") {
                                city_widget("300x200", "#widget2");
                                document.getElementById('widget2').firstElementChild.style.width = '100%'
                                clearInterval(i);
                            }
                        }, 50);
                        setTimeout(function () {
                            clearInterval(i)
                        }, 5000);
                    }(document, 'script'));
                `
            }]
        }
    },
    name: 'events',
    components: {
        EventsInside
    },
    created() {
        axios.get('/api/news')
            .then((response) => {
                this.addMetaNames(response.data.seo)
                this.eventItems = response.data.news
                this.tags = this.eventItems.map(i => {
                    return i.tag
                }).reduce((total, item) => {
                    if (total.indexOf(item) === -1) total.push(item)
                    return total
                }, []).map((item) => {
                    return { tag: item, active: false };
                });

                this.listReady = true
            })
            .catch((error) => {
                console.log(error)
                this.$router.push('/404')
            })
    },
    data() {
        return {
            eventItems: [],
            tags: [],
            listReady: false
        }
    },
    methods: {
        ...mapMutations(['addMetaNames'])
    },
    computed: {
        ...mapGetters(['metaNames'])
    }
}
</script>