<template>
  <div class="scrollbar scrollbar__template_bottom anim2" :class="{swiperactive:isSwiperActive}">
    <div class="scroll-container" :style="widthPage <= dividerWidth ? {top: scrollContainerTop, position: scrollContainerPosition} : ''">
      <router-link v-if="this.$route.name !== 'License'" tag="button" class="ab-router-link" :to="`/${setLinks}`">
        <AnimatedButton :classes="['buttons-group__tag-filter']" :settings="{anchor: true, class: 'w2y_right', text: 'вернуться', icon: true}" />
      </router-link>
      <div class="button-wrapper">
        <template v-if="widthPage > dividerWidth">
          <a :data-qq="item.id" v-for="(item, i) in SCROLL_OBJECTS" :key="i" >
            <span>{{ SCROLL_PRODUCTS[item.id].name }}</span>
          </a>
        </template>
        <swiper v-else class="swiper page-nav" :options="swiperOption" ref="swiperRefPageNav">
          <swiper-slide v-for="(item) in SCROLL_OBJECTS" :key="SCROLL_PRODUCTS[item.id].name + 's'">
            <a :data-qq-mobile="item.id" class="button dark swiper__button">
              <span>{{SCROLL_PRODUCTS[item.id].name}}</span>
              <div class="scroll"></div>
            </a>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import AnimatedButton from '../UI/AB.vue'
import eventHub from '../eventHub'
import { mapGetters } from "vuex";
import {
    Swiper,
    SwiperSlide
  } from 'vue-awesome-swiper'
import {
    Swiper as SwiperClass
  } from 'swiper/swiper.esm.js'
  import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'

  // local component
  const {
    directive
  } = getAwesomeSwiper(SwiperClass)
  import 'swiper/swiper-bundle.css'
export default {
  props: {
    dividerWidth: {
      type: Number,
      default: 1200
    },
  },
  components: {
    Swiper,
    SwiperSlide,
    AnimatedButton
  },
  data() {
    return {
      isSwiperActive:false,
      isFixed:false,
      scrollContainerTopStart: '',
      scrollContainerTop: '',
      scrollContainerPosition: '',


      swiperOption: {
          slidesPerView: 'auto',
          spaceBetween: 5,
          freeMode: true,
          loop: false
        },
        directives: {
          swiper: directive
        }
    };
  },
  computed: {
    ...mapGetters(["SCROLL_PRODUCTS","SCROLL_OBJECTS","widthPage"]),
    swiper() {
        return this.$refs.swiperRefPageNav ? this.$refs.swiperRefPageNav.$swiper : false
    },
    setLinks() {
      if (this.$route.name === 'Product') {
        return 'products'
      } else if (this.$route.name === 'Solution') {
        return 'solutions'
      }
      return false
    }
  },
  updated() {
    eventHub.$emit('passSwiper', this.$refs.swiperRefPageNav ? this.$refs.swiperRefPageNav.$swiper : false)
  },
  mounted () {
    this.widthPage > this.dividerWidth ? this.isSwiperActive = false : this.isSwiperActive = true;
  }
};
</script>

<style lang="scss" scoped>
.ab-router-link  {
  margin-left: 10px;
  @include mediumLaptopAlt {
    margin-left: 0;
    margin-bottom: 12px;
  }
}

.swiper-slide {
  width: auto;
  min-width: auto;
  z-index: var(--sidebarIndex);
}

.scroll {
  /*transition: all 0.05s ease;*/
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #FFD200;
}

.scroll-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 95%;
  @include mediumLaptopAlt {
    padding: 16px 0;
    height: fit-content;
  }

  @include largePhonesAlt {
    padding-bottom: 0;
  }
}

.scrollbar {
  z-index: 9;
  width: 100%;
  max-width: 277px;
  position: relative;
  padding-top: 0;
  margin-bottom: 20px;
  height: calc(100vh - 93px);
  max-height: calc(100vh - 93px);
  padding-bottom: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  >div {
    background-color: #fff;

    @include largePhonesAlt {
      width: 100vw;
    }
  }
  @include mediumLaptopAlt {
    max-width: 100%;
    height: auto;
  }

  @include largePhonesAlt {
    padding-bottom: 0;
    margin-bottom: 24px;
    margin-left: -10px;
  }
}

.button-wrapper {
  margin-bottom: 15%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  a {
    position: relative;
    overflow: hidden;
    display: inline-flex;
    box-sizing: border-box;
    padding: 6px 15px;
    margin: 0 0 5px 0;
    width: auto;
    max-width: 100%;
    height: auto;
    border: 1px solid var(--color1);
    background: linear-gradient(to right, #FFD200 0%, #fff 0%);
    border-radius: 60px;
    cursor: pointer;
    span {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      font-size: 15px;
      color: var(--color1);
      text-transform: uppercase;
    }
  }
  @include mediumLaptopAlt {
    margin-bottom: 0;
  }
  @include largePhones {
    .button {
      &.dark {
        max-width: 40vw;
        padding: 6px 12px 5px;
        >span {
          font-size: 12px;
          line-height: 100%;
        }
      }
    }
  }
}

.swiper {
  @include largePhones {
    width: 100vw;
  }

  &__button {
    span {
      position: relative;
      z-index: 1;
    }
  }
}

.swiper-wrapper {
  column-gap: 4px;
  .swiper-slide {
    @include largePhones {
      margin: 0;
      &:last-child {
        margin-right: 10px;
      }

      &:first-child {
        margin-left: 10px;
      }
    }
  }
}
</style>

<style lang="scss">
.pin-spacer {
  @include phones {
    top: 0 !important;
  }
}

.scroll-container {
  & > label {
    margin-top: 24px;
  }
  .button-full-animation__w2y.buttons-group__tag-filter.button-red.icon_plus {
    margin-bottom: 0;

    @include phones {
      margin-bottom: 20px;
    }
  }
}
</style>