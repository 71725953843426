export default function aboutAnimation(timeline) {
  timeline
    .set(".buttons-group__wrapper", { yPercent: 100, opacity: 0 })
    .set(".headline", { yPercent: 100 })
    .set(".headline__text", { yPercent: 60, opacity: 0 })
    .set(".cases", { opacity: 0, y: 150 })
    .set(".headline__logo", { opacity: 0, xPercent: 50 })
    .set(".headline button", { yPercent: 105 })
    .to(".buttons-group__wrapper", { yPercent: 0, opacity: 1 })
    .to(".headline", { yPercent: 0 })
    .to(".headline__text", { yPercent: 0, opacity: 1 }, ">")
    .to(".headline button", { yPercent: 0 }, "<50%")
    .to(".cases", { opacity: 1, y: 0 }, "<0")
    .to(".headline__logo", { opacity: 1, xPercent: 0 }, "<0");
}
