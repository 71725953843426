<template>
  <section class="section dflex-cont dflex-cont_column_center">
    <Banner text="Лицензии" image="license.svg" class="dflex-child_maxwidth" />
    <div class="section__wrapper_license dflex-child_maxwidth">
      <ScrollContainer ref="ScrollContainer" data-position="sticky"/>
      <section class="content-container">
        <div class="content-wrapper" data-position-end="sticky">
          <div class="animate-elem fadeInUpSmall" v-for="(item, key) in product.data" :key="key" :id="key">
            <div class="section-container">
              <BoxRate :id="key" class="table" :value='item' />
            </div>
          </div>
        </div>
      </section>
    </div>
    <Monq />
  </section>
</template>

<script>
import ScrollContainer from "../components/UI/scrollContainer.vue";
import Banner from "../components/UI/banner.vue";
import BoxRate from "../components/pages/license/boxRate.vue";
import Monq from "../components/pages/license/monq.vue";
import axios from "axios";
import eventHub from '../components/eventHub'
import {mapGetters,mapMutations} from 'vuex'

export default {
  metaInfo() {
      return {
          title: `${this.metaNames.title ?? 'Лицензии'}`,
          meta: this.metaNames.metas
      }
  },
  components: {
    BoxRate,
    Banner,
    Monq,
    ScrollContainer,
  },
  data() {
    return {
      scroll: 0,
      elements: "",
      product: {},
    };
  },
  created() {
    const time = Date.now()
    eventHub.$emit('updateLoader', true)
    axios
      .get("/api/license/", {timeout: 5000})
      .then((response) => {
        this.addMetaNames(response.data.seo)
        this.product = response.data;
        setTimeout(() => {
          this.$store.commit("addProduct", response.data.data);
          eventHub.$emit('solutionsDone', true)
        }, 1);
      })
      .catch((error) => {
        console.log(error);
        this.$router.push("/404");
      })
      .finally(() => {
        const setTime = Date.now() - time > 1000 ? 0 : 1000 - (Date.now() - time)
        setTimeout(() => {
          eventHub.$emit('updateLoader', false)
          setTimeout(() => {
            this.setLoading(false)
          }, 1000)
        }, setTime)
      });
  },
  methods: {
    ...mapMutations(['addMetaNames', 'setLoading'])
  },
  computed: {
    ...mapGetters(['metaNames'])
  }
};
</script>

<style lang="scss" scoped>
.scroll-container {
  height:25vh;
}
  .headline {
    width: 100%;
    margin:0;
    margin-bottom:50px;
  }

  $scroll-width: 255px;

  .section {
    overflow:hidden;
    &__wrapper_license {
      display: flex;
      @include smallLaptop {
        flex-direction: column
      }
    }
    .headline {
      @include phones {
        margin-bottom: 8px;
      }
    }
    @include smallLaptop {
      flex-direction: column
    }
    @include phones {
      padding-bottom: 0;
    }
  }

  .content-container {
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding: 0 0 80px 46px;
    @include phones {
      padding-top: 0;
    }
  }

  .content-wrapper {
    max-width: 1087px;
    margin-left: auto;
  }

  .table {
    margin-bottom: 80px;
  }

  .button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    a {
      max-width: 100%;
      // min-width: 100%;
      margin-bottom: 5px;
      position: relative;
      overflow: hidden;
      padding-left: 15px;
      padding-right: 15px;
      box-sizing: border-box;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100%);
        white-space: nowrap;
        width: 100%;
      }

      .scroll {
        position: absolute;
        top: 0;
        left: 0;
        background-color: var(--color4);
        height: 100%;
        z-index: -1;
      }
    }
  }

  @media screen and (max-width: 1390px) {
    .content-container {
      flex-direction: column;
    }

    .scroll-container {
      position: relative;
      top: 0 !important;
      width: 100%;
      padding-bottom: 25px;

      .button-wrapper {
        flex-direction: row;
        flex-wrap: nowrap;
        overflow: auto;
      }

      .button-wrapper a {
        overflow: visible;
        margin-right: 5px;
      }
    }
  }

  @media screen and (max-width: 960px) {
    .content-container {
      padding: 0;
    }

    .table {
      margin-bottom: 60px;
    }

    .section {
      padding-left: 10px;
      padding-right: 10px;
    }

    .scroll-container {
      .button span {
        font-size: 10px;
      }
    }
  }

  ::v-deep .scroll-container{
    background: transparent !important;

    @include smallLaptop {
      background: var(--color5) !important;
    }
  }
</style>

<style lang="scss">
.section.dflex-cont.dflex-cont_column_center {
  .headline.dflex-child_maxwidth {
    @include smallLaptop {
      }
    img.dflex__image {
      width: 100%;
      left: 0;
    }
  }

  .scrollbar {
    background: none !important;
    @include smallLaptop {
      padding: 0 !important;
    }

    .scroll-container {
      @include smallLaptop {
        display: flex;
        flex-direction: column;
        justify-content: center;

      }
    }

    & > div {
      background: none;

      @include smallLaptop {
        background: var(--color5);
      }
    }
  }
}

.monq {
  max-width: 1680px;
}
</style>