<template>
    <section class="section">
        <div class="filter" >
            <div class="filter__item" v-if="widthPage > 1024">
                <AnimatedButton :disabled="activeFilter.length === 0" @click="fetchFilter('resetAll')" :classes="['buttons-group__tag-filter','about__events__sidebar__clear']" :settings="{class: 'w2y', text: 'сбросить', icon: true}" />
                <ul class="filter_list">
                    <li class="filter-item" v-for="(filter,i) in filterList" :key="i">
                        <!-- <button class="button dark" @click="filterClick"
                            :name="filter.name" v-bind:class="{active:visibleFilter(filter.name)}"><span>{{filter.name}}</span></button> -->
                        <AnimatedButton @click.native="filterClick" :classes="['buttons-group__tag-filter', visibleFilter(filter.name) && 'is-active']" :settings="{class: 'w2y', text: filter.name, icon: false, buttonName: filter.name}" />
                    </li>
                </ul>
            </div>
            <div data-position="sticky" v-else>
              <div class="filter__item">
                <ul class="filter_list">
                  <swiper class="swiper" :options="swiperOption" ref="swiperRefFilter">
                    <swiper-slide v-for="(filter,i) in filterList" :key="i">
                      <li class="filter-item">
                        <AnimatedButton
                          @click.native="filterClick"
                          :classes="['buttons-group__tag-filter', visibleFilter(filter.name) && 'is-active']"
                          :settings="{class: 'w2y', text: filter.name, icon: false, buttonName: filter.name}" />
                      </li>
                    </swiper-slide>
                  </swiper>
                </ul>
                <AnimatedButton
                  :disabled="activeFilter.length === 0"
                  @click="fetchFilter('resetAll')"
                  :classes="['buttons-group__tag-filter','about__events__sidebar__clear']"
                  :settings="{class: 'w2y', text: 'сбросить', icon: true}" />
              </div>
            </div>
        </div>
        <div class="products" data-position-end="sticky">
            <ul class="product-list">
                <template v-for="(product, i) in activeProducts">
                    <Product  :product="product" :key="i" v-if="i < showItems"/>
                </template>
                <AnimatedButton
                    @click="incShowItems()"
                    :classes="['buttons-group__show-more']"
                    :settings="{class: 'w2y', text: 'показать еще', icon: true}"
                    v-if="activeProductsLength"/>
            </ul>
        </div>
    </section>
</template>
<script>
    import eventHub from '../../eventHub'
    import Product from '../products/product.vue'
    import AnimatedButton from '../../UI/AB.vue'
    import {
        mapActions,
        mapGetters
    } from 'vuex'
    import axios from 'axios'

    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    import { Swiper as SwiperClass } from 'swiper/swiper.esm.js'
    import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'

    const {directive} = getAwesomeSwiper(SwiperClass)
    import 'swiper/swiper-bundle.css'

    export default {
        components: {
            Product,
            Swiper,
            SwiperSlide,
            AnimatedButton
        },
        data() {
            return {
                products: [],
                tmpFilters: [],
                swiperOption: {
                  slidesPerView: 'auto',
                  spaceBetween: 5,
                  freeMode: true,
                },
                directives: {
                  swiper: directive
                },
                showItems: this.showItemsProp,
                inc: this.showItemsProp,
                activeProducts: []
            }
        },
        props: {
            showItemsProp: {
                type: Number,
                default: 6
            }
        },
        methods: {
            ...mapActions(['fetchFilter']),
            filterClick: async function (e) {
                const target = e.target.closest('button')
                // if (e.target && (e.target.tagName == 'BUTTON' || e.target.closest('button'))) {
                //     console.log(target)
                // }
                this.fetchFilter(target.name)
                // if (e.target.className === ("button dark active")) {
                //     e.target.className = "button dark ";
                // } else {
                //     e.target.className = "button dark active";
                // }
                const duration = 600
                await eventHub.$emit('filterUpd', duration)
            },
            visibleFilter: function (e) {
                if(this.activeFilter.includes(e)) {
                    return true
                } else {
                    return false
                }
            },
            incShowItems() {
                this.showItems += this.inc
            }
            // ето теперь в бэклоге
            // load: function () {
            //     this.products = this.products.concat(this.newProducts)
            //     // осталось добавть загрузку вместо newProducts
            // }
        },
        created() {
            axios.get('/api/products')
                .then((response) => {
                    this.products = response.data.products
                    for(let obj in this.products) {
                        for(let filter in this.products[obj].tags) {
                            if(!this.tmpFilters.includes(this.products[obj].tags[filter])) {
                                this.tmpFilters.push(this.products[obj].tags[filter])
                            }
                        }
                    }
                    this.activeProducts = this.products
                })
                .catch((error) => {
                    console.log(error)
                    this.$router.push('/404')
                });
        },
        computed: {
            ...mapGetters(['activeFilter', 'widthPage']),
            filterList() {
                let tmp = []
                for(let i=0; i<this.tmpFilters.length; i++) {
                    tmp.push({
                        name: this.tmpFilters[i]
                    })
                }
                return tmp
            },
            activeProductsLength() {
                if(this.activeProducts && this.activeProducts.length) {
                    return this.activeProducts.length > this.showItems
                } else {
                    return false
                }
            }
        },
        watch: {
            value() {
                this.activeValue = this.value
            },
            activeFilter() {
                if(this.activeFilter.length === 0) {
                    this.showItems = this.inc
                    this.activeProducts = this.products
                } else {
                    this.activeProducts = this.products.filter((item) => {
                        if(item.tags) {
                            return item.tags.filter(element => {
                                return this.activeFilter.includes(element)
                            }).length !== 0
                        }
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .swiper-slide {
      width: auto !important;
      margin-right: 0 !important;

      @include smallLaptop {
        &:first-child {
          margin-left: var(--mainPadding);
        }
      }

      @include largePhones {
        &:first-child {
          margin-left: var(--mainPaddingSmallSize);
        }
      }
    }


    .filter {
        position: relative;
        z-index: 999;

        height: fit-content;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 40px;
       //margin-left: -20px;
        //padding: 0 20px;
        width: 100%;
        &__item {
            position: relative;
            z-index: 9999;

            width: 100%;
            height: auto;

            padding-top: 10px;

            background-color: #fff;

            overflow: hidden;
        }
        @include smallLaptop {
            // position: absolute;
            width: 100%;
            margin-top: 24px;
            flex-direction: column-reverse;
            //overflow: hidden;
        }
    }

    .filter_list {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        width: 100%;
        padding: 0;

        @include smallLaptop {
          padding-left: 0;
          width: calc(100% + 2 * var(--mainPadding));
          margin-bottom: 0;
          margin-left: calc(-1 * var(--mainPadding));
        }

        @include largePhones {
          padding-left: 0;
          width: calc(100% + 2 * var(--mainPaddingSmallSize));
          margin-left: calc(-1 * var(--mainPaddingSmallSize));
        }
    }

    .filter-item {
        margin-right: 5px;
        margin-bottom: 5px;
        width: fit-content;
    }

    .button {
        font-size: 13px;
        line-height: 15px;
        text-transform: uppercase;
        color: var(--color1);
        min-width: unset;

        span {
            padding: 6px 15px;
            pointer-events: none;
            white-space: nowrap;
        }

        &.active {
            background-color: var(--color4);
        }
    }

    .product-list {
        display: flex;
        flex-direction: column;
        list-style-type: none;
        padding: 0;
        margin: 0;
        margin-top: 40px;
        padding-bottom: 80px;
        border-bottom: 1px solid #001D82;

        @include smallLaptop {
            margin-top: 24px;
        }

        @include largePhonesAlt {
            padding-bottom: 40px;
        }

        @include largePhones {
          margin-top: 24px;
        }
    }
</style>
