<template>
    <section>
        <hr class="animate-elem shiftLeft wrap"/>
        <h2 class="line animate-elem shiftUp wrap" v-bind:style="{fontSize: value.nameSize+'px'}">{{value.name}}</h2>
        <div class="slider-body">
            <div class="slider-navigation">
                <ul class="slides " ref="navEl">
                    <template v-if="bigPage">
                        <li v-for="(slide,i) in value.items" :key="i" >
                            <button @click="selectSlider(slide.name,i)" v-bind:class="{active:highlightButton(slide.name)}"
                                class="button dark">
                                <span>{{slide.name}}</span>
                            </button>
                        </li>
                    </template>
                    <template v-else>
                        <swiper class="swiper swiper-partner" :options="swiperOption" ref="mySwiperRefPartner">
                            <swiper-slide v-for="(slide,i) in value.items" :key="i">
                                <li>
                                    <button @click="selectSlider(slide.name,i)" v-bind:class="{active:highlightButton(slide.name)}"
                                        class="button dark">
                                        <span>{{slide.name}}</span>
                                    </button>
                                </li>
                            </swiper-slide>
                        </swiper>
                    </template>
                </ul>
            </div>
            <div class="slider-content">
                <ul class="slides animate-elem fadeInRight">
                    <li v-for="(slide,i) in value.items" :key="i" :name="slide.name" v-show="showSlider(slide,i)">
                        <div class="image-container">
                            <div class="button-wrapper" v-if="bigPage && value.items.length > 1">
                                <button class="button-left" @click="switchSlider('left', i)" :disabled="begin"></button>
                                <button class="button-right" @click="switchSlider('right', i)" :disabled="end"></button>
                            </div>
                            <div class="image-wrapper">
                                <img v-if="slide.img" :src="slide.img" alt="">
                            </div>
                        </div>
                        <!-- <h3 v-if="slide.name" class="line">{{slide.name}}</h3> -->
                        <div class="bottom-container">
                            <div class="slider-count">
                                <span class="active">{{activeSliderNumber}}</span>/{{value.items.length}}
                            </div>
                            <div class="button-wrapper" v-if="!bigPage">
                                <button class="button-left" @click="switchSlider('left', i)" :disabled="begin"></button>
                                <button class="button-right" @click="switchSlider('right', i)" :disabled="end"></button>
                            </div>
                            <ul class="slider-points ">
                                <span v-html="slide.html"></span>
                                <!-- <li class="red-list" v-for="(point,j) in slide.description" :key="j" v-html="point"></li> -->
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>
<script>
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    import eventHub from '../../eventHub'
    import {mapGetters} from 'vuex'
    import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
    import {
        Swiper as SwiperClass
    } from 'swiper/swiper.esm.js'

    // local component
    const {
        directive
    } = getAwesomeSwiper(SwiperClass)

    export default {
        components: {
          Swiper,
          SwiperSlide,
        },
        props: {
            value: null
        },
        data() {
            return {
                activeSlide: this.value.items[0].name,
                activeSliderNumber: 1,
                begin: true,
                end: false,
                bigPage: window.innerWidth > 1248,
                newValue: {},
                swiperOption: {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                  freeMode: true,
                  pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                  },
                  directives: {
                    swiper: directive
                  }
                },
            }
        },
        methods: {
            showSlider: function (e, i) {
                if (this.activeSlide === e.name) {
                    this.activeSliderNumber = i + 1
                    return true
                }
                return false
            },
            selectSlider: function (e, i) {
                this.activeSliderNumber = i + 1
                this.activeSlide = e
                this.switchSlider()
            },
            highlightButton: function (e) {
                if (this.activeSlide === e) {
                    return true
                }
                return false
            },
            switchSlider: function (e) {
                if (e) {
                    if (e === "left") {
                        this.activeSlide = this.value.items[this.activeSliderNumber - 2].name
                        this.activeSliderNumber--
                    }
                    if (e === "right") {
                        this.activeSlide = this.value.items[this.activeSliderNumber].name
                        this.activeSliderNumber++
                    }
                }
                if (this.activeSliderNumber === this.value.items.length) {
                    this.end = true
                } else {
                    this.end = false
                }
                if (this.activeSliderNumber === 1) {
                    this.begin = true
                } else {
                    this.begin = false
                }

                if(this.$refs.mySwiperRefPartner !== undefined){
                    window.innerWidth > 480 ? this.swiper.slideTo(this.activeSliderNumber - 2) : this.swiper.slideTo(this.activeSliderNumber - 1)
                }
                eventHub.$emit('scrollHeightUpdate')
            }
        },
        mounted() {
            if(this.value && this.value.items) {
                const regExp = new RegExp('(<a.+)(href=")(?!' + window.location.origin + ')', 'gm')
                this.newValue = {...this.value, items: this.value.items.map((slide) => {
                        if(Array.isArray(slide.description)) {
                            slide.description = slide.description.map((point) => {
                                // eslint-disable-next-line
                                return point.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
                            })

                            return slide
                        }
                    })
                }
            }
        },
        computed: {
            ...mapGetters(['widthPage']),
            swiper() {
               return this.$refs.mySwiperRefPartner !== undefined && this.$refs.mySwiperRefPartner.$swiper
            },
        },
       watch: {
           widthPage() {
               this.bigPage = this.widthPage > 1248
           }
       }
    }
</script>
<style lang="scss" scoped>
    section {
        margin-top: 160px;
    }

    hr {
        border-top: 1px solid var(--color1);
        margin: 0;
    }

    h3.line {
        border: none;
        width: 100%;
        margin-left: 160px;

        @include mediumLaptop {
            margin-left: 0;
        }
    }

    .bottom-container {
        display: flex;

        @include mediumLaptop {
            flex-wrap: wrap;
            width: 100%;
        }
    }

    .red-list {
        width: 100%;

        &:last-child {
            padding-bottom: 0;

            @include phones {
                padding-bottom: 0;
            }
        }
    }

    .slider-body {
        display: flex;
        justify-content: space-between;
        margin-top: 80px;

        @include mediumLaptop {
            flex-wrap: wrap;
        }

        @include phones {
            margin-top: 24px;
        }
    }

    .slider-navigation {
        min-width: 165px;

        @include mediumLaptop {
            width: 100%;
            display: flex;
            margin-bottom: 48px;
        }

        @include largePhonesAlt {
            margin-bottom: 24px;
        }

        .slides {
            width: 100%;
            list-style-type: none;
            margin: 0;
            padding: 0;

            @include mediumLaptop {
                display: flex;
            }

            li {
                margin-bottom: 5px;

                @include mediumLaptop {
                    width: auto;
                }

                @include largePhonesAlt {
                    margin-bottom: 0;
                }

                button {
                    width: 100%;
                }
            }
        }
    }

    .slider-content {
        width: 80%;

        @include mediumLaptop {
            width: 100%;
        }

        .slides {
            list-style-type: none;
            margin: 0;
            padding: 0;

            .image-wrapper {
                width: 100%;
                max-width: 100%;
                overflow: hidden;
                position: relative;
                border-radius: 10px;
                display: flex;
                flex-direction: column;

                img {
                    padding: 30px;
                    max-height: 500px;
                    width: 100%;
                    box-sizing: border-box;
                    object-fit: contain;
                    border-radius: 10px;
                    background-color: #c4c4c459;
                }
            }

            li {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }
    }

    .button.active {
        background-color: var(--color4);
        border: none;
    }

    .slider-count {
        width: 80px;
        margin-right: 10%;
        margin-left: 160px;

        @include mediumLaptop {
            margin-left: 0;
            margin-right: 0;
            display: flex;
            align-items: center;
        }
    }

    .slider-points {
        ::v-deep ul {
            width: 100%;
            padding-right: 20px;
            list-style-type: none;
            margin: 0;
            padding: 0;
            font-size: 16px;
            line-height: 140%;
            color: var(--color1);
            box-sizing: border-box;

            @include mediumLaptop {
                margin-top: 48px;
            }

            @include largePhonesAlt {
                margin-top: 0;
            }
        }

        ::v-deep li{
            padding-top: 8px;
            padding-bottom: 8px;
            box-sizing: border-box;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                width: 9px;
                height: 11px;
                left: -29px;
                transform: translateY(100%);
                background-image: url("data:image/svg+xml,%3Csvg width='9' height='11' viewBox='0 0 9 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 5.5L4.72849e-07 11L9.53674e-07 -3.93402e-07L9 5.5Z' fill='%23E62315'/%3E%3C/svg%3E%0A");
            }
        }
    }

    .button-wrapper {
        margin-bottom: 0;
        margin-top: auto;
        margin-right: 20px;
        display: flex;

        @include mediumLaptop {
            margin-left: auto;
            margin-right: 0;
        }

        .button-left,
        .button-right {
            width: 70px;
            height: 62px;
            border: none;
            background: none;
            cursor: pointer;

            @include phones {
                width: 60px;
                height: 53px;
                background-size: contain;
            }

            @include largePhonesAlt {
                width: 50px;
                height: 43px;
            }

            &:disabled {
                opacity: .3
            }
        }

        .button-left {
            background-image: url("data:image/svg+xml,%3Csvg width='70' height='62' viewBox='0 0 70 62' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.82664 61.5L0.554051 0.5H63.1734L69.446 61.5H6.82664Z' stroke='%23001D82'/%3E%3Cpath d='M28 30.7778L27.6708 31.1541V30.4015L28 30.7778ZM32.3333 26.9861H32.8333V27.213L32.6626 27.3624L32.3333 26.9861ZM32.3333 34.5694L32.6626 34.1932L32.8333 34.3426V34.5694H32.3333ZM27.6708 30.4015L30.5596 27.8737L31.2182 28.6263L28.3293 31.1541L27.6708 30.4015ZM30.5596 27.8737L32.0041 26.6098L32.6626 27.3624L31.2182 28.6263L30.5596 27.8737ZM31.8333 26.9861V25H32.8333V26.9861H31.8333ZM28.3293 30.4015L31.2182 32.9293L30.5596 33.6818L27.6708 31.1541L28.3293 30.4015ZM31.2182 32.9293L32.6626 34.1932L32.0041 34.9457L30.5596 33.6818L31.2182 32.9293ZM32.8333 34.5694V36.5556H31.8333V34.5694H32.8333Z' fill='%23001D82'/%3E%3Cpath d='M41 30.7778L32.5 30.7778' stroke='%23001D82'/%3E%3C/svg%3E%0A");
        }

        .button-right {
            background-image: url("data:image/svg+xml,%3Csvg width='70' height='62' viewBox='0 0 70 62' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.82664 61.5L0.554051 0.5H63.1734L69.446 61.5H6.82664Z' stroke='%23001D82'/%3E%3Cpath d='M41 30.7778L41.3293 31.1541V30.4015L41 30.7778ZM36.6667 26.9861H36.1667V27.213L36.3374 27.3624L36.6667 26.9861ZM36.6667 34.5694L36.3374 34.1932L36.1667 34.3426V34.5694H36.6667ZM41.3293 30.4015L38.4404 27.8737L37.7819 28.6263L40.6708 31.1541L41.3293 30.4015ZM38.4404 27.8737L36.9959 26.6098L36.3374 27.3624L37.7819 28.6263L38.4404 27.8737ZM37.1667 26.9861V25H36.1667V26.9861H37.1667ZM40.6708 30.4015L37.7819 32.9293L38.4404 33.6818L41.3293 31.1541L40.6708 30.4015ZM37.7819 32.9293L36.3374 34.1932L36.9959 34.9457L38.4404 33.6818L37.7819 32.9293ZM36.1667 34.5694V36.5556H37.1667V34.5694H36.1667Z' fill='%23001D82'/%3E%3Cpath d='M28 30.7778L36.5 30.7778' stroke='%23001D82'/%3E%3C/svg%3E%0A");
        }

    }

    .image-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        @include largePhonesAlt {
            margin-bottom: 12px;
        }
    }

    .slider-count {
        font-size: 16px;
        line-height: 140%;
        color: var(--color1);

        .active {
            font-size: 26px;
            line-height: 140%;
        }
    }

    .swiper-slide {
      width: fit-content;
    }
</style>