<template>
    <section>
        <hr class="animate-elem shiftLeft wrap"/>
        <h2 class="animate-elem shiftUp wrap">{{value.name}}</h2>
        <ul class="for-list">
            <li class="for-item" v-for="(item,i) in value.items" :key="i">
                <span class="label animate-elem fadeInUp wrap">{{item.name}}</span>
                <!-- <span v-if="item.html" v-html="item.html"></span> -->
                <ul v-if="item.text" class="item-list animate-elem fadeInUp">
                    <li v-html="item.text"></li>
                </ul>
            </li>
        </ul>
    </section>
</template>
<script>
    export default {
        props: {
            value: null
        }
    }
</script>
<style lang="scss" scoped>
    hr {
        border-top: 1px solid var(--color1);
        margin-top: 0;
        margin-bottom: 5px;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    section {
        margin-top: 150px;
    }

    .for-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 80px;
        row-gap: 60px;
        @include largePhonesAlt {
           flex-direction: column;
           margin-top: 24px;
           row-gap: 32px;
        }
    }

    .for-item {
        width: calc(50% - 200px);
        margin-right: 200px;
        @include largePhonesAlt {
           width: 100%;
           margin-right: 0;
        }
    }

    .label {
        background: var(--color4);
        border-radius: 60px;
        font-size: 24px;
        line-height: 130%;
        color: var(--color1);
        text-transform: uppercase;
        padding: 6px 15px;
        display: inline-block;
        white-space: nowrap;
        max-width: calc(100%);
        overflow: hidden;
        text-overflow: ellipsis;

        @include largePhonesAlt {
            font-size: 14px;
        }
    }

    .item-list{
        ::v-deep ul {
            margin: 0;
            padding: 0;
            margin-top: 40px;
            list-style: none;
            display: flex;
            flex-direction: column;
            row-gap: 15px;

            li {
                position: relative;
                margin: 0;
                font-size: 16px;
                line-height: 140%;
                padding-left: 49px;
                color: var(--color1);
                &::before {
                    content: '';
                    position: absolute;
                    transform: translateY(50%);
                    left: 0;
                    width: 11px;
                    height: 9px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-image: url("data:image/svg+xml,%3Csvg width='9' height='11' viewBox='0 0 9 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 5.5L4.72849e-07 11L9.53674e-07 -3.93402e-07L9 5.5Z' fill='%23E62315'/%3E%3C/svg%3E%0A");
                }
            }

            @include largePhonesAlt {
               margin: 0px;
               margin-top: 15px;

               li {
                   padding-left: 29px;
                    margin: 0;
               }
            }
        }
    }
</style>