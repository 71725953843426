<template>
    <section class="cropped dflex-child_maxwidth">
        <div class="buttons-group__wrapper">
            <div class="buttons-group buttons-group__general">
                <router-link tag="button" custom v-slot="{ navigate, isActive}" :to="`${item.href}`" v-for="(item, index) in buttonsGroupGeneral" :key="index" :class="['buttons-group__general__item', 'button-full-animation__w2y']">
                    <!-- <AnimatedButton :href="href" :route="route" :navigate="navigate" :classes="['buttons-group__tag-filter']" :settings="{anchor: true, class: 'w2y_right', text: item.name, icon: true}" /> -->
                    <a :class="{'is-active': isActive || (currPath && item.href === '/events')}" @click="navigate">
                        <span>
                            <h1 v-if="isActive">{{ item.name }}</h1>
                            <span v-else>{{ item.name }}</span>
                        </span>
                    </a>
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>
// import AnimatedButton from '../../UI/AB.vue'
export default {
    name: "sectionSwitcherAlt",
    components: {
        // AnimatedButton
    },
    props: {
        currPath: {
            type: Boolean
        },
    },
    data() {
        return {
            tmpName: 'events',
            buttonsGroupGeneral: [
                {
                    name: 'о компании',
                    href: '/about',
                    tag: 'about'
                },
                {
                    name: 'карьера',
                    href: '/career',
                    tag: 'career'
                },
                {
                    name: 'события',
                    href: '/events',
                    tag: 'events'
                },
                {
                    name: 'контакты',
                    href: '/contacts',
                    tag: 'contacts'
                }
            ]
        }
    },
    methods: {
        qwe(val) {
            this.tmpName = val
            this.$emit('clicked', val)
        }
    }
}
</script>

<style lang="scss" scoped>
.cropped {
    box-sizing: border-box;
    padding: 0 var(--mainPadding);
    margin-top: 5px;
    @include laptop {
        padding-bottom: 64px !important;
    }
    @include largePhonesAlt {
        padding-bottom: 8px !important;
        padding: 0 10px;
    }
}
@keyframes MoveUpInitial {
	to {
		transform: translate3d(0,-105%,0);
	}
}

@keyframes MoveUpEnd {
	from {
		transform: translate3d(0,100%,0);
	}
	to {
		transform: translate3d(0,0,0);
	}
}
@keyframes identifier {
    0% {
        color: red;
        transform: translateY(100px);
    }
    100% {
        color: blue;
        transform: translateY(0px);
    }
}
.buttons-group {
    display: inline-flex;
    width: fit-content;
    &__wrapper {
        width: 100%;
        overflow: auto;
        padding-bottom: 16px;
    }
    &__general {
        margin-bottom: 0px;
        &__item {
            padding: 12px 24px;
            border-radius: 90px;
            &:not(:last-child) {
                margin-right: 8px;
            }
            >span {
                >span {
                    font-size: 60px;
                }
                h1{
                    font-size: 60px;
                    margin:0;
                    text-transform: uppercase;
                    font-weight:400;
                    color:var(--color1);
                    display: inline;
                    white-space: nowrap;
                }
            }
        }
    }
    @include smallLaptop {
        &__general {
            &__item {
                padding: 8px 16px;
                >span {
                    >span {
                        font-size: 36px;
                    }
                    h1 {
                        font-size: 36px;
                    }
                }
            }
        }
    }
    @include largePhonesAlt {
        &__general {
            &__item {
                padding: 4px 8px;
                >span {
                    >span {
                        font-size: 20px;
                    }
                    h1 {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}
@include largePhonesAlt {
    .values {
        display: none;
    }
}
</style>