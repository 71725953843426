<template>
    <div id="widget2"></div>
</template>

<script>
export default {
    name: 'newsWidget',
    mounted() {
        let count = 0
        const idInterval = setInterval(() => {
            count++
            if(document.querySelectorAll('#widget2 a').length > 0) {
                document.querySelectorAll('#widget2 a').forEach(a => {
                    a.setAttribute('rel', 'nofollow')
                })

                clearInterval(idInterval)
            }
            if(count === 100) clearInterval(idInterval)
        }, 100)
    }
}
</script>

<style lang="scss" scoped>
#widget2 {
    width: 100%;
}
</style>