import { gsap } from "gsap";
import PAGES from "../../lib/constants/pagesName";

import {
  mainAnimation,
  servicesAnimation,
  solutionsAnimation,
  solutionAnimation,
  productsAnimation,
  licenseAnimation,
  casesAnimation,
  aboutAnimation,
  careerAnimation,
  tenderAnimation,
  eventsAnimation
} from "./pages";

export default function onloadingAnimation(name) {
  const newTimeline = gsap.timeline({
    defaults: { duration: 1, ease: "circ.out" }
  });
  newTimeline.clear();

  switch (name) {
    case PAGES.index:
      mainAnimation(newTimeline);
      break;
    case PAGES.services:
      servicesAnimation();
      break;
    case PAGES.solutions:
      solutionsAnimation(newTimeline);
      break;
    case PAGES.solution:
      solutionAnimation(newTimeline);
      break;
    case PAGES.products:
      productsAnimation(newTimeline);
      break;
    case PAGES.license:
      licenseAnimation(newTimeline);
      break;
    case PAGES.cases:
      casesAnimation(newTimeline);
      break;
    case PAGES.about:
      aboutAnimation(newTimeline);
      break;
    case PAGES.career:
      careerAnimation(newTimeline);
      break;
    case PAGES.tender:
      tenderAnimation(newTimeline);
      break;
    case PAGES.events:
      eventsAnimation(newTimeline);
      break;
    default:
      return "Анимация для страницы не найдена";
  }
}
