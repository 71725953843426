<template>
    <transition @enter="quoteModalEnter" @leave="quoteModalLeave" :css="false">
        <div class="modal" v-if="quoteModal.visible">
            <div :class="['modal__container', setClasses]">
                <div v-if="modalType && modalType === 'history'" class="modal__container__inside modal__container__inside__history">
                    <span>{{ content[quoteModal.personId].name }}</span>
                    <span v-html="content[quoteModal.personId].description"></span>
                </div>
                <div v-if="modalType && modalType === 'quotes'" class="modal__container__inside">
                    <span v-html="content[quoteModal.personId].description"></span>
                    <span>{{ content[quoteModal.personId].name }}</span>
                    <span>— {{ content[quoteModal.personId].position }}</span>
                </div>
                <div v-if="modalType && modalType === 'positionCallback' || modalType === 'caseCallback'" class="modal__container__inside">
                    <ContactForm :content="content" :info="{ type: 'positionCall', text: modalType === 'positionCallback' ? 'Связаться с нами' : 'ЗАДАТЬ ВОПРОС ПО КЕЙСУ', fields: formFileds }" :classes="['contacts__bottom__form position-callback__form model']" />
                </div>
                <div class="popup__modal__wrapper" v-if="modalType == 'portal'">
                    <span>Актуальные цены для {{content.mainTitle}}</span>
                    <table class="popup-top-table">
                        <thead>
                        <tr>
                            <td v-if="content.users"><span>Пользователей</span></td>
                            <td v-if="content.popupData.prolongationRate"><span>Стоимость продления</span></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td v-if="content.users">{{ content.popupData.users }}</td>
                            <td v-if="content.popupData.prolongationRate" class="price">{{ content.popupData.prolongationRate }}</td>
                            <td v-if="content.popupData.description"> {{content.popupData.description}}</td>
                        </tr>
                        </tbody>
                    </table>
                    <table class="popup-bottom-table" v-if="content.popupData.transfer">
                        <thead>
                        <tr>
                            <td><span>Переход с {{content.name}} на</span></td>
                            <td><span>Стоимость</span></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(dataObj, index) in content.popupData.transfer" :key="index">
                            <td>{{dataObj[0]}}</td>
                            <td class="price">{{dataObj[1]}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="popup__modal__wrapper" v-if="modalType == 'boxTd'">
                    <span>Актуальные цены для {{content.mainTitle}}</span>
                    <table class="popup-top-table">
                        <thead>
                            <tr>
                                <td v-if="content.popupData.users"><span>Пользователей</span></td>
                                <td v-if="content.popupData.prolongationRate"><span>Стоимость продления</span></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td v-if="content.popupData.users">{{ content.popupData.users }}</td>
                                <td v-if="content.popupData.prolongationRate"  class="price">{{ content.popupData.prolongationRate }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="popup-bottom-table" v-if="content.popupData.transfer">
                        <thead>
                            <tr>
                                <td><span>Переход на другую лицензию</span></td>
                                <td><span>Стоимость</span></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            v-for="(dataObj, index) in content.popupData.transfer"
                            :key="index"
                            >
                                <td v-for="(value, index) in dataObj" :key="index" :class="{'price': index === 1}">
                                    {{ value }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="popup__modal__wrapper popup_image" v-if="modalType == 'image'">
                    <img :src="`/storage/${content}`" alt="Увеличенное изображение"/>
                </div>
                <button @click.capture="closeQuote">
                    <span>закрыть</span>
                </button>
            </div>
            <div class="closed-wrapper" @click.capture="closeQuote"></div>
        </div>
    </transition>
</template>

<script>
import ContactForm from './contactForm.vue'
import { gsap } from "gsap"
import { mapGetters } from 'vuex'
// import eventHub from '../../eventHub'
export default {
    name: 'modal',
    components: {
        ContactForm
    },
    props: {
        bsb: Object,
        content: null,
        container: null,
        quoteModal: {
            type: Object,
            default: function() {
                return {
                    visible: false,
                    personId: undefined
                }
            }
        },
        iW: Number,
        modalType: String,
        formFileds: Array,
        scrollPoint: Number
    },
    updated() {
        if (this.quoteModal.visible) {
            const events = ['touchmove', 'mousewheel', 'wheel']
            const el = document.querySelector('.popup__modal__wrapper');
            const el2 = document.querySelector('.modal__container__inside')
            events.forEach((eventType) => {
                if (el) {
                    el.addEventListener(eventType, (e) => e.stopPropagation());
                }
                if (el2) {
                    el2.addEventListener(eventType, (e) => e.stopPropagation());
                }
            });
        }

        this.$nextTick(function () {
            if(this.content && this.content[this.quoteModal.personId] && this.content[this.quoteModal.personId].description) {
                const regExp = new RegExp('(<a.+)(href=")(?!' + window.location.origin + ')', 'gm')
                // eslint-disable-next-line
                this.content[this.quoteModal.personId].description = this.content[this.quoteModal.personId].description.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
            }
        })
    },
    computed: {
        ...mapGetters(['widthPage', 'isIPhone']),
        setClasses() {
            let addClass = ''
            if (this.modalType && this.modalType === 'history') {
                addClass = 'modal__container__history'
            } else if (this.modalType && this.modalType === 'quotes') {
                addClass = 'modal__container__quotes'
            } else if (this.modalType && this.modalType === 'positionCallback' || this.modalType === 'caseCallback') {
                addClass = 'modal__container__positionCallback'
            } else if (this.modalType && this.modalType === 'image') {
                addClass = 'modal__container__image'
            }
            return addClass
        },
        setTerspective() {
            const one = 50 / 100
            const two = 3 / 100
            if (this.scrollPoint <= 50) {
                return (this.scrollPoint / one * two) - 3
            } else {
                return this.scrollPoint / one * two - 3
            }
        },
        widthForTransform() {
          return 960
        }
    },
    methods: {
        quoteModalEnter(el, done) {
            this.tl3 = gsap.timeline()
            if (this.widthPage <= this.widthForTransform || this.isIPhone) {
                if (this.modalType == 'image') {
                    this.tl3
                        .to('.modal', {opacity: 1, duration: .15})
                        .to('.modal > .modal__container', {y: -window.innerHeight / 4, duration: .25, ease: "power.inOut"})
                        .to('.modal > .modal__container > button', {y: -34, duration: .2, ease: "power.inOut"})
                        .then(done)
                } else {
                    this.tl3
                        .to('.modal', {opacity: 1, duration: .15})
                        .to('.modal > .modal__container', {y: 0, duration: .3, ease: "power.inOut"})
                        .to('.modal > .modal__container > button', {y: -40, duration: .3, ease: "power.inOut"})
                        .then(done)
                }
            } else {
                if (this.modalType == 'boxTd' || this.modalType == 'portal') {
                    this.tl3.to('.scroll-content', {perspective: 230, perspectiveOrigin: `center ${this.scrollPoint}%`, duration: .25})
                    this.tl3.to('.scroll-content > .section', {transform:`rotateX(${this.setTerspective}deg) translateZ(-160px)`, duration: .25})
                    .then(done)
                }
                if (this.modalType == 'image') {
                    this.tl3
                        .to('.modal', {opacity: 1, duration: .15})
                        .to('.modal > .modal__container', {y: -window.innerHeight / 7, duration: .25, ease: "power.inOut"})
                        .to('.modal > .modal__container > button', {x: 100, duration: .2, ease: "power.inOut"})
                        .then(done)
                 } else {
                    this.tl3
                        .to('.modal', {opacity: 1, duration: .15})
                        .to('.modal > .modal__container', {y: 0, duration: .3, ease: "power.inOut"})
                        .to('.modal > .modal__container > button', {x: 100, duration: .3, ease: "power.inOut"})
                        .then(done)
                 }
            }
        },
        quoteModalLeave(el, done) {
            // const styleOverflow = () => {
            //     document.querySelector('body').style.overflow = ''
            // }
            // this.tl3.reverse().then(styleOverflow).then(done)
            this.tl3.reverse().then(done)
        },
        closeQuote() {
            this.bsb.updatePluginOptions('modal', { open: false });
            this.quoteModal.visible = false
        }
    },
    mounted() {
        if(this.content && this.content[this.quoteModal.personId] && this.content[this.quoteModal.personId].description) {
            const regExp = new RegExp('(<a.+)(href=")(?!' + window.location.origin + ')', 'gm')
            // eslint-disable-next-line
            this.content[quoteModal.personId].description = this.content[quoteModal.personId].description.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/table.scss";
@import "@/assets/styles/popup.scss";

.modal {
    z-index: var(--headerIndex);
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000d3940;
    &__container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        max-width: 717px;
        height: fit-content;
        height: 80vh;
        max-height: 619px;
        background-color: #fff;
        transform: translateY(100%);
        ::-webkit-scrollbar {
            width: 9px;
        }
        ::-webkit-scrollbar-track {
            background: #E9E9E9;        /* color of the tracking area */
        }
        ::-webkit-scrollbar-thumb {
            background-color: var(--color1);    /* color of the scroll thumb */
            border-radius: 20px;       /* roundness of the scroll thumb */
            border: 3px solid #E9E9E9;  /* creates padding around scroll thumb */
        }
        &.modal__container__quotes {
            &:before {
                position: absolute;
                top: 20px;
                left: 20px;
                width: 74px;
                height: 66px;
                background-image: url("data:image/svg+xml,%3Csvg width='74' height='66' viewBox='0 0 74 66' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M29.7552 36.1181H17.3357L30.7902 0H15.7832L0 34.0394V66H29.7552V36.1181ZM72.965 36.1181H60.5455L74 0H58.993L43.2098 34.0394V66H72.965V36.1181Z' fill='%23FFD200'/%3E%3C/svg%3E");
                background-size: cover;
                content: '';
            }
            &:after {
                position: absolute;
                bottom: 20px;
                right: 20px;
                width: 74px;
                height: 66px;
                background-image: url("data:image/svg+xml,%3Csvg width='74' height='66' viewBox='0 0 74 66' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M44.2448 36.1181H56.6643L43.2098 0H58.2168L74 34.0394V66H44.2448V36.1181ZM1.03497 36.1181H13.4545L0 0H15.007L30.7902 34.0394V66H1.03497V36.1181Z' fill='%23F3F3F3'/%3E%3C/svg%3E");
                background-size: cover;
                content: '';
            }
        }
        .modal__container__inside {
            width: 100%;
            box-sizing: border-box;
            padding: 92px 106px 140px;
            overflow-y: auto;
            >span {
                display: inline-block;
                width: 100%;
                color: var(--color1);
                &:first-of-type {
                    margin-bottom: 40px;
                    font-size: 28px;
                    line-height: 130%;
                }
                &:nth-of-type(2) {
                    margin-bottom: 10px;
                    margin-top: auto;
                    font-size: 16px;
                }
                &:last-of-type {
                    font-size: 12px;
                }
            }
            &__history {
                padding: 20px 0px;
                >span {
                    box-sizing: border-box;
                    display: inline-block;
                    width: 100%;
                    color: var(--color1);
                    &:first-child {
                        border-top: 1px solid var(--color1);
                        width: calc(100% - 40px);
                        margin-left: 20px;
                        font-size: 24px;
                        text-transform: uppercase;
                        margin-bottom: 24px;
                    }
                    &:nth-of-type(2) {
                        padding: 0 91px;
                        margin-bottom: 40px;
                        font-size: 18px;
                        line-height: 160%;
                    }
                }
                @include mediumAlt {
                    >span {
                        &:first-child {
                            margin-left: 10px;
                            width: calc(100% - 20px);
                        }
                        &:nth-of-type(2) {
                            padding: 0 5vw;
                        }
                    }
                }
            }
        }
        &.modal__container__image {
            max-height: fit-content;
            max-width: fit-content;
            background: transparent;

            .popup__modal__wrapper.popup_image {
                overflow: visible;
                padding: 0;

                img {
                    max-height: 80vh;
                    max-width: 60vw;
                }
            }
        }
        &__positionCallback {
            .modal__container__inside {
                padding: 0;
            }
        }
        >button {
            position: absolute;
            top: 0;
            right: 0;
            box-sizing: border-box;
            padding: 6px 15px;
            background-color: transparent;
            border: 1px solid #fff;
            border-radius: 60px;
            outline: none;
            cursor: pointer;
            >span {
                color: #fff;
                font-size: 13px;
                text-transform: uppercase;
            }
        }
    }
    @include phones {
        &__container {
            max-width: 100vw;
            min-height: 60vh;
            margin: 0 10px;
            &.modal__container__quotes {
                &:before {
                    top: 16px;
                    left: 16px;
                    width: 41px;
                    height: 37px;
                }
                &:after {
                    bottom: 16px;
                    right: 16px;
                    width: 55px;
                    height: 49px;
                }
            }
            .modal__container__inside {
                padding: 69px 16px;
                >span {
                    &:first-of-type {
                        margin-bottom: 40px;
                        font-size: 18px;
                    }
                }
            }
            &.modal__container__image {
                .popup__modal__wrapper.popup_image {
                    img {
                        max-height: 80vh;
                        max-width: 95vw;
                    }
                }
            }
        }
    }
}
.closed-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
}
// Popups for licenses
.popup__modal__wrapper {
    overflow: auto;
    width: 100%;
    height: 619px;
    box-sizing: border-box;
    padding: 24px;

    @include phones {
        padding: 10px;
        height: fit-content;
    }
    >span {
        border-top: 1px solid var(--color1);
        padding: 6px 0 80px;
        width: 100%;
        position: relative;
        display: inline-flex;
        font-size: 26px;
        color: var(--color1);
        text-transform: uppercase;
        @include phones {
            font-size: 14px;
            padding-bottom: 14px;
        }
    }

    .popup-bottom-table,
    .popup-top-table {
        .price {
            white-space: nowrap;
            vertical-align: top;
        }
        thead {
            span {
                @include phones {
                    font-size: 8px;
                    padding: 4px 8px;
                }
            }
        }

        tbody {
            td {
                @include phones {
                    font-size: 14px;
                }
            }
        }
    }

    .popup-bottom-table {
        @include phones {
            margin-top: 12px;
            padding-top: 32px;
        }
    }
}
</style>