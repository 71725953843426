<template>
    <section class="cropped">
        <div class="photo">
            <span class="animate-elem shiftUp">hopper it — </span>
            <span class="animate-elem shiftUp">лаборатория для бизнеса</span>
            <div class="photo__container">
                <img class="animate-elem fadeInUpSmall" :src="'/storage/'+photo" alt="">
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'motto',
    props: {
        photo: null
    }
}
</script>

<style lang="scss" scoped>
.cropped {
    @include largePhonesAlt {
        padding-bottom: 0 !important;
    }
}
.photo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > span {
        box-sizing: border-box;
        font-size: 80px;
        color: var(--color1);
        text-transform: uppercase;
        display: flex;
        width: 100%;
        border-bottom: 1px solid var(--color1);
        &:first-child {
            border-top: 1px solid var(--color1);
        }
        &:nth-child(2) {
            padding-left: 277px;
        }
    }
    &__container {
        margin-top: 60px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        >img {
            border-radius: 10px;
            height: 340px;
            max-width: 60vw;
            width: auto;
            object-fit: cover;
        }
    }
    @include smallLaptop {
        >span {
            font-size: 60px;
        }
    }
    @include largePhonesAlt {
        >span {
            font-size: 40px;
            &:nth-child(2) {
                padding-left: 101px;
            }
        }
        &__container {
            max-width: calc(100vw - 40px);
            margin-bottom: 0;
        }
    }
    @include mediumPhones {
        >span {
            font-size: 24px;
            &:nth-child(2) {
                padding-left: 61px;
            }
        }
        &__container {
            margin: 24px 0;
            margin-bottom: 0;
            >img {
                height: 188px;
            }
        }
    }
}
</style>