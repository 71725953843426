<template>
    <div class="about__events__event-list" data-position-end="sticky">
        <template v-if="!listReady">
            <div class="eventlist-loader">
                <div class="loader1"></div>
            </div>
        </template>
        <template v-for="(item, index) in eventItems">
            <div :ref="`qwe${index}`" v-if="index < listLimit && sorting(item)" :key="index" class="about__events__event-list__item">
                <div class="event-list__item__taglist">
                    <div class="event-list__item__taglist__item about__events__tag-item">
                        <span>{{ item.type }}</span>
                    </div>
                    <div class="event-list__item__taglist__item about__events__tag-item">
                        <span>{{ item.tag }}</span>
                    </div>
                </div>
                <span class="event-list__item__headline"><router-link :to="item.link">{{ item.name }}</router-link></span>
                <div class="event-list__item__wrapper">
                    <span class="event-list__item__date">{{ item.publication_date }}</span>
                    <router-link class="ab-router-link" tag="button" :to="item.link">
                        <AnimatedButton :classes="'button__animate__link'" :settings="{anchor: true, class: 'r2y', text: 'подробнее', icon: true}" />
                    </router-link>
                </div>
            </div>
        </template>
        <span class="show-more">
            <AnimatedButton v-if="showButton" :disabled="hiddenArticles <= 0" :classes="['buttons-group__tag-filter', 'show-more']" :settings="{class: 'w2y', text: 'показать ещё', icon: false}" @click="addLimit()" />
        </span>
        <ContactForm v-if="iW <= 768 || iH <= 810" :info="{type: 'events',text: 'Подписаться на Hopper-IT', fields: formFileds}" :classes="['about__events__sidebar__form']" />
        <NewsWidget v-if="iW <= 768 || iH <= 810" />
    </div>
</template>

<script>
import AnimatedButton from '../../UI/AB.vue'
import eventHub from '../../eventHub'
import NewsWidget from './newsWidget.vue'
import ContactForm from './contactForm.vue'

export default {
    name: 'eventsInsideList',
    components: {
        AnimatedButton,
        NewsWidget,
        ContactForm,
    },
    data() {
        return {
            formFileds: [{type: "email", placeholder: "Email*", required: true}],
            eventsClone: [],
            listLimit: 6,
            showedArticles: Object.keys(this.$refs).length,
            activeTagsByName: [],
            expandButton: true,
            iW: 0,
            iH: 0
        }
    },
    computed: {
        hiddenArticles: function(){
            return this.eventItems.length - this.showedArticles
        },
        showButton() {
            if (this.listReady && this.expandButton) {
                return true
            } else {
                return false
            }
        }
    },
    updated(){
        this.showedArticles = Object.keys(this.$refs).length
    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    },
    mounted() {
        this.iW = window.innerWidth
        this.iH = window.innerHeight
        this.showedArticles = Object.keys(this.$refs).length
        eventHub.$on('bebe123', (data) => {
            this.activeTagsByName = data
            if (data.length === 0) {
                this.listLimit = 6
                this.expandButton = true
            } else {
                this.listLimit = this.eventItems.length
                this.expandButton = false
            }
        })
    },
    onBeforeUnmount() {
        eventHub.$off('bebe123')
    },
    methods: {
        myEventHandler(e) {
            this.iW = e.target.innerWidth
            this.iH = e.target.innerHeight
        },
        addLimit() {
            this.listLimit += 6
        },
        sorting(q) {
            let qwe
            if (typeof q.tag !== 'object') {
                q = [q.tag]
            }
            if (this.activeTagsByName.length === 0) {
                qwe = true
            } else {
                qwe = q.some(t => {
                    return this.activeTagsByName.indexOf(t) >= 0
                })
            }
            return qwe
        }
    },
    props: {
        eventItems: Array,
        listReady: Boolean
    }
}
</script>

<style lang="scss" scoped>
.about__events {
    &__event-list {
        position: relative;
        box-sizing: border-box;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        row-gap: 20px;
        align-items: flex-start;
        padding-left: 46px;
        padding-bottom: 36px;
        max-width: 1087px;
        margin-left: auto;
        @include largePhonesAlt {
            padding-bottom: 30px;
            &:after {
                display: none;
            }
        }
        @include smallHeight {
            width: 100%;
            padding-left: 0;
            padding-bottom: 30px;
            &:after {
                display: none;
            }
        }
        &:after {
            position: absolute;
            bottom: 0px;
            right: 0;
            width: calc(100% - 46px);
            height: 1px;
            background-color: var(--color1);
            content: '';
        }
        &__item {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            padding: 20px;
            width: 100%;
            border: 1px solid var(--color1);
            border-radius: 10px;
            .event-list__item__taglist {
                display: flex;
                margin-bottom: 46px;
                width: 100%;
                justify-content: flex-start;
                align-items: flex-start;
                flex-wrap: wrap;
                &__item {
                    padding: 5px 16px 6px;
                    border: 1px solid var(--color1);
                    border-radius: 60px;
                    margin-right: 8px;
                    &.asdasd {
                        background-color: var(--color4);
                    }
                    >span {
                        font-size: 13px;
                        line-height: 100%;
                        color: var(--color1);
                        text-transform: uppercase;
                    }
                }
            }
            .event-list__item__headline {
                margin-bottom: 28px;
                font-size: 32px;
                line-height: 130%;
                color: var(--color1);
            }
            .event-list__item__date {
                font-size: 13px;
                line-height: 15px;
                color: var(--color1);
            }
            .event-list__item__wrapper {
                width: 100%;
                height: max-content;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

        & > div:not(.about__events__event-list__item) {
            margin: auto;
            margin-top: 40px;
        }
    }
    @include smallLaptop {
        &__event-list {
            padding-left: 0;
        }
    }
    @include largePhonesAlt {
        &__event-list {
            width: 100%;
            padding-left: 0;
            &__item {
                padding: 10px;
                .event-list__item__taglist {
                    margin-bottom: 24px;
                    &__item {
                        padding: 4px 15px 6px;
                        >span {
                            font-size: 10px;
                        }
                    }
                }
                .event-list__item__headline {
                    font-size: 18px;
                }
            }
        }
    }
}
.eventlist-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 300px;
}
.loader1 {
    opacity: .7;
    width: 60px;
    height: 60px;
    border: 5px solid transparent;
    border-top: 5px solid var(--color1);
    border-bottom: 5px solid var(--color1);
    border-radius: 50%;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

.show-more {
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    // padding-bottom: 40px;

    @include largePhonesAlt {
        border-bottom: 1px solid var(--color1);
        padding-bottom: 24px;
        padding-top: 4px;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>

<style lang="scss">
.about__events__event-list {
    a.button-full-animation__r2y.button__animate__link {
        padding: 6px 15px;
        & > span {
            margin-right: 0;
        }
    }
}
</style>
