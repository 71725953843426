<template>
    <label :class="['input_container', 'animate-elem', 'fadeInUp', ...classes]" v-bind:style="customStyle">
        <input type="checkbox" class="visually-hidden" :checked="checked" :required="required" @change="$emit('click', $event)">
        <div class="indicator"></div>
        <span class="placeholder" >
            <a class="user-agreement" target="_blank" href="../storage/politics.pdf">{{placeholder}}</a>
        </span>
    </label>
</template>
<script>
    export default {
        props: {
            placeholder: null,
            required: null,
            name: null,
            checked: null,
            customStyle: null,
            classes: {
                type: Array
            }
        },
        emits: ['click']
    }
</script>
<style lang="scss" scoped>
    .input_container {
        min-height: 32px;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        input[type='checkbox']:checked + .indicator {
            background-color: var(--color2);
            &:before,
            &:after {
                background-color: var(--color5);
            }
        }
    }

    .indicator {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 30px;
        height: 30px;
        background-color: var(--color5);
        border-radius: 50px;
        transition: .3s opacity ease;
        border: 1px solid var(--color2);
        transition: .3s all ease;

        &:before {
            content: '';
            position: absolute;
            width: 12px;
            height: 1px;
            background-color: var(--color2);
            top: 14px;
            right: 6px;
            transform: rotate(135deg)
        }

        &:after {
            content: '';
            position: absolute;
            width: 6px;
            height: 1px;
            background-color: var(--color2);
            top: 18px;
            left: 7px;
            transform: rotate(45deg)
        }
    }
    .placeholder {
        .user-agreement{
            font-size: 11px;
            line-height: 120%;
            color: var(--color2);
            padding-left: 50px;
            padding-right: 20px;
            padding-top: 2px;
            padding-bottom: 2px;
            display: block;
            user-select: none;
            @include phones {
                max-width: 323px;
            }

            @include smallPhonesAlt {
                max-width: 280px;
            }
        }
    }

@media screen and (max-width: $small) {
  .input_container {
    margin-top:20px;
  }
}
</style>