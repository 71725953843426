<template>
    <!-- <td class="title td">{{ info.title }}</td> -->
    <!-- <td class="box-user td">{{ info.users }}</td>
    <td class="no-wrap box-price td">{{ info.price }}</td> -->
    <td class="box-cta td">
      <button class="button" @click="toggleModal(info, 'boxTd')">
        <span>Смотреть условия</span>
        <div class="button__icon"></div>
      </button>
      <!-- <Popup
        v-if="showPopup"
        @closePopup="closePopup"
        :title="'Актуальные цены для ' + info.title "
      >
        <table class="popup-top-table">
          <thead>
            <tr>
              <td v-if="info.popupData.users"><span>Пользователей</span></td>
              <td v-if="info.popupData.prolongationRate"><span>Стоимость продления</span></td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td v-if="info.popupData.users">{{ info.popupData.users }}</td>
              <td v-if="info.popupData.prolongationRate">{{ info.popupData.prolongationRate }}</td>
            </tr>
          </tbody>
        </table>

        <table class="popup-bottom-table" v-if="info.popupData.transfer">
          <thead>
            <tr>
              <td><span>Переход на другую лицензию</span></td>
              <td><span>Стоимость</span></td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(dataObj, index) in info.popupData.transfer"
              :key="index"
            >
              <td v-for="(value, name, index) in dataObj" :key="index">
                {{ value }}
              </td>
            </tr>
          </tbody>
        </table>
      </Popup> -->
    </td>
</template>

<script>
// import Popup from "../../../UI/popup.vue";
import eventHub from '../../../eventHub'
export default {
  props: ["info"],
  components: {
    // Popup,
  },
  data() {
    return {
      showPopup: false,
      quoteModal: {visible: false}
    };
  },
  methods: {
    toggleModal(data, type) {
      this.quoteModal.visible = true
      data.mainTitle = this.info.title
      eventHub.$emit('emitModal', {content: data, quoteModal: this.quoteModal, modalType: type})
    },
    popupShow(e) {
      this.showPopup = true;
      this.transferData = e
    },
    closePopup() {
      this.showPopup = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/table.scss';
@import '@/assets/styles/popup.scss';
</style>