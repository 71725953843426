<template>
    <div :class="qwClss">
        <template v-if="container == 'about'">
            <transition @enter="enter" @leave="leave" :css="false" mode="out-in">
                <template v-for="(tab, index) in value.items">
                    <div v-if="valuesOpenedTab == index" class="values__text__item" :key="index">
                        <div class="decoration-section"></div>
                        <div class="values__list">
                            <!-- <template v-if="index == 0">
                                <svg class="first2second" width="40" height="87" viewBox="0 0 40 87" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path class="testpath" d="M40 1H5C2.79086 1 1 2.79086 1 5V82C1 84.2091 2.79086 86 5 86H40" stroke="#FFD200"/>
                                </svg>
                            </template> -->
                            <!-- <template v-else>
                                <svg class="first2second-2" viewBox="0 0 40 165" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path class="testpath-2" d="M40 1H5C2.79086 1 1 2.79086 1 5V160C1 162.209 2.79086 164 5 164H40" stroke="#FFD200"/>
                                </svg>
                            </template> -->
                            <div v-for="(li, liindex) in tab.points" :key="`li_${liindex}`" class="values__list__item">
                                <svg v-if="liindex === 0" :class="`circlesvg-${liindex}`" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle class="circle1" cx="17" cy="17" r="16.5" stroke="#FFD200"/>
                                </svg>
                                <svg v-else :class="`starsvg-${liindex}`" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path class="path1" d="M28.6411 5.35545L28.6411 5.35547L28.6445 5.35893L33.499 10.1659L33.4654 16.9975L33.4654 17.0025L33.499 23.8341L28.6445 28.6411L28.6445 28.6411L28.6411 28.6445L23.8341 33.499L17.0025 33.4654L16.9975 33.4654L10.1659 33.499L5.35893 28.6445L5.35894 28.6445L5.35545 28.6411L0.501025 23.8341L0.534603 17.0025L0.534579 16.9975L0.501025 10.1659L5.35545 5.35893L5.35547 5.35894L5.35893 5.35545L10.1659 0.501025L16.9975 0.534604L17.0025 0.53458L23.8341 0.501025L28.6411 5.35545Z" stroke="#FFD200"/>
                                </svg>
                                <span :class="`span span-${liindex}`">{{li}}</span>
                            </div>
                            <router-link v-if="index == 1" to="/cases" class="button2cases">
                                <AnimatedButton :classes="['buttons-group__tag-filter']" :settings="{class: 'w2y', text: 'Наши кейсы', icon: true}" />
                            </router-link>
                        </div>
                    </div>
                </template>
            </transition>
        </template>
        <template v-if="container == 'contacts'">
            <transition @enter="enter2" @leave="leave2" :css="false" mode="out-in">
                <template v-for="(tabs,index) in tabsSettings">
                    <div :key="index" class="address__wrapper" v-if="valuesOpenedTab == index">
                        <div v-for="(tab,idx) in tabs" :key="`tb_${idx}`" class="address__item">
                            <span>{{tab.address}}</span>
                            <AnimatedButton :classes="['buttons-group__tag-filter', 'button-contats-link']" :href="tab.link" :navigate="undefined" :settings="{anchor: true, class: 'w2y', text: 'смотреть на карте', icon: true, target: '_blank', rel: 'nofollow'}" />
                        </div>
                    </div>
                </template>
            </transition>
        </template>
    </div>
</template>

<script>
import { gsap } from 'gsap'
import AnimatedButton from '../../../components/UI/AB.vue'

export default {
    name: 'tabsSwitcherBody',
    components: {
        AnimatedButton
    },
    props: {
        valuesOpenedTab: Number,
        tabsSettings: Array,
        container: String,
        value: null
    },
    computed: {
        qwClss() {
            if (this.container === 'contacts') {
                return 'address__info'
            }
            if (this.container === 'about') {
                return 'values__text'
            }
            return ''
        }
    },
    methods: {
        enter(el, done) {
            let tl2 = gsap.timeline({onComplete: done})
                tl2.from('.circlesvg-0 .circle1', {duration: .5, strokeDashoffset: 103})
                tl2.to('.circlesvg-0 .circle1', {strokeDashoffset: 0, duration: 0})
                tl2.from('.span-0', {opacity: 0, x: 500, duration: .5}, '-=.5')
                tl2.to('.span-0', {opacity: 1, x: 0, ease: "power1", duration: 0})
            if (this.valuesOpenedTab === 0) {
                tl2.from('.testpath', {strokeDashoffset: 160, duration: .5})
                tl2.to('.testpath', {strokeDashoffset: 0, duration: 0})
            }
            if (this.valuesOpenedTab === 1) {
                tl2.from('.testpath-2', {strokeDashoffset: 238, duration: .5})
                tl2.to('.testpath-2', {strokeDashoffset: 0, duration: 0})
            }
                tl2.from('.starsvg-1 .path1', {duration: .5, strokeDashoffset: 110})
                tl2.to('.starsvg-1 .path1', {strokeDashoffset: 0, duration: 0})
                tl2.from('.span-1', {opacity: 0, x: 500, duration: .5}, '-=.5')
                tl2.to('.span-1', {opacity: 1, x: 0, ease: "power1", duration: 0})
                tl2.from('.starsvg-2 .path1', {duration: .5, strokeDashoffset: 110})
                tl2.to('.starsvg-2 .path1', {strokeDashoffset: 0, duration: 0})
                tl2.from('.span-2', {opacity: 0, x: 500, duration: .5}, '-=.5')
                tl2.to('.span-2', {opacity: 1, x: 0, ease: "power1", duration: 0})
            if (this.valuesOpenedTab === 0) {
                tl2.from('.testline', {strokeDashoffset: 53, duration: .5})
                tl2.to('.testline', {strokeDashoffset: 0, duration: 0})
                tl2.from('.testpath2', {strokeDashoffset: 113, duration: .5})
                tl2.to('.testpath2', {strokeDashoffset: 0, duration: 0})
            }
            if (this.valuesOpenedTab === 1) {
                tl2.from('.button2cases', {opacity: 0, x: 230, duration: .5})
                tl2.to('.button2cases', {opacity: 1, x: 0, ease: "power1", duration: 0})
            }
        },
        leave(el, done) {
            let tl3 = gsap.timeline({onComplete: done})
            // tl3.to(el, {y: 190, opacity: 0, duration: .3})
            if (this.valuesOpenedTab === 0) {
                tl3.to('.button2cases', {opacity: 0, x: -230, ease: "power1", duration: .3})
            }
            if (this.valuesOpenedTab === 1) {
                tl3.to('.testpath2', {strokeDashoffset: 113, duration: .3})
                tl3.to('.testline', {strokeDashoffset: 53, duration: .3})
            }
            tl3.to('.starsvg-2 .path1', {duration: .3, strokeDashoffset: 110})
            if (this.valuesOpenedTab === 0) {
                tl3.to('.testpath-2', {strokeDashoffset: 238, duration: .3})
            }
            if (this.valuesOpenedTab === 1) {
                tl3.to('.testpath', {strokeDashoffset: 160, duration: .3})
            }
            tl3.to('.starsvg-1 .path1', {strokeDashoffset: 113, duration: .5})
            tl3.to('.span-2', {opacity: 0, x: -500, ease: "power1", duration: .5})
            tl3.to('.span-1', {opacity: 0, x: -500, ease: "power1", duration: .5}, '-=.2')
            tl3.to('.circlesvg-0 .circle1', {duration: .3, strokeDashoffset: 103})
            tl3.to('.span-0', {opacity: 0, x: -500, ease: "power1", duration: .5})
        },
        enter2(el, done) {
            let tl2 = gsap.timeline({onComplete: done})
            tl2.to('.address__item', {opacity: 0, x: 300, duration: 0})
            tl2.to('.address__item', {opacity: 1, x: 0, ease: "power1", duration: 1})
        },
        leave2(el, done) {
            let tl3 = gsap.timeline({onComplete: done})
            tl3.to('.address__item', {x: -300, opacity: 0, duration: .3})
        }
    }
}
</script>

<style lang="scss" scoped>
.values {
    &__text {
        position: relative;
        // height: 543px;
        overflow: hidden;
        &__container {
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
        }
        &__item {
            // position: absolute;
            display: flex;
        }
    }
    &__list {
        position: relative;
        max-width: 1360px;
        flex: 1;
        // padding-bottom: 160px;
        .testpath {
            stroke-dasharray: 160;
            stroke-dashoffset: 0;
        }
        .testpath2 {
            stroke-dasharray: 113;
            stroke-dashoffset: 0;
        }
        .testpath-2 {
            stroke-dasharray: 238;
            stroke-dashoffset: 0;
        }
        .testline {
            stroke-dasharray: 53;
            stroke-dashoffset: 0;
        }
        .circle1 {
            stroke-dasharray: 103;
            stroke-dashoffset: 0;
        }
        .path1 {
            stroke-dasharray: 110;
            stroke-dashoffset: 0;
        }
        >svg {
            position: absolute;
            &.first2second {
                left: -90px;
                top: 40px;
            }
            &.first2second-2 {
                height: 84px;
                width: 41px;
                left: -80px;
                top: 43px;
            }
            &.second2last {
                left: -34px;
                top: 142px;
            }
            &.last2none {
                left: -53px;
                top: 193px;
            }
        }
        &__item {
            position: relative;
            >span {
                display: inline-block;
                font-size: 60px;
                line-height: 140%;
                color: var(--color1);
            }
            >svg {
                position: absolute;
                left: -50px;
                top: 25px;
            }
            &:last-child {
                min-height: 143px;
            }
        }
    }
    @include smallLaptop {
        &__list {
            &__item {
                >span {
                    font-size: 50px;
                }
            }
            >svg {
                &.first2second {
                    left: -87px;
                    height: 75px;
                }
                &.first2second-2 {
                    height: 71px;
                }
                &.second2last {
                    top: 128px;
                }
                &.last2none {
                    top: 179px;
                }
            }
        }
    }
    @include largePhonesAlt {
        &__list {
            &__item {
                >svg {
                    top: 15px;
                }
                >span {
                    font-size: 40px;
                }
            }
            >svg {
                &.first2second {
                    top: 30px;
                    left: -83px;
                    height: 60px;
                }
                &.first2second-2 {
                    height: 61px;
                    top: 30px;
                }
                &.second2last {
                    top: 104px;
                }
                &.last2none {
                    top: 154px;
                }
            }
        }
    }
    @include mediumPhones {
        &__list {
            margin-bottom: 30px;
            &__item {
                >span {
                    font-size: 30px;
                }
                &:last-child {
                    min-height: unset;
                }
            }
        }
    }
}
.address__wrapper {
    width: 100%;
    max-width: 533px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
.address__item {
    border-top: 1px solid var(--color1);
    margin-bottom: 30px;
    box-sizing: border-box;
    padding-bottom: 10px 0;
    >span {
        display: inline-flex;
        padding: 0;
        margin: 0 0 10px 0;
        font-size: 24px;
        font-weight: 400;
        line-height: 140%;
        text-transform: none;
        color: var(--color1);
    }
    @include largePhonesAlt {
        >span {
            font-size: 18px;
        }
    }
}
.button2cases {
    margin-top: 18px;
    display: inline-block;
}
</style>