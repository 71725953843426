<template>
  <div>
    <div class="header-item">
      <button
        :class="
          headerTheme ? 'search-button search-button-light' : 'search-button'
        "
        @click="toggleSearch"
      ></button>
    </div>
    <transition name="fade">
      <div class="search-container" v-if="showSearch">
        <div class="search-modal">
          <button class="close-search" @click="toggleSearch">Закрыть</button>
          <div>
            <input
              type="text"
              placeholder="Что вы хотите найти?"
              class="search-modal__input"
              v-model="searchText"
              v-on:keyup.enter="handleSearch"
            />
            <button class="search-button" @click="handleSearch"></button>
          </div>
          <div class="modal-mask" @click.prevent="toggleSearch"></div>
          <div class="hr"></div>
          <ul class="search-modal__chips">
            <li @click="selectAll" :class="{ active: selectall }">Все</li>
            <li
              v-for="(obj, i) in tags"
              :key="i"
              @click="onTagClick(i)"
              :class="{ active: obj.active }"
            >
              {{ obj.tag }}
            </li>
          </ul>
        </div>
      </div>
    </transition>
    <portal to="modal-search" v-if="showSearchResult">
      <SearchResult
        :searchText="searchText"
        @handleClose="handleClose"
        class="result"
      />
    </portal>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import SearchResult from "./searchResult.vue";
import axios from "axios";
export default {
  components: {
    SearchResult,
  },
  data() {
    return {
      showSearch: false,
      showSearchResult: false,
      selectall: true,
      tags: [
        { tag: "Кейсы", active: false },
        { tag: "События", active: false },
        { tag: "Услуги", active: false },
        { tag: "Решения", active: false },
        { tag: "Продукты", active: false },
      ],
      searchText: "",
      searchTags: [],
    };
  },
  methods: {
    ...mapMutations(['updatePage', 'setLoading', "setIsSearching"]),
    toggleSearch() {
      this.showSearch = !this.showSearch;
      this.selectAll();
      this.searchText = "";
    },
    selectAll() {
      for (let prop in this.tags) {
        this.tags[prop].active = false;
      }
      this.selectall = true;
      this.searchTags = ["Все"];
    },
    onTagClick(i) {
      this.tags[i].active = !this.tags[i].active;
      this.selectall = false;

      if (this.searchTags.includes("Все")) {
        this.searchTags.splice(0, 1);
      }

      if (this.searchTags.includes(this.tags[i].tag)) {
        let index = this.searchTags.indexOf(this.tags[i].tag);
        this.searchTags.splice(index, 1);
      } else {
        this.searchTags.push(this.tags[i].tag);
      }
    },
    handleSearch() {
      this.setIsSearching(true)
      if(this.$route.name !== 'Search') {
        this.setLoading(true)
        this.$router.push('/search')
      }
      this.showSearch = false;
      axios
        .get("api/search")
        .then((response) => {
          this.$store.dispatch("SET_SEARCH_POSTS", response.data);
          this.$store.commit("SET_SEARCH", this.searchText);
          this.$store.commit("SET_SEARCH_TAGS", this.searchTags);
          this.$store.commit("FILTER_POSTS");
          this.showSearchResult = true;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.setLoading(false)
          this.setIsSearching(false)
        });
    },
    handleClose() {
      this.showSearchResult = false;
    },
  },
  watch: {
    $route() {
      if (document.querySelector("#main-content")) {
        this.showSearchResult ? (this.showSearchResult = false) : null;
        this.$store.commit("clearScroll");
      }
    },
  },
  computed: {
    headerTheme: function () {
      if (this.$route.name == "Index") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.modal-mask {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 200px);
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  overflow: hidden;
}

.search-container {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  height: 200px;
}

.search-modal {
  max-width: 1084px;
  margin: 0 auto;

  div {
    display: flex;
    align-items: baseline;
  }

  .hr {
    height:1px;
    background-color: var(--color1);
    margin-top: 12px;
    margin-bottom: 20px;
  }

  .search-button {
    height: 25px;
  }
}

.search-modal__input {
  border: none;
  flex-grow: 1;
  margin-top: 30px;
  font-size: 18px;
  color: var(--color1);

  &:focus {
    border: none;
    outline: none;
  }

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    font-size: 18px;
    color: var(--color1);
  }
}

.search-modal__chips {
  list-style-type: none;
  padding: 0;
  display: flex;

  li {
    padding: 6px 15px;
    border: 1px solid var(--color1);
    border-radius: 20px;
    color: var(--color1);
    text-transform: uppercase;
    font-size: 13px;
    transition: 0.2s;

    &:hover {
      background-color: var(--color4);
      cursor: pointer;
    }

    &.active {
      background-color: var(--color4);
    }
  }

  li:not(:last-child) {
    margin-right: 8px;
  }
}

.close-search {
  position: fixed;
  top: 21px;
  right: 25px;
  padding: 6px 15px;
  border: 1px solid var(--color1);
  border-radius: 20px;
  color: var(--color1);
  background-color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: var(--color4);
  }
}

.header-item {
  display: flex;
  align-items: flex-start;
}

.search-button {
  background: none;
  border: none;
  width: 40px;
  height: 40px;
  position: relative;
  background-position: center 30%;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='19' viewBox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='0.646447' y1='18.6464' x2='4.64645' y2='14.6464' stroke='%23001d82'/%3E%3Ccircle cx='12' cy='8' r='7.5' stroke='%23001d82'/%3E%3C/svg%3E%0A");
  cursor: pointer;
  transition: 0.3s background ease;
  &:hover {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='19' viewBox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='0.646447' y1='18.6464' x2='4.64645' y2='14.6464' stroke='%23E62315'/%3E%3Ccircle cx='12' cy='8' r='7.5' stroke='%23E62315'/%3E%3C/svg%3E%0A");
  }
}

.search-button-light {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='19' viewBox='0 0 20 19' fill='none'%3E%3Cline x1='0.646447' y1='18.6464' x2='4.64645' y2='14.6464' stroke='white'/%3E%3Ccircle cx='12' cy='8' r='7.5' stroke='white'/%3E%3C/svg%3E");
}

.result {
  background-color: #fff;
}

@media screen and (min-width: 1115px) and (max-width: 1368px) {
  .search-modal__input {
    margin-top: 35px;
  }

  .close-search {
    position: relative;
    left: calc(100% - 92px);
    top: 15px;
  }
}

@media screen and (min-width: 0px) and (max-width: 1115px) {
  .close-search {
    position: relative;
    left: calc(100% - 82px);
    top: -7px;
    font-size: 10px;
  }

  .search-modal__input {
    margin-top: 10px;
  }

  .search-modal {
    padding: 20px 10px 0;
  }

  .search-modal__chips {
    flex-wrap: wrap;

    li {
      margin-bottom: 4px;
      font-size: 10px;

      &:not(:last-child) {
        margin-right: 4px;
      }
    }
  }
}
</style>