<template>
  <section class="section">
    <h3 class="headline headline_decor animate-elem fadeInUp wrap">
      Описать проект и оставить запрос на подготовку предложения
    </h3>
    <div class="calculator">
      <div class="calculator__form-wrapper">
          <vForm
          inputWrapped="true"
          :inputs="inputs"
          buttonType="dark"
          isIndex="true"
        >
          <div class="calculator-wrapper">
            <div class="calculator-container calculator-container--branch">
              <h4 class="animate-elem fadeInUp wrap">Отрасль</h4>
              <div class="drop-down" v-if="widthPage <= 1024" @click="toggelDropDown('branch')">
                <span>Выберите отрасль</span>
                <ul class="drop-down__list" v-if="isOpenBranch">
                  <li
                    class="calculator-item"
                    v-for="(branch, i) in branches"
                    :key="i"
                  >
                    <label>
                      <input
                        type="checkbox"
                        class="visually-hidden"
                        :name="branch.name"
                        @click="addCalculatorItem({item: branch, type: 'branch', active: true}); removeCalculatorItem({name: branch.name, active: false, type: 'branch'})"
                      />
                      <div class="indicator"></div>
                      <span>{{ branch.text }}</span>
                    </label>
                  </li>
                </ul>
                <ul class="calculator-list">
                  <li
                    class="calculator-item"
                    v-for="(branch, i) in activeBranches"
                    :key="i"
                  >
                    <label>
                      <input
                        type="checkbox"
                        class="visually-hidden"
                        :name="branch.name"
                        checked
                        @click="removeCalculatorItem({name: branch.name, active: true, type: 'branch'}); addCalculatorItem({item: branch, active: false, type: 'branch'})"
                      />
                      <div class="indicator"></div>
                      <span>{{ branch.text }}</span>
                    </label>
                  </li>
                </ul>
              </div>
              <ul class="calculator-list animate-elem listfadeInLeft" v-else>
                <li
                  class="calculator-item"
                  v-for="(branch, i) in branches"
                  :key="i"
                >
                  <label>
                    <input
                      type="checkbox"
                      class="visually-hidden"
                      :name="branch.name"
                    />
                    <div class="indicator"></div>
                    <div class="checkbox"></div>
                    <span>{{ branch.text }}</span>
                  </label>
                </li>
              </ul>
            </div>

            <div class="calculator-container calculator-container--tasks">
              <h4 class="animate-elem fadeInUp wrap">Задачи</h4>
              <div class="drop-down" v-if="widthPage <= 1024" @click="toggelDropDown('task')">
                <span>Выберите отрасль</span>
                <ul class="drop-down__list" v-if="isOpenTask">
                  <li
                    class="calculator-item"
                    v-for="(task, i) in tasks"
                    :key="i"
                  >
                    <label>
                      <input
                        type="checkbox"
                        class="visually-hidden"
                        :name="task.name"
                        @click="addCalculatorItem({item: task, active: true, type: 'task'}); removeCalculatorItem({name: task.name, active: false, type: 'task'})"
                      />
                      <div class="indicator"></div>
                      <span>{{ task.text }}</span>
                    </label>
                  </li>
                </ul>
                <ul class="calculator-list animate-elem listfadeInLeft">
                  <li class="calculator-item" v-for="(task, i) in activeTasks" :key="i">
                    <label>
                      <input
                        type="checkbox"
                        class="visually-hidden"
                        :name="task.name"
                        @click="addCalculatorItem({item: task, active: false, type: 'task'}); removeCalculatorItem({name: task.name, active: true, type: 'task'})"
                      />
                      <div class="indicator"></div>
                      <span>{{ task.text }}</span>
                    </label>
                  </li>
                </ul>
              </div>
              <ul class="calculator-list animate-elem listfadeInLeft" v-else>
                <li class="calculator-item" v-for="(task, i) in tasks" :key="i">
                  <label>
                    <input
                      type="checkbox"
                      class="visually-hidden"
                      :name="task.name"
                    />
                    <div class="indicator"></div>
                    <div class="checkbox"></div>
                    <span>{{ task.text }}</span>
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <div class="crater animate-elem fadeInUp wrap"></div>
        </vForm>
      </div>
    </div>
  </section>
</template>
<script>
import vForm from "../../UI/form.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  components: {
    vForm,
  },
  data() {
    return {
      inputs: [
        {
          type: "text",
          placeholder: "Имя",
          required: true,
          name: "client_name",
          customCss:"flex:1;margin-right: 20px;"
        },
        {
          type: "tel",
          placeholder: "Телефон",
          name: "phone",
          customCss:"flex:1;margin-right: 20px;"
        },
        {
          type: "email",
          placeholder: "E-mail",
          required: true,
          name: "email",
          customCss:"flex:1;margin-right: 20px;"
        },
        {
          type: "textarea",
          placeholder: "Комментарий",
          required: true,
          name: "description",
          customCss: "flex:3 1 0%",
        },
      ],
    };
  },
  methods: mapMutations(['toggelDropDown', 'addCalculatorItem', 'removeCalculatorItem', 'closeDropDowns']),
  computed: mapGetters(["widthPage", 'isOpenTask', 'isOpenBranch', 'branches', 'tasks', 'activeBranches', 'activeTasks']),
};
</script>
<style lang="scss" scoped>
@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";

.drop-down {
  position: relative;
  margin-top: 10px;
  cursor: pointer;
  & > span {
    width: 100%;
    height: 50px;
    display: flex;
    position: relative;
    align-items: center;
    background: var(--color5);
    font-size: 14px;
    color: var(--color1);
    background-color: var(--color5);
    padding-left: 10px;
    padding-right: 4px;
    box-sizing: border-box;

    &:after {
      content: '';
      position: absolute;
      right: 10px;
      width: 11px;
      height: 6px;
      background-size: contain;
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6.5 6.5L12 1' stroke='%23001D82'/%3E%3C/svg%3E%0A");
    }
  }

  & > .calculator-list {
    padding-top: 10px;
    .calculator-item {
      input {
        & ~ span {
          background-color: var(--color4);
        }

        & ~ .indicator {
          border-color: var(--color1);

          &:after {
            opacity: 0;
          }
        }
      }
    }
  }
}



.drop-down__list {
  position: absolute;
  box-sizing: border-box;
  padding: 10px;
  margin: 0;
  margin-top: 10px;
  z-index: 99;
  width: 100%;
  background: rgb(221, 221, 221);
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  .calculator-item {
    margin: 0;
  }
}

.section {
  max-width: $mainMaxWidth;
}
.headline_decor {
  &:first-letter {
    margin-left: 260px;
  }
  &:before {
    content: "";
    position: absolute;
    height: 60px;
    background-position-y: 16px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='192' height='33' viewBox='0 0 192 33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.564 20.68V21.56L22.828 32.384V31.372L1.664 21.12L22.828 10.824V9.856L0.564 20.68ZM42.2203 33V21.56H53.5723V20.68H42.2203V9.592H41.2963V20.68H29.9443V21.56H41.2963V33H42.2203ZM57.6086 33L70.3686 1.1H69.4446L56.6406 33H57.6086ZM96.6157 20.68L74.3517 9.856V10.824L95.5157 21.12L74.3517 31.372V32.384L96.6157 21.56V20.68ZM111.212 0.527998C105.404 0.527998 101.136 3.388 100.652 10.604H101.62C101.972 3.344 106.636 1.364 111.212 1.364C115.876 1.364 119.484 3.696 119.484 7.876C119.484 11.484 116.844 13.772 114.204 15.708C110.816 18.172 108.748 19.976 108.748 24.068V25.168H109.628V24.068C109.628 20.108 111.872 18.524 114.644 16.456C117.592 14.344 120.496 11.836 120.496 7.744C120.496 2.992 116.228 0.527998 111.212 0.527998ZM109.628 33V28.864H108.748V33H109.628ZM121.89 33L134.65 1.1H133.726L120.922 33H121.89ZM138.193 20.68V21.56L160.457 32.384V31.372L139.293 21.12L160.457 10.824V9.856L138.193 20.68ZM179.849 33V21.56H191.201V20.68H179.849V9.592H178.925V20.68H167.573V21.56H178.925V33H179.849Z' fill='url(%23paint0_linear_324_3421)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_324_3421' x1='-3' y1='18.0001' x2='195' y2='18.0001' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FFD200'/%3E%3Cstop offset='0.542526' stop-color='%23E62315'/%3E%3Cstop offset='1' stop-color='%23462DC7'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
    background-size: 100%;
    width: 250px;
  }

  &:after {
    content: "";
    display: inline-block;
    margin-left: 10px;
    margin-bottom: -3px;
    width: 55px;
    height: 60px;
    background-position-y: 10px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='35' height='33' viewBox='0 0 35 33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.044 0.527998C5.236 0.527998 0.968 3.388 0.484 10.604H1.452C1.804 3.344 6.468 1.364 11.044 1.364C15.708 1.364 19.316 3.696 19.316 7.876C19.316 11.484 16.676 13.772 14.036 15.708C10.648 18.172 8.58 19.976 8.58 24.068V25.168H9.46V24.068C9.46 20.108 11.704 18.524 14.476 16.456C17.424 14.344 20.328 11.836 20.328 7.744C20.328 2.992 16.06 0.527998 11.044 0.527998ZM9.46 33V28.864H8.58V33H9.46ZM21.7219 33L34.4819 1.1H33.5579L20.7539 33H21.7219Z' fill='url(%23paint0_linear_324_3422)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_324_3422' x1='2.61513e-08' y1='18.0001' x2='35' y2='18.0001' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FFD200'/%3E%3Cstop offset='0.542526' stop-color='%23E62315'/%3E%3Cstop offset='1' stop-color='%23462DC7'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
    background-size: 100%;
  }

  @include smallDesktop {
    &:first-letter {
      margin-left: 210px;
    }
    &:before {
      height: 45px;
      background-position-y: 7px;
      background-size: unset;
    }
    &:after {
      background-size: unset;
      height: 40px;
    }
  }

  @include smallDesktop {
    &:before {
      height: 45px;
      background-position-y: 7px;
    }
  }

  @include smallLaptop {
    &:first-letter {
      margin-left: 100px;
    }

    &:before {
      background-position-y: 0;
      background-size: 100% 100%;
      height: 26px;
      width: 90px;
    }

    &:after {
      margin-left: 0;
      height: 20px;
      width: 16px;
      background-size: 100% 100%;
      background-position-y: 0;
    }
  }

  @include mediumPhones {
    &:before {
      height: 20px;
      width: 100px;
    }
    &:after {
      margin-bottom: -5px;
    }
    &:first-letter {
      margin-left: 120px;
    }
  }

  @include smallPhones {
    &:after {
      background-size: 100% 100%;
      height: 16px;
      max-width: 40px;
      margin-bottom: 0;
    }
  }
}

.headline {
  position: relative;
  box-sizing: border-box;
  align-items: flex-start;
  max-width: var(--large);
  margin: 63px auto;
  margin-top: 0;
  width: 100%;

  border-bottom: 1px solid rgba(0, 0, 0, 0);
  text-align: left;
  justify-content: flex-start;
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  line-height: 130%;
  text-transform: uppercase;
  color: var(--color1);

  background: linear-gradient(180deg, rgba(0, 29, 130, 0) 74px, white 74px, var(--color1) 75px);
  background-size: 100% 75px;
  background-repeat: repeat;
  background-position-y: 1px;

  @include smallDesktop {
    font-size: 32px;
    line-height: 45px;
    background: linear-gradient(
            180deg,
            rgba(0, 29, 130, 0) 44px,
            white 44px,
            var(--color1) 45px
    );
    background-size: 100% 45px;
    background-position-y: 1px;
  }

  @include smallLaptop {
    font-size: 20px;
    line-height: 28px;
    background: linear-gradient(
            180deg,
            rgba(0, 29, 130, 0) 26px,
            white 26px,
            var(--color1) 27px
    );
    background-size: 100% 27px;
    background-position-y: 1px;
  }

  @include mediumLaptopAlt {
    margin-bottom: 24px;
  }

  @include mediumPhones {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 23px;
    border-bottom: 1px solid rgba(0, 28, 130, 0.2);

    background: linear-gradient(
            180deg,
            rgba(0, 29, 130, 0) 21px,
            white 21px,
            var(--color1) 26px
    );
    background-size: 100% 23px;
    background-position-y: 2px;
  }

  @include smallPhones {
    line-height: 24px;
    background: linear-gradient(
            180deg,
            rgba(0, 29, 130, 0) 22px,
            white 22px,
            var(--color1) 27px
    );
    background-size: 100% 24px;
    background-position-y: 1px;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }
}

h2 {
  font-size: 60px;
  line-height: 140%;
}

.calculator {
  padding: var(--mainPadding);
  margin-top: 80px;
  background-color: #f0f0f0;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  @include smallLaptop {
    margin-top: 24px;
    padding: 10px;
  }

  &__form-wrapper {
    width: 100%;
  }
}
.calculator-wrapper {
  display:flex;
  width: 100%;
  column-gap: 40px;

  @include smallLaptop {
    justify-content: space-between;
    column-gap: 10px;
  }

  @include mediumPhones {
    flex-wrap: wrap;
    row-gap: 24px;
  }
}

.calculator-container {
  width: 50%;

  h4 {
    text-transform: uppercase;
    margin: 0;
    font-size: 60px;
    line-height: 110%;
    color: var(--color1);
    @include smallDesktop {
      font-size: 44px;
    }
    @include smallLaptop {
      font-size: 20px;
    }
  }

  &.calculator-container--tasks {
    .indicator {
      background-color: #c4baf4;

      &:after {
        box-shadow: 0 0 2.5px 2.5px #c4baf4;
      }
    }

    .checkbox {
      &:before,
      &:after {
        background-color: #c4baf4;
      }
    }

    input[type="checkbox"]:checked {
      & ~ span {
        background-color: #c4baf4;
      }
    }

    .drop-down {
      z-index: var(--headerInde);
      & > .calculator-list {
        .checkbox {
          &:before,
          &:after {
            background-color: #c4baf4;
          }
        }

        input{
          & ~ span {
            background-color: #c4baf4;
          }
        }
      }
    }
  }

  &.calculator-container--branch {
    .drop-down {
      z-index: var(--modalIndex);
    }
  }

  @include mediumPhones {
    width: 100%;
    margin-left: 0;

    &.calculator-container--tasks {
      margin-left: 0;
      // margin-top: 24px;
    }
  }
}

.calculator-list {
  padding-left: 0;
  margin: 0;
  padding-top: 40px;
  display: flex;
  flex-wrap: wrap;

  @include smallLaptop {
    flex-direction: column;
    row-gap: 10px;
  }

  @include mediumPhones {
    padding-top: 16px;
  }
}

.calculator-item {
  height: 120px;
  width: calc(20% - 5px);
  min-width: 150px;
  position: relative;
  margin-right: 5px;
  margin-bottom: 5px;
  list-style-type: none;
  span {
    font-size: 14px;
    color: var(--color1);
    background-color: var(--color5);
    border-radius: 6px;
    padding: 10px;
    padding-right: 4px;
    display: block;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    transition: 0.3s background-color ease;
    user-select: none;

    @include smallLaptop {
      display: flex;
      align-items: center;
    }
  }

  @include smallDesktop {
    min-width: 140px;
    height: 115px;

    span {
      font-size: 13px;
    }
  }

  @include smallLaptop {
    width: 100%;
    height: 50px;
    margin: 0;
  }

  label {
    cursor: pointer;

    @include smallLaptop {
      width: 100%;
      height: 100%;
      display: flex;
    }
  }

  .indicator {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 43px;
    height: 43px;
    background-color: var(--color4);
    border: 1px solid transparent;
    border-radius: 100px;

    @include smallLaptop {
      width: 29px;
      height: 29px;
    }

    &:after {
      content: "";
      position: absolute;
      width: 2px;
      height: 25px;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      background-color: var(--color1);
      border-radius: 10px;
      box-shadow: 0 0 2.5px 2.5px var(--color4);
      transition: 0.3s opacity ease;

      @include smallLaptop {
        height: 16px;
      }
    }

    &::before {
      content: "";
      position: absolute;
      width: 25px;
      height: 2px;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      background-color: var(--color1);
      border-radius: 10px;

      @include smallLaptop {
        width: 16px;
      }
    }
  }

  .checkbox {
    position: absolute;
    left: 10px;
    bottom: 10px;
  }

  input[type="checkbox"]:checked {
    & ~ span {
      background-color: var(--color4);
    }

    & ~ .indicator {
      border-color: var(--color1);

      &:after {
        opacity: 0;
      }
    }

    & ~ .checkbox {
      opacity: 1;
    }
  }
}

.crater {
  margin-top: 7px;
  width: 100%;
  height: 59px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-image: url("data:image/svg+xml,%3Csvg width='1582' height='59' viewBox='0 0 1582 59' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M791.354 58.3536C791.158 58.5488 790.842 58.5488 790.646 58.3536L787.464 55.1716C787.269 54.9763 787.269 54.6597 787.464 54.4645C787.66 54.2692 787.976 54.2692 788.172 54.4645L791 57.2929L793.828 54.4645C794.024 54.2692 794.34 54.2692 794.536 54.4645C794.731 54.6597 794.731 54.9763 794.536 55.1716L791.354 58.3536ZM781 24.5H10.9999V23.5H781V24.5ZM0.499939 14V7H1.49994V14H0.499939ZM790.5 58V34H791.5V58H790.5ZM10.9999 24.5C5.20097 24.5 0.499939 19.799 0.499939 14H1.49994C1.49994 19.2467 5.75325 23.5 10.9999 23.5V24.5ZM781 23.5C786.799 23.5 791.5 28.201 791.5 34H790.5C790.5 28.7533 786.247 24.5 781 24.5V23.5Z' fill='%23001D82'/%3E%3Cpath d='M1571 23.5H801V24.5H1571V23.5ZM790.5 34V41H791.5V34H790.5ZM1580.5 0V14H1581.5V0H1580.5ZM801 23.5C795.201 23.5 790.5 28.201 790.5 34H791.5C791.5 28.7533 795.753 24.5 801 24.5V23.5ZM1571 24.5C1576.8 24.5 1581.5 19.799 1581.5 14H1580.5C1580.5 19.2467 1576.25 23.5 1571 23.5V24.5Z' fill='%23001D82'/%3E%3C/svg%3E%0A");

  @include smallLaptop {
    margin-top: 15px;
    height: 45px;
    background-image: url("data:image/svg+xml,%3Csvg width='1202' height='59' viewBox='0 0 1202 59' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M601.354 58.3536C601.158 58.5488 600.842 58.5488 600.646 58.3536L597.464 55.1716C597.269 54.9763 597.269 54.6597 597.464 54.4645C597.66 54.2692 597.976 54.2692 598.172 54.4645L601 57.2929L603.828 54.4645C604.024 54.2692 604.34 54.2692 604.536 54.4645C604.731 54.6597 604.731 54.9763 604.536 55.1716L601.354 58.3536ZM591 24.5H10.9999V23.5H591V24.5ZM0.499939 14V7H1.49994V14H0.499939ZM600.5 58V34H601.5V58H600.5ZM10.9999 24.5C5.20093 24.5 0.499939 19.799 0.499939 14H1.49994C1.49994 19.2467 5.75317 23.5 10.9999 23.5V24.5ZM591 23.5C596.799 23.5 601.5 28.201 601.5 34H600.5C600.5 28.7533 596.247 24.5 591 24.5V23.5Z' fill='%23001D82'/%3E%3Cpath d='M1191 23.5H611V24.5H1191V23.5ZM600.5 34V41H601.5V34H600.5ZM1200.5 0V14H1201.5V0H1200.5ZM611 23.5C605.201 23.5 600.5 28.201 600.5 34H601.5C601.5 28.7533 605.753 24.5 611 24.5V23.5ZM1191 24.5C1196.8 24.5 1201.5 19.799 1201.5 14H1200.5C1200.5 19.2467 1196.25 23.5 1191 23.5V24.5Z' fill='%23001D82'/%3E%3C/svg%3E%0A");
  }

  @include largePhonesAlt {
    background-image: url("data:image/svg+xml,%3Csvg width='730' height='36' viewBox='0 0 730 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M365.354 35.3535C365.158 35.5488 364.842 35.5488 364.646 35.3535L361.464 32.1716C361.269 31.9763 361.269 31.6597 361.464 31.4644C361.66 31.2692 361.976 31.2692 362.172 31.4644L365 34.2929L367.828 31.4644C368.024 31.2692 368.34 31.2692 368.536 31.4644C368.731 31.6597 368.731 31.9763 368.536 32.1716L365.354 35.3535ZM355 14.9827H11V13.9827H355V14.9827ZM0.499969 4.48274V4.22412H1.49997V4.48274H0.499969ZM364.5 35V24.4827H365.5V35H364.5ZM11 14.9827C5.20099 14.9827 0.499969 10.2817 0.499969 4.48274H1.49997C1.49997 9.72944 5.7533 13.9827 11 13.9827V14.9827ZM355 13.9827C360.799 13.9827 365.5 18.6838 365.5 24.4827H364.5C364.5 19.236 360.247 14.9827 355 14.9827V13.9827Z' fill='%23001D82'/%3E%3Cpath d='M719 13.9826H375V14.9826H719V13.9826ZM364.5 24.4826V24.7412H365.5V24.4826H364.5ZM728.5 -0.000167847V4.48259H729.5V-0.000167847H728.5ZM375 13.9826C369.201 13.9826 364.5 18.6836 364.5 24.4826H365.5C365.5 19.2359 369.753 14.9826 375 14.9826V13.9826ZM719 14.9826C724.799 14.9826 729.5 10.2816 729.5 4.48259H728.5C728.5 9.7293 724.247 13.9826 719 13.9826V14.9826Z' fill='%23001D82'/%3E%3C/svg%3E%0A");
  }

  @include mediumPhones {
    height: 40px;
    margin-top: 15px;
    background-image: url("data:image/svg+xml,%3Csvg width='337' height='36' viewBox='0 0 337 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M168.854 35.354C168.658 35.5493 168.342 35.5493 168.146 35.354L164.964 32.172C164.769 31.9768 164.769 31.6602 164.964 31.4649C165.16 31.2697 165.476 31.2697 165.672 31.4649L168.5 34.2934L171.328 31.4649C171.524 31.2697 171.84 31.2697 172.036 31.4649C172.231 31.6602 172.231 31.9768 172.036 32.172L168.854 35.354ZM158.5 14.9832H11V13.9832H158.5V14.9832ZM0.499985 4.48323V4.22461H1.49998V4.48323H0.499985ZM168 35.0005V24.4832H169V35.0005H168ZM11 14.9832C5.201 14.9832 0.499985 10.2822 0.499985 4.48323H1.49998C1.49998 9.72993 5.75328 13.9832 11 13.9832V14.9832ZM158.5 13.9832C164.299 13.9832 169 18.6842 169 24.4832H168C168 19.2365 163.747 14.9832 158.5 14.9832V13.9832Z' fill='%23001D82'/%3E%3Cpath d='M326 13.9826H178.5V14.9826H326V13.9826ZM168 24.4826V24.7412H169V24.4826H168ZM335.5 -0.000167847V4.48259H336.5V-0.000167847H335.5ZM178.5 13.9826C172.701 13.9826 168 18.6836 168 24.4826H169C169 19.2359 173.253 14.9826 178.5 14.9826V13.9826ZM326 14.9826C331.799 14.9826 336.5 10.2816 336.5 4.48259H335.5C335.5 9.7293 331.247 13.9826 326 13.9826V14.9826Z' fill='%23001D82'/%3E%3C/svg%3E%0A");
  }
}

.contact {
  margin-top: 45px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @include smallLaptop {
    margin-top: 16px;
  }
}

.contact-wrapper {
  width: 100%;
  display: flex;

  .input_container {
    flex: 1;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  @include smallLaptop {
    flex-flow: column;
  }
}

.button-container {
  display: flex;
  justify-content: space-between;
  width: 48%;

  @include smallLaptop {
    width: 100%;
  }

  @include mediumPhones {
    flex-flow: column;

    button {
      margin-left: auto !important;
    }
  }
}
</style>

<style lang="scss">
@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";

.contact-wrapper {
  @include smallLaptop {
    position: relative;
    .textarea_container,
    .input_container {
      margin-left: 0;
    }

    .input_container {
      width: calc(50% - 5px);
    }

      .textarea_container {
        position: absolute;
        width: unset;
        right: 0;
        top: 0;
        bottom: 0;
        left: calc(50% + 5px);
        max-height: none;
        height: 100%;
      }
    }
  }
  @include mediumPhones {
    .textarea_container,
    .input_container {
      margin-bottom: 10px;
      width: 100%;
    }

    .textarea_container {
      position: relative;
      left: 0;
      height: 120px;

      textarea {
        height: 120px;
      }
    }
  }


.button-container {
  display: flex;
  justify-content: space-between;
  width: 48%;

  @include smallLaptop {
    width: 100%;
  }

  @include mediumPhones {
    flex-flow: column;

    button {
      margin-left: auto;
      margin-top: 20px;
    }
  }
}

@include phones {
  .calculator {
    .form {
      .input-wrapper {
        flex-direction: column;
        position: relative;

        .input_container {
          margin-bottom: 10px;

          width: calc(50% - 5px);
        }
        .textarea_container {
          position: absolute;
          width: calc(50% - 5px);
          max-height: 100%;
          height: calc(100% - 10px);
          top: 0;
          right: 0;
          //bottom: 0;
          //left: 50%;

          textarea {
            height: 100%;
          }
        }
      }

      .btn-wrapper {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;

        & > div {
          margin-left: auto;
        }

        .input_container {
          margin-top:20px;
          width: 100%;

          span {
            padding-right: 0;
          }
        }

        label[type="button"] {
          margin-left: auto;
          margin-top: 20px;
        }
      }
    }
  }
}


@include mediumPhones {
  .calculator {
    .form {
      .input-wrapper {
        .input_container {
          width: 100%;
          flex: 1 1 0% !important;
        }
        .textarea_container {
          position: relative;
          width: 100%;
          max-height: 120px;
          height: 120px;
          min-height: 120px;

          textarea {
            height: 120px;
            min-height: 120px;
          }
        }
      }

      .btn-wrapper {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;

        .input_container {
          width: 100%;
          flex:1 0 0% !important;
        }

        label[type="button"] {
          margin-left: auto;
          margin-top: 20px;
        }
      }
    }
  }
}

@include smallPhonesAlt {
    .calculator {
    .form {
      .input-wrapper {
        margin-top: 20px;
        .input_container {
          input {
            height: 50px;
          }

          .placeholder {
            top: 20px;
          }
          input:not(:placeholder-shown) ~ .placeholder {
            top: 5px;
          }
        }
      }
      .btn-wrapper {
        & > div {
          margin-right: 10px;
        }
      }
    }
  }
}

@include extraSmallPhones {
  .calculator {
    .form {
      .btn-wrapper {
        left: -5px;
        position: relative;
      }
      .input-wrapper {
          left: -5px;
      }
    }
  }
}
</style>
