<template>
    <section class="cropped">
        <TeamList :content="content" :container="container" :quoteModal="quoteModal" :headline="headline"/>
    </section>
</template>

<script>
import TeamList from './teamList.vue'
// import Modal from './modal.vue'

export default {
    name: 'team',
    components: {
        // Modal,
        TeamList
    },
    props: {
        container: null,
        content: null,
        headline: null,
        type: String
    },
    data() {
        return {
            quoteModal: {
                visible: false,
                personId: undefined,
                test: false
            },
            test: 1
        }
    }
}
</script>