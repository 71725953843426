export default {
    actions: {
        SET_SEARCH_POSTS({commit}, data){
            commit('SET_SEARCH_POSTS', data)
        },
    },
    mutations: {
        SET_SEARCH(state, value) {
            state.searchWord = value;
        },
        SET_SEARCH_TAGS(state, valueObj) {
            state.searchTags = valueObj;
        },
        FILTER_POSTS(state) {
            state.filteredPosts = state.searchPosts.filter((item) => {
                if (state.searchTags[0] == "Все") {
                    return item.title.toLowerCase().includes(state.searchWord.toLowerCase())
                } else {
                    return item.title.toLowerCase().includes(state.searchWord.toLowerCase()) & state.searchTags.includes(item.class)
                }
            })
        },
        SET_SEARCH_POSTS(state, data) {
            state.searchPosts = data;
        },
        setIsSearching(state, payload) {
            state.isSearching = payload
        }
    },
    state: {
        searchWord: "",
        searchTags: [],
        filteredPosts: [],
        searchPosts: [],
        isSearching: false
    },
    getters: {
        SEARCH_POSTS(state) {
            return state.searchPosts
        },
        FILTERED_POSTS(state) {
            return state.filteredPosts
        },
        SEARCH_WORD(state) {
            return state.searchWord
        },
        isSearching(state) {
            return state.isSearching
        }
    },
};