import { render, staticRenderFns } from "./achievements.vue?vue&type=template&id=88e743c0&scoped=true&"
import script from "./achievements.vue?vue&type=script&lang=js&"
export * from "./achievements.vue?vue&type=script&lang=js&"
import style0 from "./achievements.vue?vue&type=style&index=0&id=88e743c0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88e743c0",
  null
  
)

export default component.exports