<template>
    <div>
        <!-- <h2 class="line">{{name}}<button class="headline-button" @click="switchListWision()"></button></h2> -->
        <h2 class="line">{{name}}</h2>
        <!-- <transition name="sidebar-animation">
            <div class="sidebar" v-if="isVision">
                <div class="sidebar-background" @click="switchListWision()"></div>
                <div class="sidebar-container">
                    <h3 class="line">{{listName}}</h3>
                    <ul>
                        <li v-for="(item,i) in list" :key="i">
                            <router-link :to="item.link">{{item.name}}</router-link>
                        </li>
                    </ul>
                    <button class="button close" @click="switchListWision()"><span>Закрыть</span></button>
                </div>
            </div>
        </transition> -->
    </div>
</template>
<script>
    export default {
        props: {
            name: null,
            list: null,
            listName: null
        },
        data() {
            return {
                isVision: false
            }
        },
        methods: {
            switchListWision() {
                this.isVision = !this.isVision
                if (this.isVision) {
                    document.querySelector("body").style.overflow = 'hidden'
                } else {
                    document.querySelector("body").style.overflow = ''
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .sidebar {
        position: fixed;
        top: 0;
        left: 0;
        z-index: var(--sidebarIndex);
        width: 100%;
        height: 100%;
    }

    .sidebar-container {
        height: 100%;
        width: 553px;
        background-color: #fff;
        padding: var(--mainPadding);
        position: relative;
        // transform: translateX(-100%);
        transition: .3s transform ease;
        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            background-color: #fff;
            height: 100%;
        }

        li a {
            font-size: 40px;
            line-height: 110%;
            text-transform: uppercase;
            color: var(--color1);
            margin-bottom: 24px;
            display: block;

            &.router-link-exact-active {
                color: var(--color3)
            }
        }
    }

    .sidebar-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000D39;
        opacity: .3;
        z-index: -1;
        transition: .3s background ease;
    }

    .headline-button {
        height: 72px;
        width: 72px;
        background-color: var(--color2);
        border-radius: 10px;
        border: none;
        cursor: pointer;
        margin-left: 20px;
        position: relative;
        display: inline-flex;

        &:before,
        &:after {
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='7' viewBox='0 0 14 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 1L6.63181 0.661721L7.36819 0.661721L7 1ZM11.5938 6L11.5938 6.5L11.3741 6.5L11.2256 6.33828L11.5938 6ZM2.40625 6L2.77444 6.33828L2.62586 6.5L2.40625 6.5L2.40625 6ZM7.36819 0.661721L10.4307 3.99505L9.69431 4.67161L6.63181 1.33828L7.36819 0.661721ZM10.4307 3.99505L11.9619 5.66172L11.2256 6.33828L9.69431 4.67161L10.4307 3.99505ZM11.5937 5.5L14 5.5L14 6.5L11.5938 6.5L11.5937 5.5ZM7.36819 1.33828L4.30569 4.67161L3.56931 3.99505L6.63181 0.661721L7.36819 1.33828ZM4.30569 4.67161L2.77444 6.33828L2.03806 5.66172L3.56931 3.99505L4.30569 4.67161ZM2.40625 6.5L2.18557e-08 6.5L-2.18557e-08 5.5L2.40625 5.5L2.40625 6.5Z' fill='white'/%3E%3C/svg%3E%0A");
            position: absolute;
            width: 14px;
            height: 7px;
            top: 20px;
            left: 29px;
        }

        &:after {
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='7' viewBox='0 0 14 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 6L6.63181 6.33828L7.36819 6.33828L7 6ZM11.5938 1L11.5938 0.5L11.3741 0.5L11.2256 0.661721L11.5938 1ZM2.40625 0.999999L2.77444 0.661721L2.62586 0.5L2.40625 0.499999L2.40625 0.999999ZM7.36819 6.33828L10.4307 3.00495L9.69431 2.32839L6.63181 5.66172L7.36819 6.33828ZM10.4307 3.00495L11.9619 1.33828L11.2256 0.661721L9.69431 2.32839L10.4307 3.00495ZM11.5937 1.5L14 1.5L14 0.5L11.5938 0.5L11.5937 1.5ZM7.36819 5.66172L4.30569 2.32839L3.56931 3.00495L6.63181 6.33828L7.36819 5.66172ZM4.30569 2.32839L2.77444 0.661721L2.03806 1.33828L3.56931 3.00495L4.30569 2.32839ZM2.40625 0.499999L2.18557e-08 0.499999L-2.18557e-08 1.5L2.40625 1.5L2.40625 0.499999Z' fill='white'/%3E%3C/svg%3E%0A");
            top: unset;
            bottom: 20px;
            left: 29px;
        }
    }

    .button.close {
        position: absolute;
        top: 24px;
        right: -20px;
    }
    .sidebar-animation-enter-active, .sidebar-animation-leave-active {
        .sidebar-background {
            opacity: .3;
        }
        transition: .3s;
    }
    .sidebar-animation-enter, .sidebar-animation-leave-to {
        .sidebar-container {
            transform: translateX(-100%)
        }
    }
</style>
