import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function servicesAnimation() {
  const elems = gsap.utils.toArray("h2");
  if (typeof elems !== "object" || !elems.length) {
    return;
  }
  elems.forEach(i => {
    gsap.set(i, { opacity: 0, y: 150 });
    ScrollTrigger.create({
      trigger: i,
      animation: gsap.to(i, {
        opacity: 1,
        y: 0,
        ease: "power3.out",
        duration: 1
      }),
      start: "bottom bottom+=100%"
    });
  });
}
