<template>
  <div class="content-container dflex-cont dflex-cont_column_center">
    <section class="section-container search-intro dflex-child_maxwidth tender-container">
      <hr class="animate-elem wrap"/>
      <h1>
        <span>
          Узнать больше
        </span>
      </h1>
      <vform
        :inputs="inputs"
        :customCss="formCss"
        :checkboxStyle="checkboxStyle"
        buttonType="dark"
        :buttonStyle="buttonStyle"
        isTender="true"
        class="tender"
      />
    </section>
  </div>
</template>

<script>
import vform from "../components/UI/form.vue";
import axios from 'axios'
import eventHub from '../components/eventHub'
import {mapGetters,mapMutations} from 'vuex'

export default {
  metaInfo() {
      return {
          title: `${this.metaNames.title ?? 'Узнать больше'}`,
          meta: this.metaNames.metas
      }
  },
  components: {
    vform,
  },
  created() {
    const time = Date.now()
    eventHub.$emit('updateLoader', true)
    axios
      .get("/api/tender")
      .then((response) => {
        this.addMetaNames(response.data.seo)
      })
      .catch((error) => {
        console.log(error);
        this.$router.push("/404");
      })
      .finally(() => {
        const setTime = Date.now() - time > 1000 ? 0 : 1000 - (Date.now() - time)
        setTimeout(() => {
          eventHub.$emit('updateLoader', false)
          setTimeout(() => {
            this.setLoading(false)
          }, 1000)
        }, setTime)
      });
  },
  data() {
    return {
      messageDelivered: false,
      formCss:
        "flex-direction:row;flex-wrap:wrap;max-width: 1086px;margin-left: auto;",
      checkboxStyle: "flex:1 0 calc(50% - 10px);",
      buttonStyle: "margin-left:auto; margin-top:20px;",
      inputs: [
        {
          type: "text",
          placeholder: "Имя",
          required: true,
          name: "client_name",
          customCss:
            "background-color: #F0F0F0;margin-right:20px;flex:1 0 calc(50% - 10px)",
        },
        {
          type: "text",
          placeholder: "Название компании",
          name: "company-name",
          customCss:
            "background-color: #F0F0F0;margin-right:0px;flex:1 0 calc(50% - 10px)",
        },
        {
          type: "email",
          placeholder: "E-mail",
          required: true,
          name: "email",
          customCss:
            "background-color: #F0F0F0;margin-right:20px;flex:1 0 calc(50% - 10px)",
        },
        {
          type: "tel",
          placeholder: "Телефон",
          name: "phone",
          customCss:
            "background-color: #F0F0F0;margin-right:0px;flex:1 0 calc(50% - 10px)",
        },
        {
          type: "textarea",
          placeholder: "Несколько слов о проекте и задачах",
          required: true,
          name: "description",
          customCss: "background-color: #F0F0F0;flex:1 0 100%",
        },
        {
          type: 'file',
          placeholder: "Прикрепить файл",
          required: false,
          name: "files",
        }
      ],
    };
  },
  methods: {
    ...mapMutations(['addMetaNames', 'setLoading'])
  },
  computed: {
    ...mapGetters(['metaNames'])
  }
};
</script>

<style lang="scss" scoped>
.section-container {
  padding-left: var(--mainPadding);
  padding-right: var(--mainPadding);
  margin: 0 auto;
  position: relative;

  @include largePhonesAlt{
    padding-left: var(--mainPaddingSmallSize);
    padding-right: var(--mainPaddingSmallSize);
  }
}

.content-container {
  flex: 1;

  hr {
    border-color: var(--color1);
  }

  h2 {
    margin-bottom: 80px;
  }

  h1 {
    font-size: var(--h2-size);
    line-height: 110%;
    color: var(--color1);
    margin: 0;
    margin-bottom: 80px;
    text-transform: uppercase;
    overflow: hidden;

    span {
      display: block;
    }

    @include laptop {
      font-size:80px;
    }

    @include smallLaptop {
      font-size:36px;
    }

    @include largePhonesAlt{
      font-size:32px;
      margin-bottom: 24px;
    }
  }
}

.contact-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1087px;
  margin-left: auto;
}

.agree-term {
  display: flex;
  align-items: center;
  flex: 1 0 calc(50% - 10px);
  margin-bottom: 40px;
}

.submit-btn {
  margin-left: auto;
}

// Сообщение отправлено
.message-delivered {
  .contact-wrapper {
    flex-direction: column;

    h3 {
      font-weight: 400;
      font-size: 40px;
      margin-bottom: 60px;
    }

    p {
      color: var(--color1);
      font-size: 24px;
      margin-bottom: 30px;
      margin-left: 260px;
      //max-width: 530px;
    }

    label {
      margin-left: 260px;
    }
  }
}

@media screen and (min-width: 640px) and (max-width: 980px) {
  .content-container {
    margin-left: 0px;
    max-width: 100%;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .content-container {
    margin-left: 0px;
    max-width: 100%;
  }

  .content-container h1 {
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 24px;
  }

  .input_container {
    flex: 1 0 100% !important;
  }

  .custom-file-upload {
    margin-bottom: 20px;
  }

  // Сообщение отправлено
  .message-delivered {
    .contact-wrapper {
      flex-direction: column;

      h3 {
        font-weight: 400;
        font-size: 24px;
        margin-bottom: 20px;
      }

      p {
        color: var(--color1);
        font-size: 16px;
        margin-bottom: 20px;
        margin-left: 0px;
        max-width: 100%;
      }

      label {
        margin-left: 0px;
      }
    }
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .form .input_container{
    background-color: black;
  }
}
</style>

<style lang="scss">
  .tender-container {
    & > form.form {
      .input_container {
        @include largePhonesAlt {
          margin-right: 0 !important;
          margin-bottom: 10px;
        }
      }
      .textarea_container {
        height: 70px;
        textarea {
          height: 100%;
          @include largePhonesAlt{
            height: 120px;
          }
        }
        @include largePhonesAlt{
          max-height: 120px;
          height: 120px;
        }
      }
      .upload-wrapper {
        margin-right: auto;
      }
      & > div:not(.upload-wrapper) {
        margin-left: auto;
        margin-top: 40px;
        @include largePhonesAlt {
          margin-top: 20px;
        }
      }
      @include largePhonesAlt {
        flex-direction: column !important;
      }
    }
  }
</style>

<style lang="scss">

  .message-delivered.del-tender {
    .contact-wrapper {
      h3 {
        @include largePhonesAlt {
          font-size: 26px;
          margin-bottom: 24px;
        }
      }

       p {
        margin-left: 280px;
        max-width: 530px;

        @include largePhonesAlt {
          margin-left: 0;
          margin-bottom: 24px;
          margin-top: 0;
          max-width: 530px;
          font-size: 18px;
        }
      }

      div {
        button {
          margin-left: 280px;
          @include largePhonesAlt {
            margin-left: 0;
          }
        }
      }
    }
  }
</style>