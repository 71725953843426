<template>
    <div class="dflex-cont dflex-cont_column_center">
        <section-switcher-alt :currPath="currPath" />
        <transition name="animation" @leave="appOnLeave" @enter="appOnEnter" @after-enter="appOnAfterEnter" @before-leave="appOnBeforeLeave">
            <router-view></router-view>
        </transition>
    </div>
</template>

<script>
import SectionSwitcherAlt from '../../components/pages/about/sectionSwitcherAlt.vue'
import {gsap} from 'gsap'
import eventHub from '../../components/eventHub'
import axios from "axios";
import {mapMutations} from "vuex";
export default {
    components: {
        SectionSwitcherAlt,
    },
    data() {
        return {
            currPath: false
        }
    },
  mounted() {
    const time = Date.now()
    eventHub.$emit('updateLoader', true)
    axios.get('/api/aboutus/we')
      .then(() => {
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        const setTime = Date.now() - time > 1000 ? 0 : 1000 - (Date.now() - time)
        setTimeout(() => {
          eventHub.$emit('updateLoader', false)
          setTimeout(() => {
            this.setLoading(false)
          }, 1000)
        }, setTime)
      })
  },
  methods: {
        appOnAfterEnter() {
            eventHub.$emit('aboutOnAfterEnter')
        },
        appOnBeforeLeave() {
            eventHub.$emit('aboutOnBeforeLeave')
        },
        appOnLeave(el, done) {
            eventHub.$emit('aboutOnLeave')
            this.currPath = this.$router.currentRoute.path.startsWith('/news/')
            gsap.to(el, {opacity: 0, duration: 1, onComplete: () => {
                done()
            }})
        },
        appOnEnter(el, done) {
            gsap.set(el, {opacity: 0, duration: 0})
            gsap.to(el, {opacity: 1, duration: 1, delay: 1, onComplete: () => {
                done()
            }})
        },
        ...mapMutations(['setLoading'])
    }
}
</script>

<style lang="scss">
.dflex-child_maxwidth > section.cropped {
    &:first-child {
        overflow: hidden;
        margin-bottom: 120px;
        padding-bottom: 0;
        .headline {
            margin-bottom: 0;
        }
    }
    @include largePhonesAlt {
        padding-left: 10px;
        padding-right: 10px;
    }
}
</style>