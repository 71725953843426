<template>
    <section>
        <hr class="animate-elem shiftLeft wrap"/>
        <h2 class="line animate-elem shiftUp wrap">{{value.name}}</h2>
            <template v-if="value.items">
                <ul class="value-list" >
                    <li class="value-item animate-elem fadeInUp" v-for="(item,i) in value.items" :key="i">
                        <!-- <span class="animate-elem fadeInUp"> -->
                            <span class="value-name">{{item.name}}</span>
                            <p class="value-description" v-for="(obj,i) of item.description" :key="i" v-html="obj"></p>
                            <p class="value-description" v-if="item.text" v-html="item.text" :key="i"></p>
                        <!-- </span> -->
                    </li>
                </ul>
            </template>
        <div class="description" v-if="!value.items">
            <ul class="value-list">
                <li class="value-item animate-elem fadeInUp">
                    <span class="value-name" v-html="value.description">
                    </span>
                    <p class="value-description" v-html="value.answer">
                    </p>
                </li>
            </ul>
        </div>
    </section>
</template>
<script>
    import { mapGetters } from 'vuex'

    export default {
        props: {
            value: null
        },
        computed: {
            ...mapGetters(['widthPage']),
            // haveDescription: function () {
            //     if (this.value.items) {
            //         for (let item of this.value.items) {
            //             if (item.description) {
            //                 return true
            //             }
            //         }
            //     }
            //     return false
            // }
        },
        mounted() {
            // FIXME Throws a console error
            if(this.value) {
                const regExp = new RegExp('(<a.+)(href=")(?!' + window.location.origin + ')', 'gm')
                if(this.value.items && Array.isArray(this.value.items)) {
                    this.value.items = this.value.items.map((item) => {
                        if(item && item.description && Array.isArray(item.description)) {
                            item.description = item.description.map((obj) => {
                                // eslint-disable-next-line
                                return obj.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
                            })
                        }

                        if(item && item.html) {
                            // eslint-disable-next-line
                            item.html = item.html.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
                        }

                        return item
                    })
                }

                if(!this.value.items) {
                    this.value.description = this.value.description.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
                    this.value.answer = this.value.answer.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    section {
        margin-top: 150px;
    }

    hr {
        border-top: 1px solid var(--color1);
        margin: 0;
    }

    .value-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        padding-top: 90px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        column-gap: 30px;
        row-gap: 60px;

        &.column {
            flex-direction: column;
        }
        @include largePhonesAlt {
            flex-direction: column;
            padding: 0;
            margin-top: 24px;
            row-gap: 37px;
        }
    }

    .value-item {
        overflow: hidden;
        width: calc(50% - 15px);

        @include largePhonesAlt {
            width: 100%;
        }
    }

    .value-name {
        position: relative;
        z-index: 0;

        // margin-right: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        max-width: 461px;
        min-height: 85px;

        font-size: 24px;
        line-height: 130%;
        color: var(--color1);
        text-transform: uppercase;

        &::before {
            content: '';
            position: absolute;
            width: 103px;
            height: 110px;
            top: 50%;
            transform: translateY(-50%);
            left: -20px;
            z-index: -1;
            background-position: center;
            background-size: 100%;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='103' height='106' viewBox='0 0 103 106' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M102.6 45.4L0 0V19.6L79.8 53.2L0 86.2V105.6L102.6 61V45.4Z' fill='%23FFD200'/%3E%3C/svg%3E%0A");
        }

        @include largePhonesAlt {
            flex-direction: column;
            align-items: flex-start;

            min-height: unset;

            overflow: visible;

            font-size: 14px;
            margin-bottom: 16px;
            &::before {
                height: 40px;
                width: 40px;
            }
        }
    }

    .value-description {
        width: 100%;
        margin: 0;
        font-size: 18px;
        line-height: 160%;
        color: var(--color1);
    }
</style>
