<template>
  <label
    :class="['textarea_container', ...classes]"
    v-if="type === 'textarea'"
    v-bind:style="customCss"
  >
    <textarea :required="required" :name="name" placeholder=" "> </textarea>
    <span class="placeholder">{{ placeholder }}</span>
  </label>

  <div v-else-if="type === 'file'" :class="['upload-wrapper', ...classes]">
    <label class="custom-file-upload">
      <input type="file" @change="processFile($event)" multiple />
      <span class="custom-file-upload__icon"></span>
      <span class="custom-file-upload__text">Прикрепить файл</span>
    </label>
    <ul class="file-list">
      <li v-for="(file, index) in files" :key="index">
        <span>{{ file.name }}</span>
        <span @click="removeFile(index)" class="file-remove"></span>
      </li>
    </ul>
  </div>

  <label v-else-if="type === 'tel'" :class="['input_container', ...classes]" v-bind:style="customCss">
    <input :type="type" :required="required" :name="name" placeholder=" " v-mask="'+7 (###) ###-##-##'" v-model="inputvalue"/>
    <span class="placeholder">{{ placeholder }}</span>
  </label>

  <label v-else :class="['input_container', ...classes]" v-bind:style="customCss">
    <input :type="type" :required="required" :name="name" placeholder=" " />
    <span class="placeholder">{{ placeholder }}</span>
  </label>
</template>

<script>

export default {
  props: {
    type: null,
    placeholder: null,
    required: null,
    name: null,
    value: null,
    customCss: null,
    files: Array,
    animation: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      inputvalue: ""
    }
  },
  computed: {
    classes() {
      return this.animation ? ['animate-elem', 'fadeInUp'] : []
    }
  },
  methods: {
    processFile(e) {
      this.$emit('processFile', e);
    },
    removeFile(index) {
      this.$emit('removeFile', index);
    },
  },
};
</script>

<style lang="scss" scoped>
.textarea_container,
.input_container {
  background-color: #fff;
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  textarea,
  input {
    border: none;
    font-size: 14px;
    line-height: 120%;
    color: inherit;
    padding-top: 26px;
    padding-bottom: 26px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    box-sizing: border-box;
    background: none;
    &:required {
      &+.placeholder {
        &::after {
          content: ' *'
        }
      }
    }
    &:not(:placeholder-shown) {
      & ~ .placeholder {
        top: 10px;
        font-size: 10px;
        @include largePhonesAlt {
          top: 5px;
          left: 20px;
        }
      }
    }
    @include largePhonesAlt {
      padding: 17px 20px;
    }
  }
  .placeholder {
    position: absolute;
    top: 26px;
    left: 20px;
    font-size: 14px;
    line-height: 120%;
    color: inherit;
    transition: 0.3s all ease;
    @include largePhonesAlt {
      top: 20px;
      left: 16px;
    }
  }
  & + .input_container {
    // margin-left: 20px;
  }
}

.input_container {
  @include largePhonesAlt {
    height: 50px;
  }
}

.textarea_container {
  max-height: 70px;
  width: 100%;

  textarea {
    resize: none;
    color: inherit;
    height: 100%;
  }
}

// Input type=file
.upload-wrapper {
  flex: 1 0 calc(50% - 10px);
  margin-bottom: 15px;
  margin-right: 20px;
  display: flex;
  align-items: flex-start;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
  flex-shrink: 0;

  &:hover {
    .custom-file-upload__text {
      color: var(--color3);
    }

    .custom-file-upload__icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='16' viewBox='0 0 18 16' fill='none'%3E%3Cpath d='M5.10196 15.8091C3.73871 15.8091 2.45737 15.2786 1.49392 14.3151C0.530479 13.3517 0 12.0704 0 10.7071C0 9.34385 0.530479 8.0625 1.49392 7.09906L6.82602 1.76696L7.65294 2.59389L2.32085 7.92403C1.57779 8.66709 1.16822 9.65394 1.16822 10.7051C1.16822 11.7564 1.57779 12.7432 2.32085 13.4863C3.85378 15.0192 6.35015 15.0192 7.88308 13.4863L15.3995 5.96984C16.4956 4.87378 16.4956 3.08926 15.3995 1.9932C14.3034 0.897134 12.5189 0.897134 11.4229 1.9932L3.90643 9.50962C3.58659 9.82947 3.41106 10.2527 3.41106 10.7051C3.41106 11.1576 3.58659 11.5808 3.90643 11.9007C4.22628 12.2205 4.64949 12.3961 5.10196 12.3961C5.55443 12.3961 5.97764 12.2205 6.29749 11.9007L11.6296 6.56858L12.4565 7.3955L7.12636 12.7276C6.58613 13.2678 5.86843 13.5662 5.10391 13.5662C4.3394 13.5662 3.62169 13.2678 3.08146 12.7276C2.54123 12.1874 2.24284 11.4697 2.24284 10.7051C2.24284 9.94063 2.54123 9.22293 3.08146 8.6827L10.5979 1.16627C11.3507 0.413462 12.3492 0 13.4141 0C14.479 0 15.4775 0.413462 16.2303 1.16627C16.9831 1.91909 17.3966 2.91764 17.3966 3.98249C17.3966 5.0454 16.9831 6.0459 16.2303 6.79871L8.71195 14.3132C7.74656 15.2786 6.46522 15.8091 5.10196 15.8091Z' fill='%23E62315'/%3E%3C/svg%3E");
    }
  }
}

.custom-file-upload__icon {
  width: 20px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='16' viewBox='0 0 18 16' fill='none'%3E%3Cpath d='M5.10196 15.8091C3.73871 15.8091 2.45737 15.2786 1.49392 14.3151C0.530479 13.3517 0 12.0704 0 10.7071C0 9.34385 0.530479 8.0625 1.49392 7.09906L6.82602 1.76696L7.65294 2.59389L2.32085 7.92403C1.57779 8.66709 1.16822 9.65394 1.16822 10.7051C1.16822 11.7564 1.57779 12.7432 2.32085 13.4863C3.85378 15.0192 6.35015 15.0192 7.88308 13.4863L15.3995 5.96984C16.4956 4.87378 16.4956 3.08926 15.3995 1.9932C14.3034 0.897134 12.5189 0.897134 11.4229 1.9932L3.90643 9.50962C3.58659 9.82947 3.41106 10.2527 3.41106 10.7051C3.41106 11.1576 3.58659 11.5808 3.90643 11.9007C4.22628 12.2205 4.64949 12.3961 5.10196 12.3961C5.55443 12.3961 5.97764 12.2205 6.29749 11.9007L11.6296 6.56858L12.4565 7.3955L7.12636 12.7276C6.58613 13.2678 5.86843 13.5662 5.10391 13.5662C4.3394 13.5662 3.62169 13.2678 3.08146 12.7276C2.54123 12.1874 2.24284 11.4697 2.24284 10.7051C2.24284 9.94063 2.54123 9.22293 3.08146 8.6827L10.5979 1.16627C11.3507 0.413462 12.3492 0 13.4141 0C14.479 0 15.4775 0.413462 16.2303 1.16627C16.9831 1.91909 17.3966 2.91764 17.3966 3.98249C17.3966 5.0454 16.9831 6.0459 16.2303 6.79871L8.71195 14.3132C7.74656 15.2786 6.46522 15.8091 5.10196 15.8091Z' fill='%23001D82'/%3E%3C/svg%3E");
}

.custom-file-upload__text {
  margin-left: 10px;
  font-size: 14px;
  color: var(--color1);
}

.file-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: inline-flex;
  flex-wrap: wrap;
  color: var(--color1);
  font-size: 14px;
  margin-top: 7px;

  li {
    margin-right: 20px;
  }

  li:not(:last-child) {
    margin-bottom: 5px;
  }

  .file-remove {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 5px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='13' viewBox='0 0 13 13' fill='none'%3E%3Cline x1='9.54594' y1='3.18168' x2='3.18198' y2='9.54564' stroke='%23001D82'/%3E%3Cline x1='5.30318' y1='5.30375' x2='3.18186' y2='3.18243' stroke='%23001D82'/%3E%3Cline x1='9.54586' y1='9.54594' x2='7.42454' y2='7.42462' stroke='%23001D82'/%3E%3C/svg%3E");
  }
}

@media screen and (max-width: $small) {
  .input_container,
  .upload-wrapper {
    // flex:1 0 100% !important;
  }
}

// input end
</style>
