<template>
  <section class="section dflex-cont dflex-cont_row_center">
    <div class="dflex_inside dflex-child_maxwidth">
      <ScrollContainer ref="ScrollContainer"  data-position="sticky" />
      <div class="content-container" data-position-end="sticky">
        <template class="section-container" v-for="(item, key) in product">
          <!-- <HeadlineMenu v-if="key=='client_name' && product.name" :name="product.name" :list="productsList"
                      listName="Продукты" /> -->
          <Advantage
            v-if="item.type == 'advantage' && item"
            :value="item"
            :id="key"
            :key="key"
          />
          <Value
            v-else-if="item.type == 'value' && item"
            :value="item"
            :id="key"
            :key="key"
          />
          <Value-solution
            v-else-if="item.type == 'valueSolution' && item"
            :value="item"
            :id="key"
            :key="key"
          />
          <For-solutions
            v-else-if="item.type == 'forSolutions' && item"
            :value="item"
            :id="key"
            :key="key"
          />
          <Faq
            v-else-if="item.type == 'faq' && item"
            :value="item"
            :id="key"
            :key="key"
          />
          <Slider
            v-else-if="item.type == 'slider' && item"
            :value="item"
            :id="key"
            :key="key"
          />
          <Cases
            v-else-if="item.type == 'cases' && item"
            :cases="item"
            :id="key"
            :key="key"
          />
        </template>
        <Form :form="form" id="form" />
      </div>
    </div>
  </section>
</template>
<script>
// import HeadlineMenu from '../components/UI/headlineMenu.vue'
import Advantage from "../components/UI/advantage.vue";
import Value from "../components/pages/products/value.vue";
import ValueSolution from "../components/pages/products/valueSolution.vue";
import ForSolutions from "../components/pages/products/forSolutions.vue";
import Faq from "../components/pages/products/faq.vue";
import Slider from "../components/pages/products/slider.vue";
import Cases from "../components/UI/caseScroll.vue";
import Form from "../components/pages/products/form.vue";
import axios from "axios";
import ScrollContainer from "../components/UI/scrollContainer.vue";
import eventHub from '../components/eventHub'
// import AnimatedButton from '../components/UI/AB.vue'
import {mapGetters,mapMutations} from 'vuex'

export default {
  metaInfo() {
      return {
          title: `Продукты: ${this.metaNames.title ?? ''}`,
          meta: this.metaNames.metas
      }
  },
  components: {
    Advantage,
    Value,
    ValueSolution,
    ForSolutions,
    Faq,
    Slider,
    Cases,
    Form,
    ScrollContainer,
  },
  data() {
    return {
      scroll: 0,
      elements: "",
      product: {
        form: "",
      },
      form: {
        name: "Задать вопрос по продукту или запросить демо",
        type: "form",
        value: [
          {
            type: "text",
            placeholder: "Имя",
            required: true,
            name: "client_name",
            value: "",
            customCss: 'background: rgba(255,255,255,.05);color:#fff'
          },
          {
            type: "tel",
            placeholder: "Телефон",
            name: "phone",
            customCss: 'background: rgba(255,255,255,.05);color:#fff'
          },
          {
            type: "email",
            placeholder: "E-mail",
            required: true,
            name: "email",
            value: "",
            customCss: 'background: rgba(255,255,255,.05);color:#fff'
          },
          {
            type: "textarea",
            placeholder: "Комментарий",
            required: true,
            name: "description",
            customCss: 'background: rgba(255,255,255,.05);color:#fff'
          },
        ],
      },
      productsList: [
        {
          name: "Битрикс24",
          link: "/products/product-template",
        },
        {
          name: "UNA Company",
          link: "/products/product-template",
        },
        {
          name: "UNA Contact",
          link: "/products/product-template",
        },
        {
          name: "Jira Software",
          link: "/products/product-template",
        },
        {
          name: "Confluence",
          link: "/products/product-template",
        },
        {
          name: "Jira service management",
          link: "/products/product-template",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["widthPage",'metaNames'])
  },
  methods: {
    ...mapMutations(['addMetaNames', 'setLoading']),
    fetchData() {
      const time = Date.now()
      eventHub.$emit('updateLoader', true)
      axios
        .get("/api" + this.$route.path)
        .then((response) => {
          this.addMetaNames(response.data.seo)
          this.product = response.data;
          this.product["form"] = this.form;
          setTimeout(() => {
            this.$store.commit("addProduct", response.data);
            eventHub.$emit('solutionsDone', true)
          }, 1);
        })
        .catch((error) => {
          console.log(error);
          this.$router.push("/404");
        })
        .finally(() => {
          const setTime = Date.now() - time > 1000 ? 0 : 1000 - (Date.now() - time)
          setTimeout(() => {
            eventHub.$emit('updateLoader', false)
            setTimeout(() => {
              this.setLoading(false)
            }, 1000)
          }, setTime)
        });
    }
  },
  mounted() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
};
</script>
<style lang="scss" scoped>
// $scroll-width: 237px;

.section {
  padding: 20px;
  padding-top: 40px;
  max-width: 1680px;
  margin: auto;
  display: flex;
  box-sizing: border-box;
  @include smallDesktop {
    margin-right: 0;
  }

  @include smallLaptop {
    padding: 0 20px;
    margin: 0;
  }

  @include largePhonesAlt {
    padding: 0 10px;
  }
}

section {
  margin-top: 160px;
  @include laptop {
    margin-top: 128px;
  }
  @include phones {
    margin-top: 48px;
  }
}


.content-container {
  flex: 1;
  max-width: 100%;
  padding-left: 40px;
  section:first-child {
    margin-top: 0;
  }
  @include smallLaptop {
    padding-left: 0;
    box-sizing: border-box;
  }
}

.headline-button {
  height: 72px;
  width: 72px;
  background-color: var(--color2);
  border-radius: 10px;
  border: none;
  cursor: pointer;
  margin-left: 20px;
  position: relative;
  display: inline-flex;

  &:before,
  &:after {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='7' viewBox='0 0 14 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 1L6.63181 0.661721L7.36819 0.661721L7 1ZM11.5938 6L11.5938 6.5L11.3741 6.5L11.2256 6.33828L11.5938 6ZM2.40625 6L2.77444 6.33828L2.62586 6.5L2.40625 6.5L2.40625 6ZM7.36819 0.661721L10.4307 3.99505L9.69431 4.67161L6.63181 1.33828L7.36819 0.661721ZM10.4307 3.99505L11.9619 5.66172L11.2256 6.33828L9.69431 4.67161L10.4307 3.99505ZM11.5937 5.5L14 5.5L14 6.5L11.5938 6.5L11.5937 5.5ZM7.36819 1.33828L4.30569 4.67161L3.56931 3.99505L6.63181 0.661721L7.36819 1.33828ZM4.30569 4.67161L2.77444 6.33828L2.03806 5.66172L3.56931 3.99505L4.30569 4.67161ZM2.40625 6.5L2.18557e-08 6.5L-2.18557e-08 5.5L2.40625 5.5L2.40625 6.5Z' fill='white'/%3E%3C/svg%3E%0A");
    position: absolute;
    width: 14px;
    height: 7px;
    top: 20px;
    left: 29px;
  }

  &:after {
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='7' viewBox='0 0 14 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 6L6.63181 6.33828L7.36819 6.33828L7 6ZM11.5938 1L11.5938 0.5L11.3741 0.5L11.2256 0.661721L11.5938 1ZM2.40625 0.999999L2.77444 0.661721L2.62586 0.5L2.40625 0.499999L2.40625 0.999999ZM7.36819 6.33828L10.4307 3.00495L9.69431 2.32839L6.63181 5.66172L7.36819 6.33828ZM10.4307 3.00495L11.9619 1.33828L11.2256 0.661721L9.69431 2.32839L10.4307 3.00495ZM11.5937 1.5L14 1.5L14 0.5L11.5938 0.5L11.5937 1.5ZM7.36819 5.66172L4.30569 2.32839L3.56931 3.00495L6.63181 6.33828L7.36819 5.66172ZM4.30569 2.32839L2.77444 0.661721L2.03806 1.33828L3.56931 3.00495L4.30569 2.32839ZM2.40625 0.499999L2.18557e-08 0.499999L-2.18557e-08 1.5L2.40625 1.5L2.40625 0.499999Z' fill='white'/%3E%3C/svg%3E%0A");
    top: unset;
    bottom: 20px;
    left: 29px;
  }
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  a {
    max-width: 100%;
    // min-width: 100%;
    margin-bottom: 5px;
    position: relative;
    overflow: hidden;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100%);
      white-space: nowrap;
      width: 100%;
    }

    .scroll {
      position: absolute;
      top: 0;
      left: 0;
      background-color: var(--color4);
      height: 100%;
      z-index: -1;
    }
  }
}
.dflex_inside {
  display: flex;
  @include mediumLaptopAlt {
    flex-direction: column;
    height: fit-content;
    position: relative;
    width: 100%;
  }
}
</style>