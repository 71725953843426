<template>
  <section class="cropped dflex-cont dflex-cont_column_center" :class="{'serviceItemIsUp': activeService.isUp}">
    <div class="section__wrapper dflex-child_maxwidth" v-if="widthPage >= 1200">
      <transition @enter="enterDesctop" @leave="leaveDesctop" :css="false" mode="out-in">
          <div key="KOne" v-if="!activeService.isUp" class="section__wrapper__directions anim3" ref="directions">
            <h1>{{services.name}}</h1>
            <ul ref="directionsMenu">
              <li v-for="(item, index) in services.serviceList" :key="item.name" :class="{'section-li-active': clickedDirection === index}" @click.prevent="showRightMenu($event, index)" @mouseenter="arrowAdapt($event)" @mouseleave="arrowLeft($event)">
                <hr>
                <span>{{index + 1}}</span>
                <h2>{{item.name}}</h2>
              </li>
            </ul>
          </div>
          <div key="KTwo" v-else class="section__wrapper__newpage" ref="serviceTmpl">
            <ServiceTemplate :value="serviceTmplValue" />
          </div>
      </transition>
      <div class="section__wrapper__arrow" :class='{"section__wrapper__arrow_blue": !activeService.isUp}'>
        <div class="section__wrapper__arrow__container" :class='{"arrow__container__sticky": activeService.isUp}' ref="arrow">
          <div class="arrow__container_top" ref="arrowTop"></div>
          <div class="arrow__container_middle" ref="arrowMiddle"></div>
          <div class="arrow__container_bottom" ref="arrowBottom">
            <div class="arrow__container_bottom__icon"></div>
          </div>
        </div>
      </div>
      <div class="section__wrapper__menu">
        <div class="section__wrapper__menu__container" :class='{"menu__container__sticky": activeService.isUp}'>
          <ul ref="menuList">
            <transition @enter="backButtonEnter" @leave="backButtonLeave" :css="false" mode="out-in">
              <AnimatedButton v-if="activeService.backButton" @click.native="backButtonEvent()" :classes="['buttons-group__tag-filter']" :settings="{anchor: true, class: 'w2y_right', text: `${this.isFromSearch ? 'Вернуться к списку всех услуг' : 'вернуться'}`, icon: true}" />
            </transition>
            <!-- <li v-if="clickedDirection != -1" @click.prevent="activeService.isUp = false">back</li> -->
            <li v-for="(item, index) in menu" :key="item.name" class="submenu-item" :class="{zxcxzc: linkCurrent === index}" @click.prevent="loadPage($event, item.link, index)">
              <h3 :data-redirect-to="item.link">{{item.name}}</h3>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-else class="section__wrapper">
      <transition :css="false" mode="out-in" @enter="enterAdp" @leave="leaveAdp">
        <div key="AKOne" v-if="!activeService.isUp" class="AKOne">
          <h1>{{services.name}}</h1>
          <ul class="subtitle-list">
            <li v-for="(item, index) in services.serviceList" :key="item.name" :class="['external-list-item', {'external-list-item-active': currentLi === index}]">
              <div class="external-list-item__head" @click="toggleListElement($event, index)">
                <h2 class="external-list-item__text">{{item.name}}</h2>
                <div class="external-list-item__icon"></div>
              </div>
              <transition-group @enter="adaptiveListEnter" @leave="adaptiveListLeave" class="internal-list" name="list" tag="ul">
                <template v-if="currentLi === index">
                  <li class="internal-list-item" v-for="(item, index) in item.value" :key="`insideLi_${index}`" @click.prevent="loadPageAdaptive($event, item.link)">
                    <h3>{{item.name}}</h3>
                  </li>
                </template>
              </transition-group>
            </li>
          </ul>
        </div>
        <div key="AKTwo" v-else class="AKTwo">
          <ServiceTemplate :value="serviceTmplValue" />
        </div>
      </transition>
    </div>
    <CasesNew v-if="activeService.isUp" :cases="cases" class="section" />
  </section>
</template>
<script>
  import { gsap } from "gsap"
  import axios from 'axios'
  import {mapGetters,mapMutations} from 'vuex'
  import ServiceTemplate from './serviceTemplate.vue'
  import eventHub from '../components/eventHub'
  import AnimatedButton from '../components/UI/AB.vue'
  import CasesNew from '../components/pages/index/casesNew.vue'
  export default {
    metaInfo() {
      return {
        title: `${this.metaNames.title ?? 'Услуги'}`,
        meta: this.metaNames.metas
      }
    },
    components: {
      ServiceTemplate,
      AnimatedButton,
      CasesNew
    },
    data() {
      return {
        linkCurrent: -1,
        clickedDirection: -1,
        currentLi: null,
        cases: {
          name: 'Кейсы',
          type: 'cases',
          description: 'Реализуем комплексные внедрения для государственного и бизнес сектора',
          link: "/cases",
          index: "true",
          value: []
        },
        serviceTmplValue: {},
        ifServiceIsUp: false,
        activeService: {
          isLoaded: false,
          isUp: false,
          backButton: false,
          onSameDirection: false
        },
        services: {},
        menu: [],
        qqe: -1,
        arrowH: 0,
        ulTop: 0,
        arrowAdjusting: null,
        isFromSearch: Boolean(this.$route.query.link)
      }
    },
    created() {
      this.$watch(
        () => this.$route.params,
        () => {
            this.fetchData()
        },
        // fetch the data when the view is created and the data is
        // already being observed
        { immediate: true }
      )

      axios.get('/api/cases_main')
        .then((response) => {
          this.cases.value = response.data.cases
        })
        .catch((error) => {
            console.log(error)
        })

      window.addEventListener("resize", this.updateHeaderBG);
    },
    computed: {
      ...mapGetters(['widthPage', 'metaNames'])
    },
    mounted() {
      eventHub.$emit('serviceBackgroundUpdate', {type: 'slide', nmber: 0})
      eventHub.$on('goBackToServices', data => {
        this.activeService.isUp = data
      })
      this.tl = gsap.timeline({duration: 1, delay: 0})
      this.arrowTl = gsap.timeline()
      this.loadIn = gsap.timeline()
      this.loadOut = gsap.timeline()
      this.toggleServiceContent = gsap.timeline()
      this.arrowAdjusting = gsap.timeline()

      if(this.isFromSearch) {
        this.activeService.isUp = true;
        this.activeService.backButton = true;
        axios.get('/api/' + this.$route.query.link)
          .then((response) => {
            this.serviceTmplValue = response.data
            // this.toggleServiceContent.resume()
            // this.loadIn.timeScale(1)
          })
          .catch((error) => {
            console.log(error)
            this.$router.push('/404')
          });
      }
    },
    methods: {
      ...mapMutations(['addMetaNames', 'setLoading']),
      fetchData() {
        const time = Date.now()
        eventHub.$emit('updateLoader', true)
        axios.get('/api/services')
          .then((response) => {
            this.addMetaNames(response.data.seo)
            this.services = response.data.services
            setTimeout(() => {
              if (document.querySelector('.external-list-item__head')) {
                gsap.set('.external-list-item__head', {background: 'linear-gradient(94.5deg, #E62315 22.96%, #FFC52F 100%)', duration: 0})
              }
            }, 0);
          })
          .catch((error) => {
            console.log(error)
            this.$router.push('/404')
          })
          .finally(() => {
            const setTime = Date.now() - time > 1000 ? 0 : 1000 - (Date.now() - time)
            setTimeout(() => {
              eventHub.$emit('updateLoader', false)
              setTimeout(() => {
                this.setLoading(false)
              }, 1000)
            }, setTime)
          });
      },
      updateHeaderBG() {
        if (this.$refs.serviceTmpl) {
          eventHub.$emit('serviceBackgroundUpdate', {type: 'slide', ele: this.$refs.serviceTmpl.getBoundingClientRect().x, nmber: Math.round(this.$refs.serviceTmpl.getBoundingClientRect().width)})
        }
      },
      arrowLeft() {
      },
      arrowAdapt() {
        this.tl123 = gsap.timeline()
      },
      showRightMenu(event, index) {
        if (index === this.clickedDirection) {
          return
        }
        this.arrowAdjusting.clear()
        this.ulTop = 103
        this.tl.clear()
        this.arrowTl.clear()
        this.arrowTl.to(this.$refs.arrowBottom, {width: 0, height: 0, opacity: 0, duration: 0})
        this.arrowTl.to(this.$refs.arrowMiddle, {height: 0, opacity: 0, duration: 0})
        this.arrowTl.to(this.$refs.arrowTop, {width: 0, height: 0, opacity: 0, duration: 0})
        this.clickedDirection = index
        if (this.menu.length <= 0) {
          this.menu = this.services.serviceList[index].value
          this.tl.fromTo('.section__wrapper__menu  ul', {y: 100, opacity: 0, duration: 0}, {y: 0, opacity: 1, duration: .5, onComplete: () => {
            let q = gsap.utils.selector(this.$refs.menuList);
            gsap.set(q('li'), {background: 'linear-gradient(to right, var(--color4) 0%, var(--color4) 0%, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 100%)', duration: 0})
          }})
        } else {
          this.tl.fromTo('.section__wrapper__menu  ul', {y: 0, opacity: 1, duration: 0}, {y: -100, opacity: 0, duration: .5, onComplete: () => {
            this.menu = this.services.serviceList[index].value
          }})
          this.tl.fromTo('.section__wrapper__menu  ul', {y: 100, opacity: 0, duration: 0}, {y: 0, opacity: 1, duration: .5, onComplete: () => {
            let q = gsap.utils.selector(this.$refs.menuList);
            gsap.set(q('li'), {background: 'linear-gradient(to right, var(--color4) 0%, var(--color4) 0%, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 100%)', duration: 0})
          }})
        }
        this.drawArrow(event.target)
      },
      headerBackgroundUpd(self) {
        if (self.classList.contains('section__wrapper__newpage')) {
          eventHub.$emit('serviceBackgroundUpdate', {type: 'slide', ele: self.getBoundingClientRect().x, nmber: Math.round(self.getBoundingClientRect().x + self.getBoundingClientRect().width)})
        }
      },
      loadPage(event, link, index) {
        if (event.target === this.evt) {
          return
        }
        eventHub.$emit('chooseService')
        this.arrowAdjusting.clear()
        this.linkCurrent = index
        this.evt = event.target
        // useless shit
        // this.activeService.onSameDirection = true
        this.loadOut = gsap.timeline()
        let menuListSelectors = gsap.utils.selector(this.$refs.menuList);
        const menuListSelectorsList = menuListSelectors('li').filter(i => {
          return i != event.target.closest('li')
        })
        this.loadIn.clear()
        this.loadOut.to(menuListSelectorsList, {background: 'linear-gradient(to right, var(--color4) 0%, var(--color4) 0%, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 100%)', duration: .3})
        this.loadIn.to(event.target.closest('li'), {background: 'linear-gradient(to right, var(--color4) 0%, var(--color4) 100%, rgba(0,0,0,0) 100%, rgba(0,0,0,0) 100%)', duration: .5})
        this.loadIn.timeScale(.3)
        if (this.activeService.isUp) {
          this.toggleServiceContent.isActive() && this.toggleServiceContent.clear()
          this.toggleServiceContent
            .to(this.$refs.serviceTmpl, {xPercent: -110, duration: .5, onUpdate: this.headerBackgroundUpd, onUpdateParams: [this.$refs.serviceTmpl], onComplete: () => {
              this.toggleServiceContent.pause()
              this.loadIn.timeScale(.15)
              axios.get('/api' + link)
                .then((response) => {
                  this.serviceTmplValue = response.data
                  this.toggleServiceContent.resume()
                  this.loadIn.timeScale(1)
                })
                .catch((error) => {
                    console.log(error)
                    this.$router.push('/404')
                });
            }})
            .to(this.$refs.serviceTmpl, {xPercent: 0, ease: "power1", duration: .5, onUpdate: this.headerBackgroundUpd, onUpdateParams: [this.$refs.serviceTmpl], onComplete: () => {
              eventHub.$emit("updateServicesOnClick")
              eventHub.$emit('filterUpd')
            }})
        } else {
          this.loadIn.timeScale(.15)
          axios.get('/api' + link)
            .then((response) => {
              this.serviceTmplValue = response.data
              this.activeService.isUp = this.activeService.isUp || true
              this.activeService.backButton = true
              eventHub.$emit("updateServicesOnClick")
              eventHub.$emit('filterUpd')
              setTimeout(() => eventHub.$emit('loadService'), 500)
            })
            .catch((error) => {
                console.log(error)
                this.$router.push('/404')
            });
        }
      },
      loadPageAdaptive(event, link) {
        axios.get('/api' + link)
          .then((response) => {
            this.serviceTmplValue = response.data
            this.activeService.isUp = true
          })
          .catch((error) => {
              console.log(error)
              this.$router.push('/404')
          });
      },
      enterDesctop(el, done) {
        const resumeLoadingAnimation = () => {
          this.loadIn.timeScale(1)
        }
        const completeEnter = () => {
          eventHub.$emit("updateServicesOnClick")
          done()
        }
        let tl = gsap.timeline({onComplete: completeEnter})
        tl
          .call(resumeLoadingAnimation)
          .to(el, {xPercent: -110, duration: 0})
          .to(el, {xPercent: 0, ease: "power1", duration: .5, onUpdate: function() {
            if (this._targets[0].classList.contains('section__wrapper__newpage')) {
              eventHub.$emit('serviceBackgroundUpdate', {type: 'slide', ele: this._targets[0].getBoundingClientRect().x, nmber: Math.round(this._targets[0].getBoundingClientRect().x + this._targets[0].getBoundingClientRect().width)})
            }
          }})
      },
      leaveDesctop(el, done) {
        let tl = gsap.timeline({onComplete: done})
        tl.to(el, {xPercent: -180, duration: .5, onUpdate: function() {
          if (this._targets[0].classList.contains('section__wrapper__newpage')) {
            eventHub.$emit('serviceBackgroundUpdate', {type: 'slide', ele: this._targets[0].getBoundingClientRect().x, nmber: Math.round(this._targets[0].getBoundingClientRect().x + this._targets[0].getBoundingClientRect().width)})
          }
        }})
      },
      backButtonEnter(el, done) {
        let tl = gsap.timeline({onComplete: () => {
          done()
        }})
        this.arrowAdjusting
          .to(this.$refs.arrow, {height: this.letstest, maxHeight: this.letstest, duration: 0})
          .to(this.$refs.arrowMiddle, {height: () => {
            return this.letstest - 190
          }, duration: 1, delay: 0.3})
        tl
          .to('.submenu-item', {y: 60, duration: 1, ease: 'none'})
          .fromTo(el, {height: 0, y: -60, opacity: 0}, {height: 32, y: 0, opacity: 1, duration: 1, ease: 'none'}, "<")
      },
      backButtonLeave(el, done) {
        let menuListSelectors = gsap.utils.selector(this.$refs.menuList);
        this.loadOut.to(menuListSelectors('li'), {background: 'linear-gradient(to right, var(--color4) 0%, var(--color4) 0%, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 100%)', duration: .3})
        let tl = gsap.timeline({onComplete: () => {
          eventHub.$emit('filterUpd')
          done()
        }})
        this.arrowAdjusting
          .to(this.$refs.arrow, {height: this.letstest, maxHeight: this.letstest, duration: 0})
          .to(this.$refs.arrowMiddle, {height: () => {
            return this.letstest - 100
          }, duration: 1, delay: 0.3})
        tl
          .to(el, {height: 0, y: -60, opacity: 0, duration: 1, ease: 'none'})
          .to('.submenu-item', {y: 0, duration: 1, ease: 'none'}, "<")
      },
      enterAdp(el, done) {
        let tl = gsap.timeline({onComplete: () => {
          eventHub.$emit("updateServicesOnClick")
          done()
          }, onStart: () => {
          if (document.querySelector('.external-list-item__head')) {
            this.currentLi = false
            gsap.set('.external-list-item__head', {background: 'linear-gradient(94.5deg, #E62315 22.96%, #FFC52F 100%)', duration: 0})
          }
        }})
        if (el.classList.contains('AKOne')) {
          tl
            .to(el, {yPercent: -35, opacity: 0, duration: 0})
            .to(el, {yPercent: 0, opacity: 1, ease: "power1", duration: .5})
        } else {
          tl
            .to(el, {xPercent: -75, opacity: 0, duration: 0})
            .to(el, {xPercent: 0, opacity: 1, ease: "power1", duration: .5, onUpdate: function() {
              eventHub.$emit('serviceBackgroundUpdate', {type: 'slide', ele: this._targets[0].getBoundingClientRect().x, nmber: Math.round(this._targets[0].getBoundingClientRect().x + this._targets[0].getBoundingClientRect().width)})
          }})
        }
      },
      leaveAdp(el, done) {
        let tl = gsap.timeline({onComplete: () => {
          eventHub.$emit('serviceBackgroundUpdate', {type: 'slide', nmber: 0})
          done()
        }})
        tl.to(el, {xPercent: -75, opacity: 0, duration: .5, onUpdate: function(){
          if (el.classList.contains('AKTwo')) {
            eventHub.$emit('serviceBackgroundUpdate', {type: 'slide', ele: this._targets[0].getBoundingClientRect().x, nmber: Math.round(this._targets[0].getBoundingClientRect().x + this._targets[0].getBoundingClientRect().width)})
          }
        }})
      },
      setArrowContainer(e) {
        const ulParentTop = this.$refs.menuList ? this.$refs.menuList.parentElement.getBoundingClientRect().top : 0
        const padding = this.ulTop - ulParentTop
        const {top: containerTop} = this.$refs.directions.getBoundingClientRect()
        const {top: liTop, height: liHeight} = e.closest('li').getBoundingClientRect()
        this.$refs.arrow.style.paddingTop = this.$refs.arrow.classList.contains('arrow__container__sticky') ? `${padding}px` : '10px'
        this.$refs.arrow.style.maxHeight = `${liTop + liHeight/2 - containerTop - padding}px`
        return liTop + liHeight/2 - containerTop
      },
      drawArrow(e) {
        const setarrow = this.setArrowContainer(e)
        this.letstest = setarrow
        this.arrowTl
          .to(this.$refs.arrow, {height: this.letstest, maxHeight: this.letstest, duration: 0})
          .to(this.$refs.arrowBottom, {width: 72, opacity: 1, duration: .3, ease: 'none', onStart: () => {
            this.$refs.arrowTop.classList.remove('asadqdqd')
          }})
          .to(this.$refs.arrowBottom, {height: 50, duration: .15, ease: 'none'})
          .to(this.$refs.arrowMiddle, {opacity: 1, duration: 0})
          .to(this.$refs.arrowMiddle, {height: this.letstest - 20, duration: .5, ease: 'none'})
          .to(this.$refs.arrowTop, {opacity: 1, duration: 0})
          .to(this.$refs.arrowTop, {height: 50, width: 72, duration: .3, ease: 'none', onComplete: () => {
            this.$refs.arrowTop.classList.add('asadqdqd')
          }})
      },
      toggleListElement(ev, i) {
        this.currentLi = i
        // document.querySelectorAll('.internal-list').forEach(i => {
        //   i.classList.remove('list-toggle_open')
        // })
        // if (ev.target && ev.target.closest('div')) {
          // this.qqe = ev.target.closest('div').nextElementSibling
          // ev.target.closest('div').nextElementSibling.classList
        // }
      },
      adaptiveListEnter(el, done) {
        this.tladaptiveListEnter = gsap.timeline({ease: "power0", onComplete: done})
        let $ul = gsap.utils.selector(el.parentElement)
        let $itemHeader = gsap.utils.selector(el.parentElement.previousElementSibling)
        let totalHeight = $ul('li').reduce((h,i) => {
          return h + i.offsetHeight + 8
        }, 12)
        let $headerText = $itemHeader('.external-list-item__text')
        this.tladaptiveListEnter
          .to(el.parentElement, {height: totalHeight, duration: .3})
          .to(el.parentElement.previousElementSibling, {background: 'linear-gradient(94.5deg, #fff 0%, #fff 100%)', duration: .1}, '-=.3')
          .to($headerText, {color: '#E62315', duration: .1}, '-=.3')
          .to(el.closest('.external-list-item'), {border: '1px solid #E62315', duration: .1}, '-=.3')
      },
      adaptiveListLeave(el, done) {
        let $itemHeader = gsap.utils.selector(el.parentElement.previousElementSibling)
        let $headerText = $itemHeader('.external-list-item__text')
        let tl = gsap.timeline({onComplete: done})
        tl
          .to(el.parentElement, {height: 0, duration: .3})
          .to(el.parentElement.previousElementSibling, {background: 'linear-gradient(94.5deg, #E62315 22.96%, #FFC52F 100%)', duration: .1}, '-=.3')
          .to($headerText, {color: '#fff', duration: .1}, '-=.3')
          .to(el.closest('.external-list-item'), {border: '1px solid #fff', duration: .1}, '-=.3')
      },
      backButtonEvent() {
        eventHub.$emit('aboutOnBeforeLeave')
        if(this.isFromSearch) {
          this.isFromSearch = false
        }
        this.arrowAdjusting.clear()
        this.linkCurrent = -1
        this.activeService.isUp = false
        this.activeService.backButton = false
        this.evt = null
      }
    }
  }
</script>
<style lang="scss" scoped>
.cropped {
  overflow: hidden;
}
.serviceItemIsUp {
  padding: 0;

  .casses__inner.section {
    @include largePhonesAlt {
      padding-bottom: 55px;
    }
  }
}
.section__wrapper {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
  height: fit-content;
  min-height: calc(100vh - 213px);
  @include mediumLaptopAlt {
    min-height: calc(100vh - 126px);
  }
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  &__newpage {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: calc(50% - 82px);
    box-sizing: border-box;
    padding: 0;
    background-color: #FFEC94;
  }
  &__directions {
    position: relative;
    box-sizing: border-box;
    padding: 28px 0;
    width: calc(50% - 82px);
    max-height: calc(100vh - 213px);
    min-height: 578px;
    // min-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(94.5deg, #E62315 0%, #FBFF37 100%);
    border-radius: 20px;
    h1 {
      display: inline-flex;
      box-sizing: border-box;
      padding: 0 20px;
      color: #fff;
      font-size: 13px;
      font-weight: 400;
    }
    ul {
      display: inline-flex;
      flex-direction: column;
      li {
        overflow: hidden;
        position: relative;
        box-sizing: border-box;
        padding: 0 20px;
        width: 100%;
        height: 90px;
        display: inline-flex;
        align-items: flex-start;
        justify-content: space-between;
        transition: all .3s ease;
        cursor: pointer;
        hr {
          position: absolute;
          top: 0;
          left: 10px;
          margin: 0;
          padding: 0;
          width: calc(100% - 20px);
          height: 1px;
          border: 0;
          background-color: #fff;
        }
        h2 {
          display: inline-flex;
          font-weight: 400;
          color: #fff;
          text-transform: uppercase;
          width: calc(100% - 64px);
          text-align: left;
          font-size: 4vw;
          line-height: 100%;
          @media screen and (min-width: $secondBreakpointExtraExtraLarge) {
            font-size: 69px;
          }
        }

        span {
          display: inline-flex;
          font-weight: 400;
          color: #fff;
          text-transform: uppercase;
          font-size: 13px;
          line-height: 2vw;

          @media screen and (min-width: $secondBreakpointExtraExtraLarge) {
            line-height: 36px;
          }
        }

        &.section-li-active {
          height: 112px;
          background-color: #fff;
          span,
          h2 {
            color: var(--color3);
          }
        }
        &:hover {
          background-color: #fff;
          height: 112px;
          span,
          h2 {
            color: var(--color3);
          }
        }
      }
    }
  }
  &__arrow {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 164px;
    min-width: 164px;
    height: calc(100vh - 93px);
    &__container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      height: fit-content;
      max-height: calc(100vh - 93px);
      .arrow__container {
        &_top, &_bottom, &_middle {
          opacity: 0;
          box-sizing: border-box;
          overflow: hidden;
        }
        &_top, &_bottom {
          position: relative;
          overflow: visible;
          display: inline-flex;
          height: 10px;
          border-radius: 0;
        }
        &_top {
          // align-self: flex-end;
          margin-left: 81px;
          border-top: 1px solid var(--color4);
          border-left: 1px solid var(--color4);
          border-top-left-radius: 20px;
          &.asadqdqd {
            &:after {
              position: absolute;
              top: -4px;
              right: 0;
              width: 6px;
              height: 6px;
              border-right: 1px solid var(--color4);
              border-bottom: 1px solid var(--color4);
              transform: rotate(-45deg);
              content: '';
            }
          }
        }
        &_bottom {
          margin-left: 10px;
          border-bottom: 1px solid var(--color4);
          border-right: 1px solid var(--color4);
          border-bottom-right-radius: 20px;
          &:after {
            position: absolute;
            bottom: -10px;
            left: 0;
            width: 20px;
            height: 20px;
            background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.99754 0.520338V0.520363L10.0025 0.520338L13.9346 0.501025L16.7014 3.29513L16.7014 3.29515L16.7049 3.2986L19.499 6.0654L19.4796 9.99754L19.4797 10.0025L19.499 13.9346L16.7049 16.7014L16.7049 16.7014L16.7014 16.7049L13.9346 19.499L10.0025 19.4796L9.99754 19.4797L6.0654 19.499L3.2986 16.7049L3.29862 16.7049L3.29513 16.7014L0.501025 13.9346L0.520338 10.0025H0.520362L0.520338 9.99754L0.501025 6.0654L3.29513 3.2986L3.29515 3.29862L3.2986 3.29513L6.0654 0.501025L9.99754 0.520338Z' fill='white' stroke='%23FFD200'/%3E%3C/svg%3E");
            content: '';
          }
        }
        &_middle {
          align-self: center;
          width: 100px;
          box-sizing: border-box;
          display: inline-flex;
          align-items: flex-end;
          background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 49px, var(--color4) 49px, var(--color4) 50px, rgba(255,255,255,0) 50px, rgba(255,255,255,0) 100%);
        }
      }
    }
    &_blue {
      .arrow__container_top {
        border-top: 1px solid var(--color1);
        border-left: 1px solid var(--color1);
        &.asadqdqd {
          &:after {
            border-right: 1px solid var(--color1);
            border-bottom: 1px solid var(--color1);
          }
        }
      }
      .arrow__container_bottom {
        border-bottom: 1px solid var(--color1);
        border-right: 1px solid var(--color1);
        &:after {
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.99754 0.520338V0.520363L10.0025 0.520338L13.9346 0.501025L16.7014 3.29513L16.7014 3.29515L16.7049 3.2986L19.499 6.0654L19.4796 9.99754L19.4797 10.0025L19.499 13.9346L16.7049 16.7014L16.7049 16.7014L16.7014 16.7049L13.9346 19.499L10.0025 19.4796L9.99754 19.4797L6.0654 19.499L3.2986 16.7049L3.29862 16.7049L3.29513 16.7014L0.501025 13.9346L0.520338 10.0025H0.520362L0.520338 9.99754L0.501025 6.0654L3.29513 3.2986L3.29515 3.29862L3.2986 3.29513L6.0654 0.501025L9.99754 0.520338Z' fill='white' stroke='%23001d82'/%3E%3C/svg%3E");
        }
      }
      .arrow__container_middle {
        background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 49px, var(--color1) 49px, var(--color1) 50px, rgba(255,255,255,0) 50px, rgba(255,255,255,0) 100%);
      }
    }
  }
  &__menu {
    width: calc(50% - 82px);
    ul {
      li {
        overflow: hidden;
        position: relative;
        padding: 8px 16px;
        display: flex;
        width: fit-content;
        height: auto;
        border: 1px solid var(--color1);
        border-radius: 60px;
        cursor: pointer;
        h3 {
          font-size: 24px;
          margin: 0;
          text-transform: none;
          font-weight: 400;
          color: var(--color1);
        }
        &:not(:last-child) {
          margin-bottom: 8px;
        }
        &:not(.zxcxzc) {
          &:hover {
            &:before {
              display: flex;
            }
          }
        }
        &:before {
          z-index: -1;
          position: absolute;
          top: 0;
          left: 0;
          display: none;
          background-color: #fff1b5;
          width: 100%;
          height: 100%;
          content: '';
        }
      }
    }
    &__container {
      box-sizing: border-box;
      padding-top: 10px;
      display: flex;
    }
  }
  @include mediumLaptopAlt {
    flex-direction: column;
    h1 {
      padding-top: 8px;
      margin-top: 24px;
      color: var(--color1);
      font-size: 13px;
      border-top: 1px solid var(--color1);
    }

    .subtitle-list {
      margin-top: 24px;
    }
    .external-list-item {
      box-sizing: border-box;
      margin-bottom: 4px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      border: 1px solid #fff;
      border-radius: 10px;
      &__head {
        box-sizing: border-box;
        padding: 0 12px;
        width: 100%;
        height: fit-content;
        min-height: 64px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px;
        cursor: pointer;
        // background: linear-gradient(94.5deg, #E62315 22.96%, #FFC52F 100%);
      }
      &__text {
        font-size: 24px;
        font-weight: 400;
        color: #fff;
        text-transform: uppercase;
      }
      &__icon {
        width: 45px;
        height: 45px;
        background-image: url("data:image/svg+xml,%3Csvg width='45' height='45' viewBox='0 0 45 45' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='22.5' cy='22.5' r='22' stroke='white'/%3E%3Cpath d='M22.5 10L22.5 35.0002' stroke='white'/%3E%3Cpath d='M20 22.5H10' stroke='white'/%3E%3Cpath d='M35 22.5H25' stroke='white'/%3E%3C/svg%3E");
        background-size: cover;
        transition: all .5s ease;
      }
    }
    .internal-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 12px;
      height: 0;
      &-item {
        max-width: 100%;
        box-sizing: border-box;
        padding: 0 12px;
        display: inline-flex;
        align-items: center;
        border: 1px solid var(--color1);
        border-radius: 10px;
        min-height: 35px;
        height: auto;
        margin-bottom: 8px;
        cursor: pointer;
        >h3 {
          overflow: hidden;
          margin: 0;
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-transform: none;
          font-size: 16px;
          font-weight: 400;
          color: var(--color1)
        }
      }
    }
    .aqwezxc {
      height: max-content;
    }
    .external-list-item-active {
      .external-list-item__head {
        cursor: default;
      }
      h2 {
        color: var(--color3);
      }
      .external-list-item__icon {
        background-image: url("data:image/svg+xml,%3Csvg width='45' height='45' viewBox='0 0 45 45' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='22.5' cy='22.5' r='22' fill='%23FFD200' stroke='%23FFD200'/%3E%3Cpath d='M22.5 10L22.5 35.0002' stroke='%23001D82'/%3E%3Cpath d='M20 22.5H10' stroke='%23001D82'/%3E%3Cpath d='M35 22.5H25' stroke='%23001D82'/%3E%3C/svg%3E");
        transform: rotate(45deg);
      }
    }
  }
}
</style>