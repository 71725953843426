export default {
    state: {
        pages: {
            "Index": false,
            "Solutions": false,
            "Services": false,
            "Products": false,
            "License": false,
            "Career": false,
            "Events": false,
            "Contacts": false,
            "Tender": false
        }
    },

    mutations: {
        setAnimationState(state, routeName) {
            state.pages[routeName] = true
        }
    },

    getters: {
        animationPages(state) {
            return state.pages
        }
    }
}