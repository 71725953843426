<template>
    <li class="product" v-show="isVisible">
        <template v-if="widthPage >= 760">
          <div class="product-logo">
            <img :src="product.logo" :alt="product.name">
          </div>
          <div class="product-body">
            <div class="product-description">
              <h3 class="product-name">{{product.name}}</h3>
              <p>{{product.description}}</p>
            </div>
            <ul class="product-tags">
              <li class="filter-item" v-for="(filter,i) in product.tags" :key="i">
                <button @click="addFilter" class="button dark" :name="filter.name"
                        v-bind:class="{active:visibleFilter(filter)}"><span>{{filter}}</span></button>
              </li>
            </ul>
          </div>
        </template>
        <template v-else>
          <div class="product-logo">
            <img v-if="product.logo" :src="product.logo" :alt="product.name">
            <div class="product-description">
              <h3 class="product-name">{{product.name}}</h3>
              <p>{{product.description}}</p>
            </div>
          </div>
          <div class="product-body">
          </div>
          <ul class="product-tags">
            <li class="filter-item" v-for="(filter,i) in product.tags" :key="i">
              <button @click="addFilter" class="button dark" :name="filter.name"
                      v-bind:class="{active:visibleFilter(filter)}"><span>{{filter}}</span></button>
            </li>
          </ul>
        </template>
        <div class="products-label">
            <img v-if="product.label" :src="product.label" alt="">
            <!-- <router-link class="button red" :to="'products/'+product.link">
              Подробнее
              <div class="button__icon"></div>
            </router-link> -->
            <router-link class="button" :to="'products/'+product.link">
                <AnimatedButton
                  :classes="['buttons-group__tag-filter']"
                  :settings="{ class: 'r2y', text: 'Подробнее', icon: true }"
                />
            </router-link>
            <!-- <router-link class="button" :to="'products/'+product.link">{{product.id}}</router-link> -->
        </div>
    </li>
</template>
<script>
import AnimatedButton from '../../UI/AB.vue'
    import {
        mapActions,
        mapGetters
    } from 'vuex'

    export default {
        components: {
          AnimatedButton
        },
        props: {
            product: null
        },
        methods: {
            ...mapActions(['fetchFilter']),
            addFilter: function (e) {
                this.fetchFilter(e.target.name)
            },
            visibleFilter: function (e) {
                if (this.activeFilter.includes(e)) {
                    return true
                } else {
                    return false
                }
            }
        },
        computed: {
            ...mapGetters(['activeFilter', 'widthPage']),
            isVisible: function () {
                if (this.activeFilter.length === 0) {
                    return true
                }

                for (let i = 0; i < this.product.tags.length; i++) {
                    if (this.activeFilter.includes(this.product.tags[i])) {
                        return true
                    }
                }
                return false
            }
        }
    }
</script>
<style lang="scss" scoped>
    .product {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--color1);
        padding-top: var(--mainPadding);
        padding-bottom: var(--mainPadding);

        &:first-child {
            border-top: 1px solid var(--color1);
        }

        @include mediumPhones {
          flex-flow: column;
        }
    }

    .product-logo {
        max-width: 340px;
        width: 25%;
        max-height: 200px;
        img {
            width: 100%;
            max-height: 100%;
            object-fit: contain;
            @include mediumPhones {
              width: 30%;
              height: 50%;
            }
        }

        @include largePhonesAlt{
          max-height: unset;
        }

        @include mediumPhones {
          width: 100%;
          max-width: unset;
          display: flex;
          column-gap: 20px;
        }
    }

    .product-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 40%;

        @include mediumPhones {
          margin-bottom: 24px;
          flex-flow: row;
          width: 100%;
        }
    }

    .product-name {
        font-size: 60px;
        line-height: 140%;
        color: var(--color1);
        margin: 0;
        text-transform: uppercase;

        @include smallLaptop {
          font-size: 40px;
        }

        @include mediumPhones {
          font-size: 20px;
        }
    }

    .product-description {
        display: flex;
        flex-direction: column;
        flex: 1;

        p {
            font-size: 18px;
            line-height: 160%;
            color: var(--color1);

            @include mediumPhones {
              margin-bottom: 0;
            }
        }

        @include mediumPhones {
          width: 100%;

          p {
            font-size: 16px;
          }
        }
    }

    .product-tags {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;

        @include mediumPhones {
          margin-bottom: 24px;
        }
    }

    .products-label {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 163px;

        img {
          max-width: 160px;
          margin-left: auto;
          @include smallLaptop {
            margin-left: unset;
            margin-right: auto;
            margin-bottom: 24px;
            margin-top: 24px;
          }
          @include mediumPhones {
            margin-top: 0;
          }
        }

        a {
            padding: 0;
            margin-bottom: 5px;
            button {
                width: 240px !important;
                background: none;
                border: none;
                font-size: 13px;
                line-height: 15px;
                text-transform: uppercase;
                color: var(--color1);
                min-width: unset;
                padding: 6px 15px;
                cursor: pointer;
            }
            div {
              width: 100%;
              .button-full-animation__r2y.buttons-group__tag-filter::before {
                width: 100%;
              }
            }
        }

        @include mediumPhones {
          img {
            max-width: 82px;
            max-height: 82px;
          }
        }
    }

    .button {
        font-size: 13px;
        line-height: 15px;
        text-transform: uppercase;
        color: var(--color1);
        min-width: unset;
        display: flex;
        justify-content: center;

        &.red {
          width: 240px;
          color: var(--color3)
        }

        span {
            padding: 6px 15px;
            pointer-events: none;
            white-space: nowrap;
        }

        &.active {
            background-color: var(--color4);
        }

        @include smallLaptop {
          &.red {
            width: 100%;
          }
        }

        @include mediumPhones {
          &.dark {
            padding: 6px 12px;
            span {
              font-size: 10px;
            }
          }
        }

        @include phones {
          &.dark {
            padding: 6px 12px;
            max-height: fit-content;
            height: fit-content;
            span {
              white-space: unset;
              padding: 0;
            }
          }
        }
    }

    .filter-item {
        margin-right: 5px;
        margin-bottom: 5px;
        pointer-events: none;
    }
</style>

<style lang="scss">
    .products-label {
      a {
        padding: 0;
        margin-bottom: 5px;
        button {
          width: 240px !important;
          background: none;
          border: none;
          font-size: 13px;
          line-height: 15px;
          text-transform: uppercase;
          color: var(--color1);
          min-width: unset;
          padding: 6px 15px;
          cursor: pointer;
        }
        div {
          width: 100%;
          .buttons-group__tag-filter {
            width: 100% !important;
            max-width: unset;
            display: flex;
            justify-content: center;
              border: 1px solid var(--color3) !important;
            &::before {
              max-width: unset;
              width: 100%;
            }
          }
        }
      }
    }
</style>
