<template>
    <div :class="['aviable-positions__list__item']">
        <div :class="['position__headline']" @click="$emit('toggle')">
            <span class="position__name">{{itemInfo.name}}</span>
            <div class="position__city">
                <span v-for="city in itemInfo.cities" :key="city.code">{{city.name}}</span>
            </div>
            <button class="aviable-positions__list__item__close">
                <div class="icon"></div>
            </button>
        </div>
        <div :class="['position__container']">
            <div v-html="itemInfo.description"></div>
            <!-- <AnimatedButton :classes="['buttons-group__tag-filter', 'button-red']" :settings="{class: 'w2y', text: 'откликнуться', icon: false}" @click="toggleModal(itemInfo.id)" /> -->
            <AnimatedButton :classes="['buttons-group__tag-filter', 'button-red']" :settings="{class: 'w2y', text: 'откликнуться', icon: false}" @click="toggleModalWindow(itemInfo.id, 'positionCallback')" />
        </div>
    </div>
</template>

<script>
import eventHub from '../eventHub'
import AnimatedButton from '../UI/AB.vue'

export default {
    name: 'careerAccordionItem',
    components: {
        AnimatedButton
    },
    props: {
        itemInfo: Object,
        itemIndex: Number,
        quoteModal: Object,
        content: Object
    },
    data() {
        return {
            formFileds: [
                {type: "text", placeholder: "Имя*", required: true},
                {type: "text", placeholder: "Телефон", required: false, masked: '+7 (###) ###-##-##'},
                {type: "email", placeholder: "Email*", required: true},
                {type: "textarea", placeholder: "Текст сопроводительного письма*", required: true},
                {type: "file", required: false}
            ]
        }
    },
    methods: {
        toggle(q) {
            this.activeEl = q
        },
        toggleModalWindow(qqq, bebebe) {
            this.quoteModal.visible = true
            this.quoteModal.personId = qqq
            eventHub.$emit('emitModal', { formFileds: this.formFileds, content: this.content, modalType: bebebe, quoteModal: this.quoteModal})
            // document.querySelector('body').style.overflow = 'hidden'
        },
        // toggleModal(id) {
        //     this.quoteModal.visible = true
        //     this.quoteModal.personId = id
        // }
    },
    mounted() {
        if(this.itemInfo && this.itemInfo.description) {
            const regExp = new RegExp('(<a.+)(href=")(?!' + window.location.origin + ')', 'gm')
            // eslint-disable-next-line
            this.itemInfo.description = this.itemInfo.description.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
        }
    },
    emits: ['toggle']
}
</script>

<style lang="scss" scoped>
.aviable-positions__list__item {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid var(--color1);
    border-radius: 20px;
    margin-bottom: 10px;
    &__close {
        position: relative;
        display: flex;
        width: 45px;
        height: 45px;
        background-color: var(--color4);
        border-radius: 50%;
        outline: none;
        border: none;
        >.icon {
            width: 45px;
            height: 45px;
            margin: auto;
            background-image: url("data:image/svg+xml,%3Csvg width='37' height='36' viewBox='0 0 37 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M27.3389 8.98096L9.66101 26.6587' stroke='%23001D82'/%3E%3Cpath d='M16.7322 16.0522L9.66113 8.98096' stroke='%23001D82'/%3E%3Cpath d='M27.3389 26.6587L20.2678 19.5874' stroke='%23001D82'/%3E%3C/svg%3E");
            background-size: 37px 36px;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
    &.is-visible {
        border: 1px solid var(--color4);
        .position__container {
            transition: all .3s linear;
            height: 100%;

            & > div:last-child {
                margin-bottom: 60px;
                @include largePhonesAlt {
                    margin-bottom: 20px;
                }
            }

            @include largePhonesAlt {
                padding: 0 24px;
            }
        }
        .aviable-positions__list__item__close {
            background-color: var(--color1);
            >.icon {
                background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.5 0L12.5 25.0002' stroke='white'/%3E%3Cpath d='M10 12.5L0 12.5' stroke='white'/%3E%3Cpath d='M25 12.5L15 12.5' stroke='white'/%3E%3C/svg%3E");
                background-size: 25px 25px;
            }
        }
    }
    .position__headline {
        box-sizing: border-box;
        padding: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
    }
    .position__name {
        position: relative;
        font-size: 22px;
        text-transform: uppercase;
        color: var(--color1);
        word-wrap: break-word;
        margin-right: 10px;
        max-width: 80%;
        &:before {
            position: relative;
            margin-right: 32px;
            width: 11px;
            height: 24px;
            content: '/';
        }
    }
    .position__city {
        margin-left: auto;
        color: var(--color1);
        font-size: 13px;
        text-transform: uppercase;
        margin-right: 23px;

        display: flex;
        column-gap: 7px;

        span {
            &::after {
                content: '/';
                margin-left: 7px;
            }

            &:last-child {
                &::after {
                    content: '';
                }
            }
        }

        @include mediumLaptop {
            margin-top: 10px;
            flex-wrap: wrap;
            row-gap: 10px;
        }
    }
    .position__container {
        box-sizing: border-box;
        padding: 0 185px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        height: 0;
        transition: all .3s linear;
        ::v-deep p {
            color: var(--color1);
            font-size: 18px;
            text-transform: uppercase;
            margin: 48px 0 32px;
        }
        ::v-deep ul {
            list-style-type: none;
            box-sizing: border-box;
            padding: 0;
            list-style-position: inside;
            font-size: 18px;
            text-transform: uppercase;
            color: var(--color1);
            margin: 0;
            li {
                font-size: 16px;
                margin-bottom: 16px;
                text-transform: none;
                padding-left: 49px;
                position: relative;

                &:before {
                    content: '';
                    width: 9px;
                    height: 11px;
                    position: absolute;
                    top: 4px;
                    left: 0;
                    background-repeat: no-repeat;
                    background-size: 100%;
                    background-position: center;
                    background-image: url("data:image/svg+xml,%3Csvg width='9' height='11' viewBox='0 0 9 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 5.5L4.72849e-07 11L9.53674e-07 -3.93402e-07L9 5.5Z' fill='%23E62315'/%3E%3C/svg%3E%0A");
                }

                @include smallDesktop {
                    padding-left: 20px;
                }
            }
        }
    }
    @include mediumLaptop {
        .position__headline {
            position: relative;
            padding-right: 65px;
            flex-direction: column;
            align-items: flex-start;
            min-height: 84px;
        }
        .position__city {
            margin-left: 0;
        }
        &__close {
            position: absolute;
            top: 20px;
            right: 10px;
        }
    }
    @include smallLaptop {
        .position__container {
            padding: 0 46px;
        }
    }
    @include largePhonesAlt {
        .position__name {
            font-size: 18px;
        }
    }
    @include smallPhonesAlt {
        &.is-visible {
            .aviable-positions__list__item__close {
                background-color: red;
            }
        }
        .position__name {
            font-size: 16px;
            &:before {
                margin-right: 10px;
            }
        }
        .position__city {
            >span {
                font-size: 10px;
            }
        }
        .position__container {
            padding: 0 24px;
            ::v-deep p {
                font-size: 14px;
            }
            ::v-deep ul {
                font-size: 14px;
            }
        }
    }
}
</style>
