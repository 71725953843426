<template v-if="value">
    <section>
        <hr class="animate-elem shiftLeft wrap"/>
        <h1 class="line animate-elem shiftUp wrap" v-if="value.name">{{value.name}}</h1>
        <div class="description-wrapper">
            <span class="arrow animate-elem fadeInLeft" v-if="value.description"></span>
            <p class="description animate-elem fadeInUp" v-if="value.description" v-html="value.description"></p>
        </div>
        <div class="answer-wrapper">
            <!-- <span class="arrow animate-elem fadeInLeft" v-if="value.answer"></span> -->
            <p class="answer animate-elem fadeInUp" v-if="value.answer" v-html="value.answer"></p>
        </div>
        <ListBlocks v-if="value.items" :value="value.items"/>
    </section>
</template>
<script>
    import { mapGetters } from 'vuex'
    import ListBlocks from './listBlocks.vue'

    export default {
        components: {
            ListBlocks
        },
        props: {
            value: null,
        },
        computed: mapGetters(['widthPage']),
        data() {
            return {
                flag: false
            }
        },
        mounted() {
            // FIXME Throws errors
            if(this.value) {
                const regExp = new RegExp('(<a.+)(href=")(?!' + window.location.origin + ')', 'gm')
                if(this.value.description) {
                    // eslint-disable-next-line
                    this.value.description = this.value.description.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
                }

                if(this.value.answer) {
                    // eslint-disable-next-line
                    this.value.answer = this.value.answer.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
                }

                if(this.value.items && Array.isArray(this.value.items)) {
                    this.value.items = this.value.items.map((item) => {
                        if(item && item.name && typeof item.name === 'string') {
                            // eslint-disable-next-line
                            item.name = item.name.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
                            return item
                        }
                        if(item && typeof item === 'string') {
                            // eslint-disable-next-line
                            item = item.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
                            return item
                        }
                    })
                }

                if(this.value.items && !Array.isArray(this.value.items)) {
                    this.value.items = this.value.items.map((item) => {
                        // eslint-disable-next-line
                        item = item.replace(regExp, '$1 rel="nofollow" target="_blank" $2')
                        return item
                    })
                }
            }
          if (this.widthPage <= 960) {
            this.flag = document.querySelector('.section__solution .content-container section:first-of-type .line') === this.$el.querySelector('.line')
          }
        },
        watch: {
          widthPage() {
            if (this.widthPage <= 960) {
              this.flag = document.querySelector('.section__solution .content-container section:first-of-type .line') === this.$el.querySelector('.line')
            } else this.flag = false
          }
        }
    }
</script>
<style lang="scss" scoped>
    section {
        position: relative;

        hr {
            border-top: 1px solid var(--color1);
            margin: 0;
        }

        h1{
            padding-top: 5px;
            font-size: var(--h2-size);
            line-height: 110%;
            color: var(--color1);
            margin: 0;
            text-transform: uppercase;

            @include laptop {
                font-size:80px;
            }

            @include phones{
                padding-top: 10px;
            }

            @include smallLaptop {
                font-size:36px;
            }

            @include largePhonesAlt{
                font-size:24px;
            }
        }
        @include largePhonesAlt{
            margin-top: 48px;
        }
    }

    .arrow {
        position: absolute;
        width: 71px;
        height: 90px;
        top: -20px;
        left: 0;
        transform: translateY(20%);
        background-image: url("data:image/svg+xml,%3Csvg width='73' height='94' viewBox='0 0 73 94' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M72.3536 90.3536C72.5488 90.1583 72.5488 89.8417 72.3536 89.6464L69.1716 86.4645C68.9763 86.2692 68.6597 86.2692 68.4645 86.4645C68.2692 86.6597 68.2692 86.9763 68.4645 87.1716L71.2929 90L68.4645 92.8284C68.2692 93.0237 68.2692 93.3403 68.4645 93.5355C68.6597 93.7308 68.9763 93.7308 69.1716 93.5355L72.3536 90.3536ZM0.5 0V80H1.5V0H0.5ZM11 90.5H72V89.5H11V90.5ZM0.5 80C0.5 85.799 5.20101 90.5 11 90.5V89.5C5.7533 89.5 1.5 85.2467 1.5 80H0.5Z' fill='%23001D82'/%3E%3C/svg%3E%0A");
        background-size: 100%;
        background-position: center;

        @include largePhonesAlt{
            display: none;
        }
    }

    .answer-wrapper,
    .description-wrapper {
        position: relative;
    }
    .description {
        position: relative;
        font-size: 36px;
        line-height: 130%;
        color: var(--color1);
        padding-left: 91px;
        margin-top: 80px;
        margin-bottom: 0px;
        padding-bottom: 40px;
        overflow: hidden;

        @include largePhonesAlt {
            margin-top: 24px;
            margin-bottom: 24px;
            padding-left: 0;
            font-size: 24px;

            &:before {
                display: none;
            }
        }
    }

    .answer {
        font-size: 18px;
        line-height: 160%;
        color: var(--color1);
        margin: 0;
        margin-left: auto;
        margin-right: 0;
        width: 60%;

        @include largePhonesAlt {
            width: 100%;
        }
    }
</style>