export default function eventsAnimation(timeline) {
  timeline
    .set("#filter", {
      xPercent: -50,
      opacity: 0
    })
    .set(".about__events__event-list", {
      xPercent: 50,
      opacity: 0
    })
    .to("#filter", {
      xPercent: 0,
      opacity: 1
    })
    .to(
      ".about__events__event-list",
      {
        xPercent: 0,
        opacity: 1
      },
      "<50%"
    );
}
